import SimpleModal from "app/components/Modals/SimpleModal";
import { useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import SmallTeam360Card from "app/containers/Dashboard/Cards/SmallTeam360Card";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onPickInstance: (payload: {
    instanceId: number;
    teamId?: number;
    departmentId?: number;
  }) => void;
  teamIds?: number[];
  departmentIds?: number[];
  instanceId?: number;
}

export default function PickInstanceFromMultipleModal({
  isOpen,
  onClose,
  teamIds,
  departmentIds,
}: Readonly<Props>) {
  // ------------------------- States ----------------------------------//
  const [type, setType] = useState<"team" | "department">("team");

  // ------------------------- Effect ----------------------------------//
  useEffect(() => {
    if (teamIds?.length) setType("team");
    else if (departmentIds?.length) setType("department");
  }, [type, teamIds, departmentIds]);

  const getModalBody = () => {
    if (teamIds?.length) {
      return teamIds.map((teamId) => <SmallTeam360Card teamId={teamId} />);
    }
    return getResultsCard("Departments", departmentIds?.length ?? 0, 1);
  };

  const getResultsCard = (title: string, memberCount: number, id: number) => (
    <div>
      <h5>{title}</h5>
      <p>
        {memberCount} member{memberCount === 1 ? "" : "s"}
      </p>
      <div>
        <Button variant="secondary-blue">View Results</Button>
      </div>
    </div>
  );

  return (
    <SimpleModal
      show={isOpen}
      onHide={onClose}
      title="Pick a team or department"
    >
      {getModalBody()}
    </SimpleModal>
  );
}
