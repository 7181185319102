import Button from "app/storybookComponents/Button";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { BlindSpot } from "./types";
import SectionHeader from "./SectionHeader";

interface Props {
  readonly blindSpots: BlindSpot[];
}

export default function TeamBlindSpotReport({ blindSpots }: Props) {
  const [blindSpotIndex, setBlindSpotIndex] = useState(0);
  const blindSpot = blindSpots[blindSpotIndex];
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const setNewBlindSpotIndex = () => {
    if (!blindSpots.length) {
      return 0;
    }
    const newIndex = (blindSpotIndex + 1) % blindSpots.length;

    setBlindSpotIndex(newIndex);
  };

  if (!blindSpots.length) {
    return null;
  }

  if (isCollapsed) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Team Blindspot Report"
          isCollapsed={isCollapsed}
        />
      </Card>
    );
  }

  let reflectionQuestionsCount = 0;
  let actionStepsCount = 0;

  return (
    <Card
      style={{
        minHeight: "550px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="column-gap-20px">
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Team Blindspot Report"
          isCollapsed={isCollapsed}
        />
        <p>Your team has {blindSpots.length} blindspots.</p>
        <div
          className="card-with-left-strip column-gap-8px dark-blue card"
          style={{ padding: "10px" }}
        >
          <div style={{ marginLeft: "10px" }}>
            <h3>{blindSpot.trait}</h3>
            <p>{blindSpot.description}</p>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Card
            style={{
              width: "49%",
              padding: "10px",
              marginRight: "10px",
              minHeight: "148px",
            }}
          >
            <h3 style={{ marginBottom: "10px" }}>Strengths</h3>
            <p>{blindSpot.strength}</p>
          </Card>
          <Card
            style={{
              width: "49%",
              padding: "10px",
              marginLeft: "10px",
              minHeight: "148px",
            }}
          >
            <h3 style={{ marginBottom: "10px" }}>Risks</h3>
            <p>{blindSpot.risk}</p>
          </Card>
        </div>
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <Card
            style={{
              width: "49%",
              padding: "10px",
              marginRight: "10px",
              minHeight: "148px",
              fontSize: "14px",
            }}
          >
            <h3 style={{ marginBottom: "10px" }}>Reflection Questions</h3>
            <ul>
              {blindSpot.reflectionQuestions.map((question) => {
                reflectionQuestionsCount += 1;
                return <li key={reflectionQuestionsCount}>{question}</li>;
              })}
            </ul>
          </Card>
          <Card
            style={{
              width: "49%",
              padding: "10px",
              marginLeft: "10px",
              minHeight: "148px",
              fontSize: "14px",
            }}
          >
            <h3 style={{ marginBottom: "10px" }}>Action Items</h3>
            <ul>
              {blindSpot.actionSteps.map((actionStep) => {
                actionStepsCount += 1;
                return <li key={actionStepsCount}>{actionStep}</li>;
              })}
            </ul>
          </Card>
        </div>
      </div>
      <Button
        onClick={setNewBlindSpotIndex}
        style={{ alignSelf: "flex-start" }}
        variant="secondary-blue"
      >
        See another blindspot
      </Button>
    </Card>
  );
}
