import { useEffect, useRef } from "react";
import { trackEvent } from "utils/trackingFunctions";

const useTrackActiveTimeInComponent = () => {
  const startTime = useRef(Date.now());

  // Event handler for visibility change
  const resetStartTime = () => {
    startTime.current = Date.now();
  };

  const onTrackEvent = () => {
    const timeSpentInSeconds = (Date.now() - startTime.current) / 1000;
    if (timeSpentInSeconds < 1) return;
    trackEvent("Time Spent", {
      timeSpentInSeconds,
      trackingTimeSpentVersion: 2,
    });
  };

  // Set up event listener for visibility change
  useEffect(() => {
    window.addEventListener("focus", resetStartTime);
    window.addEventListener("blur", onTrackEvent); // Fires when the user switches tabs or navigates away.
    window.addEventListener("beforeunload", onTrackEvent); // Fires when the user closes the tab, browser, or logs out.

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("focus", resetStartTime);
      window.removeEventListener("blur", onTrackEvent);
      window.removeEventListener("beforeunload", onTrackEvent);
    };
  }, []);

  return null;
};

export default useTrackActiveTimeInComponent;
