interface Props {
  template: {
    [key: number]: string;
  };
  isContentChecked: {
    [key: number]: boolean;
  };
  onCheck: (id: number) => void;
  checklistClassName?: string;
}

export default function Checklist({
  template = {},
  isContentChecked,
  onCheck,
  checklistClassName,
}: Readonly<Props>) {
  const getChecklistClassName = () => {
    let className = "checklist";

    if (checklistClassName) {
      className += ` ${checklistClassName}`;
    }

    return className;
  };

  const templateLength = Object.keys(template).length;
  return (
    <div className={getChecklistClassName()}>
      {Object.entries(template).map(([id, val], idx) => {
        const parsedId = Number(id);
        const isLast = idx === templateLength - 1;
        return (
          <>
            <div key={parsedId} className="row-gap-8px align-items-baseline">
              <input
                type="checkbox"
                checked={isContentChecked[parsedId]}
                onChange={() => onCheck(parsedId)}
              />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                {val}
              </span>
            </div>
            {!isLast ? <hr className="p-0 m-0" /> : null}
          </>
        );
      })}
    </div>
  );
}
