import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarCircle from "app/components/AvatarCircle";
import { UserInfo } from "app/containers/Global/types";
import { OptionProps, StylesConfig, components } from "react-select";

export const getDropdownIndicator = (
  type: "search" | "caret-down" = "search"
) => {
  const icon = type === "search" ? "search" : "caret-down";
  return (props: any) => (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={icon} />
    </components.DropdownIndicator>
  );
};

export const getUserOptionValue = (user?: UserInfo) => {
  if (!user) return null;
  const fullName =
    user?.lastName || user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.emailAddress;
  // if (fullName.trim() === "") return; // If the user has no name, don't add them to the list
  return {
    label: fullName,
    value: String(user.userAccountId),
    avatarCircle: (
      <AvatarCircle
        name={fullName}
        userAccountId={user.userAccountId}
        size="small"
      />
    ),
    emailAddress: user.emailAddress,
  };
};

// This is used for when we want to show the light blue on hover and the dark blue when selected
export const singleSelectStyleOptions: StylesConfig<
  {
    label: string;
    value: string;
  },
  false
> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    fontSize: "14px",
  }),
  option: (styles, { isFocused, isSelected }) => {
    const getBackgroundColor = () => {
      if (isSelected) return "#D9DEF4";
      if (isFocused) return "#ECEEF9";
      return undefined;
    };

    const getColor = () => {
      if (isSelected || isFocused) return "black";
      return undefined;
    };

    return {
      ...styles,
      backgroundColor: getBackgroundColor(),
      color: getColor(),
      fontSize: "14px",
    };
  },
  menu: (styles) => ({
    ...styles,
    minWidth: "200px",
  }),
};

export const getCheckmarkOption = (
  props: OptionProps<
    {
      label: string;
      value: string;
    },
    false
  >
) => (
  <components.Option {...props}>
    {props.isSelected ? (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FontAwesomeIcon icon="check" />
        {props.label}
      </div>
    ) : (
      <div
        style={{
          marginLeft: "25px",
        }}
      >
        {props.label}
      </div>
    )}
  </components.Option>
);
