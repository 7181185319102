import {
  EntityState,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  ChatbotCoachingPlan,
  ChatbotConversation,
  ChatbotMessages,
  ChatbotResponse,
  CoachbotTemplatePrompt,
  InstructionsPayload,
  RecurringChat,
} from "./types";
import { decodeStringArray } from "utils/helperFunctions";
import { chatbotConversationAdaptor } from "./adaptors";
import {
  dismissNotification,
  selectAllChatWithDirectReportNotifications,
} from "app/components/Notifications/slice";
import { getChatWithDirectReportIntroMessage } from "./helpers";

// ------------------ State Type/Structure ------------------
export interface ChatbotState {
  isCoachbotOpen: boolean;
  showFeedbackCardOnClose: boolean;
  chatbotMessages: ChatbotMessages | null;
  threadId: string | null;
  hidingChatbotButtonFromRoot: boolean;
  selectedChatbotPrompt: CoachbotTemplatePrompt | null;
  savedChatbotConversations: EntityState<ChatbotConversation>;
  activeCoachingPlan: ChatbotCoachingPlan | null;
  recurringChat: RecurringChat | null;
  deleteConversationStatusById: {
    [threadId: string]: responseStatus;
  };
  processConversationStatus: responseStatus;
  putCoachingPlanStatus: responseStatus;
  editSummaryStatus: responseStatus;
  getRecurringChatStatus: responseStatus;
  putRecurringChatStatus: responseStatus;
  getCoachingPlanStatus: responseStatus;
  sendMessageStatus: responseStatus;
  generateInstructionsStatus: responseStatus;
  submittingCoachbotFeedbackStatus: responseStatus;
  getCoachBoConversationsStatus: responseStatus;
  deleteAllConversationsStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: ChatbotState = {
  isCoachbotOpen: false,
  showFeedbackCardOnClose: false,
  chatbotMessages: null,
  threadId: null,
  hidingChatbotButtonFromRoot: false,
  selectedChatbotPrompt: null,
  activeCoachingPlan: null,
  savedChatbotConversations: chatbotConversationAdaptor.getInitialState(),
  recurringChat: null,
  deleteConversationStatusById: {},
  processConversationStatus: "idle",
  putCoachingPlanStatus: "idle",
  editSummaryStatus: "idle",
  getRecurringChatStatus: "idle",
  putRecurringChatStatus: "idle",
  getCoachingPlanStatus: "idle",
  sendMessageStatus: "idle",
  generateInstructionsStatus: "idle",
  submittingCoachbotFeedbackStatus: "idle",
  getCoachBoConversationsStatus: "idle",
  deleteAllConversationsStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const sendGeneralMessage = createAsyncThunk(
  "chatbot/sendGeneralMessage",
  async (
    {
      message,
      instructionsPayload,
    }: {
      message: string;
      instructionsPayload?: InstructionsPayload;
    },
    { dispatch, getState }
  ) => {
    const { threadId, chatbotMessages } = (getState() as RootState).chatbot;

    const messages = [
      ...(chatbotMessages || []),
      {
        role: "user",
        content: message,
      } as ChatbotMessages[0],
    ];

    dispatch(dismissChattingWithNewDirectReportIfAny());
    dispatch(setChatbotMessages(messages));
    dispatch(setShowFeedbackCardOnClose(true)); // when sending a message we want to show the feedback card on close
    if (!threadId) {
      await dispatch(generalGenerateInstructions(instructionsPayload ?? {}));
    }
    dispatch(sendMessage());
  }
);

export const generalGenerateInstructions = createAsyncThunk(
  "chatbot/generalGenerateInstructions",
  async (payload: InstructionsPayload, { getState, dispatch }) => {
    const { selectedChatbotPrompt } = (getState() as RootState).chatbot;
    if (
      selectedChatbotPrompt?.type === "teamScan" &&
      selectedChatbotPrompt.chatTypeId
    ) {
      switch (selectedChatbotPrompt?.chatType) {
        case "team":
        case "department":
        case "company":
          return await dispatch(
            generateTEAMscanInstructions({
              entityType: selectedChatbotPrompt.chatType,
              entityId: selectedChatbotPrompt.chatTypeId,
              report: selectedChatbotPrompt.report,
              comments: selectedChatbotPrompt.comments,
            })
          );
        default:
      }
    }

    // if personality report then we need to call a different endpoint,
    if (selectedChatbotPrompt?.type === "personalityReport") {
      switch (selectedChatbotPrompt?.chatType) {
        case "workplaceInsights":
        case "managerGuide":
        case "collaborationGuide":
          return await dispatch(
            generatePersonalityDiscussionInstructions({
              chatType: selectedChatbotPrompt.chatType,
              report: selectedChatbotPrompt.report,
              subjectUserAccountId: selectedChatbotPrompt.chatTypeId,
            })
          );
        case "teamPersonalityGuide":
          return await dispatch(
            generatePersonalityDiscussionInstructions({
              chatType: selectedChatbotPrompt.chatType,
              report: selectedChatbotPrompt.report,
            })
          );
        default:
      }
    }

    if (selectedChatbotPrompt?.type === "onboardingChat") {
      return await dispatch(
        getOnboardingChat(selectedChatbotPrompt.chatTypeId)
      );
    }

    if (
      selectedChatbotPrompt?.type === "chatWithDirectReport" &&
      selectedChatbotPrompt.chatTypeId
    ) {
      return await dispatch(
        getDirectReportChatThreadId(selectedChatbotPrompt.chatTypeId)
      );
    }

    const generalInstructionsPayload = { ...payload };
    if (selectedChatbotPrompt?.chatTypeId) {
      generalInstructionsPayload.chatTypeId = selectedChatbotPrompt.chatTypeId;
    }
    if (selectedChatbotPrompt?.chatType) {
      generalInstructionsPayload.chatType = selectedChatbotPrompt.chatType;
    }

    // if no special case then we call the regular generate instructions
    await dispatch(generateInstructions(generalInstructionsPayload));
  }
);

// ------------------------ GET Functions ------------------------
// This endpoint returns back the active coaching plan for the user
export const getCoachingPlan = createAsyncThunk(
  "chatbot/getCoachingPlan",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachingPlan`;
    const response = (await request(requestUrl)) as ChatbotCoachingPlan;
    if (Object.keys(response).length === 0) {
      return null;
    }
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        chatbot: { getCoachingPlanStatus },
      } = getState() as RootState;
      if (getCoachingPlanStatus === "loading") {
        return false;
      }
    },
  }
);

// This function is used to send an email to the user to remind them to complete their coaching plan
export const sendCoachingPlanEmail = createAsyncThunk(
  "chatbot/sendCoachingPlanEmail",
  async (emailType: "digest" | "recap") => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sendCoachingPlanEmail/${emailType}`;
    const response = await request(requestUrl);
    return response;
  }
);

// This endpoint call is used to retreive previous chatbot conversations
// We will then use this to pick up where the user left off if the user selects a previous conversation
export const getCoachBoConversations = createAsyncThunk(
  "chatbot/getCoachBoConversations",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachBoConversations`;
    const response = (await request(requestUrl)) as (Omit<
      ChatbotConversation,
      "messages"
    > & {
      conversation: string;
    })[];

    const mappedResponse = response.map((c) => ({
      ...c,
      messages: decodeStringArray(c.conversation) as ChatbotMessages,
    }));

    return mappedResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        chatbot: { getCoachBoConversationsStatus },
      } = getState() as RootState;
      if (getCoachBoConversationsStatus === "loading") {
        return false;
      }
    },
  }
);

export const getRecurringChat = createAsyncThunk(
  "chatbot/getRecurringChat",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/recurringChat`;
    const response = (await request(requestUrl)) as RecurringChat | {};
    if (Object.keys(response).length === 0) {
      return null;
    }

    return response as RecurringChat;
  },
  {
    condition: (_, { getState }) => {
      const {
        chatbot: { getRecurringChatStatus },
      } = getState() as RootState;
      if (getRecurringChatStatus === "loading") {
        return false;
      }
    },
  }
);

export const getOnboardingChat = createAsyncThunk(
  "chatbot/getOnboardingChat",
  async (userAccountId?: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/onboardingChat`;
    const response = (await request(requestUrl)) as { threadId: string };
    return response;
  },
  {
    condition: (userAccountId) => !!userAccountId,
  }
);

export const getDirectReportChatThreadId = createAsyncThunk(
  "chatbot/getDirectReportChatThreadId",
  async (userAccountId: string | number, { getState, dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/getDirectReportChatThreadId/${userAccountId}`;
    const response = (await request(requestUrl)) as {
      threadId: string;
    };

    dispatch(setThreadId(response.threadId));

    const savedConversation = selectChatbotConversationById(
      getState() as RootState,
      response.threadId
    );

    const { usersInfoById, currentUserAccountId } = (getState() as RootState)
      .global;

    // If no saved conversation then we will add the welcome message
    if (!savedConversation) {
      dispatch(
        setChatbotMessages([
          {
            role: "assistant",
            content: getChatWithDirectReportIntroMessage(
              usersInfoById,
              currentUserAccountId,
              userAccountId
            ),
          },
        ])
      );
    }

    if (savedConversation?.messages) {
      dispatch(setChatbotMessages(savedConversation.messages));
    }
  }
);

// ------------------------ POST Functions ------------------------
export const generateInstructions = createAsyncThunk(
  "chatbot/generateInstructions",
  async (payload: InstructionsPayload = {}) => {
    const generateInstructionsUrl = `${REACT_APP_API_URL}/talentInsights/generateInstructions`;
    const response = (await request(generateInstructionsUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as { threadId: string };
    return response;
  }
);

export const generateTEAMscanInstructions = createAsyncThunk(
  "chatbot/generateTEAMscanInstructions",
  async ({
    entityType,
    entityId,
    report,
    comments = ["no comments"],
  }: {
    entityType: "team" | "department" | "company";
    entityId: number;
    report?: any;
    comments?: any;
  }) => {
    let reportPayload = report;
    let commentsPayload = comments;

    if ((Array.isArray(report) && report.length === 0) || !report) {
      reportPayload = ["no report"];
    }

    if ((Array.isArray(comments) && comments.length === 0) || !comments) {
      commentsPayload = ["no comments"];
    }

    const generateInstructionsUrl = `${REACT_APP_API_URL}/talentInsights/generateTEAMscanInstructions`;
    const response = (await request(generateInstructionsUrl, {
      method: "POST",
      body: JSON.stringify({
        entityType,
        entityId,
        report: reportPayload,
        comments: commentsPayload,
      }),
    })) as { threadId: string };

    return response;
  }
);

export const generatePersonalityDiscussionInstructions = createAsyncThunk(
  "chatbot/generatePersonalityDiscussionInstructions",
  async ({
    report,
    chatType,
    subjectUserAccountId = null,
  }: {
    report: any;
    chatType: string;
    subjectUserAccountId?: number | null;
  }) => {
    const generateInstructionsUrl = `${REACT_APP_API_URL}/talentInsights/generatePersonalityInstructions`;

    let reportPayload = report;

    if ((Array.isArray(report) && report.length === 0) || !report) {
      reportPayload = ["no report"];
    }
    const response = (await request(generateInstructionsUrl, {
      method: "POST",
      body: JSON.stringify({
        report: reportPayload,
        chatType,
        subjectUserAccountId,
      }),
    })) as { threadId: string };
    return response;
  }
);

export const sendMessage = createAsyncThunk(
  "chatbot/sendMessage",
  async (_, { getState, rejectWithValue }) => {
    const { threadId, chatbotMessages: messages } = (getState() as RootState)
      .chatbot;

    if (!threadId) {
      return rejectWithValue("threadId is not set");
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/chatbot`;

    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        threadId: threadId,
        messages,
      }),
    })) as ChatbotResponse;
  }
);

// This function will be used to make a coaching plan, will initiate a countdown timer for the user to complete the plan
export const postCoachingPlan = createAsyncThunk(
  "chatbot/postCoachingPlan",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachingPlan`;
    const response = await request(requestUrl, {
      method: "POST",
    });
    return response;
  }
);

// ------------------------ PUT Functions ------------------------
// This function should be called when the user closes/ends the conversation
export const processConversation = createAsyncThunk(
  "chatbot/processConversation",
  async (threadId: string, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachBoConversations/${threadId}/processConversation`;
    const response = await request(requestUrl, {
      method: "PUT",
    });
    // When done processing the conversation we will want to get the latest conversations and latest coaching plan
    dispatch(getCoachBoConversations());
    dispatch(getCoachingPlan());
    return response;
  }
);

export const putRecurringChat = createAsyncThunk(
  "chatbot/putRecurringChat",
  async (payload: {
    cadence: "weekly" | "daily";
    dayOfWeek?: string | null;
    emailReminderOn: boolean;
    timeOfDayUTC?: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/recurringChat`;
    const response = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as RecurringChat;
    return response;
  }
);

export const editSummary = createAsyncThunk(
  "chatbot/editSummary",
  async (payload: {
    threadId: string;
    summary: string;
    commitment: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachBoConversations/${payload.threadId}/editSummary`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        summary: payload.summary,
        commitment: payload.commitment,
      }),
    });
    return payload;
  }
);

export const putCoachingPlan = createAsyncThunk(
  "chatbot/putCoachingPlan",
  async ({ id, currentFocus }: { id: number; currentFocus: string }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachingPlan/${id}`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        currentFocus,
      }),
    });
    return { id, currentFocus };
  }
);

// ------------------------ DELETE Functions ------------------------
export const deleteConversation = createAsyncThunk(
  "chatbot/deleteConversation",
  async (threadId: string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachBoConversations/${threadId}`;
    await request(requestUrl, {
      method: "DELETE",
    });

    return threadId;
  }
);

export const deleteAllConversations = createAsyncThunk(
  "chatbot/deleteAllConversations",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachBoConversations`;
    await request(requestUrl, {
      method: "DELETE",
    });
  }
);

// ------------------------ Helper Functions ------------------------
export const submitCoachbotFeedback = createAsyncThunk(
  "chatbot/submitCoachbotFeedback",
  async (
    payload: {
      feedbackType: "thumbsUp" | "thumbsDown";
      feedback?: string;
    },
    { getState }
  ) => {
    const { chatbotMessages: messages } = (getState() as RootState).chatbot;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/coachBoFeedback`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ ...payload, conversationHistory: messages }),
    })) as { success: boolean };
  }
);

export const setCoachBotPrompt = createAsyncThunk(
  "chatbot/setCoachBotPrompt",
  async (payload: CoachbotTemplatePrompt | null, { dispatch, getState }) => {
    const { currentUserAccountId, usersInfoById } = (getState() as RootState)
      .global;
    const user = currentUserAccountId
      ? usersInfoById[currentUserAccountId]?.firstName
      : "there";

    let coachBotIntro: string = `Hi ${user}`;
    if (payload?.fullBotIntro) {
      coachBotIntro = payload.fullBotIntro;
    } else if (payload?.coachBotIntro) {
      coachBotIntro += ". " + payload.coachBotIntro;
    } else {
      coachBotIntro += ", I’m Coach Bo. What can I help you with?";
    }

    dispatch(
      setChatbotMessages([
        {
          role: "assistant",
          content: coachBotIntro,
        },
      ])
    );
    dispatch(setCoachBotPromptReducer(payload));
    await dispatch(generalGenerateInstructions({}));
  }
);

export const regenerateLastMessage = createAsyncThunk(
  "chatbot/regenerateLastMessage",
  async (_, { dispatch, getState }) => {
    const { chatbotMessages } = (getState() as RootState).chatbot;
    if (!chatbotMessages) return;
    const lastMessage = chatbotMessages?.[chatbotMessages.length - 1];
    if (lastMessage?.role === "assistant") {
      dispatch(setChatbotMessages(chatbotMessages?.slice(0, -1)));
      dispatch(sendMessage());
    }
  }
);

export const dismissChattingWithNewDirectReportIfAny = createAsyncThunk(
  "chatbot/dismissChattingWithNewDirectReportIfAny",
  async (_, { dispatch, getState }) => {
    const { selectedChatbotPrompt } = (getState() as RootState).chatbot;

    if (selectedChatbotPrompt?.chatType !== "chatWithDirectReport") {
      return;
    }
    const chatWithDirectReportNotifications =
      selectAllChatWithDirectReportNotifications(getState() as RootState)?.[0];

    if (
      !chatWithDirectReportNotifications ||
      chatWithDirectReportNotifications?.subjectUserAccountId !==
        selectedChatbotPrompt.chatTypeId
    ) {
      return;
    }

    dispatch(
      dismissNotification(chatWithDirectReportNotifications.notificationId)
    );
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const chatbotReducer = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    // reset the coachbot to close and clear all the states
    resetChatbotState: (
      state,
      {
        payload,
      }: PayloadAction<
        | undefined
        | {
            keepPrompt: boolean;
            keepChatOpen: boolean;
          }
      >
    ) => {
      const selectedChatbotPrompt = payload?.keepPrompt
        ? state.selectedChatbotPrompt
        : null;
      const isCoachbotOpen = payload?.keepChatOpen
        ? state.isCoachbotOpen
        : false;

      return {
        ...state,
        isCoachbotOpen,
        selectedChatbotPrompt,
        generateInstructionsStatus: "idle",
        sendMessageStatus: "idle",
        submittingCoachbotFeedbackStatus: "idle",
        showFeedbackCardOnClose: false,
      };
    },
    setIsCoachbotOpen: (state, action: PayloadAction<boolean>) => {
      state.isCoachbotOpen = action.payload;
    },
    setCoachBotPromptReducer: (
      state,
      { payload }: PayloadAction<CoachbotTemplatePrompt | null>
    ) => {
      state.selectedChatbotPrompt = payload;
    },
    setCoachbotSuggestedPrompts: (
      state,
      { payload = [] }: PayloadAction<string[]>
    ) => {
      if (!state.selectedChatbotPrompt) {
        return;
      }
      state.selectedChatbotPrompt.userPrompts = payload;
    },
    setChatbotMessages: (
      state,
      { payload }: PayloadAction<ChatbotMessages | null>
    ) => {
      state.chatbotMessages = payload;
    },
    setHidingChatbotButtonFromRoot: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.hidingChatbotButtonFromRoot = payload;
    },
    setThreadId: (state, { payload }: PayloadAction<string>) => {
      state.threadId = payload;
    },
    setShowFeedbackCardOnClose: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showFeedbackCardOnClose = payload;
    },
    updateCoachbotConversation: (
      state,
      { payload }: PayloadAction<ChatbotConversation>
    ) => {
      chatbotConversationAdaptor.upsertOne(
        state.savedChatbotConversations,
        payload
      );
    },
    removeChatBoConversationsFromState: (state) => {
      chatbotConversationAdaptor.removeAll(state.savedChatbotConversations);
    },
    resetCoachingPlan: (state) => {
      state.activeCoachingPlan = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendGeneralMessage.pending, (state) => {
        state.sendMessageStatus = "loading";
      })
      .addCase(sendGeneralMessage.rejected, (state) => {
        state.sendMessageStatus = "failed";
      })
      .addCase(
        sendMessage.fulfilled,
        (state, action: PayloadAction<ChatbotResponse>) => {
          state.chatbotMessages = action.payload.messages;
          state.sendMessageStatus = "succeeded";
        }
      )
      .addCase(sendMessage.pending, (state) => {
        state.sendMessageStatus = "loading";
      })
      .addCase(sendMessage.rejected, (state) => {
        state.sendMessageStatus = "failed";
      })
      .addCase(generateTEAMscanInstructions.pending, (state) => {
        state.generateInstructionsStatus = "loading";
      })
      .addCase(generateTEAMscanInstructions.rejected, (state) => {
        state.generateInstructionsStatus = "failed";
      })
      .addCase(
        generateTEAMscanInstructions.fulfilled,
        (state, action: PayloadAction<{ threadId: string }>) => {
          state.threadId = action.payload.threadId;
          state.generateInstructionsStatus = "succeeded";
        }
      )
      .addCase(generateInstructions.pending, (state) => {
        state.generateInstructionsStatus = "loading";
      })
      .addCase(generateInstructions.rejected, (state) => {
        state.generateInstructionsStatus = "failed";
      })
      .addCase(
        generateInstructions.fulfilled,
        (state, action: PayloadAction<{ threadId: string }>) => {
          state.threadId = action.payload.threadId;
          state.generateInstructionsStatus = "succeeded";
        }
      )
      .addCase(generatePersonalityDiscussionInstructions.pending, (state) => {
        state.generateInstructionsStatus = "loading";
      })
      .addCase(generatePersonalityDiscussionInstructions.rejected, (state) => {
        state.generateInstructionsStatus = "failed";
      })
      .addCase(
        generatePersonalityDiscussionInstructions.fulfilled,
        (state, action: PayloadAction<{ threadId: string }>) => {
          state.threadId = action.payload.threadId;
          state.generateInstructionsStatus = "succeeded";
        }
      )
      .addCase(getOnboardingChat.pending, (state) => {
        state.generateInstructionsStatus = "loading";
      })
      .addCase(getOnboardingChat.rejected, (state) => {
        state.generateInstructionsStatus = "failed";
      })
      .addCase(
        getOnboardingChat.fulfilled,
        (state, action: PayloadAction<{ threadId: string }>) => {
          state.threadId = action.payload.threadId;
          state.generateInstructionsStatus = "succeeded";
        }
      )
      .addCase(getDirectReportChatThreadId.pending, (state) => {
        state.generateInstructionsStatus = "loading";
      })
      .addCase(getDirectReportChatThreadId.fulfilled, (state) => {
        state.generateInstructionsStatus = "succeeded";
      })
      .addCase(getDirectReportChatThreadId.rejected, (state) => {
        state.generateInstructionsStatus = "failed";
      })
      .addCase(submitCoachbotFeedback.fulfilled, (state) => {
        state.submittingCoachbotFeedbackStatus = "succeeded";
      })
      .addCase(submitCoachbotFeedback.pending, (state) => {
        state.submittingCoachbotFeedbackStatus = "loading";
      })
      .addCase(submitCoachbotFeedback.rejected, (state) => {
        state.submittingCoachbotFeedbackStatus = "failed";
      })
      .addCase(getCoachBoConversations.pending, (state) => {
        state.getCoachBoConversationsStatus = "loading";
      })
      .addCase(getCoachBoConversations.rejected, (state) => {
        state.getCoachBoConversationsStatus = "failed";
      })
      .addCase(
        getCoachBoConversations.fulfilled,
        (state, action: PayloadAction<ChatbotConversation[]>) => {
          state.savedChatbotConversations = chatbotConversationAdaptor.setMany(
            state.savedChatbotConversations,
            action.payload
          );
          state.getCoachBoConversationsStatus = "succeeded";
        }
      )
      .addCase(getCoachingPlan.pending, (state) => {
        state.getCoachingPlanStatus = "loading";
      })
      .addCase(getCoachingPlan.rejected, (state) => {
        state.getCoachingPlanStatus = "failed";
      })
      .addCase(
        getCoachingPlan.fulfilled,
        (state, action: PayloadAction<ChatbotCoachingPlan | null>) => {
          state.activeCoachingPlan = action.payload;
          state.getCoachingPlanStatus = "succeeded";
          state.processConversationStatus = "succeeded";
        }
      )
      .addCase(
        deleteConversation.pending,
        (
          state,
          {
            meta: { arg: id },
          }: PayloadAction<undefined, string, { arg: string }>
        ) => {
          state.deleteConversationStatusById = {
            ...state.deleteConversationStatusById,
            [id]: "loading",
          };
        }
      )
      .addCase(
        deleteConversation.rejected,
        (
          state,
          { meta: { arg: id } }: PayloadAction<unknown, string, { arg: string }>
        ) => {
          state.deleteConversationStatusById = {
            ...state.deleteConversationStatusById,
            [id]: "failed",
          };
        }
      )
      .addCase(
        deleteConversation.fulfilled,
        (state, action: PayloadAction<string>) => {
          toast.success(
            "Conversation successfully deleted!",
            successNotificationOptions
          );

          chatbotConversationAdaptor.removeOne(
            state.savedChatbotConversations,
            action.payload
          );
          state.deleteConversationStatusById = {
            ...state.deleteConversationStatusById,
            [action.payload]: "succeeded",
          };
        }
      )

      .addCase(putRecurringChat.pending, (state) => {
        state.putRecurringChatStatus = "loading";
      })
      .addCase(putRecurringChat.rejected, (state) => {
        state.putRecurringChatStatus = "failed";
      })
      .addCase(
        putRecurringChat.fulfilled,
        (state, { payload }: PayloadAction<null | RecurringChat>) => {
          const previousRecurringChat = state.recurringChat;
          state.recurringChat = payload;
          state.putRecurringChatStatus = "succeeded";
          toast.success(
            `Recurring chat successfully ${
              previousRecurringChat === null ? "set up" : "updated"
            }!`,
            successNotificationOptions
          );
        }
      )
      .addCase(getRecurringChat.pending, (state) => {
        state.getRecurringChatStatus = "loading";
      })
      .addCase(getRecurringChat.rejected, (state) => {
        state.getRecurringChatStatus = "failed";
      })
      .addCase(
        getRecurringChat.fulfilled,
        (state, { payload }: PayloadAction<null | RecurringChat>) => {
          state.recurringChat = payload;
          state.getRecurringChatStatus = "succeeded";
        }
      )
      .addCase(editSummary.pending, (state) => {
        state.editSummaryStatus = "loading";
      })
      .addCase(editSummary.rejected, (state) => {
        state.editSummaryStatus = "failed";
      })
      .addCase(
        editSummary.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            threadId: string;
            summary: string;
            commitment: string;
          }>
        ) => {
          const savedSummary =
            state.savedChatbotConversations.entities[payload.threadId]?.summary;
          if (savedSummary) {
            chatbotConversationAdaptor.updateOne(
              state.savedChatbotConversations,
              {
                id: payload.threadId, // Assuming this is the correct identifier for the entity
                changes: {
                  summary: {
                    ...savedSummary,
                    summary: payload.summary,
                    commitment: payload.commitment,
                  },
                },
              }
            );
          }
          toast.success(
            "Conversation summary/commitment successfully updated!",
            successNotificationOptions
          );

          state.editSummaryStatus = "succeeded";
        }
      )
      .addCase(putCoachingPlan.pending, (state) => {
        state.putCoachingPlanStatus = "loading";
      })
      .addCase(putCoachingPlan.rejected, (state) => {
        state.putCoachingPlanStatus = "failed";
      })
      .addCase(
        putCoachingPlan.fulfilled,
        (
          state,
          action: PayloadAction<{ id: number; currentFocus: string }>
        ) => {
          if (state.activeCoachingPlan) {
            state.activeCoachingPlan.currentFocus = action.payload.currentFocus;
          }
          toast.success(
            "Current focus successfully updated!",
            successNotificationOptions
          );
          state.putCoachingPlanStatus = "succeeded";
        }
      )
      .addCase(deleteAllConversations.pending, (state) => {
        state.deleteAllConversationsStatus = "loading";
      })
      .addCase(deleteAllConversations.rejected, (state) => {
        state.deleteAllConversationsStatus = "failed";
      })
      .addCase(deleteAllConversations.fulfilled, (state) => {
        chatbotConversationAdaptor.removeAll(state.savedChatbotConversations);
        state.deleteAllConversationsStatus = "succeeded";
      })
      .addCase(processConversation.pending, (state) => {
        state.processConversationStatus = "loading";
      })
      .addCase(processConversation.rejected, (state) => {
        state.processConversationStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const {
  setIsCoachbotOpen,
  resetChatbotState,
  setCoachBotPromptReducer,
  setChatbotMessages,
  setHidingChatbotButtonFromRoot,
  setCoachbotSuggestedPrompts,
  setThreadId,
  setShowFeedbackCardOnClose,
  updateCoachbotConversation,
  removeChatBoConversationsFromState,
  resetCoachingPlan,
} = chatbotReducer.actions;

export const selectChatbotMessages = (state: RootState) =>
  state.chatbot.chatbotMessages || [];
export const selectThreadId = (state: RootState) => state.chatbot.threadId;
export const selectSendMessageStatus = (state: RootState) =>
  state.chatbot.sendMessageStatus;
export const selectIsCoachbotOpen = (state: RootState) =>
  state.chatbot.isCoachbotOpen;
export const selectSelectedChatbotPrompt = (state: RootState) =>
  state.chatbot.selectedChatbotPrompt;
export const selectHidingChatbotButtonFromRoot = (state: RootState) =>
  state.chatbot.hidingChatbotButtonFromRoot;
export const selectGenerateInstructionsStatus = (state: RootState) =>
  state.chatbot.generateInstructionsStatus;
export const selectSubmittingCoachbotFeedbackStatus = (state: RootState) =>
  state.chatbot.submittingCoachbotFeedbackStatus;
export const selectGetCoachBoConversationsStatus = (state: RootState) =>
  state.chatbot.getCoachBoConversationsStatus;
export const selectShowFeedbackCardOnClose = (state: RootState) =>
  state.chatbot.showFeedbackCardOnClose;
export const selectActiveCoachingPlan = (state: RootState) =>
  state.chatbot.activeCoachingPlan;
export const selectGetCoachingPlanStatus = (state: RootState) =>
  state.chatbot.getCoachingPlanStatus;
export const selectDeleteConversationStatusById =
  (threadId: string | undefined | null) => (state: RootState) =>
    state.chatbot.deleteConversationStatusById[threadId ?? ""] || "idle";
export const selectGetRecurringChatStatus = (state: RootState) =>
  state.chatbot.getRecurringChatStatus;
export const selectPutRecurringChatStatus = (state: RootState) =>
  state.chatbot.putRecurringChatStatus;
export const selectRecurringChat = (state: RootState) =>
  state.chatbot.recurringChat;
export const selectEditSummaryStatus = (state: RootState) =>
  state.chatbot.editSummaryStatus;
export const selectPutCoachingPlanStatus = (state: RootState) =>
  state.chatbot.putCoachingPlanStatus;
export const selectDeleteAllConversationsStatus = (state: RootState) =>
  state.chatbot.deleteAllConversationsStatus;
export const selectProcessConversationStatus = (state: RootState) =>
  state.chatbot.processConversationStatus;

export const {
  selectById: selectChatbotConversationById,
  selectAll: selectAllChatbotConversations,
  selectEntities: selectChatbotConversationEntities,
} = chatbotConversationAdaptor.getSelectors<RootState>(
  (state) => state.chatbot.savedChatbotConversations
);

export default chatbotReducer.reducer;
