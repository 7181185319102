import Toggle from "app/components/Toggle";
import TextArea from "app/storybookComponents/Forms/TextArea";
import TextInput from "app/storybookComponents/Forms/TextInput";
import { ReactElement, useState } from "react";
import { Card, Form } from "react-bootstrap";

interface Props {
  onSave: (
    section: string,
    title: string,
    moduleType: string,
    moduleContent: string,
    moduleTemplateId: number,
    link: string
  ) => void;
  getSubmitButton: (
    talentInsightsModuleId?: number,
    isDisabled?: boolean
  ) => ReactElement;
  talentInsightsModuleId?: number;
  moduleTitle?: string;
  moduleDescription?: string;
  moduleLink?: string | false;
}

export default function EditResourceModuleForm({
  onSave,
  talentInsightsModuleId,
  moduleTitle,
  moduleDescription,
  moduleLink = "",
  getSubmitButton,
}: Props) {
  const [title, setTitle] = useState(moduleTitle ?? "");
  const [description, setDescription] = useState(moduleDescription ?? "");
  const [link, setLink] = useState<false | string>(moduleLink);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSave("Resources", title, "Resource", description, 0, link || "");
      }}
    >
      <Card className="module-form-card column-gap-16px">
        <div>
          <p>
            <b>Module title</b>
          </p>
          <p>This is the title that will be displayed for this module</p>
        </div>
        <TextInput
          inputText={title}
          onTextChange={(e) => setTitle(e)}
          inputLabel=""
          controlId="module-title"
          placeholder="Enter module title"
          maxLength={30}
        />
      </Card>
      <Card className="module-form-card column-gap-16px">
        <TextArea
          inputText={description}
          onTextChange={(e) => setDescription(e)}
          inputLabel="Description"
          controlId="module-description"
          placeholder="Enter description"
          maxLength={250}
        />
        <div>
          <div className="d-flex mb-2">
            <span
              className="me-2"
              style={{ color: "#53565A", fontSize: "14px", fontWeight: "bold" }}
            >
              Add Link
            </span>
            <Toggle
              isOn={link !== false}
              handleToggle={() => setLink(link === false ? "" : false)}
            />
          </div>
          {link !== false ? (
            <Form.Control
              placeholder="https://www."
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          ) : null}
        </div>
      </Card>
      {getSubmitButton(talentInsightsModuleId, !title || !description)}
    </Form>
  );
}
