import { ChangeEvent, useRef } from "react";
import { parse } from "papaparse";
import Button from "app/storybookComponents/Button";
import { ButtonVariant, CSVUploadTableStructureError } from "./types";

interface Props {
  templateColumns?: {
    key: string;
    displayName: string;
    required?: boolean;
  }[];
  resetUpload: () => void;
  onCSVUploadSuccess: (
    data: { [key: string]: string | number }[],
    uploadedFields?: string[]
  ) => void;
  setError?: (error: "file-size-error" | "empty-file" | null) => void;
  setStructureError?: (payload: CSVUploadTableStructureError | null) => void;
  buttonText?: React.ReactNode;
  buttonVariant?: ButtonVariant;
  buttonClassName?: string;
  setLoading?: (loading: boolean) => void;
  disabled?: boolean;
  maxRows?: number;
}

export default function UploadCsvButton({
  resetUpload,
  setError,
  setStructureError,
  onCSVUploadSuccess,
  buttonText = "Upload File",
  buttonVariant = "secondary-blue",
  buttonClassName,
  templateColumns,
  setLoading,
  disabled,
  maxRows,
}: Readonly<Props>) {
  const inputRef = useRef<HTMLInputElement>(null);

  const checkTableStructure = ({
    data,
    meta: { fields },
  }: {
    data: {
      [columnName: string]: string | number;
    }[];
    meta: { fields: string[] };
  }) => {
    if (data.length === 0 || fields.length === 0) {
      setError?.("empty-file");
      setLoading?.(false);
      return;
    }
    // If no templateColumnsMap is provided, then when just send the data directly uploaded by user to the parent component.
    if (!templateColumns) {
      onCSVUploadSuccess(data);
      setLoading?.(false);
      return;
    }
    const errorPositions: number[] = [];
    const copyHeadersTemplate = [...templateColumns];
    fields.forEach((field, idx) => {
      const foundIndex = copyHeadersTemplate.findIndex(
        (column) => column.displayName === field || column.key === field
      );
      if (foundIndex === -1) {
        errorPositions.push(idx);
      } else {
        copyHeadersTemplate.splice(foundIndex, 1);
      }
    });

    const filteredRequiredColumns = copyHeadersTemplate.filter(
      (column) => column.required
    );

    if (filteredRequiredColumns.length !== 0) {
      setStructureError?.({
        uploadedColumns: fields,
        errorPositions,
        missingRequiredColumns: filteredRequiredColumns.map(
          (column) => column.displayName
        ),
      });
      setLoading?.(false);
      return;
    }

    // If there are no mismatching column names, then we set the state to success and save the data the is being uploaded to our state.
    const updatedUploadedData: { [key: string]: string | number }[] = [];

    data.forEach((row) => {
      const constructedData: { [key: string]: string | number } = {};
      let hasData = false;
      templateColumns.forEach((value) => {
        const key = value.key;
        hasData = hasData || !!row[value.displayName]; // will set hasData to true if any of the columns has data.
        constructedData[key] = row[value.displayName] ?? "";
      });

      // This is here so that we dont add rows that are empty to the state.
      if (hasData) {
        updatedUploadedData.push(constructedData);
      }
    });

    if (updatedUploadedData.length === 0) {
      setError?.("empty-file");
      setLoading?.(false);
      return;
    }

    if (maxRows && updatedUploadedData.length > maxRows) {
      setError?.("file-size-error");
      setLoading?.(false);
      return;
    }

    const uploadedFields = new Set<string>();
    const keys = Object.keys(data[0]);
    templateColumns.forEach((tc) => {
      if (keys.includes(tc.displayName)) {
        uploadedFields.add(tc.key);
      }
    });

    onCSVUploadSuccess(updatedUploadedData, [...uploadedFields]);
    setLoading?.(false);
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading?.(true);
    setError?.(null);
    setStructureError?.(null);
    if (
      !event?.target?.files ||
      inputRef?.current?.files?.length === undefined ||
      inputRef.current.files.length === 0
    ) {
      event.target.value = "";
      return resetUpload();
    }

    const uploadFileIsCSV =
      event.target.files.length > 0 &&
      /\.csv$/i.test(event.target.files[0].name);

    if (uploadFileIsCSV) {
      // @ts-ignore
      parse(event.target.files[0], {
        delimiter: "",
        chunkSize: 3,
        header: true,
        complete: checkTableStructure,
      });
    }

    if (inputRef?.current) {
      inputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    if (disabled) return;
    inputRef?.current?.click();
  };

  const getButtonClassName = () => {
    let baseClassName = "white-button";
    if (buttonClassName) {
      baseClassName += ` ${buttonClassName}`;
    }
    return baseClassName;
  };

  return (
    <>
      <input
        type="file"
        id="fileUpload"
        className="csv-upload"
        accept=".csv"
        onChange={(e) => handleUpload(e)}
        ref={inputRef}
        style={{ display: "none" }} // Hide the file input
      />
      <Button
        variant={buttonVariant}
        onClick={handleButtonClick}
        className={getButtonClassName()}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </>
  );
}
