import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, SyntheticEvent } from "react";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { IconName } from "@fortawesome/fontawesome-svg-core";

interface Props {
  title: string;
  body: string | number;
  onPendingTextClick?: () => void;
  pendingText?: string | JSX.Element;
  toolTipContent?: ReactNode;
  actions?: {
    text: string;
    onClick: () => void;
    iconName?: IconName;
  }[];
  pendingTextIcon?: IconName;
  onCardClick?: () => void;
  className?: string;
}

export default function InfoCardV2({
  pendingText,
  title,
  body,
  pendingTextIcon = "clock",
  toolTipContent,
  onPendingTextClick,
  onCardClick,
  actions,
  className,
}: Readonly<Props>) {
  const renderPopover = () => {
    if (!toolTipContent) {
      return;
    }
    const overlay = (
      <Popover className="team-360-popover">
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {title ? <strong>{title}</strong> : null}
          {toolTipContent}
        </div>
      </Popover>
    );
    return (
      <OverlayTrigger rootClose placement="auto" overlay={overlay}>
        <FontAwesomeIcon icon={["far", "circle-info"]} />
      </OverlayTrigger>
    );
  };

  const onButtonClick = (e: SyntheticEvent, onClick: () => void) => {
    e.stopPropagation();
    onClick();
  };

  const getPendingText = () => {
    if (!pendingText) {
      return null;
    }

    const pendingTextElement =
      typeof pendingText === "string" ? (
        <>
          <FontAwesomeIcon icon={["far", pendingTextIcon]} />
          <p className="info-card-v2__pending-text">{pendingText}</p>
        </>
      ) : (
        pendingText
      );

    if (onPendingTextClick) {
      return (
        <div
          className="row-gap-8px align-items-center"
          role="button"
          onClick={(e) => onButtonClick(e, onPendingTextClick)}
        >
          {pendingTextElement}
        </div>
      );
    }

    return (
      <div className="row-gap-8px align-items-center">{pendingTextElement}</div>
    );
  };

  const getActions = () => {
    if (!actions?.length) {
      return null;
    }

    return (
      <div className="row-gap-8px">
        {actions.map((action) => (
          <Button
            key={action.text}
            onClick={(e) => onButtonClick(e, action.onClick)}
            variant="tertiary-blue"
            style={{
              padding: "12px 16px",
              width: "100%",
            }}
          >
            {action.text}
            <FontAwesomeIcon
              icon={action.iconName ?? "arrow-right"}
              className="ms-2"
            />
          </Button>
        ))}
      </div>
    );
  };

  const getCardClassName = () => {
    let classNameReturned = "info-card-v2";
    if (className) {
      classNameReturned += ` ${className}`;
    }
    return classNameReturned;
  };

  return (
    <Card
      className={getCardClassName()}
      onClick={onCardClick}
      style={{
        cursor: onCardClick ? "pointer" : "default",
      }}
    >
      <div className="column-gap-16px">
        <div className="column-gap-8px">
          <div className="row-gap-8px align-items-center">
            <p className="info-card-v2__title">{title}</p>
            <div>{renderPopover()}</div>
          </div>
          <p
            style={{
              fontSize: "32px",
              lineHeight: "40px",
            }}
          >
            {body}
          </p>
        </div>
        {getPendingText()}
      </div>
      {getActions()}
    </Card>
  );
}
