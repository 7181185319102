import { useEffect, useMemo, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Select from "react-select";
import AvatarCircle from "app/components/AvatarCircle";
import { getS, getSelectProps } from "utils/helperFunctions";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getManagerAndDirectReports,
  selectGettingSingleUserManagerAndDirectReportsStatus,
  selectManagerAndDirectReportsByUserAccountId,
} from "app/containers/AdvancedCsvUpload/slice";
import {
  selectAllCompanyUsersById,
  selectCurrentUserInfo,
} from "app/containers/Global/slice";
import { UserInfo } from "app/containers/Global/types";
import { useNavigate } from "react-router-dom";
import { onChatWithDirectReportNotificationClick } from "app/components/Notifications/helpers";
import Loading from "app/storybookComponents/Loading";

const DirectReports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectCurrentUserInfo);
  const managersAndDirectReportsByUserAccountId = useAppSelector(
    selectManagerAndDirectReportsByUserAccountId
  );
  const gettingSingleUserManagerAndDirectReportsStatus = useAppSelector(
    selectGettingSingleUserManagerAndDirectReportsStatus
  );
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const [searchInput, setSearchInput] = useState("");

  // ------------------------- Effects ----------------------------------//
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      getManagerAndDirectReports({
        userAccountId: userInfo.userAccountId,
        forceRefresh: true,
      })
    );
  }, [dispatch, userInfo]);

  const getSearchInput = () => {
    const { selectStyles, components } = getSelectProps();
    return (
      <Form.Group>
        <Select
          isClearable
          isSearchable
          menuIsOpen={false}
          components={components}
          inputValue={searchInput}
          styles={selectStyles}
          placeholder="Search by name, job title or email..."
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setSearchInput(e);
            }
          }}
        />
      </Form.Group>
    );
  };

  const usersDirectReports = useMemo(() => {
    if (!userInfo) return [];
    return (
      managersAndDirectReportsByUserAccountId[userInfo.userAccountId]
        ?.directReportRelationships ?? []
    );
  }, [managersAndDirectReportsByUserAccountId, userInfo]);

  const getAllDirectReports = () => {
    if (gettingSingleUserManagerAndDirectReportsStatus === "loading") {
      return <Loading />;
    }

    if (!usersDirectReports?.length) {
      return (
        <div>
          <h1>No direct reports found</h1>
        </div>
      );
    }

    return usersDirectReports
      .filter((dR) => {
        const user = usersById[dR.employeeUserAccountId];
        if (!user) return false;
        if (!searchInput) return true;
        const { firstName, lastName, jobTitle, emailAddress } = user;
        const lowerSearchInput = searchInput.toLowerCase();
        const firstNameAndLastName = `${firstName ?? ""} ${
          lastName ?? ""
        }`.toLowerCase();

        return (
          firstNameAndLastName.includes(lowerSearchInput) ||
          jobTitle?.toLowerCase().includes(lowerSearchInput) ||
          emailAddress?.toLowerCase().includes(lowerSearchInput)
        );
      })
      .map((dR) => getUserCard(usersById[dR.employeeUserAccountId]));
  };

  const getUserCard = (userInfo: UserInfo) => {
    const { userAccountId, jobTitle, firstName, lastName, emailAddress } =
      userInfo;
    const hasFirstOrLastName = firstName || lastName;
    const isPending = !(firstName || lastName || jobTitle);
    const name = hasFirstOrLastName
      ? `${firstName || ""} ${lastName || ""}`.trim()
      : emailAddress;

    return (
      <Card className="single-search-card" key={userAccountId}>
        <AvatarCircle
          userAccountId={userAccountId}
          size="large"
          avatarColor={isPending ? "53565A" : undefined}
        />
        <h3 className="pt-2 member-name">{name}</h3>
        <span className="job-title">{jobTitle ?? "No Job Title"}</span>
        {isPending ? (
          <span className="tag pending">Account Pending</span>
        ) : null}
        <div className="mt-auto column-gap-16px w-100">
          <Button
            variant="secondary-blue"
            className="view-guide"
            disabled={isPending}
            onClick={() => {
              if (isPending) return;
              navigate(`/UserGuide/${userAccountId}`);
            }}
          >
            View Guide
          </Button>
          <Button
            variant="secondary-blue"
            className="view-guide"
            disabled={isPending}
            onClick={() => {
              if (isPending) return;
              onChatWithDirectReportNotificationClick({
                userAccountId,
                dispatch,
                name,
              });
            }}
          >
            Chat with Coach Bo
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <Card className="column-gap-20px">
      <div className="search-header">
        <div className="column-gap-20px">
          <div className="column-gap-6px">
            <span className="company-name">
              {usersDirectReports.length} member
              {getS(usersDirectReports.length)}
            </span>
            <h1>Your Direct Reports</h1>
          </div>
        </div>
      </div>
      {getSearchInput()}
      <div className="search-cards-container">{getAllDirectReports()}</div>
    </Card>
  );
};

export default DirectReports;
