import { Card } from "react-bootstrap";
import { TManagerGuide } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotAvailable from "./NotAvailable";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { ManagerGuideNavTitles } from "./constants";
import ManagerGuideSamplePdf from "resources/sample_pdfs/sample-manager-guide.pdf";
import { selectSampleUsersInfoById } from "app/containers/Global/slice";
import SampleBanner from "../Team360Assessment/SampleBanner";
import Loading from "app/storybookComponents/Loading";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import CustomChatbotButton from "app/containers/Chatbot/CustomChatbotButton";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";
import { getManagerGuideChatbotPrompt } from "./helper-functions";

interface Props {
  managerGuide: TManagerGuide;
  firstName?: string;
  lastName?: string;
  userAccountId: number;
  loading?: boolean;
}

export default function ManagerGuide({
  managerGuide,
  firstName = "",
  lastName = "",
  userAccountId,
  loading,
}: Props) {
  const dispatch = useAppDispatch();
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companySettings = useAppSelector(selectCompanySettings);
  const isSampleUser = sampleUsersById[userAccountId];

  const getCards = () => {
    return Object.entries(managerGuide).map(([key, questionAndTip]) => {
      const { title } = ManagerGuideNavTitles[key];
      return (
        <Card key={title} className="manager-guide-card shadow-sm">
          <Card.Header>{title}</Card.Header>
          <Card.Body>
            <h3>
              <FontAwesomeIcon
                icon={["far", "lightbulb-on"]}
                className="me-2"
              />
              <span className="text-dark">Tip</span>
            </h3>
            <Card.Text>{questionAndTip.tips}</Card.Text>
            {getConversationStarters(questionAndTip.questions)}
          </Card.Body>
        </Card>
      );
    });
  };

  const getNav = () => {
    return Object.keys(managerGuide).map((key) => {
      const { icon, title } = ManagerGuideNavTitles[key];
      return (
        <div key={key}>
          <img
            src={icon}
            alt={title}
            className="me-3"
            style={{ height: "40px", width: "40px" }}
          />
          <span>{title}</span>
        </div>
      );
    });
  };

  const getConversationStarters = (questions: string[]) => {
    return (
      <div>
        <span>Conversation Starters</span>
        <ul>
          {questions.map((question) => (
            <li key={question}>{question}</li>
          ))}
        </ul>
      </div>
    );
  };
  const onCoachbotClick = () => {
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt({
        ...getManagerGuideChatbotPrompt(firstName),
        type: "personalityReport",
        report: managerGuide,
        chatTypeId: userAccountId,
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  if (loading) {
    return <Loading />;
  }

  if (!managerGuide) {
    return (
      <NotAvailable
        message="Manager Guides provide an overview of how to communicate, support, and motivate your direct reports. Within the guide there are also tips and conversation starters to help you better manage your direct reports."
        linkLabel="View Sample Manager Guide"
        link={ManagerGuideSamplePdf}
      />
    );
  }

  return (
    <div>
      <Card className="p-4">
        {isSampleUser && <SampleBanner entity="Manager Guide" />}
        <div>
          <h2 className="mb-2">Manager Guide</h2>
          <p className="collaboration-guide-header-text mb-0">
            This guide provides tips for managing
            <strong>{` ${firstName} ${lastName} `}</strong>
            based on their personality assessment results. For more in-depth
            information about {firstName}'s personality, view their{" "}
            <span style={{ textDecoration: "underline" }}>
              Work Insights report
            </span>
            . This guide should not be used for selection decisions.
          </p>
        </div>
        <hr />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gap: "12px",
          }}
        >
          <div className="navigation-box">{getNav()}</div>
          <div className="d-flex flex-column gap-4">{getCards()}</div>
        </div>
      </Card>
      {companySettings?.aiFeaturesEnabled ? (
        <CustomChatbotButton
          onCoachbotClick={onCoachbotClick}
          text={`Need help managing ${firstName}?`}
        />
      ) : null}
    </div>
  );
}
