export const getText = (
  skill: {
    [key: string]: any;
  },
  key: string,
  isList?: boolean,
  hasBoldedHeader?: boolean
) => {
  // If it is not a string, then return the text as is.
  const text = skill[key];
  if (typeof text !== "string") return <p>{text}</p>;

  // If no list, then we need to remove the backslashes
  if (!isList) return text.replace(/\\/g, "");

  if (hasBoldedHeader) {
    const headerList = tryConvertingJsonToHeaderList(text);
    if (headerList) return <>{headerList}</>;
  }

  const list = tryConvertingJson(text);

  // If we tried to convert the list into a jsx element and it returns false we try to remove the "Filled In:" prepended text
  if (list) return <ul>{list}</ul>;

  // Sometimes the endpoint returns a string like this: "Filled In: [\" or "FilledIn: ", so we need to try to remove before parsing
  let modifiedText = text.replace("Filled In: ", "");
  const list2 = tryConvertingJson(modifiedText);

  //  If after trying to convert again doesn't work then we try to add the "[" to see if that fixes it
  if (list2) return <ul>{list2}</ul>;
  modifiedText = "[" + modifiedText;
  const list3 = tryConvertingJson(modifiedText);

  // If it still doesn't work then we just return the text as is
  return list3 ? <ul>{list3}</ul> : <p>{text}</p>;
};

// we want to bold the text in each item that comes before the colon
export const tryConvertingJsonToHeaderList = (text: string): JSX.Element[] => {
  try {
    const list = JSON.parse(text);
    return list.map((item: string) => {
      const splitItem = item.split(":");
      return (
        <>
          <strong>{splitItem[0]}</strong>:
          <ul>
            <li key={item}>{splitItem[1]}</li>
          </ul>
        </>
      );
    });
  } catch (error) {
    return [];
  }
};

export const tryConvertingJson = (text: string): JSX.Element[] | false => {
  try {
    return JSON.parse(text).map((item: string) => <li key={item}>{item}</li>);
  } catch (error) {
    return false;
  }
};
