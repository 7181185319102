import { Card } from "react-bootstrap";
import Robot from "resources/images/robot.svg";
import Button from "app/storybookComponents/Button";
import { UserInfo } from "app/containers/Global/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectedCandidate } from "../types";
import { useAppDispatch } from "utils/redux/hooks";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import { getWelcomeChatPrompt } from "../helpers";

interface Props {
  candidateProfile?: ConnectedCandidate | null;
  userInfo?: UserInfo;
  areAIFeaturesEnabled?: boolean;
}

const JobCandidateProfileCard = ({
  candidateProfile,
  userInfo,
  areAIFeaturesEnabled,
}: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  if (!candidateProfile || !userInfo) {
    return null;
  }

  const getCandidateStartDate = () => {
    const stringDate = candidateProfile?.candidateStartDate;
    if (!stringDate) {
      return null;
    }
    const date = new Date(stringDate);
    return (
      <span
        className="label-tag blue"
        style={{
          padding: "1px 4px",
          fontWeight: "400",
          fontSize: "13px",
        }}
      >
        Added to Develop on{" "}
        {date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}
      </span>
    );
  };

  const getHiringManagerNote = () => {
    if (!candidateProfile.noteToManager) {
      return null;
    }
    return (
      <Card className="job-candidate-profile-card__info-card">
        <h3>Notes From The Hiring Manager</h3>
        <p>{candidateProfile.noteToManager}</p>
      </Card>
    );
  };

  const getLink = (
    url: string,
    label: string,
    attachmentType: "Cover Letter" | "Resume"
  ) => (
    <div className="row-gap-8px">
      <FontAwesomeIcon
        icon="link"
        style={{
          color: "#425CC7",
        }}
      />
      <a href={url} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </div>
  );

  const getResume = () => {
    if (!candidateProfile.shareResume || !candidateProfile.resumeUrl) {
      return null;
    }
    return (
      <Card className="job-candidate-profile-card__info-card">
        <h3>Resume</h3>
        {getLink(candidateProfile.resumeUrl, "View Resume", "Resume")}
      </Card>
    );
  };
  const getCoverLetter = () => {
    if (
      !candidateProfile.shareCoverLetter ||
      !candidateProfile.coverLetterUrl
    ) {
      return null;
    }
    return (
      <Card className="job-candidate-profile-card__info-card">
        <h3>Cover Letter</h3>
        {getLink(
          candidateProfile.coverLetterUrl,
          "View Cover Letter",
          "Cover Letter"
        )}
      </Card>
    );
  };

  const getContent = () => {
    const managerNote = getHiringManagerNote();
    const resume = getResume();
    const coverLetter = getCoverLetter();
    if (!managerNote && !resume && !coverLetter) {
      return (
        <div className="empty-card" style={{ padding: "40px 20px" }}>
          <span>No Candidate Profile Information Has Been Shared</span>
          <p>
            The hiring manager has not shared any information from{" "}
            {userInfo?.firstName}
            {"'"}s candidate profile.
          </p>
        </div>
      );
    }

    return (
      <>
        <p
          style={{
            marginTop: "-12px",
          }}
        >
          This profile includes information shared by the hiring manager from{" "}
          {userInfo.firstName}'s job candidate profile:
        </p>
        {getHiringManagerNote()}
        {getResume()}
        {getCoverLetter()}
      </>
    );
  };

  const onCoachBoClick = () => {
    if (!userInfo) {
      return null;
    }
    const firstName = userInfo?.firstName ?? "your new hire";
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt(getWelcomeChatPrompt(firstName, userInfo.userAccountId))
    );
    dispatch(setIsCoachbotOpen(true));
  };

  const getCoachBoSection = () => {
    if (!areAIFeaturesEnabled) {
      return null;
    }
    return (
      <div className="row-gap-32px warning-banner light-blue align-items-center justify-content-between">
        <div className="row-gap-10px align-items-center">
          <img src={Robot} alt="Criteria" className="assistant-avatar" />
          <div className="column-gap-8px">
            <p className="fw-bold">
              Ready to start onboarding {userInfo?.firstName}?
            </p>
            <p>
              Chat with Coach Bo to more effectively welcome, integrate and set
              up {userInfo?.firstName} for success in their first few weeks and
              months.
            </p>
          </div>
        </div>
        <div>
          <Button
            onClick={onCoachBoClick}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Chat with Coach Bo
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="column-gap-20px">
      {getCoachBoSection()}
      <Card className="job-candidate-profile-card">
        <div className="row-gap-8px">
          <h2>Job Candidate Profile</h2>
          {getCandidateStartDate()}
        </div>
        {getContent()}
      </Card>
    </div>
  );
};

export default JobCandidateProfileCard;
