import { SuperPower } from "./types";
import { Card } from "react-bootstrap";
import SectionHeader from "./SectionHeader";
import { useState } from "react";

interface Props {
  readonly superPower: SuperPower | null;
}

export default function TeamSuperPower({ superPower }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!superPower) {
    return null;
  }

  const getGreenCheckMark = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2882 0.110795C14.3581 0.0369318 14.4512 0 14.5677 0C14.6841 0 14.7889 0.0369318 14.8821 0.110795L15.8603 1.18182C15.9534 1.25568 16 1.35417 16 1.47727C16 1.60038 15.9534 1.71117 15.8603 1.80966L5.37991 12.8892C5.31004 12.9631 5.21688 13 5.10044 13C4.98399 13 4.87918 12.9631 4.78603 12.8892L0.139738 7.94034C0.0465791 7.86648 0 7.76799 0 7.64489C0 7.52178 0.0465791 7.41099 0.139738 7.3125L1.1179 6.27841C1.21106 6.17992 1.31587 6.13068 1.43231 6.13068C1.54876 6.13068 1.64192 6.17992 1.71179 6.27841L5.10044 9.8608L14.2882 0.110795Z"
          fill="#009952"
        />
      </svg>
    );
  };

  const getRedX = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 10.5125L16.7179 5.79462C16.7763 5.73629 16.8565 5.70712 16.9586 5.70712C17.0606 5.70712 17.1409 5.73629 17.1992 5.79462L18.2054 6.80087C18.2638 6.8592 18.2929 6.93941 18.2929 7.0415C18.2929 7.14358 18.2638 7.22379 18.2054 7.28212L13.4875 12L18.2054 16.7179C18.2638 16.7762 18.2929 16.8564 18.2929 16.9585C18.2929 17.0606 18.2638 17.1408 18.2054 17.1992L17.1992 18.2054C17.1409 18.2637 17.0606 18.2929 16.9586 18.2929C16.8565 18.2929 16.7763 18.2637 16.7179 18.2054L12 13.4875L7.28215 18.2054C7.22382 18.2637 7.14361 18.2929 7.04153 18.2929C6.93945 18.2929 6.85924 18.2637 6.8009 18.2054L5.79465 17.1992C5.73632 17.1408 5.70715 17.0606 5.70715 16.9585C5.70715 16.8564 5.73632 16.7762 5.79465 16.7179L10.5125 12L5.79465 7.28212C5.73632 7.22379 5.70715 7.14358 5.70715 7.0415C5.70715 6.93941 5.73632 6.8592 5.79465 6.80087L6.8009 5.79462C6.85924 5.73629 6.93945 5.70712 7.04153 5.70712C7.14361 5.70712 7.22382 5.73629 7.28215 5.79462L12 10.5125Z"
          fill="#D50057"
        />
      </svg>
    );
  };

  const getStrengthsOrRisksCard = (type: string) => {
    const title =
      type === "strengths"
        ? "Strengths Of This Superpower"
        : "Risks Of This Superpower";

    const bulletPoint = type === "strengths" ? getGreenCheckMark() : getRedX();
    const arrayToMap =
      type === "strengths" ? superPower.strengths : superPower.risks;

    let counter = 0;
    return (
      <Card style={{ padding: "10px", width: "49%" }}>
        <h3>{title}</h3>
        {arrayToMap.map((item) => {
          counter += 1;
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "10px",
                paddingTop: "10px",
                fontSize: "14px",
              }}
              key={counter}
            >
              {bulletPoint}
              <div style={{ paddingLeft: "10px" }}>{item}</div>
            </div>
          );
        })}
      </Card>
    );
  };

  if (isCollapsed) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Team Superpower"
          isCollapsed={isCollapsed}
        />
      </Card>
    );
  }

  return (
    <Card className="column-gap-20px">
      <SectionHeader
        toggleIsCollapsed={toggleIsCollapsed}
        title="Team Superpower"
        isCollapsed={isCollapsed}
      />
      <div
        className="card-with-left-strip column-gap-8px dark-blue card"
        style={{ padding: "10px" }}
      >
        <div style={{ marginLeft: "10px" }}>
          <h3>{superPower.trait}</h3>
          <p>{superPower.summary}</p>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {getStrengthsOrRisksCard("strengths")}
        {getStrengthsOrRisksCard("risks")}
      </div>
    </Card>
  );
}
