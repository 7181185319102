import { ReactElement, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card, Dropdown } from "react-bootstrap";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  showAddModuleModal,
  showEditModuleModal,
} from "app/components/Modules/slice";
import VisibilityBanner from "app/storybookComponents/WarningBanner/VisibilityBanner";
import {
  selectCurrentUserAccountId,
  selectUpdateUserInfoStatus,
  getUserInfoById,
  selectSampleUsersInfoById,
  selectSampleTeamsByTeamId,
  selectGetAllTeamsStatus,
  selectGetUserInfoByIdStatus,
  selectGetAllUserStatus,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectConfigCatFlag,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import {
  openCreateTeamModal,
  setIsNewDirectReportModalOpen,
} from "app/components/Modals/slice";
import {
  getWorkPlaceInsightsReportByUserId,
  selectWorkPlaceInsightsReportsById,
  getManagerGuide,
  selectManagerGuideById,
  getPendingTests,
  selectGetPendingTestsStatus,
  selectPendingTests,
  selectGetCollaborationGuideStatus,
  selectGetManagerGuideStatus,
} from "app/components/WorkplaceInsightsReport/slice";
import ModulesModal from "app/components/Modules/Modals/ModuleModal";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Section from "app/components/Modules/Section";
import Module from "app/components/Modules/Module";
import BuildYourUserGuideCard from "app/components/BuildYourGuideCard";
import UploadPictureModal from "app/components/ImageEditor/UploadPictureModal";
import WorkPlaceInsightsReport from "app/components/WorkplaceInsightsReport/WorkPlaceInsightsReport";
import ManagerReport from "app/components/WorkplaceInsightsReport/ManagerGuide";
import CollaborationGuide from "app/components/WorkplaceInsightsReport/CollaborationGuide";
import NoGuideYet from "app/components/GuideComponents/NoGuideYet";
import Header from "app/storybookComponents/GuideHeaderCard";
import { Section as SectionType } from "app/components/Modules/types";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import Button from "app/storybookComponents/Button";
import Loading from "app/storybookComponents/Loading";
import {
  getUserVisibilitySettings,
  selectUserVisibilitySettingsByUserAccountId,
} from "app/containers/UserSettings/slice";
import {
  getModuleTemplates,
  getUserGuide,
  selectGetModuleTemplatesStatus,
  selectGetUserGuideStatus,
  selectUserGuides,
  selectUserModuleTemplates,
  selectUsedUserGuideModules,
  selectUpdatingGuidePhotoStatus,
  selectDeletingGuidePhotoStatus,
  selectDeletingStatus,
  deleteUserModuleById,
  deleteSectionById,
  selectAddToUserGuideStatus,
  clearAddModuleStatus,
  clearDeleteStatus,
  deleteGuidePhoto,
  updateGuidePhoto,
  selectGetUserTeams,
  selectGetUserTeamsStatus,
  getUserTeams,
  getEppPermissions,
  selectEppPermissionsByUserId,
  selectUserIsAbleToCreateTeams,
  getCandidateProfile,
  selectConnectedCandidate,
} from "./slice";
import EditInformationModal from "./Modals/EditInformationModal";
import { UserGuideTabs, isUserGuideTabs } from "./types";
import {
  getWelcomeChatPrompt,
  isAtLeastAMangerOf,
  sortSections,
  getUserVisibilityOnClick,
} from "./helpers";
import NavigateBackButton from "app/components/NavigateBackButton";
import {
  selectAiFeaturesEnabled,
  selectCompanySettings,
  selectHasCoachBoConversationHistoryEnabled,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import { getUserVisibilityBannerText } from "app/containers/UserSettings/helpers";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import SampleBanner from "app/components/Team360Assessment/SampleBanner";
import useTrackPage from "utils/hooks/useTrackPage";
import RecentCoachBoChats from "./Cards/RecentCoachBoChats";
import JobCandidateProfileCard from "./Cards/JobCandidateProfileCard";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "../Chatbot/slice";
import {
  getUserIdendifier,
  trackUserGuideTabChange,
} from "utils/trackingFunctions";
import {
  getAllManagerAndDirectReports,
  selectManagerAndDirectReportsByUserAccountId,
} from "../AdvancedCsvUpload/slice";

export default function UserGuide() {
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const { userAccountId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const trueUserAccountId = Number(userAccountId || loggedInUserAccountId);

  // ------------ App Selectors ---------------
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const getUserInfoStatus = useAppSelector(selectGetUserInfoByIdStatus);
  const getTeamStatus = useAppSelector(selectGetAllTeamsStatus);
  const userTeams = useAppSelector(selectGetUserTeams);
  const getUserTeamsStatus = useAppSelector(selectGetUserTeamsStatus);
  const updateUserInfoStatus = useAppSelector(selectUpdateUserInfoStatus);
  const getModuleTemplatesStatus = useAppSelector(
    selectGetModuleTemplatesStatus
  );
  const getAllUsersStatus = useAppSelector(selectGetAllUserStatus);
  const moduleTemplates = useAppSelector(selectUserModuleTemplates);
  const userGuides = useAppSelector(selectUserGuides);
  const getUserGuideStatus = useAppSelector(selectGetUserGuideStatus);
  const usedUserModules = useAppSelector(selectUsedUserGuideModules);
  const updatingGuidePhotoStatus = useAppSelector(
    selectUpdatingGuidePhotoStatus
  );
  const deletingGuidePhotoStatus = useAppSelector(
    selectDeletingGuidePhotoStatus
  );
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const deletingStatus = useAppSelector(selectDeletingStatus);
  const addingModuleStatus = useAppSelector(selectAddToUserGuideStatus);
  const workplaceInsightsReport = useAppSelector(
    selectWorkPlaceInsightsReportsById(trueUserAccountId)
  );
  const managerGuide = useAppSelector(
    selectManagerGuideById(trueUserAccountId)
  );
  const connectedCandidate = useAppSelector(
    selectConnectedCandidate(trueUserAccountId)
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const getPendingTestsStatus = useAppSelector(selectGetPendingTestsStatus);
  const pendingTests = useAppSelector(selectPendingTests);
  const companySettings = useAppSelector(selectCompanySettings);
  const currentCompanyGuide = useAppSelector(selectCompanyGuide);
  const eppPermissions = useAppSelector(
    selectEppPermissionsByUserId(Number(userAccountId || loggedInUserAccountId))
  );
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const getCollaborationGuideStatus = useAppSelector(
    selectGetCollaborationGuideStatus
  );
  const getManagerGuideStatus = useAppSelector(selectGetManagerGuideStatus);
  const coachBoConversationHistoryFeatureFlag = useAppSelector(
    selectConfigCatFlag("develop_coachboconversationhistory")
  );
  const isConversationHistoryEnabledCompanyWide = useAppSelector(
    selectHasCoachBoConversationHistoryEnabled
  );
  const areAIFeaturesEnabled = useAppSelector(selectAiFeaturesEnabled);
  const managersAndDirectReportsByUserAccountId = useAppSelector(
    selectManagerAndDirectReportsByUserAccountId
  );
  const userVisibilitySettings = useAppSelector(
    selectUserVisibilitySettingsByUserAccountId(
      Number(userAccountId || loggedInUserAccountId)
    )
  );

  const teamInfoById = { ...sampleTeamsById, ...allTeamsById };
  const usersInfoById = useMemo(
    () => ({ ...sampleUsersById, ...companyUsersById }),
    [sampleUsersById, companyUsersById]
  );
  const isSampleUser = userAccountId && sampleUsersById[userAccountId];

  // ---------------- Modal States ----------------
  const [activeHeaderTab, setActiveHeaderTab] =
    useState<UserGuideTabs>("About");
  const [photoModalShowing, setPhotoModalShowing] = useState<
    "profilePicture" | "coverPhoto" | null
  >(null);
  const [showEditInformationModal, setShowEditInformationModal] = useState<
    null | "Contact" | "Personal"
  >(null);
  const [deleting, setDeleting] = useState<null | {
    type: "Module" | "Section";
    id: number | string;
    moduleType?: string;
    moduleTemplateId?: number;
  }>(null);
  const [sections, setSections] = useState<{
    remainingSections: SectionType[];
    connectSection: SectionType | null;
  }>({ remainingSections: [], connectSection: null });

  const isLoggedInUserManagerOrAbove = useMemo(
    () =>
      isAtLeastAMangerOf({
        loggedInUserAccountId,
        isAdmin: !!isAdmin,
        teamAndDepartmentsLeaderOf: teamAndDepartmentLeaderOf,
        userInfo: usersInfoById[trueUserAccountId],
        teamsById: allTeamsById,
        manageeMangerAndDirectReports:
          managersAndDirectReportsByUserAccountId[trueUserAccountId],
      }),
    [
      loggedInUserAccountId,
      isAdmin,
      teamAndDepartmentLeaderOf,
      usersInfoById,
      allTeamsById,
      trueUserAccountId,
      managersAndDirectReportsByUserAccountId,
    ]
  );

  useTrackPage();

  // gets all the available module templates so that the user is able to add and edit modules
  useEffect(() => {
    if (getModuleTemplatesStatus === "idle") {
      dispatch(getModuleTemplates());
    }
  }, [getModuleTemplatesStatus, dispatch]);

  useEffect(() => {
    if (getPendingTestsStatus === "idle") {
      dispatch(getPendingTests());
    }
  }, [getPendingTestsStatus, dispatch]);

  useEffect(() => {
    if (!loggedInUserAccountId) {
      return;
    }
    const Develop_Profile_ThisIsMyUserGuide = !userAccountId;
    window.Appcues.identify(
      getUserIdendifier(loggedInUserAccountId.toString()),
      {
        Develop_Profile_ThisIsMyUserGuide,
      }
    );

    return () => {
      window.Appcues.identify(
        getUserIdendifier(loggedInUserAccountId.toString()),
        {
          Develop_Profile_ThisIsMyUserGuide: null,
        }
      );
    };
  }, [userAccountId, loggedInUserAccountId]);

  // Gets the user's guide
  useEffect(() => {
    // If the user is not yet logged in do nothing
    if (!loggedInUserAccountId || getUserGuideStatus === "loading") {
      return;
    }

    // If we already have the user guide no need to fetch it again.
    const userId = Number(userAccountId || loggedInUserAccountId);
    if (userGuides[userId]) {
      return;
    }

    dispatch(getUserGuide(userId));
  }, [
    getUserGuideStatus,
    dispatch,
    loggedInUserAccountId,
    userAccountId,
    userGuides,
  ]);

  // gets eppPermissions for the user
  useEffect(() => {
    if (!loggedInUserAccountId) return;
    const userId = Number(userAccountId || loggedInUserAccountId);
    dispatch(getEppPermissions(userId));
    dispatch(getWorkPlaceInsightsReportByUserId(userId));
    dispatch(getManagerGuide(userId));
    dispatch(getCandidateProfile({ userAccountId: userId }));
  }, [loggedInUserAccountId, dispatch, userAccountId]);

  // Gets the user guide sections for the user
  useEffect(() => {
    const userId = Number(userAccountId || loggedInUserAccountId);
    if (!userId || !userGuides[userId]?.content) return;

    // Once we have user guide information we separate the connect section from the rest of the sections.
    let connectSection: SectionType | null = null;
    const remainingSections: SectionType[] = [];
    Object.values(userGuides[userId].content).forEach((section) => {
      if (section.title?.toLowerCase() === "connect") {
        connectSection = section;
      } else {
        remainingSections.push(section);
      }
    });

    setSections({ remainingSections, connectSection });
  }, [loggedInUserAccountId, userAccountId, userGuides]);

  // Gets the user info for the user
  useEffect(() => {
    // If the user is not yet logged in do nothing
    if (!loggedInUserAccountId) {
      return;
    }

    // If we already have the user info no need to fetch it again.
    const userId = Number(userAccountId || loggedInUserAccountId);
    if (usersInfoById[userId] || getUserInfoStatus === "loading") {
      return;
    }

    dispatch(getUserInfoById(userId));
  }, [
    dispatch,
    userAccountId,
    usersInfoById,
    loggedInUserAccountId,
    getUserInfoStatus,
  ]);

  // Fetches the data for the user's teams
  useEffect(() => {
    // If the user is not yet logged in do nothing
    if (!loggedInUserAccountId) {
      return;
    }

    // If we already have the user info no need to fetch it again.
    const userId = Number(userAccountId || loggedInUserAccountId);
    if (userTeams[userId] || getUserTeamsStatus === "loading") {
      return;
    }

    dispatch(getUserTeams(userId));
  }, [
    dispatch,
    userAccountId,
    userTeams,
    getUserTeamsStatus,
    loggedInUserAccountId,
  ]);

  useEffect(() => {
    const newDirectReportUserAccountId = searchParams.get("newDirectReport");
    if (!newDirectReportUserAccountId) return;
    setSearchParams(
      (sp) => {
        sp.delete("newDirectReport");
        return sp;
      },
      {
        replace: true,
      }
    );
    dispatch(
      setIsNewDirectReportModalOpen({
        userAccountId: Number(newDirectReportUserAccountId),
      })
    );
  }, [searchParams, dispatch, setSearchParams]);

  useEffect(() => {
    const newDirectReportUserAccountId = searchParams.get("onboardingChat");
    const queryThreadId = searchParams.get("threadId");
    const userInfo = trueUserAccountId && usersInfoById[trueUserAccountId];
    if (!newDirectReportUserAccountId || queryThreadId || !userInfo) return;
    setSearchParams(
      (sp) => {
        sp.delete("onboardingChat");
        return sp;
      },
      {
        replace: true,
      }
    );
    const firstName = userInfo?.firstName ?? "your new hire";
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt(getWelcomeChatPrompt(firstName, trueUserAccountId))
    );
    dispatch(setIsCoachbotOpen(true));
  }, [
    searchParams,
    dispatch,
    setSearchParams,
    trueUserAccountId,
    usersInfoById,
  ]);

  useEffect(() => {
    // If the query params is set for the tab and the tab is a valid tab then we set the active tab to the query param.
    const tab = searchParams.get("tab");
    if (tab && isUserGuideTabs(tab)) {
      setActiveHeaderTab(tab);
    } else {
      // if no tab is set or the tab is not a valid tab then we set the tab to the about tab.
      setActiveHeaderTab("About");
    }
  }, [searchParams, userAccountId]);

  // -------------- Upon a successful request we close the modals using the useEffect hook.
  useEffect(() => {
    if (updatingGuidePhotoStatus === "succeeded") {
      setPhotoModalShowing(null);
    }
  }, [updatingGuidePhotoStatus]);

  useEffect(() => {
    if (deletingGuidePhotoStatus === "succeeded") {
      setPhotoModalShowing(null);
    }
  }, [deletingGuidePhotoStatus]);

  useEffect(() => {
    if (updateUserInfoStatus === "succeeded") {
      setShowEditInformationModal(null);
    }
  }, [updateUserInfoStatus]);

  useEffect(() => {
    if (deletingStatus === "succeeded") {
      setDeleting(null);
      dispatch(clearDeleteStatus());
    }
  }, [deletingStatus, dispatch]);

  useEffect(() => {
    if (addingModuleStatus === "succeeded") {
      dispatch(clearAddModuleStatus());
    }
  }, [addingModuleStatus, dispatch]);

  useEffect(() => {
    dispatch(getAllManagerAndDirectReports());
  }, [dispatch]);

  useEffect(() => {
    const userId = Number(userAccountId || loggedInUserAccountId);
    dispatch(getUserVisibilitySettings({ userAccountId: userId }));
  }, [dispatch, userAccountId, loggedInUserAccountId]);

  const currentUserAccountId = Number(userAccountId || loggedInUserAccountId);
  const isCurrentUser = loggedInUserAccountId === currentUserAccountId;
  const currentUserInfo = usersInfoById[currentUserAccountId] || null;

  if (currentUserInfo === null) {
    return currentUserInfo;
  }

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "edit":
        return setShowEditInformationModal("Contact");
      case "add":
        return dispatch(
          showAddModuleModal({
            section: "Connect",
            guideType: "user",
            userIdOrTeamIdOrCompanyId: currentUserInfo.userAccountId,
          })
        );
    }
  };

  const getContactCard = () => (
    <Card className="d-grid section">
      <div className="section-header">
        <h2>Connect</h2>
        {isCurrentUser ? (
          <Dropdown onSelect={(e) => onDropdownSelect(e)}>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="no-caret"
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="add">
                <FontAwesomeIcon icon="plus" /> Add To Section
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="edit">Edit Contact Info</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
      </div>

      <div className="contact-buttons">
        <div>
          <div className="contact-button" role="button">
            <a
              href={`mailto:${currentUserInfo?.emailAddress}`}
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon="envelope" />
            </a>
          </div>
        </div>
        {currentUserInfo?.linkedInUrl ? (
          <div>
            <div className="contact-button" role="button">
              <a
                href={currentUserInfo?.linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn as IconProp} />
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <div className="connect-modules">{getConnectModules()}</div>
    </Card>
  );

  const getTeamsCard = () => {
    const teamRows: ReactElement[] = [];
    const userInfo = usersInfoById[currentUserAccountId];
    let teamIds = userInfo?.teamIds || [];
    if (sampleUsersById[currentUserAccountId]) {
      teamIds = sampleUsersById[currentUserAccountId].teamIds;
    }

    teamIds.forEach((teamId) => {
      const teamInfo = teamInfoById[teamId];
      if (!teamInfo) {
        return;
      }
      const totalMembers = teamInfo?.teamMemberIds?.length || 0;
      teamRows.push(
        <div
          className="section-body-row"
          key={teamId}
          role="button"
          onClick={() => navigate(`/TeamGuide/${teamId}`)}
        >
          <div className="section-body-name-and-description">
            <span className="section-body-row-name">{teamInfo.teamName}</span>
            <span className="section-body-row-description">
              {totalMembers || 1} Member{totalMembers > 1 ? "s" : ""}
            </span>
          </div>
          {userTeams[currentUserAccountId]?.[teamId]?.isTeamLead === 1 ? (
            <span className="team-role ti-badge">Team Leader</span>
          ) : null}
        </div>
      );
    });
    const getMyTeamsText = () => {
      if (isCurrentUser) {
        return "My Teams";
      }
      if (currentUserInfo.firstName) {
        return `${currentUserInfo.firstName}'s Teams`;
      }

      return "This User's Teams";
    };
    const finishedLoading = () => {
      return (
        getTeamStatus === "succeeded" &&
        getUserTeamsStatus === "succeeded" &&
        getAllUsersStatus === "succeeded"
      );
    };

    const getMyTeamsBody = () => {
      if (!finishedLoading()) {
        return <Loading />;
      }
      if (!teamRows.length) {
        return (
          <div className="no-teams-joined-yet">
            <h3 style={{ color: "#53565a" }}>No Teams Joined Yet</h3>
            <p>Check your notifications for invites</p>
            {getCreateOrJoinTeam(teamRows.length)}
          </div>
        );
      }
      return (
        <>
          <div className="section-container">{teamRows}</div>
          {getCreateOrJoinTeam(teamRows.length)}
        </>
      );
    };

    return (
      <Card className="section my-teams">
        <div className="section-header">
          <h2>{getMyTeamsText()}</h2>
        </div>
        {getMyTeamsBody()}
      </Card>
    );
  };

  const getCreateOrJoinTeam = (teamTotal: number) => {
    if (!isCurrentUser) {
      return null;
    }
    const style = teamTotal ? {} : { backgroundColor: "white" };
    return (
      <div className="my-teams-card-join-create">
        <div className="my-teams-card-join-create-buttons">
          <Button onClick={() => navigate("/Search/Teams")}>
            See All Teams
          </Button>
          {canUserCreateTeams ? (
            <Button
              variant="secondary-blue"
              style={style}
              onClick={() =>
                dispatch(
                  openCreateTeamModal({
                    redirectUponTeamCreation: true,
                  })
                )
              }
            >
              Create a Team
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  const getUserSections = () => {
    if (userGuides === null || !userGuides[currentUserInfo.userAccountId]) {
      return null;
    }

    const orderedSections = sortSections(sections.remainingSections);
    // If the orderSection is zero and the user we are looking at is not the same as the user that is logged in then we show the no guide yet component.
    if (orderedSections.length === 0 && !isCurrentUser) {
      return <NoGuideYet name={currentUserInfo.firstName} />;
    }

    return orderedSections.map(({ sectionId, title, modules, moduleOrder }) => {
      return (
        <Section
          guideType="user"
          userIdOrTeamIdOrCompanyId={currentUserInfo.userAccountId}
          moduleOrder={getModuleOrder(moduleOrder)}
          sectionId={sectionId}
          title={title}
          modules={modules}
          key={sectionId}
          setDeleting={setDeleting}
          hasEditAccess={isCurrentUser}
          moduleTemplates={moduleTemplates}
        />
      );
    });
  };

  const getConnectModules = () => {
    if (!sections.connectSection || !moduleTemplates) {
      return null;
    }

    return Object.values(sections.connectSection.modules).map(
      ({
        moduleTemplateId,
        title,
        moduleType,
        moduleContent,
        link,
        talentInsightsModuleId,
      }) => (
        <Module
          key={`${talentInsightsModuleId}${moduleType}`}
          title={title}
          moduleType={moduleType}
          content={moduleContent}
          onEditClick={() => {
            dispatch(
              showEditModuleModal({
                moduleType,
                moduleTemplateId,
                moduleContent,
                link,
                talentInsightsModuleId,
                userIdOrTeamIdOrCompanyId: currentUserInfo.userAccountId,
                guideType: "user",
              })
            );
          }}
          onDeleteClick={() =>
            setDeleting({
              type: "Module",
              id: talentInsightsModuleId,
              moduleType,
              moduleTemplateId,
            })
          }
          hasEditAccess={isCurrentUser}
          moduleTemplate={moduleTemplates[moduleType][moduleTemplateId]}
        />
      )
    );
  };

  const getModuleOrder = (moduleOrderString: string) => {
    try {
      return JSON.parse(moduleOrderString) || [];
    } catch (e) {
      return [];
    }
  };

  const getSelfUserTabs = (): string[] => {
    const userTabs = ["About", "Workplace Insights"];
    if (
      companySettings?.aiFeaturesEnabled &&
      coachBoConversationHistoryFeatureFlag &&
      isConversationHistoryEnabledCompanyWide
    ) {
      userTabs.push("Chat History");
    }

    userTabs.push("User Settings");
    return userTabs;
  };

  const getOtherUserTabs = (): string[] => {
    const tabs = ["About"];

    if (eppPermissions?.canSeeManagerGuide) {
      tabs.push("Manager Guide");
    }

    if (eppPermissions?.canSeeCollaborationGuide) {
      tabs.push("Collaboration Guide");
    }

    if (eppPermissions?.canSeeWorkplaceInsights) {
      tabs.push("Workplace Insights");
    }

    // if the user is not viewing their own guide and has a candidateProfile
    if (
      connectedCandidate?.userAccountId !== loggedInUserAccountId &&
      isLoggedInUserManagerOrAbove &&
      connectedCandidate
    ) {
      tabs.push("Job Candidate Profile");
    }

    return tabs;
  };

  const getAvailableTabs = (): string[] => {
    if (loggedInUserAccountId === currentUserInfo.userAccountId) {
      return getSelfUserTabs();
    }

    return getOtherUserTabs();
  };

  const getBuildYourUserGuide = (): null | ReactElement => {
    // If the user guide is null or the module templates are null then we will not want to show build your guide section.
    if (!userGuide || moduleTemplates === null || !isCurrentUser) {
      return null;
    }

    // If the user already has more than 2 sections then we will not want to show build your guide section.
    if (Object.keys(userGuide.content).length > 2) {
      return null;
    }

    // Initialize the modules we are going to use to display the build your guide section.
    const sampleModules = [];

    // We filter out the modules that the user has already used.
    const freeTextModule = Object.entries(moduleTemplates["Free Text"]).find(
      ([moduleTemplateId]) =>
        !userUsedModules["Free Text"].includes(Number(moduleTemplateId))
    );

    // Then we push the first one onto the return array so that we have one of each.
    if (freeTextModule) {
      sampleModules.push({ ...freeTextModule[1], moduleType: "Free Text" });
    }

    // Follow the same steps for the List Modules as for the Free Text Modules.
    const listModule = Object.entries(moduleTemplates.List).find(
      ([moduleTemplateId]) =>
        !userUsedModules["List"].includes(Number(moduleTemplateId))
    );

    if (listModule) {
      sampleModules.push({ ...listModule[1], moduleType: "List" });
    }

    return (
      <BuildYourUserGuideCard
        onClickHandler={() =>
          dispatch(
            showAddModuleModal({
              guideType: "user",
              userIdOrTeamIdOrCompanyId: currentUserInfo.userAccountId,
            })
          )
        }
        modules={sampleModules}
        userIdOrTeamIdOrCompanyId={currentUserInfo.userAccountId}
        guideType="user"
      />
    );
  };

  const onUserGuideDeleteClick = () => {
    if (deleting?.id === undefined) {
      return;
    }

    if (deleting?.type === "Module") {
      dispatch(
        deleteUserModuleById({
          talentInsightsModuleId: Number(deleting.id),
        })
      );
    } else if (deleting?.type === "Section") {
      dispatch(deleteSectionById(Number(deleting.id)));
    }
  };

  const getAddUserGuideModuleBanner = () => {
    if (!isCurrentUser) {
      return null;
    }

    return (
      <div className="warning-banner blue add-module-banner">
        <p>
          Add / Edit your user profile: Add personal and job-related information
          to this profile or edit any existing information.
        </p>
        <Button
          onClick={() =>
            dispatch(
              showAddModuleModal({
                guideType: "user",
                userIdOrTeamIdOrCompanyId: currentUserInfo.userAccountId,
              })
            )
          }
        >
          <FontAwesomeIcon icon="plus" className="me-2" />
          Add to user profile
        </Button>
      </div>
    );
  };

  const getTabContent = (): React.JSX.Element | null => {
    const visibilityOnClick = getUserVisibilityOnClick({
      navigate,
      isCurrentUser: !!isCurrentUser,
      isAdmin: !!isAdmin,
    });

    const visibilityBannerParameter = {
      companySettings: companySettings,
      userVisibilitySettings,
      isAdmin: !!isAdmin,
      isCurrentUser: isCurrentUser,
      usersName: currentUserInfo.firstName,
    };

    switch (activeHeaderTab) {
      case "About":
        return (
          <div className="guide-body">
            {getAddUserGuideModuleBanner()}
            {getUserGuideStatus === "loading" ? (
              <Loading />
            ) : (
              <div className="sections">
                {isSampleUser && <SampleBanner entity="user guide" />}
                {getUserSections()}
                {getBuildYourUserGuide()}
              </div>
            )}
            <div className="side-bar">
              <div>{getContactCard()}</div>
              <div>{getTeamsCard()}</div>
            </div>
          </div>
        );
      case "Workplace Insights":
        return (
          <div>
            {workplaceInsightsReport && !isSampleUser ? (
              <VisibilityBanner button={visibilityOnClick}>
                <p>
                  {getUserVisibilityBannerText({
                    ...visibilityBannerParameter,
                    userVisibilityKey: "Workplace Insights",
                  })}
                </p>
              </VisibilityBanner>
            ) : null}
            <WorkPlaceInsightsReport
              workplaceInsightsReport={workplaceInsightsReport}
              userAccountId={currentUserInfo.userAccountId}
              firstName={currentUserInfo.firstName}
              pendingTestId={pendingTests?.[0]?.eventId}
            />
          </div>
        );
      case "Manager Guide":
        return (
          <div>
            {getManagerGuideStatus === "succeeded" && !isSampleUser ? (
              <VisibilityBanner button={visibilityOnClick}>
                <p>
                  {getUserVisibilityBannerText({
                    ...visibilityBannerParameter,
                    userVisibilityKey: "Manager Guide",
                  })}
                </p>
              </VisibilityBanner>
            ) : null}
            <ManagerReport
              managerGuide={managerGuide}
              userAccountId={currentUserInfo.userAccountId}
              loading={getManagerGuideStatus === "loading"}
              firstName={currentUserInfo.firstName}
              lastName={currentUserInfo.lastName}
            />
          </div>
        );
      case "Collaboration Guide":
        return (
          <div>
            {getCollaborationGuideStatus === "succeeded" && !isSampleUser ? (
              <VisibilityBanner button={visibilityOnClick}>
                <p>
                  {getUserVisibilityBannerText({
                    ...visibilityBannerParameter,
                    userVisibilityKey: "Collaboration Guide",
                  })}
                </p>
              </VisibilityBanner>
            ) : null}
            <CollaborationGuide
              userAccountId={currentUserInfo.userAccountId}
              loading={getCollaborationGuideStatus === "loading"}
            />
          </div>
        );
      case "Job Candidate Profile": {
        if (!isLoggedInUserManagerOrAbove) {
          return null;
        }
        return (
          <JobCandidateProfileCard
            candidateProfile={connectedCandidate}
            userInfo={currentUserInfo}
            areAIFeaturesEnabled={areAIFeaturesEnabled}
          />
        );
      }
      case "Chat History": {
        if (
          !coachBoConversationHistoryFeatureFlag ||
          !companySettings?.aiFeaturesEnabled
        ) {
          return null;
        }
        return <RecentCoachBoChats />;
      }
    }
  };

  const onSetTab = (tab: string) => {
    const selectedTab = tab as UserGuideTabs;
    if (tab === "User Settings") {
      return navigate("/UserSettings");
    }
    trackUserGuideTabChange(
      selectedTab,
      connectedCandidate?.userAccountId !== loggedInUserAccountId
    );
    setActiveHeaderTab(selectedTab);
    setSearchParams({ tab: selectedTab });
  };

  const userUsedModules = usedUserModules?.[currentUserInfo.userAccountId];
  const userGuide = userGuides?.[currentUserInfo.userAccountId];
  const companyHeaderPhoto =
    companySettings?.globalHeader || currentCompanyGuide?.coverPhoto?.picture;

  return (
    <div className="page guide">
      <ModulesModal />
      <UploadPictureModal
        modalShowing={photoModalShowing}
        closeModal={() => setPhotoModalShowing(null)}
        picture={
          photoModalShowing === "profilePicture"
            ? userGuide?.profilePicture?.base64
            : userGuide?.coverPhoto?.base64
        }
        isLoading={
          deletingGuidePhotoStatus === "loading" ||
          updatingGuidePhotoStatus === "loading"
        }
        updateGuidePhoto={(photo, imgSrc, imageName) => {
          if (!currentUserInfo.userAccountId || photoModalShowing === null) {
            return;
          }
          dispatch(
            updateGuidePhoto({
              photo,
              imgSrc,
              photoType: photoModalShowing,
              userAccountId: currentUserInfo.userAccountId,
              imageName,
            })
          );
        }}
        deleteGuidePhoto={() => {
          if (!currentUserInfo.userAccountId || photoModalShowing === null) {
            return;
          }
          dispatch(
            deleteGuidePhoto({
              userAccountId: currentUserInfo.userAccountId,
              photoType: photoModalShowing,
            })
          );
        }}
      />
      {deleting !== null && (
        <WarningModal
          modalTitle={`Delete ${
            deleting.type === "Section" ? "Section" : "Module"
          }`}
          warningTitle={`Are you sure you want to delete this ${deleting?.type.toLocaleLowerCase()}`}
          warningMessage={
            deleting.type === "Section"
              ? "All modules inside this section will be deleted as well. This action cannot be undone"
              : "This action cannot be undone"
          }
          hideModal={() => setDeleting(null)}
          onConfirmClick={onUserGuideDeleteClick}
          isOpen={deleting !== null}
          isInProgress={deletingStatus === "loading"}
        />
      )}
      <EditInformationModal
        modalShowing={showEditInformationModal}
        hideModal={() => setShowEditInformationModal(null)}
        user={currentUserInfo}
        userInfo={currentUserInfo}
        isLoading={updateUserInfoStatus === "loading"}
      />
      <div className="header-buttons">
        <NavigateBackButton />
      </div>
      <Header
        name={`${currentUserInfo.firstName} ${currentUserInfo.lastName}`}
        profilePicture={currentUserInfo.profilePicture}
        avatarColor={currentUserInfo.avatarColor}
        setPhotoModalShowing={setPhotoModalShowing}
        coverPhoto={userGuide?.coverPhoto?.picture ?? undefined}
        hasEditAccess={isCurrentUser}
        onEditClickHandler={() => setShowEditInformationModal("Personal")}
        headerDescription={currentUserInfo?.jobTitle}
        companyHeaderPhoto={companyHeaderPhoto}
        userAccountId={currentUserInfo.userAccountId}
        tabs={getAvailableTabs()}
        activeKey={activeHeaderTab}
        setTab={onSetTab}
      />
      {getTabContent()}
    </div>
  );
}
