import SimpleModal from "app/components/Modals/SimpleModal";
import Team360TeamsCard from "app/containers/Dashboard/Cards/Team360TeamsCard";
import Button from "app/storybookComponents/Button";
import SectionedDropdownItems from "app/components/Dropdowns/SectionedDropdownItems";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getSelectProps } from "utils/helperFunctions";
import { useCallback, useMemo, useState } from "react";
import {
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import { useAppSelector } from "utils/redux/hooks";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import { DropdownItem, DropdownSection } from "../Dropdowns/types";
import DepartmentTempCard from "app/containers/Dashboard/Cards/DepartmentTempCard";

interface Props {
  onHide: () => void;
  show?: boolean;
  teamIds?: number[];
  showManageSurveysButton?: boolean;
  isAdmin?: boolean;
  getEmptyCard: (selectedDepartmentId?: number) => JSX.Element;
  departmentIds?: number[];
}

export default function SurveyResultsModal({
  show,
  onHide,
  teamIds,
  showManageSurveysButton,
  isAdmin,
  getEmptyCard,
  departmentIds,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const departments = useAppSelector(selectDepartments);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);
  const [searchInput, setSearchInput] = useState("");
  // this dropdown is for filtering teams by department, all teams, or all departments. If the user is not an admin, then the dropdown will only show all teams
  const [dropdownSelected, setDropdownSelected] = useState<{
    key: string | number;
    title: string;
  }>({
    key: "allTeams",
    title: "All Teams",
  });

  const getFilteredTeamsIds = useCallback(
    (teamIds: number[]) => {
      if (!searchInput) {
        return teamIds;
      }

      return teamIds?.filter((teamId) => {
        const team = teamsById[teamId];
        if (!team) {
          return false;
        }
        // if the department name includes the search input, return true. If no department should continue to check the team name
        const department = team.departmentId
          ? departments[team.departmentId]
          : null;

        return (
          team.teamName.toLowerCase().includes(searchInput.toLowerCase()) ||
          department?.name?.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    },
    [searchInput, departments, teamsById]
  );

  const filterTeamByDropdown = useCallback(
    (teamIds: number[]) => {
      if (dropdownSelected.key === "allTeams") {
        return teamIds;
      }

      const department = departments[Number(dropdownSelected.key)];

      if (!department) {
        return [];
      }

      // only return teams that are in the selected department
      return teamIds?.filter((teamId) => {
        const team = teamsById[teamId];
        if (!team) {
          return false;
        }
        return team.departmentId === department?.departmentId;
      });
    },
    [departments, teamsById, dropdownSelected.key]
  );

  const filteredTeamsIds = getFilteredTeamsIds(
    filterTeamByDropdown(teamIds ?? [])
  );

  const onDropdownSelect = (key: string | null) => {
    if (!key) {
      return;
    }

    if (key === "allTeams") {
      setDropdownSelected({ key: "allTeams", title: "All Teams" });
      setSearchInput("");
      return;
    }

    if (key === "allDepartments") {
      setDropdownSelected({ key: "allDepartments", title: "All Departments" });
      setSearchInput("");
      return;
    }

    const department = departments[parseInt(key)];
    if (department) {
      setDropdownSelected({
        key: department?.departmentId,
        title: department?.name ?? "",
      });
      setSearchInput("");
    }
  };

  const filteredDepartmentIds = useMemo(() => {
    return (
      departmentIds ??
      Object.values(departments).map((department) => department?.departmentId)
    );
  }, [departments, departmentIds]);

  const dropdownOptions = useMemo(() => {
    const allDropdownOptions: DropdownSection[] = [];

    if (!isAdmin && !filteredDepartmentIds?.length) {
      return allDropdownOptions;
    }

    if (isAdmin) {
      const mainDropdownItems: DropdownItem[] = [];
      mainDropdownItems.push({
        title: "All Teams",
        key: "allTeams",
      });
      if (!isDepartmentsHidden) {
        mainDropdownItems.push({
          title: "All Departments",
          key: "allDepartments",
        });
      }
      allDropdownOptions.push({
        items: mainDropdownItems,
      });
    } else if (filteredDepartmentIds?.length) {
      const mainDropdownItems: DropdownItem[] = [];
      mainDropdownItems.push({
        title: "My Teams",
        key: "allTeams",
      });
      if (!isDepartmentsHidden) {
        mainDropdownItems.push({
          title: "My Departments",
          key: "allDepartments",
        });
      }
      allDropdownOptions.push({
        items: mainDropdownItems,
      });
    }

    if (!isDepartmentsHidden) {
      const dropdownItems: DropdownItem[] = [];
      filteredDepartmentIds.forEach((departmentId) => {
        const department = departments[Number(departmentId)];
        dropdownItems.push({
          title: department?.name ?? "",
          key: department?.departmentId,
        });
      });

      allDropdownOptions.push({
        title: "Filter by teams in a department",
        items: dropdownItems,
      });
    }

    return allDropdownOptions;
  }, [departments, isAdmin, isDepartmentsHidden, filteredDepartmentIds]);

  const getSelectPlaceHolder = () => {
    if (isAdmin && !isDepartmentsHidden) {
      return "Search by team, department...";
    }
    return "Search by team...";
  };

  const onGetEmptyCard = () => {
    if (
      dropdownSelected?.key === "allDepartments" ||
      dropdownSelected?.key === "allTeams"
    ) {
      return getEmptyCard();
    }

    return getEmptyCard(Number(dropdownSelected?.key));
  };

  const getModalBody = () => {
    if (dropdownSelected?.key === "allDepartments") {
      return filteredDepartmentIds?.length ? (
        <DepartmentTempCard departmentIds={filteredDepartmentIds} hideTodoTab />
      ) : (
        onGetEmptyCard()
      );
    }

    if (!filteredTeamsIds?.length) {
      return onGetEmptyCard();
    }

    return (
      <Team360TeamsCard userTeamIds={filteredTeamsIds ?? []} hideTodoTab />
    );
  };

  const { selectStyles, components } = getSelectProps("search");
  return (
    <SimpleModal show={show} title="TEAMscan Results" onHide={onHide}>
      <div className="row-gap-12px">
        {(isAdmin || departmentIds?.length) && !isDepartmentsHidden ? ( // Only if the user is an admin or leader of any department, show the dropdown to filter by department
          <SectionedDropdownItems
            sections={dropdownOptions}
            onSelect={onDropdownSelect}
            selected={dropdownSelected}
          />
        ) : null}
        <Form.Group className="w-100">
          <Select
            placeholder={getSelectPlaceHolder()}
            isClearable={true}
            isSearchable={true}
            components={components}
            inputValue={searchInput}
            styles={selectStyles}
            menuIsOpen={false}
            onInputChange={(e, actionMeta) => {
              if (actionMeta.action === "input-change") {
                setSearchInput(e);
              }
            }}
          />
        </Form.Group>
      </div>
      {getModalBody()}
      {showManageSurveysButton ? (
        <div>
          <Button
            variant="secondary-blue"
            onClick={() => {
              navigate("/AdminConsole/Surveys");
            }}
          >
            Manage surveys
          </Button>
        </div>
      ) : null}
    </SimpleModal>
  );
}
