import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import {
  acceptInvitation,
  declineInvitation,
  selectDismissedNotificationIds,
  selectAllTeamInvitations,
  selectAllPendingAssessmentsNotifications,
  selectAcceptInvitationStatus,
  selectAllAssessmentResultsNotifications,
  selectAllTeamCreationNotifications,
  selectAllAddTeamMemberNotifications,
  selectAllSendTeamScanReminderNotifications,
  selectAllLaunchTeamScanNotifications,
  dismissNotification,
  selectAllNewDirectReportNotifications,
  selectConnectedYourPersonalityReportNotification,
  selectAllChatWithDirectReportNotifications,
} from "./slice";
import {
  getConnectEppNotificationText,
  getTeamName,
  onChatWithDirectReportNotificationClick,
} from "./helpers";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";
import { setIsNotificationDropdownOpen } from "../GlobalNavbar/slice";
import {
  openCreateTeamModal,
  setIsNewDirectReportModalOpen,
} from "../Modals/slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { sendReminder } from "app/containers/Dashboard/slice";
import { TeamNotification } from "./types";
import NotificationCard from "./NotificationCard";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";
import {
  getManagerAndDirectReports,
  selectManagerAndDirectReportsByUserAccountId,
} from "app/containers/AdvancedCsvUpload/slice";

interface Props {
  onInviteUsersToTeam: (teamId: number) => void;
}

// This is for the navbar notification dropdown
const NotificationsCard = ({ onInviteUsersToTeam }: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const allAssessmentResultsNotifications = useAppSelector(
    selectAllAssessmentResultsNotifications
  );
  const allTeamCreationNotifications = useAppSelector(
    selectAllTeamCreationNotifications
  );
  const dismissedNotificationIds = useAppSelector(
    selectDismissedNotificationIds
  );
  const allAddTeamMemberNotifications = useAppSelector(
    selectAllAddTeamMemberNotifications
  );
  const allSendTeamScanReminderNotifications = useAppSelector(
    selectAllSendTeamScanReminderNotifications
  );
  const allLaunchTeamScanNotifications = useAppSelector(
    selectAllLaunchTeamScanNotifications
  );
  const allNewDirectReportNotifications = useAppSelector(
    selectAllNewDirectReportNotifications
  );
  const connectYourPersonalityReportNotification = useAppSelector(
    selectConnectedYourPersonalityReportNotification
  );
  const allChatWithDirectReportNotifications = useAppSelector(
    selectAllChatWithDirectReportNotifications
  );
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const acceptInvitationStatus = useAppSelector(selectAcceptInvitationStatus);
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const canUserInviteUsers = useAppSelector(selectUserIsAbleToInviteUsers);
  const companySettings = useAppSelector(selectCompanySettings);
  const managersAndDirectReportsByUserAccountId = useAppSelector(
    selectManagerAndDirectReportsByUserAccountId
  );

  const [showRemindWarningModal, setShowRemindWarningModal] =
    useState<null | TeamNotification>(null);

  const currentUsersDirectReports = useMemo(() => {
    if (
      !currentUserAccountId ||
      !managersAndDirectReportsByUserAccountId[currentUserAccountId]
        ?.directReportRelationships
    ) {
      return [];
    }

    return (
      managersAndDirectReportsByUserAccountId[currentUserAccountId]
        ?.directReportRelationships ?? []
    );
  }, [managersAndDirectReportsByUserAccountId, currentUserAccountId]);

  useEffect(() => {
    if (!currentUserAccountId) return;
    dispatch(
      getManagerAndDirectReports({ userAccountId: currentUserAccountId })
    );
  }, [dispatch, currentUserAccountId]);

  const onActionItemClick = useCallback(() => {
    dispatch(setIsNotificationDropdownOpen(false));
  }, [dispatch]);

  const pendingTeamNotificationsElms = useMemo(() => {
    const onAcceptInvitationClick = async (
      teamId: number,
      notificationId: number
    ) => {
      await dispatch(acceptInvitation({ teamId, notificationId }));
      navigate(`/TeamGuide/${teamId}`);
      onActionItemClick();
    };

    const pendingNotifications: React.JSX.Element[] = [];
    allPendingTeamNotifications.forEach((notification) => {
      const teamObj = teamsById[notification.teamId];
      if (!teamObj) {
        return;
      }
      const isDismissed = dismissedNotificationIds.includes(
        notification.notificationId
      );
      if (isDismissed) {
        return;
      }
      const { teamId, notificationId } = notification;
      pendingNotifications.push(
        <NotificationCard
          key={notification.notificationId}
          notificationTitle={
            <p>
              You've been invited to join <b>{teamObj.teamName}</b>
            </p>
          }
          primaryActionButton={{
            label: "Join team",
            onClick: () => onAcceptInvitationClick(teamId, notificationId),
            disabled: acceptInvitationStatus === "loading",
          }}
          secondaryActionButton={{
            label: "Decline",
            onClick: () =>
              dispatch(declineInvitation({ teamId, notificationId })),
          }}
        />
      );
    });
    return pendingNotifications;
  }, [
    allPendingTeamNotifications,
    teamsById,
    dispatch,
    dismissedNotificationIds,
    navigate,
    acceptInvitationStatus,
    onActionItemClick,
  ]);

  const pendingAssessmentNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allPendingAssessmentNotifications.forEach((notification) => {
      const teamInfo = notification?.teamId && teamsById[notification.teamId];
      const isDismissed = dismissedNotificationIds.includes(
        notification.notificationId
      );

      if (isDismissed) {
        return;
      }

      if (
        !teamInfo ||
        isDismissed ||
        // This might be a temporary way to handle this, but if the team is private and the user is not a member of the team, then we don't want to show the notification
        (teamInfo.private === 1 && Object.keys(teamInfo).length === 1)
      ) {
        return;
      }

      pendingNotifications.push(
        <NotificationCard
          key={notification.notificationId}
          notificationTitle={
            <p>
              You have been invited by {notification.invitedByFirstName} to take
              the TEAMscan{" "}
              {teamInfo ? (
                <>
                  for <b>{getTeamName(teamInfo.teamId, teamsById)}</b>
                </>
              ) : null}
              .
            </p>
          }
          primaryActionButton={{
            label: notification.droppedOffAt ? "Resume Survey" : "Begin Survey",
            onClick: () => {
              navigate(
                `/survey/team360?eventId=${notification.eventId}&teamId=${notification.teamId}`
              );
              onActionItemClick();
            },
          }}
        />
      );
    });
    return pendingNotifications;
  }, [
    allPendingAssessmentNotifications,
    teamsById,
    dismissedNotificationIds,
    navigate,
    onActionItemClick,
  ]);

  const assessmentResultsNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allAssessmentResultsNotifications.forEach(({ notificationId, teamId }) => {
      const teamInfo = teamId && teamsById[teamId];

      if (!teamInfo) {
        return;
      }

      pendingNotifications.push(
        <NotificationCard
          key={notificationId}
          notificationTitle={
            <p>
              You have unread TEAMscan results for{" "}
              <b>{getTeamName(teamId, teamsById)}</b>.
            </p>
          }
          primaryActionButton={{
            label: "View Results",
            onClick: () => {
              navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
              dispatch(dismissNotification(notificationId));
              onActionItemClick();
            },
          }}
        />
      );
    });
    return pendingNotifications;
  }, [
    allAssessmentResultsNotifications,
    teamsById,
    navigate,
    dispatch,
    onActionItemClick,
  ]);

  const teamCreationNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    if (!canUserCreateTeams) {
      return pendingNotifications;
    }

    allTeamCreationNotifications.forEach(({ notificationId }) => {
      pendingNotifications.push(
        <NotificationCard
          key={notificationId}
          notificationTitle={
            <p>Are you a team leader? Create your first team now.</p>
          }
          primaryActionButton={{
            label: "Create a team",
            onClick: () => {
              dispatch(openCreateTeamModal());
              onActionItemClick();
            },
          }}
          secondaryActionButton={{
            label: "Dismiss",
            onClick: () => dispatch(dismissNotification(notificationId)),
          }}
        />
      );
    });
    return pendingNotifications;
  }, [
    allTeamCreationNotifications,
    dispatch,
    canUserCreateTeams,
    onActionItemClick,
  ]);

  const addTeamMemberNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    let filteredMemberInvitations = [...allAddTeamMemberNotifications];
    // if the user cannot invite new users we should hide any notifications that don't have a teamId
    if (!canUserInviteUsers) {
      filteredMemberInvitations = filteredMemberInvitations.filter(
        (notification) => !!notification.teamId
      );
    }
    filteredMemberInvitations.forEach(({ notificationId, teamId }) => {
      pendingNotifications.push(
        <NotificationCard
          key={notificationId}
          notificationTitle={
            <p>
              Invite members to{" "}
              <b>{teamId ? getTeamName(teamId, teamsById) : "Organization"}</b>
            </p>
          }
          primaryActionButton={{
            label: "Invite a team member",
            onClick: () => {
              if (teamId) onInviteUsersToTeam(teamId);
            },
          }}
          secondaryActionButton={{
            label: "Dismiss",
            onClick: () => dispatch(dismissNotification(notificationId)),
          }}
        />
      );
    });
    return pendingNotifications;
  }, [
    allAddTeamMemberNotifications,
    dispatch,
    canUserInviteUsers,
    teamsById,
    onInviteUsersToTeam,
  ]);

  const sendTeamScanReminderNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];

    allSendTeamScanReminderNotifications.forEach(
      ({ notificationId, teamId }) => {
        pendingNotifications.push(
          <NotificationCard
            key={notificationId}
            notificationTitle={
              <p>
                Remind team members to take the TEAMscan for{" "}
                <b>{getTeamName(teamId, teamsById)}</b>.
              </p>
            }
            primaryActionButton={{
              label: "Remind",
              onClick: () => {
                setShowRemindWarningModal({ notificationId, teamId });
              },
            }}
            secondaryActionButton={{
              label: "Dismiss",
              onClick: () => dispatch(dismissNotification(notificationId)),
            }}
          />
        );
      }
    );
    return pendingNotifications;
  }, [allSendTeamScanReminderNotifications, dispatch, teamsById]);

  const launchTeamScanNotificationsElms = useMemo(() => {
    return allLaunchTeamScanNotifications.map(({ notificationId, teamId }) => {
      const text = (
        <p>
          Launch TEAMscan survey{" "}
          {teamId ? (
            <>
              for <b>{getTeamName(teamId, teamsById)}</b>
            </>
          ) : null}{" "}
          to start building teamwork on this team.
        </p>
      );

      return (
        <NotificationCard
          key={notificationId}
          notificationTitle={text}
          primaryActionButton={{
            label: "Launch survey",
            onClick: () => {
              if (teamId) {
                dispatch(showScheduleAssessmentModalForTeamId(teamId));
              } else {
                dispatch(showScheduleAssessmentModal());
              }
            },
          }}
          secondaryActionButton={{
            label: "Dismiss",
            onClick: () => dispatch(dismissNotification(notificationId)),
          }}
        />
      );
    });
  }, [allLaunchTeamScanNotifications, dispatch, teamsById]);

  const newDirectReportNotificationsElms = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allNewDirectReportNotifications.forEach(
      ({ notificationId, subjectUserAccountId }) => {
        const userInfo =
          subjectUserAccountId && usersById[subjectUserAccountId];
        if (!userInfo) {
          return;
        }
        const firstName = userInfo.firstName ?? "";
        const lastName = userInfo.lastName ?? "";

        pendingNotifications.push(
          <NotificationCard
            key={notificationId}
            notificationTitle={
              <p>
                You have a new direct report, {firstName} {lastName}.
              </p>
            }
            primaryActionButton={{
              label: "View Details",
              onClick: () => {
                dispatch(
                  setIsNewDirectReportModalOpen({
                    userAccountId: Number(subjectUserAccountId),
                  })
                );
                dispatch(dismissNotification(notificationId));
                onActionItemClick();
              },
            }}
            secondaryActionButton={{
              label: "Dismiss",
              onClick: () => dispatch(dismissNotification(notificationId)),
            }}
          />
        );
      }
    );
    return pendingNotifications;
  }, [allNewDirectReportNotifications, dispatch, onActionItemClick, usersById]);

  const connectYourPersonalityReportNotificationsElm = useMemo(() => {
    if (!connectYourPersonalityReportNotification) return [];
    const { notificationId } = connectYourPersonalityReportNotification;
    return [
      <NotificationCard
        key={notificationId}
        notificationTitle={
          <p>{getConnectEppNotificationText(companySettings)}</p>
        }
        primaryActionButton={{
          label: "Connect personality profile",
          onClick: () => {
            navigate("/UserGuide?tab=Workplace+Insights");
            onActionItemClick();
          },
        }}
        secondaryActionButton={{
          label: "Dismiss",
          onClick: () => dispatch(dismissNotification(notificationId)),
        }}
      />,
    ];
  }, [
    connectYourPersonalityReportNotification,
    dispatch,
    navigate,
    companySettings,
    onActionItemClick,
  ]);

  const getChatWithDirectReportNotificationElm = useMemo(() => {
    const pendingNotifications: React.JSX.Element[] = [];
    allChatWithDirectReportNotifications.forEach(
      ({ notificationId, subjectUserAccountId }) => {
        const userInfo =
          subjectUserAccountId && usersById[subjectUserAccountId];
        if (!userInfo) {
          return;
        }
        const { firstName = "", lastName = "", emailAddress } = userInfo;
        const name =
          firstName || lastName
            ? `${firstName} ${lastName}`.trim()
            : emailAddress;

        const isDirectReport = currentUsersDirectReports.some(
          (dR) => dR.employeeUserAccountId === subjectUserAccountId
        );

        pendingNotifications.push(
          <NotificationCard
            key={notificationId}
            notificationTitle={
              <p>
                Chat with Coach Bo about how to support your{" "}
                {isDirectReport ? "direct report" : "team member"},{" "}
                <b>{name}</b>.
              </p>
            }
            primaryActionButton={{
              label: "Chat with Coach Bo",
              onClick: () => {
                onChatWithDirectReportNotificationClick({
                  userAccountId: subjectUserAccountId,
                  dispatch,
                  name,
                });
                onActionItemClick();
              },
            }}
            secondaryActionButton={{
              label: "Dismiss",
              onClick: () => dispatch(dismissNotification(notificationId)),
            }}
          />
        );
      }
    );
    return pendingNotifications;
  }, [
    allChatWithDirectReportNotifications,
    dispatch,
    onActionItemClick,
    usersById,
    currentUsersDirectReports,
  ]);

  const displayNotification = () => {
    const pendingNotifications = [
      ...connectYourPersonalityReportNotificationsElm,
      ...teamCreationNotificationsElms,
      ...addTeamMemberNotificationsElms,
      ...launchTeamScanNotificationsElms,
      ...sendTeamScanReminderNotificationsElms,
      ...pendingTeamNotificationsElms,
      ...assessmentResultsNotificationsElms,
      ...pendingAssessmentNotificationsElms,
      ...newDirectReportNotificationsElms,
      ...getChatWithDirectReportNotificationElm,
    ];
    if (pendingNotifications?.length) {
      return <div>{pendingNotifications}</div>;
    }

    return (
      <div
        className="empty-card"
        style={{
          margin: "16px",
          padding: "20px",
        }}
      >
        <span>No notifications</span>
        <p>
          Invites to join teams or to take the TEAMscan survey will be shown
          here
        </p>
      </div>
    );
  };

  return (
    <>
      <WarningModal
        modalTitle="Remind Team Members"
        warningTitle="Remind all team members who have not completed the TEAMscan?"
        warningMessage="Send out an email reminder to an team members who have not completed the TEAMscan survey for this instance."
        isOpen={!!showRemindWarningModal}
        onConfirmClick={async () => {
          if (!showRemindWarningModal) return;
          await dispatch(
            sendReminder({
              reminderType: "remindUserToTakeAssessment",
              teamId: showRemindWarningModal.teamId,
            })
          );
          dispatch(dismissNotification(showRemindWarningModal.notificationId));
          setShowRemindWarningModal(null);
        }}
        hideModal={() => {
          setShowRemindWarningModal(null);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      <div className="notification-box">
        <div
          style={{
            padding: "16px",
            paddingBottom: "0px",
          }}
        >
          <h3>Notifications</h3>
        </div>
        {displayNotification()}
      </div>
    </>
  );
};

export default memo(NotificationsCard);
