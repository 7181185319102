import { AppDispatch } from "utils/redux/store";
import {
  addError,
  removeError,
  getErrorsByType,
  checkSingleUserEmail,
  setRevalidateBeforeMovingToNextStep,
  checkManagerEmail,
} from "./slice";
import {
  CheckedEmails,
  NewPreviewCSVResponseError,
  RowData,
  StoredRows,
} from "./types";

export const isGenderValid = (gender?: string) => {
  if (!gender) {
    return false;
  }

  const lowerCaseValue = gender.toLowerCase();
  switch (lowerCaseValue) {
    case "male":
    case "female":
    case "non-binary":
    case "not disclosed":
      return true;
    default:
      return false;
  }
};

export const onGenderFieldUnFocus =
  (dispatch: AppDispatch) => async (rowId: number, updatedValue?: string) => {
    const columnName = "gender";
    if (updatedValue && !isGenderValid(updatedValue)) {
      await dispatch(
        addError({
          rowId,
          columnName,
          errorType: "invalidGender",
        })
      );
    } else {
      await dispatch(removeError({ rowId, columnName }));
    }

    dispatch(getErrorsByType());
  };

export const onRequiredFieldUnFocus =
  (
    dispatch: AppDispatch,
    csvUploadErrors: NewPreviewCSVResponseError,
    columnName: string
  ) =>
  async (rowId: number, updatedValue?: string) => {
    // first check if there is already an error for this field
    const error = csvUploadErrors?.[rowId]?.[columnName];

    // if already existing error and the field is empty then we don't need to do anything
    if (error && !updatedValue) {
      return;
    }

    // dispatch action to add the error to the state
    if (!updatedValue) {
      await dispatch(
        addError({ rowId, columnName, errorType: "missingField" })
      );
      // if there is no error and the field is not empty then we need to remove the error
    } else {
      await dispatch(removeError({ rowId, columnName }));
    }

    dispatch(getErrorsByType());
  };

export const isDangerFunction =
  (columnName: string, csvUploadErrors: NewPreviewCSVResponseError) =>
  (rowData: RowData) => {
    return !!csvUploadErrors?.[rowData.id]?.[columnName]?.[0].message;
  };

export const isDangerFnForEmail =
  (csvUploadErrors: NewPreviewCSVResponseError, checkedEmails: CheckedEmails) =>
  (rowData: RowData) => {
    if (!rowData.emailAddress) {
      return true;
    }
    const error = csvUploadErrors?.[rowData.id]?.emailAddress;
    const isInvalidEmail =
      checkedEmails?.[rowData.emailAddress]?.isValid === false;
    return !!(error || isInvalidEmail);
  };

export const checkForDuplicates = (
  rowData: StoredRows,
  email: string,
  rowId: number
): {
  isDuplicate: boolean;
  rowId: number | null;
} => {
  // if there is a duplicate then we return the rowId
  for (const key in rowData) {
    const { emailAddress, id } = rowData[key];
    if (
      String(emailAddress)?.toLowerCase() === email.toLowerCase() &&
      rowId !== id
    ) {
      return { isDuplicate: true, rowId: id };
    }
  }

  return { isDuplicate: false, rowId: null };
};
export const checkForDuplicatesHandler = (
  dispatch: AppDispatch,
  rowData: StoredRows,
  updatedValue: string,
  rowId: number
) => {
  const { isDuplicate, rowId: duplicateRowIds } = checkForDuplicates(
    rowData,
    updatedValue,
    rowId
  );
  // remove error if there is no duplicate
  if (!isDuplicate) {
    dispatch(removeError({ rowId, columnName: "emailAddress" }));
    return;
  }

  dispatch(
    addError({
      rowId,
      columnName: "emailAddress",
      errorType: "duplicateEmail",
    })
  );

  if (duplicateRowIds) {
    dispatch(
      addError({
        rowId: duplicateRowIds,
        columnName: "emailAddress",
        errorType: "duplicateEmail",
      })
    );
  }
  return isDuplicate;
};

export const onEmailUnFocus =
  (
    dispatch: AppDispatch,
    checkedEmails: CheckedEmails,
    csvUploadErrors: NewPreviewCSVResponseError,
    rowData: StoredRows
  ) =>
  async (rowId: number, updatedValue?: string) => {
    // If the email is empty then we need to add an error for missing field
    if (!updatedValue) {
      await dispatch(
        addError({
          rowId,
          columnName: "emailAddress",
          errorType: "missingField",
        })
      );
      return dispatch(getErrorsByType());
    }
    // The following is needed to revalidate just in case the user adds a duplicate email.
    dispatch(setRevalidateBeforeMovingToNextStep(true));
    // if already valid and we already checked the email then we don't need to do anything
    if (checkedEmails?.[updatedValue]?.isValid) {
      await dispatch(removeError({ rowId, columnName: "emailAddress" }));
      return dispatch(getErrorsByType());
    }

    // Only if we recogize as duplicate email we need to check for duplicates to reduce the number of iterations
    if (
      csvUploadErrors?.[rowId]?.emailAddress?.[0]?.errorType ===
      "duplicateEmail"
    ) {
      const isDuplicate = checkForDuplicatesHandler(
        dispatch,
        rowData,
        updatedValue,
        rowId
      );
      dispatch(getErrorsByType());
      if (isDuplicate) {
        return;
      }
    }

    // We need to check that the email is not the same email as the manager email, if so we need to add an error
    if (rowData[rowId].managerEmailAddress === updatedValue) {
      await dispatch(
        addError({
          rowId,
          columnName: "emailAddress",
          errorType: "emailSameAsManager",
        })
      );
      return dispatch(getErrorsByType());
    }

    const response = (await dispatch(
      checkSingleUserEmail({ emailAddress: updatedValue, rowId })
    )) as {
      payload?: { isValid: boolean; errorType?: string; errorMessage?: string };
    };

    const responsePayload = response?.payload ?? checkedEmails?.[updatedValue];
    // Once checked we can check if the email is valid, if it is then we need to remove the error
    if (responsePayload?.isValid) {
      await dispatch(removeError({ rowId, columnName: "emailAddress" }));
    } else {
      await dispatch(
        addError({
          rowId,
          columnName: "emailAddress",
          errorType: responsePayload?.errorType ?? "invalidEmail",
        })
      );
    }

    dispatch(getErrorsByType());
  };

export const onManagerEmailUnFocus =
  (
    dispatch: AppDispatch,
    checkedManagerEmails: CheckedEmails,
    rowData: StoredRows
  ) =>
  async (rowId: number, updatedManagerEmail?: string) => {
    // since manager email is not required we will remove the error if the field is empty
    if (!updatedManagerEmail) {
      await dispatch(removeError({ rowId, columnName: "managerEmailAddress" }));
      dispatch(getErrorsByType());
      return;
    }

    // We need to check that the email is not the same email as the manager email, if so we need to add an error
    if (updatedManagerEmail === rowData[rowId].emailAddress) {
      await dispatch(
        addError({
          rowId,
          columnName: "managerEmailAddress",
          errorType: "emailSameAsManager",
        })
      );
      return dispatch(getErrorsByType());
    }

    const response = (await dispatch(
      checkManagerEmail({ emailAddress: updatedManagerEmail, rowId })
    )) as { payload?: { isValid: boolean } };

    // Once checked we can check if the email is valid, if it is then we need to remove the error
    const isValidEmail =
      response?.payload?.isValid ??
      checkedManagerEmails?.[updatedManagerEmail]?.isValid;

    if (isValidEmail) {
      await dispatch(removeError({ rowId, columnName: "managerEmailAddress" }));
    } else {
      await dispatch(
        addError({
          rowId,
          columnName: "managerEmailAddress",
          errorType: "managerEmailNotFound",
        })
      );
    }

    dispatch(getErrorsByType());
  };

export const isManagerEmailDanger =
  (
    checkedManagerEmails: CheckedEmails,
    csvUploadErrors: NewPreviewCSVResponseError
  ) =>
  (rowData: RowData) => {
    const managerEmailAddress = rowData.managerEmailAddress;
    const existingError = csvUploadErrors?.[rowData.id]?.managerEmailAddress;
    const isInvalidEmail =
      managerEmailAddress &&
      checkedManagerEmails?.[managerEmailAddress]?.isValid === false;

    return !!(existingError || isInvalidEmail);
  };
