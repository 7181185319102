import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PermissionChartTemplate } from "./types";

interface PermissionChartProps {
  template: PermissionChartTemplate;
  columns: {
    label: string;
    key: string;
  }[];
}

export default function PermissionChart({
  template,
  columns,
}: Readonly<PermissionChartProps>) {
  const getCellValue = (val: 0 | 1 | null | undefined) => {
    if (val === 0) {
      return (
        <FontAwesomeIcon
          icon={["far", "circle-xmark"]}
          style={{
            color: "#A9AAAC",
          }}
        />
      );
    }
    if (val === 1) {
      return (
        <FontAwesomeIcon
          icon="circle-check"
          style={{
            color: "#001F4E",
          }}
        />
      );
    }
    return "--";
  };

  const getRows = (template: PermissionChartTemplate) => {
    const rows: JSX.Element[] = [];
    Object.entries(template).forEach(([rowName, row]) => {
      const cells = columns.map(({ key, label }) => {
        return <td key={label}>{getCellValue(row[key])}</td>;
      });
      rows.push(
        <tr key={rowName}>
          <td className="row-name">{rowName}</td>
          {cells}
        </tr>
      );
    });
    return rows;
  };

  const getHeaders = (columns: { label: string; key: string }[]) =>
    columns.map((header) => <th key={header.key}>{header.label}</th>);

  return (
    <table className="permission-chart-table">
      <thead>
        <tr>
          <th className="row-name">Report</th>
          {getHeaders(columns)}
        </tr>
      </thead>
      <tbody>{getRows(template)}</tbody>
    </table>
  );
}
