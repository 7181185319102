import { CompanySettings } from "../AdminConsole/types";
import { TeamVisibilitySetting } from "./types";

export const getModuleOrder = (moduleOrderString: string) => {
  try {
    return JSON.parse(moduleOrderString) || [];
  } catch (e) {
    return [];
  }
};

export const getTeamVisibilitySetText = (
  tvs?: TeamVisibilitySetting | null
) => {
  if (!tvs) {
    return "";
  }

  if (tvs["Organization Member"]) {
    return "everyone in this organization";
  }

  const visibilityArr: string[] = [];

  if (tvs["Department Leader"]) {
    visibilityArr.push("department leader");
  }
  if (tvs["Team Leader"]) {
    visibilityArr.push("team leader(s)");
  }
  if (tvs["Team Member"]) {
    visibilityArr.push("team member(s)");
  }
  // on the last item inside of the array pop off, add "and" and push back on
  if (visibilityArr.length > 1) {
    visibilityArr.push(`and ${visibilityArr.pop()}`);
  }

  return visibilityArr.join(visibilityArr.length > 2 ? ", " : " ");
};

export const getTeamVisibilityBanner = ({
  isAdmin,
  isTeamLeader,
  isDepartmentLeader,
  companySettings,
  teamVisibilitySetting,
}: {
  isAdmin: boolean;
  isTeamLeader: boolean;
  isDepartmentLeader: boolean;
  companySettings: CompanySettings | null;
  teamVisibilitySetting?: TeamVisibilitySetting | null;
}) => {
  const { allowVisibilityPermissionOverride } = companySettings || {};

  const visibilitySet = getTeamVisibilitySetText(teamVisibilitySetting);

  const everyoneInOrg = visibilitySet === "everyone in this organization";
  const asWellAsAdmins = everyoneInOrg ? "" : " as well as admins";
  const thisTeams = everyoneInOrg ? "" : "this team's ";

  if (isTeamLeader) {
    return (
      <p>
        This report is visible to {thisTeams}
        <b>{visibilitySet}</b>
        {asWellAsAdmins}.{" "}
        {allowVisibilityPermissionOverride
          ? "As a team leader, you can change these visibility settings."
          : "Only admins can configure organization-wide visibility defaults."}
      </p>
    );
  }

  if (isAdmin) {
    return (
      <p>
        This report is visible to {thisTeams}
        <b>{visibilitySet}</b>
        {asWellAsAdmins}. As an admin, you can configure organization-wide
        visibility defaults.
      </p>
    );
  }

  if (isDepartmentLeader) {
    return (
      <p>
        This report is visible to {thisTeams}
        <b>{visibilitySet}</b>
        {asWellAsAdmins}.{" "}
        {allowVisibilityPermissionOverride
          ? `Only the team leader can change these visibility settings. `
          : null}
        Only admins can configure organization-wide visibility defaults.
      </p>
    );
  }

  return (
    <p>
      This report is visible to {thisTeams}
      {everyoneInOrg}
      <b>{visibilitySet}</b>
      {asWellAsAdmins}.{" "}
      {allowVisibilityPermissionOverride
        ? "Only the team leader can change these visibility settings."
        : "Only admins can configure organization-wide visibility defaults."}
    </p>
  );
};

export const getTeamVisibilityOnClick = ({
  navigate,
  isAdmin,
  isTeamLeader,
  teamId,
}: {
  navigate: (path: string) => void;
  isAdmin: boolean;
  isTeamLeader: boolean;
  teamId: number;
}):
  | undefined
  | {
      onClick: () => void;
      text: string;
    } => {
  if (isTeamLeader) {
    return {
      onClick: () =>
        navigate(
          `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=general`
        ),
      text: "Change team visibility settings",
    };
  }

  if (isAdmin) {
    return {
      onClick: () => navigate("/AdminConsole/Settings#visibility-settings"),
      text: "Change organization visibility settings",
    };
  }
};

export const hasPermissionToViewReport = (
  tcs: TeamVisibilitySetting | null | undefined,
  isAdmin: boolean,
  isTeamLeader: boolean,
  isTeamMember: boolean
) => {
  if (isAdmin) {
    return true;
  }

  if (isTeamLeader) {
    return true;
  }

  if (isTeamMember && tcs?.["Team Member"]) {
    return true;
  }

  return tcs?.["Organization Member"] || false;
};
