import SimpleCollapsibleCard from "app/storybookComponents/CollapsibleCards/SimpleCollapsibleCard";
import Button from "app/storybookComponents/Button";
import React, { useEffect, useState } from "react";
import Checklist from "./Checklist";
import { ACTION_CHECKLIST_TEMPLATE } from "./constants";
import SideDrawer from "../SideDrawer/SideDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TChecklistContent } from "./types";

interface Props {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  onSeeCommentsClick?: () => void;
  type?: "Team" | "Department" | "Organization";
  isLeader?: boolean;
}

export default function TeamScanActionChecklistDrawer({
  isOpen,
  setIsOpen,
  onSeeCommentsClick,
  type = "Team",
  isLeader,
}: Readonly<Props>) {
  const [cardOpened, setCardOpened] = useState<string | null>(
    "Review Overall Results"
  );
  useEffect(() => {
    const template = ACTION_CHECKLIST_TEMPLATE[type];
    const temp = template.keys().next();
    setCardOpened(temp.value);
  }, [type]);

  const getCard = (title: string, checklistTemplate: TChecklistContent) => {
    const isReviewCommentsCard = title === "Review Comments";
    if (isReviewCommentsCard && !onSeeCommentsClick) {
      return null;
    }
    const { checklist, instructions, showInstructionOnlyForTeamLeaders } =
      checklistTemplate;

    return (
      <SimpleCollapsibleCard
        title={title}
        isOpen={cardOpened === title}
        setIsOpen={(val: boolean) => setCardOpened(val ? title : null)}
      >
        <div className="column-gap-20px">
          {!showInstructionOnlyForTeamLeaders || isLeader
            ? instructions?.map(({ title, bulletPoints, descriptions }) => (
                <React.Fragment key={title}>
                  <div className="column-gap-20px">
                    <div
                      className="column-gap-16px"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {title ? (
                        <p>
                          <b>{title}</b>
                        </p>
                      ) : null}
                      {descriptions?.map((description) => (
                        <p>{description}</p>
                      )) ?? null}
                    </div>
                    <ul
                      className="column-gap-16px"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {bulletPoints.map((point) => (
                        <li key={point}>{point}</li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              ))
            : null}
          {isReviewCommentsCard ? (
            <Button
              variant="secondary-blue"
              onClick={onSeeCommentsClick}
              style={{
                width: "100%",
                marginBottom: "12px",
              }}
            >
              Review comments for your {type.toLowerCase()}
              <FontAwesomeIcon icon="arrow-right" className="ms-2" />
            </Button>
          ) : null}
          {checklist ? (
            <Checklist
              template={checklist}
              isContentChecked={{}}
              onCheck={() => {}}
            />
          ) : null}
        </div>
      </SimpleCollapsibleCard>
    );
  };

  const getCards = () => {
    const template = ACTION_CHECKLIST_TEMPLATE[type];
    const cards: JSX.Element[] = [];
    template.forEach((value, key) => {
      const card = getCard(key, value);
      if (card) {
        cards.push(card);
      }
    });
    return cards;
  };

  return (
    <SideDrawer
      show={isOpen}
      onHide={() => setIsOpen(false)}
      title="Discussion Checklist"
      className="action-checklist-drawer"
    >
      {getCards()}
    </SideDrawer>
  );
}
