import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { TeamComments } from "app/containers/AdminConsole/types";
import { StartAndEndDateOrInstance } from "app/components/SurveyDataInstances/types";
import { getAppendedUrl } from "app/components/InstancePicker/helpers";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { TIME_INTERVAL_OPTIONS } from "app/components/InstancePicker/constants";
import {
  DepartmentInsightReport,
  GenderInsightsResponse,
  ConversationPartnerType,
  ConversationsToSuggestPayload,
  SavedConversationsToSuggest,
} from "./types";
import { getTeam360Scores } from "app/components/SurveyDataInstances/slice";
import { InstanceShowing } from "app/components/InstancePicker/types";
import { TeamAssessmentInstance } from "app/containers/Assessment/types";
import { ConversationPartner } from "../TeamGuide/types";

// ------------------ State Type/Structure ------------------
export interface DepartmentInsightReportState {
  genderInsights: { [departmentId: number]: GenderInsightsResponse | null };
  departmentInsightReportById: {
    [departmentId: number]: DepartmentInsightReport | null;
  };
  latestDepartmentInsightReport: {
    [departmentId: number]: DepartmentInsightReport | null;
  };
  departmentTeamComments: {
    [departmentId: number]: TeamComments | null;
  };
  team360InstancePicked: {
    [departmentId: number]: InstanceShowing;
  };
  team360InstancesByDepartmentId: {
    [departmentId: number]: TeamAssessmentInstance[];
  };
  conversationsToSuggest: {
    [teamId: number]: SavedConversationsToSuggest | null;
  };
  team360CardsExpanded: {
    [departmentId: number]: {
      [cardTitle: string]: boolean;
    };
  };
  getConversationToSuggestStatus: responseStatus;
  getTeamAssessmentInstancesStatus: responseStatus;
  updatingTeam360DateRangeStatus: responseStatus;
  getDepartmentTeamCommentsStatus: responseStatus;
  getDepartmentInsightReportStatus: responseStatus;
  getGenderInsightsStatus: responseStatus;
}

const initialState: DepartmentInsightReportState = {
  genderInsights: {},
  departmentInsightReportById: {},
  latestDepartmentInsightReport: {},
  departmentTeamComments: {},
  team360InstancePicked: {},
  team360InstancesByDepartmentId: {},
  conversationsToSuggest: {},
  team360CardsExpanded: {},
  getConversationToSuggestStatus: "idle",
  getTeamAssessmentInstancesStatus: "idle",
  updatingTeam360DateRangeStatus: "idle",
  getDepartmentTeamCommentsStatus: "idle",
  getDepartmentInsightReportStatus: "idle",
  getGenderInsightsStatus: "idle",
};

// ------------------ Helper Thunks ------------------
export const updateTeam360DateRange = createAsyncThunk(
  "departmentInsightReport/updateTeam360DateRange",
  async (
    {
      departmentId,
      startDate,
      endDate,
      instance,
      instanceType,
      throwErrorIfNoOverview,
    }: {
      departmentId: number;
      instanceType?: (typeof TIME_INTERVAL_OPTIONS)[number];
      throwErrorIfNoOverview?: boolean;
    } & StartAndEndDateOrInstance,
    { dispatch }
  ) => {
    const dateRange = { startDate, endDate, instance };

    const resp = (await dispatch(
      getDepartmentInsightReport({
        departmentId,
        ...dateRange,
        throwErrorIfNoOverview,
      })
    )) as { error?: any };

    if (resp?.error) {
      throw new Error("No overview found");
    }

    // Before making all the other calls we first dispatch there action, if no overview then we need to throw an error
    await Promise.all([
      dispatch(getGenderInsights({ departmentId, ...dateRange })),
      dispatch(getDepartmentTeamComments({ departmentId, ...dateRange })),
      dispatch(getTeam360Scores({ ...dateRange })),
    ]);
    return { departmentId, startDate, endDate, instanceType, instance };
  }
);

// ------------------------------------ GET Requests ------------------------------------
export const getDepartmentInsightReport = createAsyncThunk(
  "departmentInsightReport/getDepartmentInsightReport",
  async ({
    departmentId,
    startDate,
    endDate,
    instance,
    throwErrorIfNoOverview,
  }: {
    departmentId: number;
    throwErrorIfNoOverview?: boolean;
  } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/insightReport${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const getDepartmentInsightReportResponse = (await request(
      requestUrl
    )) as DepartmentInsightReport;

    // If no startDate or endDate then we need to send a flag to save the latest instance
    if (!startDate && !endDate && !instance) {
      return {
        response: getDepartmentInsightReportResponse,
        departmentId,
        isLatest: true,
      };
    }

    // If given that we are not fetching the latest instance then we need to check if the response has an overview, if not then we need to throw an error
    if (
      throwErrorIfNoOverview &&
      !getDepartmentInsightReportResponse?.overview &&
      !getDepartmentInsightReportResponse?.completionInfo?.totalInvited
    ) {
      throw new Error("No overview found");
    }

    return { response: getDepartmentInsightReportResponse, departmentId };
  }
);

export const getGenderInsights = createAsyncThunk(
  "departmentInsightReport/getGenderInsights",
  async ({
    departmentId,
    startDate,
    endDate,
    instance,
  }: {
    departmentId: number;
  } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/genderAnalysis/department/${departmentId}${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const response = (await request(requestUrl)) as GenderInsightsResponse;
    return { response, departmentId };
  }
);

export const getDepartmentTeamComments = createAsyncThunk(
  "departmentInsightReport/getDepartmentTeamComments",
  async ({
    departmentId,
    startDate,
    endDate,
    instance,
  }: {
    departmentId: number;
  } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/comments${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;

    const response = (await request(requestUrl)) as TeamComments;
    return { response, departmentId };
  }
);

export const getTeamAssessmentInstances = createAsyncThunk(
  "departmentInsightReport/getTeamAssessmentInstances",
  async (departmentId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}/teamAssessmentsInstances`;
    const response = (await request(requestUrl)) as {
      [assessmentInstanceId: number]: TeamAssessmentInstance;
    };

    const responseArray: TeamAssessmentInstance[] = [];
    Object.entries(response).forEach(([assessmentInstanceId, instance]) => {
      responseArray.push({
        ...instance,
        assessmentInstanceId: Number(assessmentInstanceId),
      });
    });
    return { response: responseArray, departmentId };
  }
);

export const getConversationPartners = createAsyncThunk(
  "departmentInsightReport/getConversationPartners",
  async ({
    userAccountId,
    departmentId,
    conversationPartner,
    conversationPartnerType,
    endDate,
    startDate,
    instance,
    teamId,
  }: ConversationsToSuggestPayload &
    StartAndEndDateOrInstance & { departmentId: number; teamId?: number }) => {
    let requestUrl = `${REACT_APP_API_URL}/talentInsights/conversationPartners/users/${userAccountId}/departments/${departmentId}`;

    const appendedUrlParams = getAppendedUrl({
      startDate,
      endDate,
      instance,
    });
    requestUrl += appendedUrlParams;
    requestUrl += appendedUrlParams?.length ? "&" : "?";
    if (conversationPartner) {
      requestUrl += `conversationPartner=${conversationPartner}`;
    } else if (conversationPartnerType) {
      requestUrl += `conversationPartnerType=${conversationPartnerType}`;
    }

    if (teamId) {
      requestUrl += `&teamId=${teamId}`;
    }

    const response = (await request(requestUrl)) as ConversationPartner[];

    return {
      response,
      departmentId,
      userAccountId,
      teamId,
      conversationPartner,
      conversationPartnerType,
      endDate,
      startDate,
      instance,
    };
  }
);

// ------------------------------------ Slice ------------------------------------

export const departmentInsightReportStateSlice = createSlice({
  name: "departmentInsightReport",
  initialState,
  reducers: {
    clearStatusStates: (state) => {
      state.getDepartmentTeamCommentsStatus = "idle";
      state.getDepartmentInsightReportStatus = "idle";
      state.getGenderInsightsStatus = "idle";
    },
    resetUpdateTeam360DateRangeStatus(state) {
      state.updatingTeam360DateRangeStatus = "idle";
    },
    clearConversationPartnersForDepartment(
      state,
      { payload: departmentId }: PayloadAction<number>
    ) {
      return {
        ...state,
        conversationsToSuggest: {
          ...state.conversationsToSuggest,
          [departmentId]: null,
        },
        getConversationToSuggestStatus: "idle",
      };
    },
    clearGetConversationStatus(state) {
      state.getConversationToSuggestStatus = "idle";
    },
    setTeam360CardsExpanded: (
      state,
      {
        payload: { departmentId, value, cardTitle },
      }: PayloadAction<{
        departmentId: number;
        value: boolean;
        cardTitle: string;
      }>
    ) => {
      return {
        ...state,
        team360CardsExpanded: {
          ...state.team360CardsExpanded,
          [departmentId]: {
            ...state.team360CardsExpanded[departmentId],
            [cardTitle]: value,
          },
        },
      };
    },
    clearTeam360ExpandedCards: (
      state,
      { payload: departmentId }: PayloadAction<number>
    ) => {
      return {
        ...state,
        team360CardsExpanded: {
          ...state.team360CardsExpanded,
          [departmentId]: {},
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentInsightReport.pending, (state) => {
        state.getDepartmentInsightReportStatus = "loading";
      })
      .addCase(
        getDepartmentInsightReport.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            response: DepartmentInsightReport;
            departmentId: number;
            isLatest?: boolean;
          }>
        ) => {
          const departmentInsightReportById = {
            ...state.departmentInsightReportById,
            [payload.departmentId]: payload.response,
          };

          if (payload.isLatest) {
            return {
              ...state,
              getDepartmentInsightReportStatus: "succeeded",
              latestDepartmentInsightReport: {
                ...state.latestDepartmentInsightReport,
                [payload.departmentId]: payload.response,
              },
              departmentInsightReportById,
            };
          }

          return {
            ...state,
            getDepartmentInsightReportStatus: "succeeded",
            departmentInsightReportById,
          };
        }
      )
      .addCase(getDepartmentInsightReport.rejected, (state) => {
        state.getDepartmentInsightReportStatus = "failed";
      })
      .addCase(getGenderInsights.pending, (state) => {
        state.getGenderInsightsStatus = "loading";
      })
      .addCase(
        getGenderInsights.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: GenderInsightsResponse;
            departmentId: number;
          }>
        ) => {
          return {
            ...state,
            getGenderInsightsStatus: "succeeded",
            genderInsights: {
              ...state.genderInsights,
              [action.payload.departmentId]: {
                ...state.genderInsights[action.payload.departmentId],
                ...action.payload.response,
              },
            },
          };
        }
      )
      .addCase(getGenderInsights.rejected, (state) => {
        state.getGenderInsightsStatus = "failed";
      })
      .addCase(getDepartmentTeamComments.pending, (state) => {
        state.getDepartmentTeamCommentsStatus = "loading";
      })
      .addCase(
        getDepartmentTeamComments.fulfilled,
        (
          state,
          action: PayloadAction<{
            response: TeamComments;
            departmentId: number;
          }>
        ) => {
          return {
            ...state,
            getDepartmentTeamCommentsStatus: "succeeded",
            departmentTeamComments: {
              ...state.departmentTeamComments,
              [action.payload.departmentId]: action.payload.response,
            },
          };
        }
      )
      .addCase(getDepartmentTeamComments.rejected, (state) => {
        state.getDepartmentTeamCommentsStatus = "failed";
      })
      .addCase(updateTeam360DateRange.pending, (state) => {
        state.updatingTeam360DateRangeStatus = "loading";
      })
      .addCase(
        updateTeam360DateRange.fulfilled,
        (
          state,
          {
            payload: {
              departmentId,
              startDate,
              endDate,
              instanceType,
              instance,
            },
          }: PayloadAction<
            {
              departmentId: number;
              instanceType?: (typeof TIME_INTERVAL_OPTIONS)[number];
            } & StartAndEndDateOrInstance
          >
        ) => {
          return {
            ...state,
            updatingTeam360DateRangeStatus: "succeeded",
            team360InstancePicked: {
              ...state.team360InstancePicked,
              [departmentId]: {
                instanceType: instanceType ?? null,
                startDate,
                endDate,
                instance,
              },
            },
          };
        }
      )
      .addCase(updateTeam360DateRange.rejected, (state) => {
        state.updatingTeam360DateRangeStatus = "failed";
      })
      .addCase(getTeamAssessmentInstances.pending, (state) => {
        state.getTeamAssessmentInstancesStatus = "loading";
      })
      .addCase(
        getTeamAssessmentInstances.fulfilled,
        (
          state,
          {
            payload: { departmentId, response = [] },
          }: PayloadAction<{
            response: TeamAssessmentInstance[];
            departmentId: number;
          }>
        ) => {
          return {
            ...state,
            getTeamAssessmentInstancesStatus: "succeeded",
            team360InstancesByDepartmentId: {
              ...state.team360InstancesByDepartmentId,
              [departmentId]: response,
            },
          };
        }
      )
      .addCase(getTeamAssessmentInstances.rejected, (state, e) => {
        const departmentId = e.meta.arg;
        return {
          ...state,
          getTeamAssessmentInstancesStatus: "failed",
          team360InstancesByDepartmentId: {
            ...state.team360InstancesByDepartmentId,
            [departmentId]: [],
          },
        };
      })
      .addCase(getConversationPartners.pending, (state) => {
        state.getConversationToSuggestStatus = "loading";
      })
      .addCase(
        getConversationPartners.fulfilled,
        (
          state,
          {
            payload: {
              response,
              departmentId,
              userAccountId,
              conversationPartner,
              conversationPartnerType,
              teamId,
            },
          }: PayloadAction<{
            response: ConversationPartner[];
            departmentId: number;
            userAccountId: number;
            conversationPartner?: number;
            conversationPartnerType?: ConversationPartnerType;
            teamId?: number;
          }>
        ) => {
          const activeComparedTo =
            conversationPartner ?? conversationPartnerType;
          if (!activeComparedTo) {
            return state;
          }
          return {
            ...state,
            getConversationToSuggestStatus: "succeeded",
            conversationsToSuggest: {
              ...state.conversationsToSuggest,
              [departmentId]: {
                activeUserAccountId: userAccountId,
                activeTeamId: teamId ?? null,
                activeComparedTo,
                activeComparedToTeamId: conversationPartner ?? null,
                conversationsToBeHad: response,
              },
            },
          };
        }
      )
      .addCase(getConversationPartners.rejected, (state) => {
        state.getConversationToSuggestStatus = "failed";
      });
  },
});

export const selectDepartmentInsightReportById =
  (departmentId: number | null) => (state: RootState) => {
    if (!departmentId) return null;
    const { departmentInsightReportById } = state.departmentInsightReport;
    return departmentInsightReportById[departmentId];
  };
export const selectLatestDepartmentInsightReportById =
  (departmentId: number | null) => (state: RootState) => {
    if (!departmentId) return null;
    const { latestDepartmentInsightReport } = state.departmentInsightReport;
    return latestDepartmentInsightReport[departmentId];
  };

export const selectGenderInsightsByDepartmentId =
  (departmentId: number | null) => (state: RootState) => {
    if (!departmentId) return null;
    const { genderInsights } = state.departmentInsightReport;
    return genderInsights[departmentId];
  };

export const selectGetDepartmentInsightReportStatus = (state: RootState) =>
  state.departmentInsightReport.getDepartmentInsightReportStatus;

export const selectGetGenderInsightsStatus = (state: RootState) =>
  state.departmentInsightReport.getGenderInsightsStatus;

export const selectDepartmentTeamCommentsByDepartmentId =
  (departmentId: number | null) => (state: RootState) => {
    return departmentId
      ? state.departmentInsightReport.departmentTeamComments[departmentId]
      : null;
  };
export const selectTeam360InstancePicked =
  (departmentId?: number) => (state: RootState) =>
    departmentId
      ? state.departmentInsightReport.team360InstancePicked[departmentId]
      : null;
export const selectUpdateTeam360DateRangeStatus = (state: RootState) =>
  state.departmentInsightReport.updatingTeam360DateRangeStatus;

export const selectGetTeamAssessmentInstancesStatus = (state: RootState) =>
  state.departmentInsightReport.getTeamAssessmentInstancesStatus;
export const selectTeam360InstancesByDepartmentId =
  (id?: number | null) => (state: RootState) => {
    if (!id) {
      return undefined;
    }
    return state.departmentInsightReport.team360InstancesByDepartmentId[id];
  };
export const selectDepartmentSuggestedConversations =
  (departmentId?: number) => (state: RootState) =>
    departmentId
      ? state.departmentInsightReport.conversationsToSuggest[departmentId]
      : null;
export const selectGetDepartmentConversationSuggestionsStatus = (
  state: RootState
) => state.departmentInsightReport.getConversationToSuggestStatus;
export const selectTeam360CardsExpanded =
  (departmentId?: number) => (state: RootState) =>
    departmentId
      ? state.departmentInsightReport.team360CardsExpanded[departmentId]
      : null;

export const {
  clearStatusStates,
  resetUpdateTeam360DateRangeStatus,
  clearGetConversationStatus,
  setTeam360CardsExpanded,
} = departmentInsightReportStateSlice.actions;

export default departmentInsightReportStateSlice.reducer;
