import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningBanner from "app/storybookComponents/WarningBanner";
import Button from "app/storybookComponents/Button";

interface Props {
  buttonLabel: string;
  label: string;
  hideButton: boolean;
  onClick: () => void;
}

export default function ErrorBanner({
  buttonLabel,
  label,
  hideButton,
  onClick,
}: Readonly<Props>) {
  return (
    <WarningBanner type="light-red">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <FontAwesomeIcon icon="exclamation-triangle" className="me-2" />
          {label}
        </div>

        {!hideButton ? (
          <Button
            onClick={onClick}
            variant="secondary-blue"
            className="text-nowrap border-0"
          >
            {buttonLabel}
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        ) : null}
      </div>
    </WarningBanner>
  );
}
