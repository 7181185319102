import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import { getCompletedTeam360AssessmentText } from "./helpers";
import Loading from "app/storybookComponents/Loading";

interface Props {
  teamId?: number;
  entityType?: "team" | "organization" | "department";
  completionInfo?: {
    totalInvited: number;
    totalCompleted: number;
    completionRate: number;
    hasScheduledAssessment?: boolean;
  };
  onScheduleAssessment?: () => void;
  onShowLeaderOnboarding?: () => void;
  onSeeSurveySettings?: () => void;
  hasEditAccess?: boolean;
  leaderExtraInfo?: string[];
  isLoading?: boolean;
  instanceBanner?: React.JSX.Element | null;
  isInstanceActive?: boolean;
  noPermission?: boolean;
}

export default function EmptyTeam360ResultsCard({
  teamId,
  entityType,
  completionInfo = {
    totalInvited: 0,
    totalCompleted: 0,
    completionRate: 0,
  },
  hasEditAccess,
  onShowLeaderOnboarding,
  onScheduleAssessment,
  onSeeSurveySettings,
  leaderExtraInfo = [
    "Boost Productivity and Engagement",
    "Identify Development Opportunities",
    "Benchmark Performance",
    "Track Progress Over Time",
    "Reduce Turnover",
  ],
  isLoading,
  instanceBanner = null,
  isInstanceActive = true,
  noPermission,
}: Readonly<Props>) {
  const getMessage = () => {
    switch (entityType) {
      case "team": {
        return (
          <p style={{ maxWidth: "none" }}>
            Good job so far! To view your team's results, we require at least 3
            team members (or both members in a 2-person team) to finish the
            survey. Upon completion, your team's results will become available
            here. Don't forget to give your teammates a nudge if needed!
          </p>
        );
      }
      case "department": {
        return (
          <p style={{ maxWidth: "none" }}>
            Good job so far! To view your department's results, we require at
            least 3 team members to finish the survey. Upon completion, your
            department's results will become available here. Don't forget to
            give your team leaders a nudge if needed!
          </p>
        );
      }
      case "organization":
      default: {
        return (
          <p style={{ maxWidth: "none" }}>
            Good job so far! To view your organization's results, we require at
            least 3 teams to finish the survey. Upon completion, your
            organization's results will become available here. Don't forget to
            give your team leaders a nudge if needed!
          </p>
        );
      }
    }
  };

  const getEmptyInitialCardContent = () => (
    <div
      className="empty-card column-gap-20px"
      style={{
        padding: "32px",
      }}
    >
      <div className="column-gap-4px">
        <span
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Learn About Your Team
        </span>
        <p>Launch your first TEAMscan for your team to see teamwork insights</p>
      </div>
      <div>
        <div
          className="d-flex flex-wrap justify-content-center"
          style={{
            gap: "20px",
            maxWidth: "640px",
          }}
        >
          {leaderExtraInfo.map((elm) => (
            <div className="circular-list-span" key={elm}>
              <FontAwesomeIcon icon="circle-check" />
              <span>{elm}</span>
            </div>
          ))}
        </div>
      </div>
      {hasEditAccess ? (
        <div className="d-flex justify-content-center gap-2">
          <Button onClick={() => onScheduleAssessment?.()}>
            Launch TEAMscan
          </Button>
          {onShowLeaderOnboarding ? (
            <Button
              onClick={() => {
                onShowLeaderOnboarding();
              }}
              variant="secondary-blue"
              className="white-button"
            >
              See details
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );

  const getEmptyCardWithSomeInvitees = () => {
    if (isInstanceActive) {
      return (
        <div
          className="empty-card column-gap-32px"
          style={{
            padding: "32px",
          }}
        >
          <div className="medium-square-icon grey">
            <FontAwesomeIcon icon={["fal", "clock"]} />
          </div>
          <div className="column-gap-12px">
            <span>
              <b>TEAMscan Results Pending</b>
            </span>
            {getMessage()}
          </div>
          {getCompletedTeam360AssessmentText(completionInfo ?? {})}
        </div>
      );
    }

    return (
      <div
        className="empty-card column-gap-32px"
        style={{
          padding: "32px",
        }}
      >
        <div className="column-gap-12px">
          <span>
            <b>Unable To View Report</b>
          </span>
          <p style={{ maxWidth: "500px" }}>
            Unfortunately, not enough team members completed the TEAMscan survey
            to view this report. To view results, at least 3 team members (or
            both members in a 2-person team) must finish the survey.
          </p>
        </div>
        {getCompletedTeam360AssessmentText(completionInfo ?? {})}
        {hasEditAccess ? (
          <div className="d-flex justify-content-center gap-2">
            <Button onClick={() => onScheduleAssessment?.()}>
              Launch TEAMscan
            </Button>
            {onSeeSurveySettings ? (
              <Button
                onClick={() => {
                  onSeeSurveySettings();
                }}
                variant="secondary-blue"
                className="white-button"
              >
                See survey settings
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const getContent = () => {
    if (isLoading) return <Loading />;

    // If pending assessment show the pending assessment screen and count
    if (
      completionInfo.totalInvited > 0 ||
      completionInfo.hasScheduledAssessment
    ) {
      return getEmptyCardWithSomeInvitees();
    }

    // If the user has edit access then we should show the information to incentivice the user to want to schedule the assessment
    if (hasEditAccess) {
      return getEmptyInitialCardContent();
    }

    // Catch all for when there are no results
    return (
      <div
        className="empty-card"
        style={{
          padding: "32px",
        }}
      >
        <div className="column-gap-12px">
          <span>
            <b>{noPermission ? "Unable To View Report" : "No results yet"}</b>
          </span>
          <p>
            {noPermission
              ? "Unfortunately, you don’t have access to this report. Please reach out to the team leader or admin to change this team’s visibility settings."
              : `No ${teamId ? "" : "organization-wide"} TEAMscan results yet`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Card style={{ padding: "20px" }}>
      <div className="column-gap-20px">
        <h2>TEAMscan Results</h2>
        {instanceBanner}
        {getContent()}
      </div>
    </Card>
  );
}
