import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

interface Props {
  text: string;
  className?: string;
}

// this will take in a text string and show a fontawesome copy icon when its hovered, and when clicked it will copy the text to the clipboard
// additionally, the text will be trunctuated if it is too long
export default function CopyText({
  text,
  className = "copy-text",
}: Readonly<Props>) {
  const [isHovered, setIsHovered] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);

  useEffect(() => {
    if (!emailCopied) {
      return;
    }

    setTimeout(() => {
      setEmailCopied(false);
    }, 1500);
  }, [emailCopied]);

  const onClickHandler = () => {
    setEmailCopied(true);
    navigator.clipboard.writeText(text);
  };

  const getIcon = () => {
    if (!isHovered) {
      return null;
    }
    if (emailCopied) {
      return (
        <FontAwesomeIcon
          icon="check"
          className="copy-icon"
          style={{ color: "green" }}
        />
      );
    }
    return <FontAwesomeIcon icon={["far", "copy"]} className="copy-icon" />;
  };

  return (
    <p
      className={className + `${isHovered ? " hovered" : ""}`}
      onClick={onClickHandler}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {text}
      {getIcon()}
    </p>
  );
}
