import { RowData } from "./types";

export const PEOPLE_INVALIDATION_ERROR_KEYS = [
  "missingField",
  "invalidEmail",
  "invalidGender",
  "userExistsInAnotherOrganization",
];
export type PeopleInvalidationErrorKey =
  (typeof PEOPLE_INVALIDATION_ERROR_KEYS)[number];

export const PEOPLE_EMAIL_ERRORS = [
  "Email Address is not in the allowed domains list.",
  "Invalid Email Address",
  "Email address is already in use.",
];

export const PEOPLE_COLUMNS_MAP: {
  [key: string]: {
    displayName: string;
    required: boolean;
    isDangerFunction?: (rowData: RowData) => boolean;
    isWarningFunction?: (rowData: RowData) => boolean;
    isDisabledFunction?: (rowData: RowData) => boolean;
    isLoadingFunction?: (rowData: RowData) => boolean;
    dropDownOptions?: string[];
  };
} = {
  firstName: {
    displayName: "First Name",
    required: true,
  },
  lastName: {
    displayName: "Last Name",
    required: true,
  },
  jobTitle: {
    displayName: "Job Title",
    required: false,
  },
  gender: {
    displayName: "Gender",
    required: false,
    isWarningFunction: (rowData: RowData) => {
      const value = String(rowData.gender);
      if (!value) {
        return false;
      }
      const lowerCaseValue = value.toLowerCase();
      switch (lowerCaseValue) {
        case "male":
        case "female":
        case "non-binary":
        case "not disclosed":
          return false;
        default:
          return true;
      }
    },
    dropDownOptions: ["Male", "Female", "Non-binary", "Not disclosed"],
  },
  emailAddress: {
    displayName: "Email",
    required: true,
    isDangerFunction: (rowData: RowData) => Boolean(rowData["isInvalidEmail"]),
    isDisabledFunction: (rowData: RowData) =>
      Boolean(rowData["isEmailDisabled"]),
    isLoadingFunction: (rowData: RowData) =>
      Boolean(rowData["isEmailDisabled"]),
  },
  managerEmailAddress: {
    displayName: "Manager Email",
    required: false,
  },
};

export const PEOPLE_COLUMNS = Object.entries(PEOPLE_COLUMNS_MAP).map(
  ([key, value]) => ({
    key,
    ...value,
  })
);
