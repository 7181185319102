import SimpleModal from "app/components/Modals/SimpleModal";
import Button from "app/storybookComponents/Button";
import { PendingTeamMember } from "app/containers/AdminConsole/types";
import { useMemo } from "react";
import { getS } from "utils/helperFunctions";

interface Props {
  show: boolean;
  onHide: () => void;
  onAccept: () => void | Promise<any>;
  onDecline: () => void | Promise<any>;
  onRemoveRequiredAcceptance: () => void;
  isLoading?: boolean;
  pendingTeamMembers?: PendingTeamMember[];
}

export default function AddPendingTeamMembersToTeam({
  show,
  onHide,
  onAccept,
  onDecline,
  isLoading,
  onRemoveRequiredAcceptance,
  pendingTeamMembers,
}: Readonly<Props>) {
  const pendingMemberBanner = useMemo(() => {
    const userIdSet = new Set<number>();
    pendingTeamMembers?.forEach((member) => {
      userIdSet.add(member.userAccountId);
    });

    if (userIdSet.size === 0 || !pendingTeamMembers) {
      return null;
    }

    const userSetSize = userIdSet.size;
    const pendingTeamMembersSize = pendingTeamMembers.length;

    return (
      <div className="warning-banner gold">
        <p>
          {userSetSize} {userSetSize === 1 ? "person" : "people"} in your
          organization {userSetSize === 1 ? "has a" : "have"} pending team
          invitation
          {getS(userSetSize)}. There{" "}
          {pendingTeamMembersSize === 1 ? "is" : "are"} {pendingTeamMembersSize}{" "}
          pending team invitation
          {getS(pendingTeamMembersSize)} total.
        </p>
      </div>
    );
  }, [pendingTeamMembers]);

  return (
    <SimpleModal
      show={show}
      onHide={onHide}
      title="Add Pending Team Members to Team"
    >
      <div style={{ textAlign: "center" }}>
        <h3 style={{ color: "black" }}>
          Do you want to accept all pending team invitations for everyone in
          this organization?
        </h3>
        <p style={{ marginTop: "8px" }}>
          You can either add everyone to teams they've been invited to or
          decline all team invitations (any pending team members will need to be
          invited again).
        </p>
      </div>
      {pendingMemberBanner}
      <div
        className="action-buttons"
        style={{
          gap: "16px",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={async () => {
            await onAccept();
            onRemoveRequiredAcceptance();
            onHide();
          }}
          disabled={isLoading}
        >
          Accept all team invitations
        </Button>
        <Button
          onClick={async () => {
            await onDecline();
            onRemoveRequiredAcceptance();
            onHide();
          }}
          disabled={isLoading}
          variant="secondary-blue"
        >
          Decline team invitations
        </Button>
      </div>
    </SimpleModal>
  );
}
