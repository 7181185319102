import { useState } from "react";
import { Card, Collapse, Dropdown } from "react-bootstrap";
import { ConversationPartner } from "app/containers/TeamGuide/types";
import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import Team360ConversationDeck from "./Team360ConversationDeck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";

interface Props {
  strengths?: ConversationPartner[] | null;
  weaknesses?: ConversationPartner[] | null;
  title?: string;
  stepsText?: string[];
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}
const listArray = [
  "Select to either focus on growth areas or build on your teamwork strengths",
  "Choose one or two people to converse with over the next two weeks.",
  "Enjoy exploring issues, asking questions, and discussing best practices.",
];

export default function Team360WhoCanYouTalkTo({
  strengths,
  weaknesses,
  title = "Who Can You Talk To?",
  stepsText = listArray,
  isExpanded = false,
  setIsExpanded,
}: Readonly<Props>) {
  // -------------------------- Redux selectors --------------------------
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const companySettings = useAppSelector(selectCompanySettings);

  // -------------------------- States --------------------------
  const [strengthOrWeakness, setStrengthOrWeakness] = useState<
    "strength" | "weakness"
  >("strength");

  // -------------------------- Helper functions --------------------------
  const getDropdown = () => {
    const onDropdownSelect = (eventKey: string | null) => {
      if (eventKey === "strength" || eventKey === "weakness") {
        setStrengthOrWeakness(eventKey);
      }
    };
    return (
      <Dropdown onSelect={onDropdownSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {strengthOrWeakness === "strength"
            ? "Build on a strength"
            : "Focus on a growth area"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <Dropdown.Item eventKey="weakness">
            Focus on a growth area
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="strength">Build on a strength</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  if (
    (!strengths && !weaknesses) ||
    !companySettings?.hasSuggestedConversations
  ) {
    return null;
  }

  return (
    <Card
      className="column-gap-20px"
      style={{
        padding: "20px",
      }}
      role={isExpanded && setIsExpanded ? undefined : "button"}
      onClick={() => {
        if (isExpanded) return;
        setIsExpanded?.(true);
      }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div className="column-gap-8px">
          <h2>{title}</h2>
          <Collapse in={isExpanded}>
            <ol
              style={{
                paddingLeft: "20px",
                margin: 0,
              }}
            >
              {stepsText.map((step, index) => (
                <li key={index}>
                  <p>{step}</p>
                </li>
              ))}
            </ol>
          </Collapse>
        </div>
        {setIsExpanded ? (
          <div>
            <Button
              onClick={() => {
                setIsExpanded?.(!isExpanded);
              }}
              variant="secondary-blue"
              className="border-0"
            >
              {!isExpanded ? "See details" : "Collapse"}
              <FontAwesomeIcon
                icon={`caret-${isExpanded ? "up" : "down"}`}
                className="ms-2"
              />
            </Button>
          </div>
        ) : null}
      </div>
      <Collapse in={isExpanded}>
        <div className="column-gap-20px">
          <div>
            <div>{getDropdown()}</div>
          </div>
          <hr className="m-0" />
          <Team360ConversationDeck
            conversationPartners={
              strengthOrWeakness === "strength" ? strengths : weaknesses
            }
            comparingUserAccountId={currentUserAccountId}
          />
        </div>
      </Collapse>
    </Card>
  );
}
