import { Card } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Team360AssessmentResultsCard from "app/components/Team360Assessment/Team360AssessmentResultsCard";
import {
  Team360AssessmentResult,
  TTeam360Factor,
} from "app/components/Team360Assessment/types";

interface Props {
  title?: string;
  overviewText: string;
  teamScores?: Team360AssessmentResult;
  companyScores?: Team360AssessmentResult;
  departmentScores?: Team360AssessmentResult;
  setShowTeam360ResultsModal: (
    factor: TTeam360Factor,
    highOrLow?: "high" | "low"
  ) => void;
  type: "organization" | "department" | "team";
  hideActionButtons?: boolean;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  footerButtons?: React.ReactNode;
}

export default function ResultsOverviewCard({
  title = "How's Your Team Doing Overall?",
  overviewText,
  companyScores,
  departmentScores,
  setShowTeam360ResultsModal,
  type,
  hideActionButtons,
  teamScores,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText = "See score breakdown",
  secondaryButtonText = "See comments",
  footerButtons,
}: Readonly<Props>) {
  const team360Scores = teamScores ?? departmentScores ?? companyScores;
  if (!team360Scores) return null;

  const getSnapshotText = (type: string) => {
    if (type === "team") {
      return `Your overall teamwork score is the simple average score
      of your team across the 17 dimensions of high-performing teams
      measured by the TEAM model. This presents a holistic snapshot of the
      how well your team thinks they are performing based on ans
      equal-weighting of all the opinions of every team member.`;
    }

    return `Your overall ${type} teamwork score is the simple average score
    of all your teams across the 17 dimensions of high-performing teams
    measured by the TEAM model. This presents a holistic snapshot of the
    how well your teams think they are performing based on ans
    equal-weighting of all the opinions of every team member.`;
  };

  const snapShotText = getSnapshotText(type);

  const onGetActionButtons = () => {
    if (!onPrimaryButtonClick && !onSecondaryButtonClick && !footerButtons) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: "20px",
        }}
        className="row-gap-12px"
      >
        {onPrimaryButtonClick ? (
          <Button onClick={onPrimaryButtonClick}>{primaryButtonText}</Button>
        ) : null}
        {onSecondaryButtonClick ? (
          <Button onClick={onSecondaryButtonClick} variant="secondary-blue">
            {secondaryButtonText}
          </Button>
        ) : null}
        {footerButtons ?? null}
      </div>
    );
  };

  return (
    <Card className="p-4">
      <h2>{title}</h2>
      {overviewText ? (
        <p
          style={{
            margin: "20px 0",
          }}
        >
          {overviewText}
        </p>
      ) : null}
      <Team360AssessmentResultsCard
        teamScores={teamScores}
        departmentScores={departmentScores}
        companyScores={companyScores}
        snapShotText={snapShotText}
        onFactorOrDimensionClick={(factor, highOrLow) => {
          setShowTeam360ResultsModal(factor as TTeam360Factor, highOrLow);
        }}
        hideActionButtons={hideActionButtons}
      />
      {onGetActionButtons()}
    </Card>
  );
}
