import { Table, Alert } from "react-bootstrap";

export interface Props {
  uploadedColumns?: string[];
  errorPositions?: number[];
  missingRequiredColumns?: string[];
}

export default function IncorrectHeaders({
  uploadedColumns = [],
  errorPositions = [],
  missingRequiredColumns = [],
}: Readonly<Props>) {
  const getRequiredColumnsText = (missingRequiredColumns: string[]) => {
    if (missingRequiredColumns.length === 0) {
      return null;
    }
    return (
      <p>
        The following columns are required/expected:
        <ul>
          {missingRequiredColumns.map((column) => (
            <li key={column}>{column}</li>
          ))}
        </ul>
      </p>
    );
  };

  const getUploadedHeaders = () => {
    const errorSet = new Set(errorPositions);
    const uploadedHeaders: JSX.Element[] = [];
    uploadedColumns.forEach((columnName, idx) => {
      const warning = errorSet.has(idx) ? "warning" : "";

      uploadedHeaders.push(
        <th
          key={columnName}
          className={`admin-console-table-header ${warning}`}
        >
          {columnName}
        </th>
      );
    });
    return uploadedHeaders;
  };

  return (
    <>
      <Alert variant="danger">
        <p className="mb-0">
          <span className="fw-bold">
            You are missing {missingRequiredColumns.length} required column
            {missingRequiredColumns.length > 1 ? "s" : ""}.
          </span>{" "}
          Please fix these errors and re-upload your file.
        </p>
      </Alert>
      <div style={{ overflowX: "auto" }}>
        <p>Uploaded Table</p>
        <Table bordered>
          <thead>
            <tr>{getUploadedHeaders()}</tr>
          </thead>
        </Table>
      </div>
      {getRequiredColumnsText(missingRequiredColumns)}
    </>
  );
}
