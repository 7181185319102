import {
  editSummary,
  selectChatbotConversationById,
  selectEditSummaryStatus,
} from "app/containers/Chatbot/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import TextArea from "app/storybookComponents/Forms/TextArea";
import { useEffect, useState } from "react";
import SimpleModal from "./SimpleModal";

interface CommitmentModalProps {
  threadId?: string | null;
  show?: boolean;
  onHide: () => void;
}

export default function CommitmentModal({
  threadId,
  show,
  onHide,
}: Readonly<CommitmentModalProps>) {
  const dispatch = useAppDispatch();
  const conversation = useAppSelector((state) =>
    selectChatbotConversationById(state, threadId ?? "")
  );
  const editCommitmentStatus = useAppSelector(selectEditSummaryStatus);

  const [summary, setSummary] = useState("");
  const [whatYouAreWorkingOn, setWhatYouAreWorkingOn] = useState("");

  useEffect(() => {
    setSummary(conversation?.summary?.summary ?? "");
    setWhatYouAreWorkingOn(conversation?.summary?.commitment ?? "");
  }, [conversation]);

  const onSave = async () => {
    if (!threadId) return;
    await dispatch(
      editSummary({ threadId, summary, commitment: whatYouAreWorkingOn })
    );
    onHide();
  };

  const getDateString = () => {
    if (!conversation) return "";
    const date = new Date(conversation.created_at);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <SimpleModal
      show={show}
      onHide={onHide}
      title="Commitment"
      className="commitment-modal"
    >
      <p
        className="grey-text"
        style={{
          marginTop: "-14px",
        }}
      >
        Based on chat from {getDateString()}
      </p>
      <div>
        <TextArea
          controlId="commitment-summary"
          inputLabel="Summary:"
          inputText={summary}
          onTextChange={(e) => setSummary(e)}
          maxLength={500}
        />
      </div>
      <div>
        <TextArea
          controlId="commitment-summary"
          inputLabel="What you're working on:"
          inputText={whatYouAreWorkingOn}
          onTextChange={(e) => setWhatYouAreWorkingOn(e)}
          maxLength={500}
        />
      </div>

      <div className="d-flex justify-content-between">
        <Button variant="secondary-blue" onClick={onHide}>
          Discard changes
        </Button>
        <Button onClick={onSave} disabled={editCommitmentStatus === "loading"}>
          Save changes
        </Button>
      </div>
    </SimpleModal>
  );
}
