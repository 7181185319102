import Button from "app/storybookComponents/Button";
import { useState, memo, useMemo, useEffect } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { MONTHS } from "./constants";
import { Month } from "./types";
import { getAllAvailableYears, getInstancesInDateRange } from "./helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TeamAssessmentInstance } from "app/containers/Assessment/types";

interface Props {
  surveyName?: string;
  onSubmit?: (startDate?: string, endDate?: string) => void;
  isLoading?: boolean;
  availableInstances?: Pick<
    TeamAssessmentInstance,
    | "startDate"
    | "endDate"
    | "assessmentInstanceId"
    | "totalCompleted"
    | "totalInvited"
  >[];
  defaultInstanceSelected?: {
    startDate?: string;
    endDate?: string;
  };
  entity?: "department" | "organization";
}

const InstancePicker = ({
  surveyName,
  onSubmit,
  availableInstances,
  isLoading,
  defaultInstanceSelected,
  entity,
}: Props) => {
  const [instanceIndexSelected, setInstanceIndexSelected] = useState(0);
  const [yearSelected, setYearSelected] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Month | null>(null);
  const [showingAllInstances, setShowingAllInstances] = useState(false);

  useEffect(() => {
    setInstanceIndexSelected(0);
  }, [yearSelected, selectedMonth]);

  const instancesInRange = useMemo(
    () => [
      ...getInstancesInDateRange(
        availableInstances,
        selectedMonth,
        yearSelected
      ),
    ],
    [availableInstances, selectedMonth, yearSelected]
  );

  useEffect(() => {
    if (!defaultInstanceSelected) {
      return setInstanceIndexSelected(0);
    }

    const index = instancesInRange.findIndex(
      (instance) =>
        instance.startDate === defaultInstanceSelected.startDate &&
        instance.endDate === defaultInstanceSelected.endDate
    );
    setInstanceIndexSelected(index !== -1 ? index : 0);
  }, [instancesInRange, defaultInstanceSelected]);

  const getInstanceOption = (
    instance: Pick<
      TeamAssessmentInstance,
      | "startDate"
      | "endDate"
      | "assessmentInstanceId"
      | "totalCompleted"
      | "totalInvited"
    >,
    idx: number
  ) => {
    let formLabel = new Date(instance.startDate).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    if (idx === 0 && !yearSelected && !selectedMonth) {
      formLabel += " (most recent)";
    }
    formLabel = "On " + formLabel;

    const totalCompleted = instance?.totalCompleted ?? 0;
    let lastElm = 0;
    // If collapsed then the last em should be either the instance cap or the total number of instances what ever is smaller
    if (!showingAllInstances) {
      lastElm = Math.min(INSTANCE_CAP, instancesInRange.length);
      // if not collapsed then the last elm should be the total number of instances
    } else {
      lastElm = instancesInRange.length;
    }

    return (
      <>
        <div className="instance-option">
          <Form.Check
            key={instance.startDate}
            type={"radio"}
            checked={instanceIndexSelected === idx}
            onChange={() => {
              setInstanceIndexSelected(idx);
            }}
          />
          <div className="column-gap-8px">
            <p className="navy-text">{formLabel}</p>
            <p className="small-body-text">
              {totalCompleted} completed response
              {totalCompleted === 1 ? "" : "s"}
            </p>
          </div>
        </div>

        {lastElm - 1 !== idx && <hr className="m-0" />}
      </>
    );
  };

  const getMonthDropdown = () => {
    const onSelect = (month: string | null) => {
      if (!month) {
        return setSelectedMonth(null);
      }
      setSelectedMonth(month as Month);
    };
    return (
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {selectedMonth || "All months"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <Dropdown.Item>All months</Dropdown.Item>
          {MONTHS.map((month) => (
            <Dropdown.Item key={month} eventKey={month}>
              {month}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getYearDropdown = () => {
    const years = getAllAvailableYears(availableInstances);
    const onSelect = (year: string | null) => {
      if (!year) {
        return setYearSelected(null);
      }
      setYearSelected(parseInt(year));
    };
    return (
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {yearSelected || "All years"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <Dropdown.Item>All years</Dropdown.Item>
          {years.map((year) => (
            <Dropdown.Item key={year} eventKey={year}>
              {year}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const onSendResults = () => {
    if (!instancesInRange || instancesInRange.length === 0) return;
    const instance = instancesInRange[instanceIndexSelected];
    onSubmit?.(instance.startDate, instance.endDate);
  };

  const getHeader = () => (
    <div>
      <p>
        <b>Single Instance</b>
      </p>
      <p className="navy-text">
        View a report with results from a single instance of the {surveyName}{" "}
        survey launched:
      </p>
    </div>
  );

  const INSTANCE_CAP = 4;
  const top4Instances = instancesInRange.slice(0, INSTANCE_CAP);
  const remainingInstances = instancesInRange.slice(INSTANCE_CAP);

  if (!availableInstances?.length) {
    // If there are no instances then we should show just a toggle with no dropdown allowing the user to select the instance or not
    return (
      <>
        {getHeader()}
        <Form.Check
          type={"radio"}
          label={`Most recent results ${
            entity ? `from each team in this ${entity} ` : ""
          }(default)`}
          checked
        />
        {entity ? (
          <div className="snapshot-box">
            <p>
              No {entity}-wide TEAMscan surveys were launched. To view results
              from teams in your {entity} over a given time period, select
              “Aggregate”.
            </p>
          </div>
        ) : null}

        <div className="ms-auto">
          <Button onClick={() => onSubmit?.()} disabled={isLoading}>
            Show results
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        {getHeader()}
        <div className="row-gap-8px" style={{ padding: "20px 0" }}>
          <div>{getMonthDropdown()}</div>
          <div>{getYearDropdown()}</div>
        </div>
        <div className="time-interval-option-container">
          {top4Instances.map((instance, idx) =>
            getInstanceOption(instance, idx)
          )}
        </div>
        <Collapse in={showingAllInstances}>
          <div className="time-interval-option-container">
            {remainingInstances.map((instance, idx) =>
              getInstanceOption(instance, idx + INSTANCE_CAP)
            )}
          </div>
        </Collapse>
        {top4Instances.length === 0 ? (
          <div className="snapshot-box mt-3">
            <p>
              No {surveyName} surveys were launched in this time period. Try
              selecting another month or year, or try viewing all surveys for a
              specific year.
            </p>
          </div>
        ) : null}
        {remainingInstances.length !== 0 ? (
          <Button
            variant="secondary-blue"
            onClick={() => setShowingAllInstances(!showingAllInstances)}
            className="w-100 border-0"
          >
            {showingAllInstances ? "Hide all instances" : "Show all instances"}{" "}
            <FontAwesomeIcon
              icon={`caret-${showingAllInstances ? "up" : "down"}`}
              className="ms-2"
            />
          </Button>
        ) : null}
      </div>
      <hr className="m-0" />
      <div className="ms-auto">
        <Button
          onClick={onSendResults}
          disabled={
            !instancesInRange || instancesInRange.length === 0 || isLoading
          }
        >
          Show results
        </Button>
      </div>
    </>
  );
};

export default memo(InstancePicker);
