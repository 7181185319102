import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import Loading from "app/storybookComponents/Loading";
import {
  decreaseCSVTimerByOne,
  getTimeToCompleteCSVUpload,
  selectTimeToCompleteCSVUpload,
} from "./slice";
import { getAllUsers } from "../Global/slice";

interface Props {
  appendedClassName?: string;
}

export default function UpdatingMembersInProcessBanner({
  appendedClassName = "",
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const timeToCompleteCSVUpload = useAppSelector(selectTimeToCompleteCSVUpload);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    dispatch(getTimeToCompleteCSVUpload());
  }, [dispatch]);

  useEffect(() => {
    if (!timeToCompleteCSVUpload || !intervalRef) {
      clearInterval(intervalRef.current ?? undefined);
      return;
    }

    intervalRef.current = setInterval(() => {
      // If the time to complete CSV upload is 0, then we need to fetch all users again
      if (timeToCompleteCSVUpload === 1) {
        dispatch(getAllUsers());
      }

      dispatch(decreaseCSVTimerByOne());
    }, 60000);

    return () => clearInterval(intervalRef.current ?? undefined);
  }, [timeToCompleteCSVUpload, dispatch]);

  const getTimeText = () => {
    if (timeToCompleteCSVUpload === 1) {
      return "minute";
    }
    return `${timeToCompleteCSVUpload} minutes`;
  };

  if (isDismissed || !timeToCompleteCSVUpload) {
    return null;
  }

  return (
    <div
      className={`warning-banner blue d-flex align-items-center justify-content-between ${appendedClassName}`}
    >
      <div className="row-gap-8px align-items-center">
        <div>
          <Loading color="#53565A" />
        </div>
        <p>
          It will take another <b>{getTimeText()}</b> to fully upload all user
          information and make changes. Please check back later.
        </p>
      </div>
      <Button onClick={() => setIsDismissed(true)} variant="secondary-blue">
        Dismiss
      </Button>
    </div>
  );
}
