import Button from "app/storybookComponents/Button";
import { TimeInterval } from "./types";

interface Props {
  latestAssessmentEndDate?: string;
  startDate?: string;
  endDate?: string;
  instanceType?: TimeInterval | null;
  onConfigureResults?: () => void;
  hideFooter?: boolean;
  bottomRightElement?: React.ReactNode | null;
}

export default function InstanceStickyFooter({
  latestAssessmentEndDate,
  hideFooter,
  instanceType,
  onConfigureResults,
  startDate,
  endDate,
  bottomRightElement,
}: Readonly<Props>) {
  if (hideFooter) {
    return null;
  }

  const getMostRecentDate = () => {
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    } as const;

    switch (instanceType) {
      case "Over the past year":
        return (
          <p>
            Results from surveys launched in <b>the past 12 months</b>
          </p>
        );
      case "Over the past 6 months":
        return (
          <p>
            Results from surveys launched in <b>the past 6 months</b>
          </p>
        );
      case "Over the past 3 months":
        return (
          <p>
            Results from surveys launched in <b>the past 3 months</b>
          </p>
        );
      case "Over the past month":
        return (
          <p>
            Results from surveys launched in <b>the past month</b>
          </p>
        );
      case "Over All Time":
        return (
          <p>
            Results from surveys launched <b>all time</b>
          </p>
        );
    }

    // if startDate and endDate are set then use those
    if (startDate && endDate) {
      const startDateString = new Date(startDate).toLocaleDateString(
        "en-US",
        options
      );
      const endDateString = new Date(endDate).toLocaleDateString(
        "en-US",
        options
      );
      return (
        <p>
          Results from surveys launched from{" "}
          <b>
            {startDateString} to {endDateString}
          </b>
        </p>
      );
    }

    if (!latestAssessmentEndDate) {
      return (
        <p>
          Viewing results from <b>most recent instance</b>
        </p>
      );
    }

    const truelatestAssessmentEndDate = new Date(
      latestAssessmentEndDate
    ).toLocaleDateString("en-US", options);

    return (
      <p>
        Most recent results from <b>{truelatestAssessmentEndDate}</b>
      </p>
    );
  };

  const getBottomRightElement = () => {
    if (bottomRightElement) {
      return bottomRightElement;
    }
    if (onConfigureResults) {
      return <Button onClick={onConfigureResults}>Configure results</Button>;
    }
    return null;
  };

  return (
    <div className="analytics-sticky-footer">
      <div className="small-body-text">{getMostRecentDate()}</div>
      {getBottomRightElement()}
    </div>
  );
}
