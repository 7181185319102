import { CompanySettings } from "app/containers/AdminConsole/types";
import { AppDispatch } from "utils/redux/store";
import {
  getDirectReportChatThreadId,
  resetChatbotState,
  setCoachBotPromptReducer,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import { getManagerGuideChatbotPrompt } from "../WorkplaceInsightsReport/helper-functions";

export const getTeamName = (
  teamId: number,
  teamsById: {
    [key: number]: { teamName: string };
  }
) => {
  if (!teamsById[teamId]) return "";
  const { teamName } = teamsById[teamId];
  const teamNameLower = teamName.toLowerCase();
  // if the name does not contain the word "team", add it to the end
  if (!teamNameLower.includes("team")) return `${teamName} Team`;
  return teamName;
};

export const getConnectEppNotificationText = (
  companySettings: CompanySettings | null
) => {
  if (!companySettings?.linkResultsAccess) {
    return "Complete the Employee Personality Profile (EPP) to view your workplace personality insights.";
  }
  return "Complete the Employee Personality Profile (EPP) or connect previous results if you’ve completed it before.";
};

export const onChatWithDirectReportNotificationClick = ({
  userAccountId,
  dispatch,
  name,
}: {
  userAccountId: number;
  dispatch: AppDispatch;
  name: string;
}) => {
  dispatch(resetChatbotState());
  const template = getManagerGuideChatbotPrompt(name);
  dispatch(
    setCoachBotPromptReducer({
      ...template,
      chatType: "chatWithDirectReport",
      chatTypeId: userAccountId,
    })
  );
  dispatch(getDirectReportChatThreadId(userAccountId));
  dispatch(setIsCoachbotOpen(true));
};
