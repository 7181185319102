import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { Link, useNavigate } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { selectTeamsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import { selectAllTeamInvitations } from "app/components/Notifications/slice";
import { selectTeamAndDepartmentLeadIdsForLoggedInUser } from "app/containers/AdminConsole/slice";
import {
  selectUserIsAbleToCreateTeams,
  selectUserIsAbleToInviteUsers,
} from "app/containers/UserGuide/slice";
import ToDoCard from "app/components/Notifications/ToDoCard";
import ManageTeamScanModal from "app/components/Modals/ManageTeamScanModal";
import AvatarCircle from "app/components/AvatarCircle";
import { openCreateTeamModal } from "app/components/Modals/slice";
import {
  getPendingTests,
  getSuperpowersAndHiddenStrengths,
  getWorkPlaceInsightsReportByUserId,
  selectGetPendingTestsStatus,
} from "app/components/WorkplaceInsightsReport/slice";
import { setShowModal } from "app/components/Onboarding/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";
import Team360TeamsCard from "../Cards/Team360TeamsCard";
import MyTeamsCard from "../Cards/MyTeamsCard";
import DashboardOnboardingCard from "../Cards/DashboardOnboardingCard";
import { getHasAnyTeam360Results, getTeamTotalText } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onShowTeamLeaderOnboardingModal: (departmentId?: null | number) => void;
  teamLeaderOnboardingModalBeenShown?: boolean;
  onShowInviteMemberModal: () => void;
  isLeader?: boolean;
}

export default function MemberDashboard({
  onShowInviteMemberModal,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ------------------------------- Selectors --------------------------------------------- //
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const getPendingTestsStatus = useAppSelector(selectGetPendingTestsStatus);
  const pendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const canUserInviteNewMembers = useAppSelector(selectUserIsAbleToInviteUsers);
  const canUserCreateTeams = useAppSelector(selectUserIsAbleToCreateTeams);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);

  // ------------------------------------- Component States ------------------------------------- //

  const [teamMembersOnSameTeam, setTeamMembersOnSameTeam] = useState<number[]>(
    []
  );
  const [isManageTeamScanModalOpen, setIsManageTeamScanModalOpen] =
    useState(false);
  const [manageTeamScanModalTeams, setManageTeamScanModalTeams] = useState<
    number[] | null
  >(null);

  // ------------------------------------- Effect ------------------------------------- //
  useEffect(() => {
    // If either request is not yet fulfilled then we don't get all the users with similar teams
    // Or if for some strange reason the user does not exist inside of the usersInfoById object then return.
    if (!loggedInUserAccountId || !usersById[loggedInUserAccountId]) {
      return;
    }
    const teamMemberIds: number[] = [];

    // Goes through the logged in user's teams
    usersById[loggedInUserAccountId]?.teamIds?.forEach((teamId) => {
      if (!teamsById[teamId]) return;

      // and then goes through each team's members and adds them to the userAccountIds object.
      teamsById[teamId].teamMemberIds?.forEach((memberId: number) => {
        teamMemberIds.push(memberId);
      });
    });

    // Save the teamMemberIds to a new set so that we only have one id per user.
    const teamMemberIdsSet = [...new Set(teamMemberIds)];
    setTeamMembersOnSameTeam(teamMemberIdsSet);
  }, [loggedInUserAccountId, usersById, teamsById]);

  useEffect(() => {
    if (!loggedInUserAccountId) return;
    dispatch(getWorkPlaceInsightsReportByUserId(loggedInUserAccountId));
    dispatch(getSuperpowersAndHiddenStrengths(loggedInUserAccountId));
  }, [loggedInUserAccountId, dispatch]);

  useEffect(() => {
    if (getPendingTestsStatus === "idle") {
      dispatch(getPendingTests());
    }
  }, [getPendingTestsStatus, dispatch]);

  // ------------------------------------- Memos ------------------------------------- //
  const userInfo = useMemo(() => {
    return loggedInUserAccountId ? usersById[loggedInUserAccountId] : null;
  }, [loggedInUserAccountId, usersById]);

  const userTeams = useMemo(() => {
    if (!userInfo?.teamIds) return [];
    // Filter out any teamIds that do not exist in the teamsById object
    return userInfo.teamIds.filter((teamId) => teamsById[teamId]);
  }, [teamsById, userInfo]);

  // ------------------------------------- Constants ------------------------------------- //
  const userTeamsLength = userTeams.length;
  const isLeader = !!teamAndDepartmentLeaderOf?.teams?.length;

  // ------------------------------------- Handlers ------------------------------------- //
  const onLaunchTeam360 = (incomingId?: number) => {
    if (incomingId) {
      return dispatch(showScheduleAssessmentModalForTeamId(incomingId));
    }
    dispatch(showScheduleAssessmentModal());
  };

  const onScrollNavigation = (path: string) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  // ------------------------------------- Getters ------------------------------------- //

  // ------------------------------------- My TEAMscans ------------------------------------- //
  const getManageOrViewResultsButton = () => {
    // if the user is not a leader of any teams, not an admin, or the department leader of any teams then we return null
    if (!teamAndDepartmentLeaderOf?.teams.length && !isAdmin) {
      return undefined;
    }
    const onButtonClick = () => {
      //if user teams length is one then the manage survey button should redirect the user to the survey table page for that team
      if (userTeamsLength === 1) {
        return onScrollNavigation(
          `/TeamGuide/${userTeams[0]}/TeamSettings?teamSettingsActiveTab=surveys`
        );
      }
      setIsManageTeamScanModalOpen(true);
    };
    return {
      text: "Manage / View Results",
      onClick: onButtonClick,
    };
  };

  const getMyTEAM360sCard = () => {
    const team360Cards = getHasAnyTeam360Results(
      teamsMostRecentAssessments,
      userTeams
    ) ? (
      <Team360TeamsCard userTeamIds={userTeams} />
    ) : null;
    const singleTeamId = userTeamsLength === 1 ? userTeams[0] : undefined;

    return (
      <DashboardOnboardingCard
        title="Understand Teamwork"
        emptyState={
          <div className="empty-card">
            <div
              className="medium-square-icon sapphire-10"
              style={{ marginBottom: "20px" }}
            >
              <FontAwesomeIcon icon={["fas", "poll-people"]} />
            </div>
            <span>Learn About Your Team</span>
            <div className="empty-card-text-container">
              <p>
                Teamwork surveys you've been invited to take and recent results
                will show here
              </p>
            </div>
            <div className="action-buttons">
              {isLeader ? (
                <Button
                  onClick={() => {
                    onLaunchTeam360();
                  }}
                >
                  Launch TEAMscan
                </Button>
              ) : null}
              <Button
                variant="secondary-blue"
                onClick={() =>
                  dispatch(
                    setShowModal({
                      eventType: "team360LeadViewNotScheduledYet",
                    })
                  )
                }
              >
                See details
              </Button>
            </div>
          </div>
        }
        body={
          team360Cards ? (
            <>
              <p>Here are the teamwork surveys you've been invited to take:</p>
              {team360Cards}
            </>
          ) : null
        }
        headerButton={{
          text: "Learn more",
          onClick: () => {
            dispatch(
              setShowModal({
                eventType: "generalTeam360Information",
                hideActionButton: true,
              })
            );
          },
        }}
        primaryButton={
          isLeader
            ? {
                isSecondaryColor: true,
                text: "Launch TEAMscan",
                onClick: () => onLaunchTeam360(singleTeamId),
              }
            : undefined
        }
        secondaryButton={getManageOrViewResultsButton()}
      />
    );
  };

  const getMyTeamsCard = () => {
    const pendingTeamLength =
      pendingTeamNotifications?.map((notification) => notification.teamId)
        ?.length ?? 0;

    return (
      <DashboardOnboardingCard
        title="My Teams"
        emptyState={
          <div className="empty-card">
            <div
              className="medium-square-icon sapphire-10"
              style={{ marginBottom: "20px" }}
            >
              <FontAwesomeIcon icon={["fas", "screen-users"]} />
            </div>
            <span>No Teams Yet</span>
            <div className="empty-card-text-container">
              <p>
                Any teams you've joined, been invited to join, or created will
                show up here.
              </p>
            </div>
            <div className="action-buttons">
              {canUserCreateTeams ? (
                <Button onClick={() => dispatch(openCreateTeamModal())}>
                  Create a team
                </Button>
              ) : null}

              <Button
                variant="secondary-blue"
                onClick={() => onScrollNavigation("/Search/Teams")}
              >
                See all teams
              </Button>
            </div>
          </div>
        }
        body={
          userTeamsLength > 0 ? (
            <>
              <p>
                Click a team to view its team guide, team members, and more:
              </p>
              <MyTeamsCard
                teamIds={userTeams}
                userAccountId={loggedInUserAccountId}
              />
            </>
          ) : null
        }
        secondaryButton={
          canUserCreateTeams
            ? {
                text: "Create a team",
                onClick: () => dispatch(openCreateTeamModal()),
              }
            : undefined
        }
        stepNumberHoverOver={{
          title: "Join your first team",
          body: "Accept or decline team invitations here.",
          timeToComplete: "Estimated time to complete: Less than a minute",
        }}
        footerText={getTeamTotalText(userTeams?.length, pendingTeamLength)}
        headerButton={{
          text: "See All",
          onClick: () => onScrollNavigation(`/Search/Teams`),
        }}
      />
    );
  };

  // ------------------------------------- My Teammates ------------------------------------- //
  const getTeammatesAvatars = (): any[] => {
    const isGreaterThan8 = teamMembersOnSameTeam.length > 8;

    // If more than 8 then we show the first 8 and then a +{number} more
    // If less than 8 then we show all of them
    const avatars = teamMembersOnSameTeam.slice(0, 9).map((userAccountId) => {
      const member = usersById[userAccountId];
      if (!member) return null;
      return (
        <Link
          to={`/UserGuide/${userAccountId}`}
          style={{
            textDecoration: "none",
          }}
          key={userAccountId}
        >
          <AvatarCircle
            userAccountId={userAccountId}
            key={userAccountId}
            className="custom-48px"
          />
        </Link>
      );
    });

    if (isGreaterThan8) {
      avatars.push(
        <Link
          to="/Search/People"
          style={{
            textDecoration: "none",
          }}
          key="more"
        >
          <div
            className="avatar-circle medium align-items-center custom-48px"
            key="more"
            style={{
              backgroundColor: "#ECEEF9",
              color: "#202D63",
              fontWeight: "bold",
            }}
          >
            +{teamMembersOnSameTeam.length - 8}
          </div>
        </Link>
      );
    }

    return avatars;
  };

  const getMyTeammatesCard = () => {
    const avatars = getTeammatesAvatars();
    if (avatars.length <= 1) return null;
    return (
      <DashboardOnboardingCard
        title="My Teammates"
        headerButton={{
          text: "See All",
          onClick: () => {
            onScrollNavigation("/Search/People");
          },
        }}
        body={<div className="row-gap-8px">{getTeammatesAvatars()}</div>}
        emptyState={<div>No Teammates</div>}
        footerText={`${teamMembersOnSameTeam.length} total teammate${
          teamMembersOnSameTeam.length === 1 ? "" : "s"
        }`}
        secondaryButton={
          canUserInviteNewMembers
            ? {
                text: "Invite Member",
                onClick: () => {
                  onShowInviteMemberModal();
                },
              }
            : undefined
        }
      />
    );
  };

  const getMainBody = () => {
    // if the user does not have work place insights then we show the my teammates card under the My Personality Snapshot Card on the right.
    return (
      <div className="dashboard-container">
        <ManageTeamScanModal
          show={isManageTeamScanModalOpen}
          onHide={() => {
            setIsManageTeamScanModalOpen(false);
            setManageTeamScanModalTeams(null);
          }}
          teams={manageTeamScanModalTeams || userTeams}
        />
        <ToDoCard />
        {getMyTEAM360sCard()}
        {getMyTeamsCard()}
        {getMyTeammatesCard()}
      </div>
    );
  };

  return getMainBody();
}
