import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getNavIcon = (
  nestedCount: number = 0,
  isNested: boolean = false,
  isSelected: boolean = false
) => {
  if (!isNested) return null;
  switch (nestedCount) {
    case 0:
      return <FontAwesomeIcon icon="arrow-right" />;
    case 1:
      return <FontAwesomeIcon icon={`caret-${isSelected ? "up" : "down"}`} />;
    default:
      return null;
  }
};
