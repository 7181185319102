import AvatarCircle from "app/components/AvatarCircle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  selectAllCompanyUsersById,
  selectSampleUsersInfoById,
} from "app/containers/Global/slice";
import { Link } from "react-router-dom";
import { useAppSelector } from "utils/redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MemberScore {
  score: number;
  userAccountId: number;
}

interface Props {
  memberScores: MemberScore[];
  lowLabel: string;
  highLabel: string;
}

export default function WorkplaceInsightBar({
  memberScores,
  lowLabel,
  highLabel,
}: Readonly<Props>) {
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const usersInfoById = { ...sampleUsersById, ...companyUsersById };
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [popoverShowingByUserId, setPopoverShowingByUserId] = useState<{
    [userId: number]: boolean;
  }>({});

  const debounce = (callback: () => void, delay: number) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    if (typeof ResizeObserver === "undefined") {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      debounce(() => {
        setContainerWidth(entry.contentRect.width);
      }, 500);
    });
    const currentRef = containerRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, []);

  // We will need to create a function that takes in the current memberScores and returns back a new array of nested arrays type
  const { allClashingScores, nonCrashingScores } = useMemo(() => {
    const threshold = 25; // This is the width size of the avatar.
    const allClashingScores: MemberScore[][] = [];
    const nonCrashingScores: MemberScore[] = [];
    let clashingScores: MemberScore[] = [];
    let isClashing = false;

    // will iterate through memberScores
    const addToArray = (
      memberScore: {
        userAccountId: number;
        score: number;
      },
      isClashingWithNext?: boolean
    ) => {
      // If the current score clashing with the next score of if the current score is clashing with the previous score, then push to clashingScores
      if (isClashing || isClashingWithNext) {
        clashingScores.push(memberScore);
      } else {
        // Else push to nonCrashingScores
        nonCrashingScores.push(memberScore);
      }

      // If the current score is not clashing with the next but is clashing with previous then we should push the current clashingScores to allClashingScores
      // and reset clashingScores to empty array
      if (isClashing && !isClashingWithNext) {
        allClashingScores.push(clashingScores);
        clashingScores = [];
      }
      isClashing = !!isClashingWithNext;
    };

    const sortedMembers = memberScores?.sort((a, b) => a.score - b.score);
    sortedMembers?.forEach((memberScore, idx) => {
      // If the last one no need to check the following one is clashing because there is no following one.
      if (idx === memberScores.length - 1) {
        addToArray(memberScore);
        return;
      }

      const currentScore = (memberScore.score / 100) * containerWidth;
      const nextScore = (memberScores[idx + 1].score / 100) * containerWidth;
      const isClashingWithNextMember = nextScore - currentScore < threshold;
      addToArray(memberScore, isClashingWithNextMember);
    });
    return { nonCrashingScores, allClashingScores };
  }, [memberScores, containerWidth]);

  const getAnonymousAvatar = (key?: string | number) => (
    <AvatarCircle
      size="small"
      key={key}
      icon={<FontAwesomeIcon icon="user" style={{ color: "#75787B" }} />}
      avatarColor="BABBBD"
    />
  );

  const getToolTipContent = (
    member: MemberScore,
    clashingMembers?: MemberScore[]
  ) => {
    if (!clashingMembers) {
      const memberInfo = usersInfoById[member.userAccountId];
      const memberName = memberInfo
        ? `${memberInfo.firstName || ""} ${memberInfo.lastName || ""}`
        : "Anonymous User";

      return <div>{memberName}</div>;
    }

    return (
      <div className="d-flex flex-nowrap gap-1">
        {clashingMembers.map((member) => {
          const memberInfo = usersInfoById[member.userAccountId];
          const avatar = memberInfo ? (
            <AvatarCircle
              userAccountId={member.userAccountId}
              size="small"
              key={member.userAccountId}
            />
          ) : (
            getAnonymousAvatar(member.userAccountId)
          );

          if (!memberInfo) {
            return <div>{avatar}</div>;
          }

          return (
            <Link
              to={`/UserGuide/${member.userAccountId}`}
              className="no-underline"
              key={member.userAccountId}
            >
              {avatar}
            </Link>
          );
        })}
      </div>
    );
  };

  const getMemberElement = (
    key: number,
    member: MemberScore,
    members?: MemberScore[]
  ) => {
    const score = member.score || 0;
    // left 95 is the width of the bar
    //so have to divide by 100 and multiply by 95
    const pos = (score / 100) * 95;

    const memberInfo = usersInfoById[member.userAccountId];
    const avatar = memberInfo ? (
      <Link
        to={`/UserGuide/${memberInfo.userAccountId}`}
        className="no-underline"
      >
        <AvatarCircle
          userAccountId={memberInfo.userAccountId}
          size="small"
          key={memberInfo.userAccountId}
        />
      </Link>
    ) : (
      <div>{getAnonymousAvatar()}</div>
    );

    return (
      <div
        style={{
          left: `${pos}%`,
        }}
        className="member"
        key={key}
      >
        {/* If member is not undefined then we should show an icon on top indicating that there is another user underneath */}
        {members ? (
          <div className="d-flex justify-content-center grey-text mb-1">
            <FontAwesomeIcon icon="location-dot" />
          </div>
        ) : null}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="button-tooltip"
              className="light-tooltip no-max-width-tooltip"
              onMouseEnter={() => {
                setPopoverShowingByUserId((prevState) => ({
                  ...prevState,
                  [key]: true,
                }));
              }}
              onMouseLeave={() => {
                setPopoverShowingByUserId((prevState) => ({
                  ...prevState,
                  [key]: false,
                }));
              }}
            >
              {getToolTipContent(member, members)}
            </Tooltip>
          }
          trigger={["hover", "focus"]}
          onToggle={() => {
            setPopoverShowingByUserId((prevState) => ({
              ...prevState,
              [key]: !prevState[key],
            }));
          }}
          show={popoverShowingByUserId[key]}
        >
          <div>{avatar}</div>
        </OverlayTrigger>
      </div>
    );
  };

  const members: React.JSX.Element[] = [];
  let key = 0;
  allClashingScores.forEach((clashingMembers) => {
    clashingMembers.forEach((member) => {
      key += 1;
      members.push(getMemberElement(key, member, clashingMembers));
    });
  });
  nonCrashingScores.forEach((member) => {
    key += 1;
    members.push(getMemberElement(key, member));
  });

  return (
    <>
      <div className="workplace-insights-bar-container" ref={containerRef}>
        <div className="bar">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="members">{members}</div>
      </div>

      <div className="workplace-insights-bar-labels">
        <div>
          <p>{lowLabel}</p>
        </div>
        <div>
          <p>{highLabel}</p>
        </div>
      </div>
    </>
  );
}
