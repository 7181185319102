import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export default function Disclaimer() {
  const [isOpen, setIsOpen] = useState(false);
  const showOrHide = isOpen ? "Hide" : "Show";
  return (
    <div className="disclaimer-box">
      <div style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon icon={["far", "circle-info"]} />
        <p className="disclaimer-text">
          By submitting a question, you acknowledge and agree to the disclaimer.{" "}
          <button
            className="disclaimer-button"
            onClick={() => setIsOpen(!isOpen)}
          >{`${showOrHide} disclaimer`}</button>
        </p>
      </div>
      {isOpen && (
        <p style={{ marginTop: "5px" }}>
          The following content is being generated by Criteria's AI system. It
          may contain errors, inconsistencies, or outdated information. If you
          don't want the AI tools turned on, you can shut them off in settings.
          This content is being provided "as-is," which means without any
          warranties or guarantees of accuracy. So, we have to disclaim any
          liability for damages or losses resulting from the use or reliance on
          this content, despite our attempts to be as accurate and up-to-date as
          possible. Don't hesitate to contact us for further details or
          explanation on any of the AI system content.
        </p>
      )}
    </div>
  );
}
