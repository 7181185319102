import Button from "app/storybookComponents/Button";
import { ChatbotConversation } from "app/containers/Chatbot/types";
import { Card, Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  deleteConversation,
  selectDeleteConversationStatusById,
  setChatbotMessages,
  setIsCoachbotOpen,
  setThreadId,
} from "app/containers/Chatbot/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { useState } from "react";

interface Props {
  chatbotConversation: ChatbotConversation;
  current?: boolean;
  onShowCommitmentModal?: () => void;
}
export default function CoachBotConversationCard({
  current,
  chatbotConversation,
  onShowCommitmentModal,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);

  const deleteConversationStatus = useAppSelector(
    selectDeleteConversationStatusById(chatbotConversation?.threadId)
  );

  const onCheckInClick = () => {
    onResumeChatClick();
  };
  const onResumeChatClick = () => {
    dispatch(setIsCoachbotOpen(true));
    dispatch(setThreadId(chatbotConversation.threadId));
    dispatch(setChatbotMessages(chatbotConversation.messages));
  };

  const onDropdownSelect = (e: string | null) => {
    if (e === "edit") {
      return onShowCommitmentModal?.();
    }
    if (e === "delete" && deleteConversationStatus !== "loading") {
      return setShowDeleteWarningModal(true);
    }
  };

  const getPrimaryActionButton = () => {
    if (current) {
      return <Button onClick={onCheckInClick}>Check in/ Resume chat</Button>;
    }
    return (
      <Button variant="secondary-blue" onClick={onResumeChatClick}>
        Resume Chat
      </Button>
    );
  };

  const getCreatedAtDate = () => {
    const date = new Date(chatbotConversation.created_at);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const getDropdown = () => (
    <Dropdown
      onSelect={onDropdownSelect}
      placement="auto"
      style={{
        position: "absolute",
        right: "20px",
      }}
    >
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" size="lg" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="edit">Edit Summary</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="delete" className="danger">
          Delete chat
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const title = chatbotConversation.summary?.title ?? null;
  return (
    <Card className="padding-20px column-gap-16px">
      <WarningModal
        isOpen={!!showDeleteWarningModal}
        hideModal={() => setShowDeleteWarningModal(false)}
        modalTitle="Delete conversation"
        warningTitle="Are you sure you want to delete this conversation?"
        warningMessage="This action cannot be undone. All chats will be permanently deleted."
        onConfirmClick={async () => {
          await dispatch(deleteConversation(chatbotConversation.threadId));
          setShowDeleteWarningModal(false);
        }}
        isDanger
      />
      {title ? <h3>{title}</h3> : null}
      {getDropdown()}
      <div className="d-flex justify-content-between">
        <div className="row-gap-8px">
          <span className="label-tag blue m-0">{getCreatedAtDate()}</span>
          {current ? (
            <span className="label-tag green m-0">Current</span>
          ) : null}
        </div>
      </div>
      <div className="column-gap-8px">
        <p className="grey-text">Summary</p>
        <p>{chatbotConversation.summary?.summary ?? "No summary provided."}</p>
      </div>
      <div className="column-gap-8px">
        <p className="grey-text">Commitment</p>
        <p>
          {chatbotConversation.summary?.commitment
            ? chatbotConversation.summary?.commitment
            : "No specific commitments were made during this conversation."}
        </p>
      </div>
      <div className="row-gap-8px">
        {getPrimaryActionButton()}
        <Button variant="secondary-blue" onClick={onShowCommitmentModal}>
          Edit
        </Button>
      </div>
    </Card>
  );
}
