import { AllPermissionChartProps, VisibilitySettings } from "./types";

export const TEAM_SUMMARY_KEY_BREAKDOWN = [
  {
    title: "REPLACE_ME in need of help",
    description: "<60 overall score & <50 on 4 or more core dimensions",
    className: "need-help",
  },
  {
    title: "Healthy REPLACE_ME",
    description: ">60 overall score & >50 on at least 14 to 17 core dimensions",
    className: "healthy",
  },
  {
    title: "High-performing REPLACE_ME",
    description: ">70 overall score & >70 on all core dimensions",
    className: "high",
  },
] as const;

export const ADMIN_CONSOLE_TABS = [
  "Dashboard",
  "Departments",
  "Surveys",
  "SurveyBreakdown",
  "Teams",
  "People",
  "Settings",
  "Billing",
  "Analytics",
  "Automations",
] as const;

export const WHO_CAN_SETTINGS_MAP = {
  1: "All members",
  2: "Only admins",
  3: "Only managers, team leaders, and admin",
  4: "Custom List",
} as { [key: number]: string };

export const SETTING_ORDER = [1, 3, 2, 4] as const;

export const ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP = {
  1: "Privacy-Focused",
  2: "Balanced",
  3: "Transparency-Focused",
} as { [key: number]: string };

const IndividualReportPermissionDataColumns = [
  {
    label: "Admin",
    key: "admin",
  },
  {
    label: "User's manager",
    key: "manager",
  },
  {
    label: "User",
    key: "user",
  },
  {
    label: "User's team leader(s)",
    key: "teamLeader",
  },
  {
    label: "Everyone on User's team(s)",
    key: "teamMembersInSameTeam",
  },
  {
    label: "Everyone else",
    key: "everyone",
  },
];

const TeamReportPermissionDataColumns = [
  {
    label: "Admin",
    key: "admin",
  },
  {
    label: "Team leader(s)",
    key: "teamLeader",
  },
  {
    label: "Team members on Team",
    key: "teamMembersInSameTeam",
  },
  {
    label: "Team's department Leader",
    key: "departmentLeader",
  },
  {
    label: "Everyone else",
    key: "everyone",
  },
];

const DepartmentReportDataColumns = [
  {
    label: "Admin",
    key: "admin",
  },
  {
    label: "Department's leader",
    key: "departmentLeaders",
  },
  {
    label: "Team leader(s)",
    key: "teamLeader",
  },
  {
    label: "Managers",
    key: "managers",
  },
  {
    label: "Everyone else",
    key: "individualContributors",
  },
];

const TeamPersonalityReportPermissionDataColumns = [
  {
    label: "Admin",
    key: "admin",
  },
  {
    label: "User's manager",
    key: "manager",
  },
  {
    label: "User",
    key: "user",
  },
  {
    label: "User's team leader(s) and members",
    key: "usersTeamMembers",
  },
  {
    label: "Team's department leader",
    key: "departmentLeader",
  },
  {
    label: "Everyone else",
    key: "everyone",
  },
];

export const BalancedPermissionsDefault: AllPermissionChartProps = {
  IndividualReportPermissionData: {
    template: {
      "Workplace Insights Report": {
        admin: 1,
        manager: 1,
        user: 1,
        teamLeader: 0,
        teamMembersInSameTeam: 0,
        everyone: 0,
      },
      "Collaboration Guide": {
        admin: 1,
        manager: 1,
        user: null,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        everyone: 0,
      },
      "Manager Guide": {
        admin: 1,
        manager: 1,
        user: null,
        teamLeader: 1,
        teamMembersInSameTeam: 0,
        everyone: 0,
      },
    },
    columns: IndividualReportPermissionDataColumns,
  },
  TeamReportPermissionData: {
    template: {
      "Team Personality Report": {
        admin: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        departmentLeader: 1,
        everyone: 0,
      },
      "TEAMscan Report (Team-Level)": {
        admin: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        departmentLeader: 1,
        everyone: 0,
      },
    },
    columns: TeamReportPermissionDataColumns,
  },
  DepartmentReportData: {
    template: {
      "TEAMscan Report (Department-Level)": {
        admin: 1,
        departmentLeaders: 1,
        teamLeader: 0,
        managers: 0,
        individualContributors: 0,
      },
    },
    columns: DepartmentReportDataColumns,
  },
  OrganizationReportData: {
    template: {
      "TEAMscan Report (Organization-Level)": {
        admin: 1,
        departmentLeaders: 0,
        teamLeader: 0,
        managers: 0,
        individualContributors: 0,
      },
    },
    columns: DepartmentReportDataColumns,
  },
  TeamPersonalityReportPermissionData: {
    template: {
      "Individual Personality Traits on the Team Personality Report": {
        admin: 1,
        manager: 0,
        user: 1,
        usersTeamMembers: 1,
        departmentLeader: 1,
        everyone: 0,
      },
    },
    columns: TeamPersonalityReportPermissionDataColumns,
  },
};

export const PrivacyFocusedPermissionDefault: AllPermissionChartProps = {
  IndividualReportPermissionData: {
    template: {
      "Workplace Insights Report": {
        admin: 1,
        manager: 0,
        user: 1,
        teamLeader: 0,
        teamMembersInSameTeam: 0,
        everyone: 0,
      },
      "Collaboration Guide": {
        admin: 1,
        manager: 1,
        user: null,
        teamLeader: 0,
        teamMembersInSameTeam: 0,
        everyone: 0,
      },
      "Manager Guide": {
        admin: 1,
        manager: 1,
        user: null,
        teamLeader: 0,
        teamMembersInSameTeam: 0,
        everyone: 0,
      },
    },
    columns: IndividualReportPermissionDataColumns,
  },
  TeamReportPermissionData: {
    template: {
      "Team Personality Report": {
        admin: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 0,
        departmentLeader: 0,
        everyone: 0,
      },
      "TEAMscan Report (Team-Level)": {
        admin: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 0,
        departmentLeader: 0,
        everyone: 0,
      },
    },
    columns: TeamReportPermissionDataColumns,
  },
  DepartmentReportData: {
    template: {
      "TEAMscan Report (Department-Level)": {
        admin: 1,
        departmentLeaders: 1,
        teamLeader: 0,
        managers: 0,
        individualContributors: 0,
      },
    },
    columns: DepartmentReportDataColumns,
  },
  OrganizationReportData: {
    template: {
      "TEAMscan Report (Organization-Level)": {
        admin: 1,
        departmentLeaders: 0,
        teamLeader: 0,
        managers: 0,
        individualContributors: 0,
      },
    },
    columns: DepartmentReportDataColumns,
  },
  TeamPersonalityReportPermissionData: {
    template: {
      "Individual Personality Traits on the Team Personality Report": {
        admin: 1,
        manager: 0,
        user: 1,
        usersTeamMembers: 0,
        departmentLeader: 0,
        everyone: 0,
      },
    },
    columns: TeamPersonalityReportPermissionDataColumns,
  },
};

export const TransparencyFocusedDefault: AllPermissionChartProps = {
  IndividualReportPermissionData: {
    template: {
      "Workplace Insights Report": {
        admin: 1,
        manager: 1,
        user: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        everyone: 1,
      },
      "Collaboration Guide": {
        admin: 1,
        manager: 1,
        user: null,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        everyone: 1,
      },
      "Manager Guide": {
        admin: 1,
        manager: 1,
        user: null,
        teamLeader: 1,
        teamMembersInSameTeam: 0,
        everyone: 0,
      },
    },
    columns: IndividualReportPermissionDataColumns,
  },
  TeamReportPermissionData: {
    template: {
      "Team Personality Report": {
        admin: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        departmentLeader: 1,
        everyone: 1,
      },
      "TEAMscan Report (Team-Level)": {
        admin: 1,
        teamLeader: 1,
        teamMembersInSameTeam: 1,
        departmentLeader: 1,
        everyone: 1,
      },
    },
    columns: TeamReportPermissionDataColumns,
  },
  DepartmentReportData: {
    template: {
      "TEAMscan Report (Department-Level)": {
        admin: 1,
        departmentLeaders: 1,
        teamLeader: 0,
        managers: 0,
        individualContributors: 0,
      },
    },
    columns: DepartmentReportDataColumns,
  },
  OrganizationReportData: {
    template: {
      "TEAMscan Report (Organization-Level)": {
        admin: 1,
        departmentLeaders: 0,
        teamLeader: 0,
        managers: 0,
        individualContributors: 0,
      },
    },
    columns: DepartmentReportDataColumns,
  },
  TeamPersonalityReportPermissionData: {
    template: {
      "Individual Personality Traits on the Team Personality Report": {
        admin: 1,
        manager: 1,
        user: 1,
        usersTeamMembers: 1,
        departmentLeader: 1,
        everyone: 1,
      },
    },
    columns: TeamPersonalityReportPermissionDataColumns,
  },
};

export const VisibilitySettingsConstant: VisibilitySettings = {
  Balanced: BalancedPermissionsDefault,
  "Privacy-Focused": PrivacyFocusedPermissionDefault,
  "Transparency-Focused": TransparencyFocusedDefault,
};
