import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { ReactElement, useEffect, useState } from "react";
import {
  getOpenAIPrompts,
  selectGettingOpenAIPromptsStatus,
  selectOpenAIPrompts,
  generateExamplePromptResponse,
  selectExamplePromptResponse,
  selectGettingExamplePromptresponseStatus,
  updateOpenAIPrompt,
  selectUpdatingOpenAIPromptStatus,
} from "./slice";

export default function OpenAIPromptEditor() {
  const dispatch = useAppDispatch();

  const openAIPrompts = useAppSelector(selectOpenAIPrompts);
  const gettingOpenAIPromptsStatus = useAppSelector(
    selectGettingOpenAIPromptsStatus
  );

  const exampleResponse = useAppSelector(selectExamplePromptResponse);
  const gettingExamplePromptresponseStatus = useAppSelector(
    selectGettingExamplePromptresponseStatus
  );
  const gettingUpdatePromptresponseStatus = useAppSelector(
    selectUpdatingOpenAIPromptStatus
  );
  const [selectedPromptId, setSelectedPromptId] = useState(0);
  const [examplePrompt, setExamplePrompt] = useState("");
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [backgroundContext, setBackgroundContext] = useState("");
  const [prompt, setPrompt] = useState("");
  const [tokensRequired, setTokensRequired] = useState(0);
  const [modelType, setModelType] = useState("");

  useEffect(() => {
    if (gettingOpenAIPromptsStatus === "idle") {
      dispatch(getOpenAIPrompts());
    }
  }, [gettingOpenAIPromptsStatus, dispatch]);

  const getOpenAIPromptResponse = () => {
    dispatch(
      generateExamplePromptResponse({
        backgroundContext: backgroundContext,
        prompt: examplePrompt,
        tokensRequired: tokensRequired,
        modelType: modelType,
      })
    );
  };

  const callUpdateOpenAIPrompt = () => {
    dispatch(
      updateOpenAIPrompt({
        promptId: selectedPromptId,
        backgroundContext: backgroundContext,
        prompt: prompt,
        tokensRequired: tokensRequired,
        modelType: modelType,
      })
    );
  };

  const promptForm = () => {
    if (!selectedPromptId) {
      return;
    }
    return (
      <Form>
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <Form.Label>Background Context</Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          value={backgroundContext}
          onChange={(e) => {
            setBackgroundContext(e.target.value);
          }}
        />
        <Form.Label>Prompt</Form.Label>
        <Form.Control
          as="textarea"
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value);
            setExamplePrompt(e.target.value);
          }}
        />
        <Form.Label>Tokens Required</Form.Label>
        <Form.Control
          type="number"
          value={tokensRequired}
          onChange={(e) => {
            setTokensRequired(Number(e.target.value));
          }}
        />
        <Form.Label>Model Type</Form.Label>
        <Form.Select
          value={modelType}
          onChange={(e) => {
            setModelType(e.target.value);
          }}
        >
          <option value="completion">completion</option>
          <option value="chat">chat</option>
        </Form.Select>
        <Form.Label>
          Example Filled In Prompt (Used to generate the Example response)
        </Form.Label>
        <Form.Control
          as="textarea"
          value={examplePrompt}
          onChange={(e) => {
            setExamplePrompt(e.target.value);
          }}
        />
      </Form>
    );
  };

  const onDropdownSelect = (eventKey: any) => {
    setSelectedPromptId(eventKey);
    if (openAIPrompts) {
      const selectedPrompt = openAIPrompts.prompts.find(
        (prompt) => prompt.id === Number(eventKey)
      );
      if (selectedPrompt) {
        setPrompt(selectedPrompt.prompt);
        setTitle(selectedPrompt.category);
        setBackgroundContext(selectedPrompt.backgroundContext);
        setTokensRequired(selectedPrompt.tokensRequired);
        setModelType(selectedPrompt.modelType);
        setExamplePrompt(selectedPrompt.prompt);
      } else {
        setPrompt("");
        setTitle("");
        setBackgroundContext("");
        setTokensRequired(0);
        setModelType("");
        setExamplePrompt("");
      }
    }
  };

  const dropdown = () => {
    switch (gettingOpenAIPromptsStatus) {
      case "loading":
        return <p>Loading...</p>;
      case "failed":
        return <p>Failed to get Prompts</p>;
      default:
        if (openAIPrompts) {
          const prompts: ReactElement[] = [];
          openAIPrompts.prompts.forEach((prompt) => {
            prompts.push(
              <Dropdown.Item eventKey={prompt.id}>
                {prompt.category}
              </Dropdown.Item>
            );
          });
          return (
            <Dropdown
              onSelect={onDropdownSelect}
              defaultValue={selectedPromptId}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Select Prompt
              </Dropdown.Toggle>
              <Dropdown.Menu id="user-settings-menu">{prompts}</Dropdown.Menu>
            </Dropdown>
          );
        }
    }
  };

  const savePromptButton = () => {
    return (
      <Button
        onClick={callUpdateOpenAIPrompt}
        disabled={gettingUpdatePromptresponseStatus === "loading"}
      >
        Update Prompt
      </Button>
    );
  };

  const generateResponseButton = () => {
    const filledInAllVariables = examplePrompt?.indexOf("$") === -1;
    return (
      <Button
        disabled={
          !filledInAllVariables ||
          gettingExamplePromptresponseStatus === "loading"
        }
        onClick={getOpenAIPromptResponse}
      >
        {!filledInAllVariables
          ? "Fill in all variables to generate example response"
          : "Generate Example Response"}
      </Button>
    );
  };

  const header = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <h2>Prompt Editor</h2>
        <Button onClick={() => setShow(!show)}>
          {show ? "Hide" : "Show"} Prompt Editor
        </Button>
      </div>
    );
  };

  const exampleResponseMessage = () => {
    if (gettingExamplePromptresponseStatus === "loading") {
      return <p>Loading...</p>;
    } else if (gettingExamplePromptresponseStatus === "failed") {
      return <p>Failed to get example response</p>;
    }
    return <p>{exampleResponse}</p>;
  };

  const exampleResponseSection = () => {
    if (!selectedPromptId) {
      return;
    }
    return (
      <div>
        {exampleResponseMessage()}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          {generateResponseButton()}
          {savePromptButton()}
        </div>
      </div>
    );
  };

  if (!show) {
    return header();
  }

  return (
    <>
      {header()}
      <Card style={{ padding: "20px" }}>
        {dropdown()}
        {promptForm()}
        {exampleResponseSection()}
      </Card>
    </>
  );
}
