import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveQueryTokenInLocalStorage } from "./helpers";

const useSessionStorageDeepLink = (sessionKey: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a path saved in session storage
    const path = sessionStorage.getItem(sessionKey);
    if (!path) {
      return;
    }

    // Save the query token in local storage
    saveQueryTokenInLocalStorage();

    // Redirect to the saved path
    navigate(path);

    // Remove the saved path from session storage
    sessionStorage.removeItem(sessionKey);
  }, [sessionKey, navigate]);
};

export default useSessionStorageDeepLink;
