import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditableDataHeaders, StoredRows, StringOrNumber } from "./types";
import { useCallback, useMemo } from "react";
import SearchableDropdown from "app/storybookComponents/SearchableInput/SearchableDropdown";
import TextInput from "app/storybookComponents/Forms/TextInput";
import Paginator from "app/components/SortableTable/Paginator";

interface EditableDataProps {
  rows: StoredRows;
  setRows: (rows: StoredRows) => void;
  headers: EditableDataHeaders;
  errorElement?: React.ReactNode | React.ReactNode[];
  totalRows?: number;
  addPagination?: boolean;
  currentPage?: number;
  itemsPerPage?: number;
  setCurrentPage?: (page: number) => void;
  continueButton?: React.ReactNode;
  onDeleteRow?: (rowId: number) => void;
}

const EditableData: React.FC<EditableDataProps> = ({
  rows,
  setRows,
  headers,
  addPagination,
  currentPage = 0,
  itemsPerPage = 20,
  totalRows,
  setCurrentPage,
  errorElement,
  continueButton,
  onDeleteRow,
}) => {
  const onInputChange = useCallback(
    (rowIndex: number, key: StringOrNumber, updatedValue: StringOrNumber) => {
      const foundRow = rows[rowIndex];
      if (!foundRow) {
        return;
      }

      setRows({
        ...rows,
        [rowIndex]: {
          ...foundRow,
          [key]: updatedValue,
        },
      });
    },
    [rows, setRows]
  );

  const getClassName = useCallback(
    (rowId: number, rowKey: StringOrNumber) => {
      const headerObject = headers[rowKey];
      let className = "input-field";
      if (typeof headerObject === "string") {
        return className; // If it is a string then we do not have any special classes
      }
      const rowData = rows?.[rowId];
      const cellData = String(rows?.[rowId]?.[rowKey]);

      if (
        (rowData &&
          headerObject?.isDangerFunction?.(rowData, String(rowKey))) ||
        (headerObject.required && !cellData)
      ) {
        className += " danger";
      }

      if (
        rowData &&
        headerObject?.isWarningFunction?.(rowData, String(rowKey))
      ) {
        className += " warning";
      }

      if (rowData && headerObject?.isLoadingFunction?.(rowData)) {
        className += " loading";
      }

      return className;
    },
    [headers, rows]
  );

  const checkIfRowIsDisabled = useCallback(
    (rowId: number, rowKey: StringOrNumber, value: StringOrNumber) => {
      const headerObject = headers[rowKey];
      const rowData = rows?.[rowId];
      if (typeof headerObject !== "object") {
        return false;
      }
      return (
        !!rowData &&
        (headerObject.isDisabledFunction?.(rowData) ||
          headerObject.isLoadingFunction?.(rowData))
      );
    },
    [rows, headers]
  );

  const inputField = useCallback(
    (rowId: number, rowKey: StringOrNumber, value: StringOrNumber) => {
      const headerObject = headers[rowKey];
      const stringValue = String(value);
      const onUnFocus = (updatedValue?: string) => {
        headerObject?.onCellUnFocus?.(rowId, updatedValue);
      };

      if (typeof headerObject === "object" && headerObject.dropDownOptions) {
        const rowData = rows?.[rowId];
        return (
          <SearchableDropdown
            items={headerObject.dropDownOptions}
            placeholder={headerObject.displayName}
            onSelect={(item) => {
              onInputChange(rowId, rowKey, item);
            }}
            showDanger
            displayText={stringValue}
            onUnFocus={onUnFocus}
            forceDanger={headerObject.isDangerFunction?.(
              rowData,
              String(rowKey)
            )}
          />
        );
      }

      return (
        <TextInput
          inputText={stringValue}
          onTextChange={(e) => {
            onInputChange(rowId, rowKey, e);
          }}
          className={getClassName(rowId, rowKey)}
          controlId={`${rowId}-${rowKey}`}
          onUnFocus={() => onUnFocus(stringValue)}
          disabled={checkIfRowIsDisabled(rowId, rowKey, value)}
        />
      );
    },
    [headers, onInputChange, getClassName, checkIfRowIsDisabled, rows]
  );

  // convert getRow to a usecallback function
  const getRow = useCallback(
    (row: { [key: StringOrNumber]: StringOrNumber }, keyIdx: number) => {
      const rowCols = Object.keys(headers).map((key) => {
        const value = row[key] ?? "";
        return <td key={key}>{inputField(keyIdx, key, value)}</td>;
      });

      return [
        ...rowCols,
        <td key="delete">
          <Button
            onClick={() => {
              onDeleteRow?.(keyIdx);
            }}
            variant="secondary-gray"
          >
            <FontAwesomeIcon icon="trash-can" />
          </Button>
        </td>,
      ];
    },
    [headers, inputField, onDeleteRow]
  );

  const visibleRows = useMemo(() => {
    const newRows: StoredRows = {};

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    Object.entries(rows)
      .slice(startIndex, endIndex)
      .forEach(([key, value]) => {
        newRows[Number(key)] = value;
      });

    return Object.entries(newRows).map(([key, value]) => (
      <tr key={key}>{getRow(value, Number(key))}</tr>
    ));
  }, [rows, currentPage, itemsPerPage, getRow]);

  const getTableHeaders = () => (
    <thead>
      <tr>
        {Object.entries(headers).map(([key, value]) => {
          const label = typeof value === "string" ? value : value.displayName;
          return (
            <th key={key} style={typeof value === "object" ? value.style : {}}>
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );

  const getPagination = () => {
    if (!addPagination || !totalRows || !currentPage || !setCurrentPage) {
      return null;
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex =
      startIndex + itemsPerPage > totalRows
        ? totalRows
        : startIndex + itemsPerPage;
    return (
      <div className="d-flex justify-content-between align-items-center mb-1">
        <Paginator
          pageRangeDisplayed={5}
          pageCount={Math.ceil(totalRows / itemsPerPage)}
          onPageChange={(selectedItem) => {
            setCurrentPage(selectedItem.selected + 1);
          }}
        />

        {continueButton ?? (
          <p className="grey-text">
            Page <b>{currentPage}</b> of {Math.ceil(totalRows / itemsPerPage)} |
            Showing {startIndex + 1} - {endIndex} of {totalRows}
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <table className="editable-table">
        {getTableHeaders()}
        <tbody>{visibleRows}</tbody>
      </table>
      {errorElement}
      {getPagination()}
    </>
  );
};

export default EditableData;
