import SimpleModal from "./SimpleModal";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  closeSamplePersonalityModal,
  selectSamplePersonalityModalIsOpen,
} from "app/components/Modals/slice";
import Button from "app/storybookComponents/Button";
import CollaborationGuideSamplePdf from "resources/sample_pdfs/sample-collaboration-guide.pdf";
import { Link } from "react-router-dom";
import { SampleDataInfo } from "./types";

interface Props {
  sampleDataInfo: SampleDataInfo | null;
}

export default function SamplePersonalityReportModal({
  sampleDataInfo,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const show = useAppSelector(selectSamplePersonalityModalIsOpen);
  const onHide = () => {
    dispatch(closeSamplePersonalityModal());
  };

  const getLink = (reportTitle: string, reportType: string, link: string) => {
    if (reportTitle === "Collaboration") {
      return (
        <Button
          className="nowrap"
          variant="secondary-blue"
          onClick={() => {
            window.open(CollaborationGuideSamplePdf);
            onHide();
          }}
        >
          View sample {reportType}
        </Button>
      );
    }
    return (
      <Link to={link} className="sample-data-link" onClick={onHide}>
        View sample {reportType}
      </Link>
    );
  };

  const getReportCard = (
    reportTitle: string,
    reportType: string,
    link: string
  ) => {
    const lowerCaseReportType = reportType.toLowerCase();
    return (
      <div className="section-body-row">
        <div
          className="d-flex gap-2 align-items-center"
          style={{ color: "#53565a" }}
        >
          <div className="section-body-name-and-description">
            <span className="section-body-row-name">
              Sample {reportTitle} {reportType}
            </span>
            <span className="section-body-row-description">
              See a sample {reportTitle} {reportType}
            </span>
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          {getLink(reportTitle, lowerCaseReportType, link)}
        </div>
      </div>
    );
  };

  if (!sampleDataInfo) {
    return null;
  }

  return (
    <SimpleModal
      show={show}
      onHide={onHide}
      title="Sample Personality Insights Report"
    >
      <div className="section-container">
        {getReportCard(
          "Workplace Insights",
          "report",
          `UserGuide/${sampleDataInfo?.sampleUserAccountId}?tab=Workplace+Insights`
        )}
        {getReportCard(
          "Team Personality",
          "report",
          `TeamGuide/${sampleDataInfo?.teamPersonalityTeamId}?tab=Team+Personality`
        )}

        {/* {getReportCard("Collaboration", "Guide", "")}
        {getReportCard(
          "Manager",
          "Guide",
          `UserGuide/${sampleDataInfo?.sampleUserAccountId}?tab=Manager+Guide`
        )} */}
      </div>
    </SimpleModal>
  );
}
