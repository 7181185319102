import { ReactNode } from "react";

export const VALID_ONBOARDING_MODAL_TYPES = [
  "visitedTeam360TabWithoutResults",
  "visitedTeam360TabWithResults",
  "team360LeadViewNotScheduledYet", // This value is not saved in the database because we want to display this modal once per load UNTIL the user schedules an assessment
  "userGuideOnboarding",
  "teamGuideOnboarding",
  "organizationGuideOnboarding",
  "generalTeam360Information",
] as const;

export const VALID_TRACKING_EVENTS = [
  "visitedTeam360TabWithoutResults",
  "visitedTeam360TabWithResults",
  // The events bellow do not have a corresponding modal
  "invitedTeamMember",
  "adminQuickActionClosed",
  "departmentLeaderQuickActionClosed",
  "teamLeaderQuickActionClosed",
] as const;

export type ValidTrackingEvent = (typeof VALID_TRACKING_EVENTS)[number];
export const isValidTrackingEvent = (
  event: string
): event is ValidTrackingEvent =>
  VALID_TRACKING_EVENTS.includes(event as ValidTrackingEvent);

export type OnboardingModalType = (typeof VALID_ONBOARDING_MODAL_TYPES)[number];

export interface TrackingObject {
  siteWideEvents: {
    visitedTeam360TabWithResults?: boolean;
  };
  teamEvents: {
    [teamId: number]: {
      visitedTeam360TabWithoutResults?: boolean;
    };
  };
}

export interface OnboardingStateTemplate {
  modalTitle: ReactNode;
  bodyHeader: {
    title?: string | null;
    description: string;
  };
  listItems: {
    title: string;
    description: string;
  }[];
  bodyFooter: ReactNode;
  dismissButtonText?: string;
}
