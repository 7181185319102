import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";

// ------------------ State Type/Structure ------------------
export interface QuickActionDrawerState {
  isQuickActionDrawerOpen: boolean;
}

// ------------------ InitialState ------------------
const initialState: QuickActionDrawerState = {
  isQuickActionDrawerOpen: false,
};

// ------------------ Beginning of Slice Definition ------------------
export const quickActionDrawerSlice = createSlice({
  name: "example",
  initialState,
  reducers: {
    setIsQuickActionDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isQuickActionDrawerOpen = action.payload;
    },
  },
});

// ------------------ Selectors ------------------

export const selectIsQuickActionDrawerOpen = (state: RootState) =>
  state.quickActions.isQuickActionDrawerOpen;

export const { setIsQuickActionDrawerOpen } = quickActionDrawerSlice.actions;

export default quickActionDrawerSlice.reducer;
