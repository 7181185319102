import { useAppSelector } from "utils/redux/hooks";
import { useSearchParams } from "react-router-dom";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import {
  ALL_TEAM_360_FACTORS,
  TEAM360_DIMENSIONS_MAP,
  CONSTRUCT_NAME_KEY,
} from "./constants";
import SimpleModal from "../Modals/SimpleModal";
import ShortCompareGraph from "app/storybookComponents/Charts/ShortCompareGraph";
import {
  selectDepartments,
  selectIsDepartmentsHidden,
} from "app/containers/AdminConsole/slice";
import {
  selectSampleTeamsByTeamId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { Team360AssessmentResult, isTeam360Factor } from "./types";
import { getTeam360Score } from "./helpers";
import { getEntries } from "utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
  onHide: () => void;
  show?: boolean;
  teamId?: number;
  departmentId?: number;
  preSelectedDepartmentOrOrg?: "department" | "organization";
  percentileScores?: {
    percentile25: Team360AssessmentResult;
    percentile50: Team360AssessmentResult;
    percentile75: Team360AssessmentResult;
  } | null;
  startDate?: string;
  endDate?: string;
  maxNumberShowingWithoutSeeMore?: number;
}

export default function Team360ComparisonModal({
  show,
  onHide,
  teamId,
  departmentId,
  preSelectedDepartmentOrOrg,
  percentileScores,
  startDate,
  endDate,
  maxNumberShowingWithoutSeeMore = 10,
}: Props) {
  const [searchParams] = useSearchParams();
  const team360Scores = useAppSelector(
    selectAllTeam360Results({ startDate, endDate })
  );
  const departments = useAppSelector(selectDepartments);
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);

  const [seeMore, setSeeMore] = useState(false);
  const teams = { ...sampleTeamsById, ...teamsById };
  const teamInfo = teamId ? teams[teamId] : null;
  const isSample = searchParams.get("isSample") === "true";

  const [selectedFactor, setSelectedFactor] =
    useState<string>("Overall Teamwork");
  const [selectedDimension, setSelectedDimension] = useState<string | null>(
    null
  );
  const [departmentOrOrgSelected, setDepartmentOrOrgSelected] = useState<
    "department" | "organization"
  >("department");

  useEffect(() => {
    if (preSelectedDepartmentOrOrg) {
      setDepartmentOrOrgSelected(preSelectedDepartmentOrOrg);
    }
  }, [preSelectedDepartmentOrOrg]);

  const DEPARTMENT_OPTIONS = {
    team: {
      department: "This department",
      organization: "This organization",
    },
    department: {
      department: "Teams in this department",
      organization: "Departments in this organization",
    },
    organization: {
      department: "Departments in this organization",
      organization: "Teams in this organization",
    },
  } as const;

  const getFactorDropdown = () => {
    const onFactorSelect = (eventKey: string | null) => {
      if (!eventKey) return;
      setSelectedFactor(eventKey);
      setSelectedDimension(null);
    };

    const dropdownOptions = ["Overall Teamwork", ...ALL_TEAM_360_FACTORS];
    return (
      <Dropdown onSelect={onFactorSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {selectedFactor}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          {dropdownOptions.map((option) => (
            <Dropdown.Item eventKey={option} key={option}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getDimensionDropdown = () => {
    if (!isTeam360Factor(selectedFactor)) return null;

    const onDimensionSelect = (eventKey: string | null) => {
      if (eventKey) {
        setSelectedDimension(eventKey);
      } else {
        setSelectedDimension(null);
      }
    };

    const dropdownOptions = [
      undefined,
      ...TEAM360_DIMENSIONS_MAP[selectedFactor],
    ];
    return (
      <Dropdown onSelect={onDimensionSelect}>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {selectedDimension ?? `Overall ${selectedFactor}`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dropdownOptions.map((option) => (
            <Dropdown.Item eventKey={option} key={option}>
              {option ?? `Overall ${selectedFactor}`}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const onOrgOrDepartmentSelect = (eventKey: string | null) => {
    if (eventKey === "department" || eventKey === "organization") {
      setDepartmentOrOrgSelected(eventKey);
    }
  };

  const getGraphValues = () => {
    if (!show) return [];
    // If no team Id return empty array
    if (teamId) {
      return getTeamGraphValues(teamId);
    }

    if (departmentId) {
      return getDepartmentGraphValues(departmentId);
    }

    return getAllGraphValues();
  };

  const getPercentileScores = (): {
    label: string;
    value: number;
    dull?: boolean;
  }[] => {
    if (!percentileScores) {
      return [];
    }

    return [
      {
        label: "25th Percentile",
        value: getTeam360Score(getGraphValue(percentileScores.percentile25)),
        dull: false,
      },
      {
        label: "50th Percentile",
        value: getTeam360Score(getGraphValue(percentileScores.percentile50)),
        dull: false,
      },
      {
        label: "75th Percentile",
        value: getTeam360Score(getGraphValue(percentileScores.percentile75)),
        dull: false,
      },
    ];
  };

  const getTeamScoreEntries = (
    teamScores: {
      [teamId: number]: Team360AssessmentResult;
    },
    isSampleTeam: number
  ) => {
    const values: {
      label: string;
      value: number;
      dull?: boolean;
    }[] = [];
    const teamsToUse = isSampleTeam ? sampleTeamsById : teamsById;
    getEntries(teamScores).forEach(
      ([team360TeamId, team360AssessmentResults]) => {
        if (team360AssessmentResults?.overall) {
          if (!teamsToUse[team360TeamId]) {
            return;
          }
          const graphValue = Math.round(
            getTeam360Score(getGraphValue(team360AssessmentResults))
          );
          values.push({
            label: teams[team360TeamId].teamName,
            value: graphValue,
            dull: teamId !== Number(team360TeamId),
          });
        }
      }
    );

    return values;
  };

  const getTeamGraphValues = (teamId: number) => {
    const values: {
      label: string;
      value: number;
      dull?: boolean;
    }[] = [];
    const departmentInfo = teamInfo?.departmentId
      ? departments[teamInfo.departmentId]
      : null;
    const isSampleTeam = teamInfo?.isSampleTeam ? 1 : 0;
    if (departmentInfo && departmentOrOrgSelected === "department") {
      departmentInfo?.teams?.forEach((teamIdNumber) => {
        const team = teams[teamIdNumber];
        const team360AssessmentResults = isSampleTeam
          ? team360Scores?.sampleTeamScores?.[teamIdNumber]
          : team360Scores?.teamScores?.[teamIdNumber];

        if (team360AssessmentResults?.overall) {
          const graphValue = Math.round(
            getTeam360Score(getGraphValue(team360AssessmentResults))
          );
          values.push({
            label: team.teamName,
            value: graphValue,
            dull: teamId !== teamIdNumber,
          });
        }
      });
    } else if (team360Scores?.teamScores) {
      const teamScores = isSampleTeam
        ? team360Scores.sampleTeamScores
        : team360Scores.teamScores;
      const entries = getTeamScoreEntries(teamScores, isSampleTeam);
      values.push(...entries);
    }
    return values.sort((a, b) => b.value - a.value);
  };

  const getDepartmentGraphValues = (departmentId: number) => {
    const values: {
      label: string;
      value: number;
      dull?: boolean;
    }[] = [];
    const departmentInfo = departments[departmentId];
    const isSample = departmentInfo?.companyAccountId === 0;
    if (departmentOrOrgSelected === "department") {
      departmentInfo?.teams?.forEach((teamIdNumber) => {
        const team = teams[teamIdNumber];
        const team360AssessmentResults = isSample
          ? team360Scores?.sampleTeamScores?.[teamIdNumber]
          : team360Scores?.teamScores?.[teamIdNumber];
        if (team360AssessmentResults?.overall) {
          const graphValue = Math.round(
            getTeam360Score(getGraphValue(team360AssessmentResults))
          );
          values.push({
            label: team.teamName,
            value: graphValue,
            dull: teamId !== teamIdNumber,
          });
        }
      });
    } else if (team360Scores?.teamScores) {
      const departmentScores = isSample
        ? team360Scores.sampleDepartmentScores
        : team360Scores.departmentScores;
      getEntries(departmentScores).forEach(
        ([team360DepartmentId, team360AssessmentResults]) => {
          if (team360AssessmentResults?.overall) {
            const graphValue = Math.round(
              getTeam360Score(getGraphValue(team360AssessmentResults))
            );
            values.push({
              label: departments[team360DepartmentId].name ?? "",
              value: graphValue,
              dull: departmentId !== Number(team360DepartmentId),
            });
          }
        }
      );
    }

    // Shall iterate through all teams in the department and get the average score for each team
    return values.sort((a, b) => b.value - a.value);
  };

  const getAllGraphValues = () => {
    let values: {
      label: string;
      value: number;
      dull?: boolean;
    }[] = [];
    if (!team360Scores) {
      return values;
    }
    const departmentScores = isSample
      ? team360Scores?.sampleDepartmentScores
      : team360Scores?.departmentScores;
    const teamScores = isSample
      ? team360Scores?.sampleTeamScores
      : team360Scores?.teamScores;
    if (departmentOrOrgSelected === "department") {
      getEntries(departmentScores).forEach(
        ([team360DepartmentId, team360AssessmentResults]) => {
          if (team360AssessmentResults?.overall) {
            const graphValue = Math.round(
              getTeam360Score(getGraphValue(team360AssessmentResults))
            );
            values.push({
              label: departments[team360DepartmentId]?.name ?? "",
              value: graphValue,
              dull: true,
            });
          }
        }
      );
    } else {
      getEntries(teamScores).forEach(
        ([team360DepartmentId, team360AssessmentResults]) => {
          if (team360AssessmentResults?.overall) {
            const graphValue = Math.round(
              getTeam360Score(getGraphValue(team360AssessmentResults))
            );
            values.push({
              label: teams[team360DepartmentId]?.teamName ?? "",
              value: graphValue,
              dull: true,
            });
          }
        }
      );
      values = [...getPercentileScores(), ...values];
    }

    return values.sort((a, b) => b.value - a.value);
  };

  const getGraphValue = (assessmentResults: Team360AssessmentResult) => {
    if (selectedFactor === "Overall Teamwork") {
      return assessmentResults.overall ?? 0;
    } else if (selectedDimension && CONSTRUCT_NAME_KEY[selectedDimension]) {
      return (
        assessmentResults?.dimensions?.[
          CONSTRUCT_NAME_KEY[selectedDimension]
        ] ?? 0
      );
    } else {
      return assessmentResults.factors?.[selectedFactor] ?? 0;
    }
  };

  const getAverageRatingText = () => {
    if (selectedFactor === "Overall Teamwork") {
      return "Average rating of teamwork by ";
    }
    return `Average rating of ${selectedFactor} by `;
  };

  const getTeamOrDepartmentShowing = (): keyof typeof DEPARTMENT_OPTIONS => {
    if (teamId) {
      return "team";
    } else if (departmentId) {
      return "department";
    } else {
      return "organization";
    }
  };

  const getAverageRatingDropdown = () => {
    // Given that we are seeing the team comparison modal and the team does not have a department no need to show the dropdown
    // Or if the departments are hidden no need to show the dropdown.
    if ((teamId && !teamInfo?.departmentId) || isDepartmentsHidden) {
      return null;
    }

    const dropdownOptions = ["department", "organization"] as const;
    const teamOrDepartmentShowing = getTeamOrDepartmentShowing();
    return (
      <div className="row-gap-12px align-items-center">
        <p>
          <b>{getAverageRatingText()}</b>
        </p>
        <Dropdown onSelect={onOrgOrDepartmentSelect}>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {
              DEPARTMENT_OPTIONS[teamOrDepartmentShowing][
                departmentOrOrgSelected
              ]
            }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {dropdownOptions.map((option: string) => {
              const label =
                DEPARTMENT_OPTIONS[teamOrDepartmentShowing][
                  option as keyof typeof DEPARTMENT_OPTIONS.team
                ];
              return (
                <Dropdown.Item eventKey={option} key={option}>
                  {label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const graphValues = getGraphValues();
  const slicedGraphValues = graphValues.slice(
    0,
    seeMore ? 100 : maxNumberShowingWithoutSeeMore
  );

  return (
    <SimpleModal show={show} onHide={onHide} title="Comparison Report">
      <>
        <p>
          Use the filters below to compare either your team's overall teamwork
          or any individual teamwork area or dimension with a sampling of
          results from other teams in your department or organization (if
          applicable). This shows you where you stand and helps you find teams
          to connect with.
        </p>
        <div className="row-gap-20px">
          <div>{getFactorDropdown()}</div>
          <div>{getDimensionDropdown()}</div>
        </div>
        <hr className="m-0" />
        {getAverageRatingDropdown()}
        <ShortCompareGraph bars={slicedGraphValues} />
        {graphValues.length > maxNumberShowingWithoutSeeMore ? (
          <div>
            <Button
              onClick={() => setSeeMore(!seeMore)}
              variant="secondary-blue"
            >
              {seeMore ? "See Less" : "See More"}{" "}
              <FontAwesomeIcon
                icon={`caret-${seeMore ? "up" : "down"}`}
                className="ms-1"
              />
            </Button>
          </div>
        ) : null}
      </>
    </SimpleModal>
  );
}
