import LaunchAssessmentConfigBody from "app/components/LaunchAssessmentModal/LaunchAssessmentConfigBody";
import SimpleModal from "app/components/Modals/SimpleModal";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectTeamAssessmentsInstancesById,
  selectTeamAssessmentsSeriesById,
  updateAssessmentInstance,
  updateAssessmentSeries,
} from "app/containers/Assessment/slice";
import {
  SurveyEventFrequency,
  SurveyEventTypes,
} from "app/components/Team360Assessment/types";
import { DEFAULT_CUSTOM_MESSAGE } from "app/components/LaunchAssessmentModal/constants";

interface Props {
  editingAssessmentId?: null | number;
  isOpen?: boolean;
  onHide: () => void;
  show?: boolean;
}

export default function EditSurveyModal({
  editingAssessmentId,
  onHide,
  show,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  // -------------------- Selectors --------------------
  const selectedAssessmentInstance = useAppSelector((state) =>
    editingAssessmentId
      ? selectTeamAssessmentsInstancesById(state, editingAssessmentId)
      : null
  );
  const selectedAssessmentSeries = useAppSelector((state) =>
    editingAssessmentId
      ? selectTeamAssessmentsSeriesById(state, editingAssessmentId)
      : null
  );
  const selectedAssessment =
    selectedAssessmentSeries ?? selectedAssessmentInstance;

  // -------------------- States --------------------
  const [eventType, setEventType] =
    useState<SurveyEventTypes>("One-time event");
  const [frequency, setFrequency] = useState<SurveyEventFrequency>("Weekly");
  const [customMessage, setCustomMessage] = useState<false | string>(
    selectedAssessment?.customMessage ?? DEFAULT_CUSTOM_MESSAGE
  );
  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [advancedOpened, setAdvancedOpened] = useState(true);

  // -------------------- Hooks --------------------
  useEffect(() => {
    const selectedAssessment =
      selectedAssessmentSeries ?? selectedAssessmentInstance;
    if (!selectedAssessment) return;
    if (selectedAssessmentSeries) {
      setEventType("Recurring event");
    } else {
      setEventType("One-time event");
    }

    setDates((prevDates) => ({
      ...prevDates,
      startDate: new Date(selectedAssessment.startDate),
      endDate: new Date(selectedAssessment.endDate),
    }));
    setCustomMessage(selectedAssessment.customMessage ?? false);
  }, [selectedAssessmentInstance, selectedAssessmentSeries]);

  const onHideAndReset = () => {
    onHide();
    setEventType("One-time event");
    setFrequency("Weekly");
    setCustomMessage(DEFAULT_CUSTOM_MESSAGE);
    setDates({
      startDate: new Date(),
      endDate: new Date(),
    });
    setAdvancedOpened(true);
  };

  return (
    <SimpleModal title="Edit Survey" onHide={onHideAndReset} show={!!show}>
      <LaunchAssessmentConfigBody
        parentDates={dates}
        parentEventType={eventType}
        parentFrequency={frequency}
        parentCustomMessage={customMessage}
        setAdvancedOpened={setAdvancedOpened}
        advancedOpened={advancedOpened}
        onCancel={() => {
          onHideAndReset();
        }}
        onSave={({ customMessage, dates }) => {
          if (!editingAssessmentId) return;
          const basePayload = {
            customMessage: customMessage || "",
            endDate: dates.endDate.toISOString(),
            startDate: selectedAssessment?.startDate,
          };

          if (selectedAssessmentInstance) {
            dispatch(
              updateAssessmentInstance({
                payload: {
                  ...basePayload,
                  isActive:
                    selectedAssessmentInstance.isAssessmentInstanceActive
                      ? 1
                      : 0,
                },
                assessmentInstanceId:
                  selectedAssessmentInstance.assessmentInstanceId,
              })
            );
            onHideAndReset();
          } else if (selectedAssessmentSeries) {
            dispatch(
              updateAssessmentSeries({
                payload: {
                  ...basePayload,
                  active: selectedAssessmentSeries.isAssessmentActive ? 1 : 0,
                },
                assessmentId: editingAssessmentId,
              })
            );
            onHideAndReset();
          }
        }}
        isEditingExistingAssessment
      />
    </SimpleModal>
  );
}
