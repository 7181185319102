import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, Modal } from "react-bootstrap";
import { TSkill } from "../types";
import Button from "app/storybookComponents/Button";
import TextArea from "app/storybookComponents/Forms/TextArea";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
} from "app/containers/Global/slice";
import {
  clearCreatedSkillFocus,
  selectSubmitTeamSkillFocusStatus,
  submitTeamSkillFocus,
  selectCreatedSkillFocus,
} from "../slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkillsFocusCard from "../SkillsFocusCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";

interface Props {
  skill: TSkill;
  showModal?: boolean;
  hideModal: () => void;
}
export default function AddSkillModal({ showModal, hideModal, skill }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const currentUserId = useAppSelector(selectCurrentUserAccountId);
  const submitTeamSkillFocusStatus = useAppSelector(
    selectSubmitTeamSkillFocusStatus
  );
  const createdSkillFocus = useAppSelector(selectCreatedSkillFocus);
  const [successCriteria, setSuccessCriteria] = useState<string>("");
  const [goal, setGoal] = useState<string>("");
  const [selectedTeamId, setSelectedTeamId] = useState<null | number>(
    searchParams.get("teamId") ? parseInt(searchParams.get("teamId")!) : null
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const getDropdownItems = () => {
    if (!currentUserId) return null;
    const userTeams = usersInfoById[currentUserId]?.teamIds;
    return userTeams?.map((teamId) => {
      const teamObj = teamInfoById[teamId];
      if (!teamObj) return null;
      return (
        <Dropdown.Item key={teamObj.teamId} eventKey={teamObj.teamId}>
          {teamObj.teamName}
        </Dropdown.Item>
      );
    });
  };

  const onTeamDropdownSelect = (teamId: string | null) => {
    setSelectedTeamId(teamId ? parseInt(teamId) : null);
  };

  const onAddSkill = () => {
    if (!selectedTeamId || !startDate) return;
    dispatch(
      submitTeamSkillFocus({
        payload: {
          skillId: skill.id,
          goal,
          successCriteria,
          targetDate: startDate.toISOString(),
        },
        teamId: selectedTeamId,
      })
    );
  };

  const onCloseModal = () => {
    hideModal();
    dispatch(clearCreatedSkillFocus());
  };

  const getModalContent = () => {
    if (submitTeamSkillFocusStatus === "succeeded" && createdSkillFocus) {
      return (
        <div>
          <div>
            <FontAwesomeIcon icon="check-circle" /> Skill added to team guide
          </div>
          <SkillsFocusCard skillFocus={createdSkillFocus} skill={skill} />
          <div>
            <Button
              onClick={() => {
                dispatch(clearCreatedSkillFocus());
                navigate(`/TeamGuide/${selectedTeamId}`);
              }}
            >
              Go to Team Guide
            </Button>
            <Button onClick={onCloseModal}>Close</Button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <p>{skill.inSummary?.replace("Filled In:", "")}</p>
        <div>
          <div>
            <span className="textarea-label">
              Set this skill as a goal for:
            </span>
            <Dropdown
              onSelect={onTeamDropdownSelect}
              className="full-width-dropdown"
              aria-required
            >
              <Dropdown.Toggle variant="light">
                {selectedTeamId
                  ? teamInfoById[selectedTeamId].teamName
                  : "Select a team"}
              </Dropdown.Toggle>
              <Dropdown.Menu>{getDropdownItems()}</Dropdown.Menu>
            </Dropdown>
          </div>
          <TextArea
            inputText={goal}
            inputLabel={
              "What are you going to try this week to strengthen this skill?"
            }
            onTextChange={setGoal}
            controlId={"editContent"}
            placeholder={"Field Placeholder"}
            maxLength={250}
          />
          <TextArea
            inputText={successCriteria}
            inputLabel={"How will you know if you are successful?"}
            onTextChange={setSuccessCriteria}
            controlId={"editContent2"}
            placeholder={"Field Placeholder"}
            maxLength={250}
          />
          Set A Deadline
          <div className="date-and-time">
            <div>
              Date / Time
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                showTimeSelect
                dateFormat="Pp"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <Button variant="secondary-gray" onClick={onCloseModal}>
            Back
          </Button>
          <Button
            onClick={onAddSkill}
            disabled={
              submitTeamSkillFocusStatus === "loading" || !selectedTeamId
            }
          >
            Add Skill
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      className="simple-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>{skill?.title}</h2>
        <Button
          onClick={() => hideModal()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      {getModalContent()}
    </Modal>
  );
}
