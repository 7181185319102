import { selectTeamAndDepartmentLeadIdsForLoggedInUser } from "app/containers/AdminConsole/slice";
import {
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";
import AvatarCircle from "../AvatarCircle";
import { getS } from "utils/helperFunctions";
import Button from "app/storybookComponents/Button";
import SimpleModal from "./SimpleModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiCardCollapsibleContainer from "app/storybookComponents/CollapsibleCards/MultiCardCollapsibleContainer";
import { useNavigate } from "react-router-dom";

interface Props {
  teams: number[];
  show: boolean;
  onHide: () => void;
}

// this component will take in an array of teams and display them, if the user is an admin all of the team should have the ability to go into the survey settings
// if the user is a team leader then theyshould too have the ability to go into the survey settings
// if the user is a regular user then they should not have the ability to go into the survey settings but they should have a button to go into the results
export default function ManageTeamScanModal({
  teams,
  show,
  onHide,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const isUserAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const teamsAndDepartmentsUserIsLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );

  const getRows = () => teams.map((teamId) => getRow(teamId));

  const getUserHasEditAccess = (teamId: number) => {
    return (
      isUserAdmin || teamsAndDepartmentsUserIsLeaderOf?.teams?.includes(teamId)
    );
  };

  const getRow = (teamId: number) => {
    const userHasEditAccess = getUserHasEditAccess(teamId);
    const teamInfo = teamsById[teamId];
    if (!teamInfo) return null;
    const {
      teamName,
      profilePicture,
      avatarColor,
      teamMemberIds = [],
    } = teamInfo;

    return (
      <div className="my-team-360-card">
        <div className="my-team-360-card-action-info">
          <AvatarCircle
            profilePicture={profilePicture}
            avatarColor={avatarColor}
            name={teamName}
            size="small"
          />
          <div className="my-team-360-card-info-text">
            <p>
              <b>{teamName}</b>
            </p>
            <p className="small-body-text">
              {teamMemberIds.length} member{getS(teamMemberIds.length)}
            </p>
          </div>
        </div>
        <div className="my-team-360-card-action">
          <Button
            variant="secondary-gray"
            onClick={() => {
              navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
            }}
          >
            Go to results
          </Button>
          {userHasEditAccess && (
            <Button
              variant="secondary-blue"
              className="border-0"
              onClick={() => {
                navigate(
                  `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=surveys`
                );
              }}
            >
              Manage TEAMscans{" "}
              <FontAwesomeIcon icon="chevron-right" className="ms-1" />
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <SimpleModal show={show} onHide={onHide} title="Manage TEAMscan results">
      <p>These are teams that have an active TEAMscan right now:</p>
      <MultiCardCollapsibleContainer
        items={getRows()}
        className="manage-survey-container"
      />
    </SimpleModal>
  );
}
