import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { parseJSON } from "utils/request";
import { getUserIdFromCookie } from "utils/helperFunctions";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { CheckInviteLinkResponse, CreateAccountPayload } from "./types";
import { signUserIn } from "app/containers/Global/slice";

// ------------------ State Type/Structure ------------------
export interface AccountSetupState {
  checkInviteLinkResponse: null | CheckInviteLinkResponse;
  checkInviteLinkStatus: responseStatus;

  checkIfEmailExistResponse: null | string;
  checkIfEmailExistStatus: responseStatus;

  setupAccountStatus: responseStatus;
  checkSetupAccountStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: AccountSetupState = {
  checkInviteLinkResponse: null,
  checkIfEmailExistResponse: null,
  checkInviteLinkStatus: "idle",
  checkIfEmailExistStatus: "idle",
  setupAccountStatus: "idle",
  checkSetupAccountStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const checkInviteLink = createAsyncThunk(
  "accountSetup/checkInviteLink",
  async (invitationCode: string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/checkInviteLink`;

    const body = JSON.stringify({ code: invitationCode });
    const checkInviteLinkResponse = await fetch(requestUrl, {
      method: "POST",
      body,
    });

    if (!checkInviteLinkResponse.ok) {
      throw new Error(checkInviteLinkResponse.statusText);
    }

    return (await parseJSON(
      checkInviteLinkResponse
    )) as CheckInviteLinkResponse;
  }
);

export const checkIfEmailExist = createAsyncThunk(
  "accountSetup/checkIfEmailExist",
  async (payload: { code: string; emailAddress: string }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/doInviteLink`;

    const body = JSON.stringify(payload);
    const checkIfEmailExistResponse = await fetch(requestUrl, {
      method: "POST",
      body,
    });

    const parsedResponse = await parseJSON(checkIfEmailExistResponse);

    if (!checkIfEmailExistResponse.ok) {
      throw new Error(parsedResponse);
    }
  }
);

export const setupAccount = createAsyncThunk(
  "accountSetup/setupAccount",
  async (payload: CreateAccountPayload) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/setupAccount`;

    const setupAccountResponse = await fetch(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        ...payload,
      }),
    });
    const parsedResponse = await parseJSON(setupAccountResponse);

    if (!setupAccountResponse.ok) {
      throw new Error(parsedResponse);
    }

    // If the response is valid we save the token then call the getUser and getTeams endpoint
    if (parsedResponse?.jwt) {
      localStorage.setItem("tmg-tkn", parsedResponse.jwt);
      // setCookieItem('critToken', parsedResponse.jwt, 1);
    }
  }
);

export const checkSetupAccount = createAsyncThunk(
  "accountSetup/checkSetupAccount",
  async (payload: { token1: string }) => {
    const requestUrl = `${REACT_APP_API_URL}/checkSetupAccount`;

    const setupAccountResponse = await fetch(requestUrl, {
      method: "POST",
      body: JSON.stringify({ ...payload }),
    });
    const parsedResponse = (await parseJSON(setupAccountResponse)) as {
      setupAccount: boolean;
    };

    // if parsedResponse.setupAccount is set to false redirect to /
    if (!parsedResponse.setupAccount) {
      window.location.href = "/";
    }
  }
);

// This is the parent function that takes care signing up the user and logging them in
export const signUserUp =
  (payload: CreateAccountPayload): AppThunk =>
  async (dispatch) => {
    const setupResponse = await dispatch(setupAccount(payload));
    const { userId } = getUserIdFromCookie();

    // If the response is valid we save the token then call the getUser and getTeams endpoint
    if (userId && setupResponse.meta.requestStatus !== "rejected") {
      dispatch(signUserIn({ userAccountId: userId }));
    }
  };

// ------------------ Beginning of Slice Definition ------------------
export const accountSetupSlice = createSlice({
  name: "accountSetup",
  initialState,
  reducers: {
    clearEmailExistStatus: (state) => {
      state.checkIfEmailExistResponse = "idle";
    },
    clearCheckIfEmailExistStatus: (state) => {
      state.checkIfEmailExistStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkInviteLink.pending, (state) => {
        state.checkInviteLinkStatus = "loading";
      })
      .addCase(
        checkInviteLink.fulfilled,
        (state, action: PayloadAction<CheckInviteLinkResponse>) => {
          return {
            ...state,
            checkInviteLinkStatus: "succeeded",
            checkInviteLinkResponse: action.payload,
          };
        }
      )
      .addCase(checkInviteLink.rejected, (state) => {
        state.checkInviteLinkStatus = "failed";
      })
      .addCase(checkIfEmailExist.pending, (state) => {
        state.checkIfEmailExistStatus = "loading";
      })
      .addCase(checkIfEmailExist.fulfilled, (state) => {
        state.checkIfEmailExistStatus = "succeeded";
      })
      .addCase(checkIfEmailExist.rejected, (state, action) => {
        state.checkIfEmailExistResponse = action.error.message || null;
        state.checkIfEmailExistStatus = "failed";
      })
      .addCase(setupAccount.pending, (state) => {
        state.setupAccountStatus = "loading";
      })
      .addCase(setupAccount.fulfilled, (state) => {
        state.setupAccountStatus = "succeeded";
      })
      .addCase(setupAccount.rejected, (state) => {
        state.setupAccountStatus = "failed";
      })
      .addCase(checkSetupAccount.pending, (state) => {
        state.checkSetupAccountStatus = "loading";
      })
      .addCase(checkSetupAccount.fulfilled, (state) => {
        state.checkSetupAccountStatus = "succeeded";
      })
      .addCase(checkSetupAccount.rejected, (state) => {
        state.checkSetupAccountStatus = "failed";
      });
  },
});

export const { clearEmailExistStatus, clearCheckIfEmailExistStatus } =
  accountSetupSlice.actions;

// ------------------ Selectors ------------------
export const selectCheckInviteLinkResponse = (state: RootState) =>
  state.accountSetup.checkInviteLinkResponse;

export const selectCheckInviteLinkStatus = (state: RootState) =>
  state.accountSetup.checkInviteLinkStatus;

export const selectCheckIfEmailExistStatus = (state: RootState) =>
  state.accountSetup.checkIfEmailExistStatus;

export const selectCheckIfEmailExistResponse = (state: RootState) =>
  state.accountSetup.checkIfEmailExistResponse;

export const selectSetupAccountStatus = (state: RootState) =>
  state.accountSetup.setupAccountStatus;

export const selectCheckSetupAccountStatus = (state: RootState) =>
  state.accountSetup.checkSetupAccountStatus;

export default accountSetupSlice.reducer;
