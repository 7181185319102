import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { request } from "utils/request";
import { SampleDataInfo, SubscriptionStatus } from "./types";
import { responseStatus } from "utils/types";
import { trackDirectReportModalOpened } from "utils/trackingFunctions";

// ------------------ State Type/Structure ------------------
export interface ModalsState {
  isCreateTeamModalOpen: boolean;
  createTeamModalPreSelectedDepartmentId: number | null;
  redirectUponTeamCreation: boolean; // It might be worth to create a whole separate slice just for modals, if we have more than one modal persisting across pages.
  isFromAdminConsole: boolean;
  createdTeamId: number | null;
  editDepartmentModalOpen: boolean;
  editingDepartmentId: number | null;
  openTeamCreationAfterDepartmentCreation?: boolean | number | null;
  isInviteTeamLeaderModalOpen: boolean;
  inviteTeamLeaderModalTeamId: number | null;
  inviteTeamLeaderModalDepartmentId: number | null;
  isSampleTEAMscanModalOpen: boolean;
  isSamplePersonalityModalOpen: boolean;
  isNewDirectReportModalOpen: {
    userAccountId: number;
  } | null;
  sampleDataInfo: SampleDataInfo | null;
  getSampleDataInfoStatus: responseStatus;
  isSubscriptionModalOpen: boolean;
  subscriptionStatus: SubscriptionStatus | null;
  getSubscriptionStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: ModalsState = {
  isCreateTeamModalOpen: false,
  createTeamModalPreSelectedDepartmentId: null,
  redirectUponTeamCreation: false,
  isFromAdminConsole: false,
  createdTeamId: null,
  editDepartmentModalOpen: false,
  editingDepartmentId: null,
  openTeamCreationAfterDepartmentCreation: false,
  isInviteTeamLeaderModalOpen: false,
  inviteTeamLeaderModalTeamId: null,
  inviteTeamLeaderModalDepartmentId: null,
  isSampleTEAMscanModalOpen: false,
  isSamplePersonalityModalOpen: false,
  sampleDataInfo: null,
  isNewDirectReportModalOpen: null,
  getSampleDataInfoStatus: "idle",
  isSubscriptionModalOpen: false,
  getSubscriptionStatus: "idle",
  subscriptionStatus: null,
};
export const getSampleDataInfo = createAsyncThunk(
  "getSampleDataInfo",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sampleDataInfo`;
    return (await request(requestUrl, {
      method: "GET",
    })) as SampleDataInfo;
  },
  {
    condition: (_, { getState }) => {
      const {
        modals: { getSampleDataInfoStatus },
      } = getState() as RootState;
      if (getSampleDataInfoStatus !== "idle") {
        return false;
      }
    },
  }
);
export const getSubscriptionInfo = createAsyncThunk(
  "getSubscriptionStatus",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/subscriptionStatus`;
    return (await request(requestUrl, {
      method: "GET",
    })) as SubscriptionStatus;
  }
);
export const onSuccessfulDepartmentCreation = createAsyncThunk(
  "modals/onSuccessfulDepartmentCreation",
  async (departmentId: number | undefined, { getState, dispatch }) => {
    const {
      modals: { openTeamCreationAfterDepartmentCreation },
    } = getState() as RootState;

    if (openTeamCreationAfterDepartmentCreation) {
      dispatch(closeEditDepartmentModal()); // Only closing the modal when we redirect to thr team creation modal, because we will be showing the success screen inside of the modal.
      dispatch(openCreateTeamModal({ preSelectedDepartmentId: departmentId }));
    }
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openCreateTeamModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            redirectUponTeamCreation?: boolean;
            preSelectedDepartmentId?: number | null;
            isFromAdminConsole?: boolean;
          }
        | undefined
      >
    ) {
      return {
        ...state,
        redirectUponTeamCreation: !!payload?.redirectUponTeamCreation,
        isCreateTeamModalOpen: true,
        createTeamModalPreSelectedDepartmentId:
          payload?.preSelectedDepartmentId || null,
        isFromAdminConsole: !!payload?.isFromAdminConsole,
      };
    },
    closeCreateTeamModal(state) {
      state.redirectUponTeamCreation = false;
      state.isCreateTeamModalOpen = false;
      state.createdTeamId = null;
      state.createTeamModalPreSelectedDepartmentId = null;
    },
    setCreatedTeamId(state, { payload }: PayloadAction<number | null>) {
      state.createdTeamId = payload;
    },
    openEditDepartmentModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            departmentId?: number;
            openTeamCreationAfterDepartmentCreation?: boolean | number;
          }
        | undefined
      >
    ) {
      state.editDepartmentModalOpen = true;
      state.editingDepartmentId = payload?.departmentId || null;
      state.openTeamCreationAfterDepartmentCreation =
        payload?.openTeamCreationAfterDepartmentCreation;
    },
    closeEditDepartmentModal(state) {
      state.editDepartmentModalOpen = false;
      state.editingDepartmentId = null;
      state.openTeamCreationAfterDepartmentCreation = false;
    },
    openInviteTeamLeaderModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            teamId?: number | null;
            departmentId?: number | null;
          }
        | undefined
      >
    ) {
      state.isInviteTeamLeaderModalOpen = true;
      state.inviteTeamLeaderModalTeamId = payload?.teamId || null;
      state.inviteTeamLeaderModalDepartmentId = payload?.departmentId || null;
    },
    closeInviteTeamLeaderModal(state) {
      state.isInviteTeamLeaderModalOpen = false;
      state.inviteTeamLeaderModalTeamId = null;
      state.inviteTeamLeaderModalDepartmentId = null;
    },
    openSampleTEAMscanModal(state) {
      state.isSampleTEAMscanModalOpen = true;
    },
    closeSampleTEAMscanModal(state) {
      state.isSampleTEAMscanModalOpen = false;
    },
    openSamplePersonalityModal(state) {
      state.isSamplePersonalityModalOpen = true;
    },
    closeSamplePersonalityModal(state) {
      state.isSamplePersonalityModalOpen = false;
    },
    setIsNewDirectReportModalOpen(
      state,
      {
        payload,
      }: PayloadAction<{
        userAccountId: number;
      } | null>
    ) {
      state.isNewDirectReportModalOpen = payload;
      if (payload) {
        trackDirectReportModalOpened();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSampleDataInfo.pending, (state) => {
        state.getSampleDataInfoStatus = "loading";
      })
      .addCase(
        getSampleDataInfo.fulfilled,
        (state, action: PayloadAction<SampleDataInfo>) => {
          state.getSampleDataInfoStatus = "succeeded";
          state.sampleDataInfo = action.payload;
        }
      )
      .addCase(getSampleDataInfo.rejected, (state) => {
        state.getSampleDataInfoStatus = "failed";
      })
      .addCase(getSubscriptionInfo.pending, (state) => {
        state.getSubscriptionStatus = "loading";
      })
      .addCase(
        getSubscriptionInfo.fulfilled,
        (state, action: PayloadAction<SubscriptionStatus>) => {
          state.isSubscriptionModalOpen = !(
            action.payload.isSubscribed || action.payload.hasValidFreeTrial
          );
          state.subscriptionStatus = action.payload;
          state.getSubscriptionStatus = "succeeded";
        }
      )
      .addCase(getSubscriptionInfo.rejected, (state) => {
        state.getSubscriptionStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectCreateTeamModalIsOpen = (state: RootState) =>
  state.modals.isCreateTeamModalOpen;
export const selectCreateTeamModalPreSelectedDepartmentId = (
  state: RootState
) => state.modals.createTeamModalPreSelectedDepartmentId;
export const selectRedirectAfterCreatingTeam = (state: RootState) =>
  state.modals.redirectUponTeamCreation;
export const selectCreatedTeamId = (state: RootState) =>
  state.modals.createdTeamId;
export const selectEditDepartmentModalIsOpen = (state: RootState) =>
  state.modals.editDepartmentModalOpen;
export const selectEditingDepartmentId = (state: RootState) =>
  state.modals.editingDepartmentId;
export const selectIsModalComingFromAdminConsole = (state: RootState) =>
  state.modals.isFromAdminConsole;
export const selectInviteTeamLeaderModalIsOpen = (state: RootState) =>
  state.modals.isInviteTeamLeaderModalOpen;
export const selectInviteTeamLeaderModalTeamId = (state: RootState) =>
  state.modals.inviteTeamLeaderModalTeamId;
export const selectInviteTeamLeaderModalDepartmentId = (state: RootState) =>
  state.modals.inviteTeamLeaderModalDepartmentId;
export const selectSampleTEAMscanModalIsOpen = (state: RootState) =>
  state.modals.isSampleTEAMscanModalOpen;
export const selectSamplePersonalityModalIsOpen = (state: RootState) =>
  state.modals.isSamplePersonalityModalOpen;
export const selectSampleDataInfo = (state: RootState) =>
  state.modals.sampleDataInfo;
export const selectGetSampleDataInfoStatus = (state: RootState) =>
  state.modals.getSampleDataInfoStatus;
export const selectIsSubscriptionModalOpen = (state: RootState) =>
  state.modals.isSubscriptionModalOpen;
export const selectSubscriptionStatus = (state: RootState) =>
  state.modals.subscriptionStatus;
export const selectGetSubscriptionStatus = (state: RootState) =>
  state.modals.getSubscriptionStatus;
export const selectIsNewDirectReportModalOpen = (state: RootState) =>
  state.modals.isNewDirectReportModalOpen;

export const {
  openCreateTeamModal,
  closeCreateTeamModal,
  setCreatedTeamId,
  closeEditDepartmentModal,
  openEditDepartmentModal,
  openInviteTeamLeaderModal,
  closeInviteTeamLeaderModal,
  openSampleTEAMscanModal,
  closeSampleTEAMscanModal,
  openSamplePersonalityModal,
  closeSamplePersonalityModal,
  setIsNewDirectReportModalOpen,
} = modalsSlice.actions;

export default modalsSlice.reducer;
