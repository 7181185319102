// Anything after this will be sorted by sectionId, since the sectionId is serialized they will be sorted by date
// Using this in preference to using just an array to define the order, so that we can set these values in the backend easily
export const userGuideSectionOrderPriority: { [title: string]: number } = {
  "about me": 1,
  "at work": 2,
  "for fun": 3,
} as const;

export const USER_TABS = [
  "About",
  "Manager Guide",
  "Collaboration Guide",
  "Workplace Insights",
  "Chat History",
  "Job Candidate Profile",
] as const;
