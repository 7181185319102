import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { request } from "utils/request";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import { hideModal } from "app/components/Modules/slice";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  ModuleTemplates,
  ModuleType,
  Guide,
  UsedModuleTemplateIds,
  AddModulePayload,
  EditModulePayload,
  EditModuleResponse,
} from "app/components/Modules/types";
import {
  deleteUsersProfilePicture,
  updateUserImage,
} from "app/containers/Global/slice";
import { ConnectedCandidate, EppPermissions } from "./types";

// ------------------ State Type/Structure ------------------
export interface UserGuideState {
  moduleTemplates: null | ModuleTemplates;
  userGuidesById: { [userAccountId: number]: Guide };
  userUsedModuleTemplateIds: { [userAccountId: number]: UsedModuleTemplateIds }; // used just to keep track of which ones the user has already enabled so we don't show duplicates
  userTeams: {
    [userAccountId: number]: { [teamId: number]: { isTeamLead: 0 | 1 } };
  };
  eppPermissions: { [userId: number]: EppPermissions };
  userIsAbleToCreateTeams: boolean;
  userIsAbleToInviteUsers: boolean;
  connectedCandidates: {
    [userAccountId: number]: ConnectedCandidate | undefined | null;
  };
  getModuleTemplatesStatus: responseStatus;
  getUserGuideStatus: responseStatus;
  addModuleToUserGuideStatus: responseStatus;
  deleting: responseStatus;
  editUserGuideModuleStatus: responseStatus;
  updatingGuidePhotoStatus: responseStatus;
  deletingGuidePhotoStatus: responseStatus;
  getUserTeamsStatus: responseStatus;
  getEppPermissionsStatus: { [userId: number]: responseStatus };
  getCandidateProfileStatusByUserId: { [userId: number]: responseStatus };
}

// ------------------ InitialState ------------------
const initialState: UserGuideState = {
  userGuidesById: {},
  userTeams: {},
  userUsedModuleTemplateIds: {},
  eppPermissions: {},
  moduleTemplates: null,
  userIsAbleToCreateTeams: false,
  userIsAbleToInviteUsers: false,
  connectedCandidates: {},
  getModuleTemplatesStatus: "idle",
  getUserGuideStatus: "idle",
  addModuleToUserGuideStatus: "idle",
  deleting: "idle",
  editUserGuideModuleStatus: "idle",
  updatingGuidePhotoStatus: "idle",
  deletingGuidePhotoStatus: "idle",
  getUserTeamsStatus: "idle",
  getEppPermissionsStatus: {},
  getCandidateProfileStatusByUserId: {},
};

// ------------------------------------ GET Requests ------------------------------------
export const getModuleTemplates = createAsyncThunk(
  "userGuide/getModuleTemplates",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/moduleTemplates/userGuide`;
    return (await request(requestUrl)) as ModuleTemplates;
  }
);

export const getUserGuide = createAsyncThunk(
  "userGuide/getUserGuide",
  async (userAccountId: number | string, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/userGuides/${userAccountId}`;
    const getUserGuideResponse = (await request(requestUrl)) as {
      [userGuideId: number]: Guide;
    };

    thunkAPI.dispatch(
      setUserGuide({
        userGuideResponse: getUserGuideResponse,
        userAccountId: Number(userAccountId),
      })
    );
    return getUserGuideResponse;
  }
);

export const getUserTeams = createAsyncThunk(
  "userGuide/getUserTeams",
  async (userAccountId: number | string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/teams`;
    const getUserTeamsResponse = (await request(requestUrl)) as {
      isTeamLead: 0 | 1;
      teamId: number;
    }[];
    return {
      response: getUserTeamsResponse,
      userAccountId: Number(userAccountId),
    };
  }
);

export const getEppPermissions = createAsyncThunk(
  "userSettings/getEppPermissions",
  async (userAccountId: number | string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/eppPermissions`;
    const getEppPermissionsResponse = (await request(
      requestUrl
    )) as EppPermissions;
    return {
      response: getEppPermissionsResponse,
      userAccountId: Number(userAccountId),
    };
  },
  {
    condition: (userAccountId: number, { getState }) => {
      const {
        userGuide: { getEppPermissionsStatus },
      } = getState() as RootState;
      // if the status is loading or succeeded for the requested userAccountId, don't make the request
      if (
        getEppPermissionsStatus[userAccountId] === "loading" ||
        getEppPermissionsStatus[userAccountId] === "succeeded"
      ) {
        return false;
      }
    },
  }
);

export const getCandidateProfile = createAsyncThunk(
  "userGuide/getCandidateProfile",
  async ({ userAccountId }: { userAccountId: number; refresh?: boolean }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/candidateProfile`;
    const getCandidateProfileResponse = (await request(
      requestUrl
    )) as ConnectedCandidate | null; // Temporarily commented out for faster testing

    return getCandidateProfileResponse;
  },
  {
    condition: ({ userAccountId, refresh }, { getState }) => {
      const {
        userGuide: { getCandidateProfileStatusByUserId },
      } = getState() as RootState;
      const candidateStatus = getCandidateProfileStatusByUserId[userAccountId];
      if (refresh) {
        return true;
      }
      // if already been fetched and force refresh is not true, don't make the request
      if (candidateStatus === "loading" || candidateStatus === "succeeded") {
        return false;
      }
    },
  }
);

// ------------------------------------ POSTS Requests -----------------------------------
export const addModuleToUserGuide = createAsyncThunk(
  "userGuide/addModuleToUserGuide",
  async (
    {
      payload,
      userAccountId,
    }: { payload: AddModulePayload; userAccountId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/userGuides/${userAccountId}/userGuideOnBoarding`;
    const addModuleResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ modules: [payload] }),
    })) as {
      [userGuideId: number]: Guide;
    };

    thunkAPI.dispatch(
      setUserGuide({ userGuideResponse: addModuleResponse, userAccountId })
    );
    thunkAPI.dispatch(hideModal());
    return addModuleResponse;
  }
);

// ------------------------------------ PUT Requests ------------------------------------
export const editUserGuideModule = createAsyncThunk(
  "userGuide/editUserGuideModule",
  async (
    {
      payload,
      userAccountId,
    }: { payload: EditModulePayload; userAccountId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules`;
    const editModuleResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ modules: [payload] }),
    })) as EditModuleResponse[];
    thunkAPI.dispatch(hideModal());
    return { response: editModuleResponse, userAccountId };
  }
);

export const updateGuidePhoto = createAsyncThunk(
  "userGuide/updateGuidePhoto",
  async (
    {
      userAccountId,
      photoType,
      photo,
      imageName,
    }: {
      userAccountId: number;
      imgSrc: string; // used to update the UI
      imageName: string;
      photoType: "coverPhoto" | "profilePicture";
      photo: string;
    },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/userGuides/${userAccountId}/photo`;
    const updateGuidePhotoResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ imageName, photo, photoType }),
    })) as {
      coverPhotoBase64: string;
      coverPhoto: string;
      profilePicture: string;
      profilePictureBase64: string;
      userAccountId: number;
    };
    thunkAPI.dispatch(updateUserImage(updateGuidePhotoResponse));
    return updateGuidePhotoResponse;
  }
);

// ------------------------------------ DELETE Requests ------------------------------------
export const deleteSectionById = createAsyncThunk(
  "userGuide/deleteSectionById",
  async (sectionId: number, thunkAPI) => {
    const {
      global: { currentUserAccountId },
    } = thunkAPI.getState() as RootState;
    if (currentUserAccountId === null) {
      throw Error();
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sections/${sectionId}`;
    const deleteSectionByIdResponse = await request(requestUrl, {
      method: "DELETE",
    });

    thunkAPI.dispatch(
      removeSectionFromUserGuide({
        sectionId,
        userAccountId: currentUserAccountId,
      })
    );
    return deleteSectionByIdResponse;
  }
);

export const deleteUserModuleById = createAsyncThunk(
  "userGuide/deleteUserModuleById",
  async (
    {
      talentInsightsModuleId,
    }: {
      talentInsightsModuleId: number;
    },
    thunkAPI
  ) => {
    const { global } = thunkAPI.getState() as RootState;
    if (global.currentUserAccountId === null) {
      throw Error();
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules/${talentInsightsModuleId}`;
    const deleteModuleResponse = (await request(requestUrl, {
      method: "DELETE",
    })) as {
      [userGuideId: number]: Guide;
    };

    // The deleteUserModuleById endpoint returns the entire user guide, so we need to update the store with the new user guide instead of handling in the extraReducers
    thunkAPI.dispatch(
      setUserGuide({
        userGuideResponse: deleteModuleResponse,
        userAccountId: global.currentUserAccountId,
      })
    );
    thunkAPI.dispatch(hideModal());
    return deleteModuleResponse;
  }
);

export const deleteGuidePhoto = createAsyncThunk(
  "userGuide/deleteGuidePhoto",
  async (
    {
      userAccountId,
      photoType,
    }: {
      userAccountId: number;
      photoType: "coverPhoto" | "profilePicture";
    },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/userGuides/${userAccountId}/${photoType}`;

    await request(requestUrl, {
      method: "DELETE",
    });

    // Since the profile picture is being stored in the userByUserId state we need to update it in the global state, might consider moving that entire state into this slice
    if (photoType === "profilePicture") {
      thunkAPI.dispatch(
        deleteUsersProfilePicture({
          userAccountId: userAccountId,
        })
      );
    }

    return { userAccountId, photoType };
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const userGuideSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserGuide(
      state,
      {
        payload: { userAccountId, userGuideResponse },
      }: PayloadAction<{
        userGuideResponse: { [userGuideId: number]: Guide };
        userAccountId: number;
      }>
    ) {
      // If the userGuideResponse is empty, return.
      if (Object.keys(userGuideResponse).length === 0) {
        return;
      }

      const incomingUserGuide = Object.values(userGuideResponse)[0];
      const userGuideContent = incomingUserGuide.content;
      const userGuideContentArray = Object.values(userGuideContent);

      // Initialize the usedModules object, which will be used to determine which templates the user has already used.
      const usedModules = {
        "Free Text": [],
        List: [],
      } as UsedModuleTemplateIds;

      // Goes through all of the user modules and adds the moduleTemplateId, to determine which templates the user has already used.
      userGuideContentArray.forEach((sectionObj) => {
        if (!sectionObj.modules) {
          return;
        }
        Object.values(sectionObj.modules).forEach((module) => {
          usedModules[module.moduleType].push(module.moduleTemplateId);
        });
      });

      return {
        ...state,
        userGuidesById: {
          ...state.userGuidesById,
          [userAccountId]: {
            ...state.userGuidesById[userAccountId],
            ...incomingUserGuide,
            content: userGuideContent,
          },
        },
        userUsedModuleTemplateIds: {
          ...state.userUsedModuleTemplateIds,
          [userAccountId]: usedModules,
        },
      };
    },
    addModuleTemplateIdToSet(
      state,
      {
        payload: { moduleTemplateId, moduleType, userAccountId },
      }: PayloadAction<{
        moduleTemplateId: number;
        moduleType: ModuleType;
        userAccountId: number;
      }>
    ) {
      // If the moduleId already exist lets not have duplicates
      if (
        state.userUsedModuleTemplateIds[userAccountId][moduleType].includes(
          moduleTemplateId
        )
      ) {
        return;
      }

      return {
        ...state,
        userUsedModuleTemplateIds: {
          ...state.userUsedModuleTemplateIds,
          [userAccountId]: {
            ...state.userUsedModuleTemplateIds[userAccountId],
            [moduleType]: [
              ...state.userUsedModuleTemplateIds[userAccountId][moduleType],
              moduleTemplateId,
            ],
          },
        },
      };
    },
    removeModuleTemplateIdFromSet(
      state,
      {
        payload: { moduleTemplateId, moduleType, userAccountId },
      }: PayloadAction<{
        moduleTemplateId: number;
        moduleType: ModuleType;
        userAccountId: number;
      }>
    ) {
      return {
        ...state,
        userUsedModuleTemplateIds: {
          ...state.userUsedModuleTemplateIds,
          [userAccountId]: {
            ...state.userUsedModuleTemplateIds[userAccountId],
            [moduleType]: state.userUsedModuleTemplateIds[userAccountId][
              moduleType
            ].filter((templateId) => templateId !== moduleTemplateId),
          },
        },
      };
    },
    removeSectionFromUserGuide(
      state,
      {
        payload: { sectionId, userAccountId },
      }: PayloadAction<{ sectionId: number; userAccountId: number }>
    ) {
      if (!state.userGuidesById?.[userAccountId]) {
        return;
      }

      const userGuidesByIdContent = {
        ...state.userGuidesById[userAccountId].content,
      };
      const userUsedModuleTemplateIds = {
        ...state.userUsedModuleTemplateIds[userAccountId],
      };

      Object.values(userGuidesByIdContent[sectionId].modules).forEach(
        ({ moduleTemplateId, moduleType }) => {
          userUsedModuleTemplateIds[moduleType] = userUsedModuleTemplateIds[
            moduleType
          ].filter((templateId) => templateId !== moduleTemplateId);
        }
      );

      delete userGuidesByIdContent[sectionId];

      return {
        ...state,
        userGuidesById: {
          ...state.userGuidesById,
          [userAccountId]: {
            ...state.userGuidesById[userAccountId],
            content: userGuidesByIdContent,
          },
        },
        userUsedModuleTemplateIds: {
          ...state.userUsedModuleTemplateIds,
          [userAccountId]: userUsedModuleTemplateIds,
        },
      };
    },
    clearDeleteStatus(state) {
      state.deleting = "idle";
    },
    clearAddModuleStatus(state) {
      state.addModuleToUserGuideStatus = "idle";
    },
    updateUserAbleToCreateTeams(state, { payload }: PayloadAction<boolean>) {
      return {
        ...state,
        userIsAbleToCreateTeams: payload,
      };
    },
    updateUserAbleToInviteUsers(state, { payload }: PayloadAction<boolean>) {
      return {
        ...state,
        userIsAbleToInviteUsers: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTeams.pending, (state) => {
        state.getUserTeamsStatus = "loading";
      })
      .addCase(
        getUserTeams.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: { teamId: number; isTeamLead: 0 | 1 }[];
            userAccountId: number;
          }>
        ) => {
          const searchedUsersTeams = { ...state.userTeams[userAccountId] };
          response.forEach((responseVal) => {
            searchedUsersTeams[responseVal.teamId] = {
              isTeamLead: responseVal.isTeamLead,
            };
          });

          return {
            ...state,
            getUserTeamsStatus: "succeeded",
            userTeams: {
              ...state.userTeams,
              [userAccountId]: searchedUsersTeams,
            },
          };
        }
      )
      .addCase(getModuleTemplates.pending, (state) => {
        state.getModuleTemplatesStatus = "loading";
      })
      .addCase(
        getModuleTemplates.fulfilled,
        (state, action: PayloadAction<ModuleTemplates>) => {
          state.moduleTemplates = action.payload;
          state.getModuleTemplatesStatus = "succeeded";
        }
      )
      .addCase(getModuleTemplates.rejected, (state) => {
        state.getModuleTemplatesStatus = "failed";
      })
      .addCase(getUserGuide.pending, (state) => {
        state.getUserGuideStatus = "loading";
      })
      .addCase(getUserGuide.fulfilled, (state) => {
        state.getUserGuideStatus = "succeeded";
      })
      .addCase(getUserGuide.rejected, (state) => {
        state.getUserGuideStatus = "failed";
      })
      .addCase(addModuleToUserGuide.pending, (state) => {
        state.addModuleToUserGuideStatus = "loading";
      })
      .addCase(addModuleToUserGuide.fulfilled, (state) => {
        toast.success("Adding Module Successful!", {
          ...successNotificationOptions,
        });
        state.addModuleToUserGuideStatus = "succeeded";
      })
      .addCase(addModuleToUserGuide.rejected, (state) => {
        state.addModuleToUserGuideStatus = "failed";
      })
      .addCase(editUserGuideModule.pending, (state) => {
        state.editUserGuideModuleStatus = "loading";
      })
      .addCase(
        editUserGuideModule.fulfilled,
        (
          state,
          {
            payload: { userAccountId, response },
          }: PayloadAction<{
            response: EditModuleResponse[];
            userAccountId: number;
          }>
        ) => {
          const { sectionId } = response[0];
          const modules = {
            ...state.userGuidesById[userAccountId].content?.[sectionId].modules,
          };
          response.forEach((module) => {
            modules[module.talentInsightsModuleId] = {
              ...modules[module.talentInsightsModuleId],
              ...module,
            };
          });
          return {
            ...state,
            editUserGuideModuleStatus: "succeeded",
            userGuidesById: {
              ...state.userGuidesById,
              [userAccountId]: {
                ...state.userGuidesById[userAccountId],
                content: {
                  ...state.userGuidesById[userAccountId].content,
                  [sectionId]: {
                    ...state.userGuidesById[userAccountId].content[sectionId],
                    modules,
                  },
                },
              },
            },
          };
        }
      )
      .addCase(editUserGuideModule.rejected, (state) => {
        state.editUserGuideModuleStatus = "failed";
      })
      .addCase(updateGuidePhoto.pending, (state) => {
        state.updatingGuidePhotoStatus = "loading";
      })
      .addCase(
        updateGuidePhoto.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            coverPhotoBase64: string;
            coverPhoto: string;
            profilePicture: string;
            profilePictureBase64: string;
            userAccountId: number;
          }>
        ) => {
          toast.success("Updating the Photo Successful!", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updatingGuidePhotoStatus: "succeeded",
            userGuidesById: {
              ...state.userGuidesById,
              [payload.userAccountId]: {
                ...state.userGuidesById[payload.userAccountId],
                coverPhoto: {
                  picture: payload.coverPhoto,
                  base64: payload.coverPhotoBase64,
                },
                profilePicture: {
                  picture: payload.profilePicture,
                  base64: payload.profilePictureBase64,
                },
              },
            },
          };
        }
      )
      .addCase(updateGuidePhoto.rejected, (state) => {
        state.updatingGuidePhotoStatus = "failed";
      })
      .addCase(deleteUserModuleById.pending, (state) => {
        state.deleting = "loading";
      })
      .addCase(deleteUserModuleById.fulfilled, (state) => {
        toast.success("Delete Module Successful!", {
          ...successNotificationOptions,
        });
        state.deleting = "succeeded";
      })
      .addCase(deleteUserModuleById.rejected, (state) => {
        state.deleting = "failed";
      })
      .addCase(deleteSectionById.pending, (state) => {
        state.deleting = "loading";
      })
      .addCase(deleteSectionById.fulfilled, (state) => {
        toast.success("Delete Section Successful!", {
          ...successNotificationOptions,
        });
        state.deleting = "succeeded";
      })
      .addCase(deleteSectionById.rejected, (state) => {
        state.deleting = "failed";
      })
      .addCase(deleteGuidePhoto.pending, (state) => {
        state.deletingGuidePhotoStatus = "loading";
      })
      .addCase(
        deleteGuidePhoto.fulfilled,
        (
          state,
          action: PayloadAction<{
            userAccountId: number;
            photoType: "coverPhoto" | "profilePicture";
          }>
        ) => {
          const photoType =
            action.payload.photoType === "coverPhoto"
              ? "Cover Photo"
              : "Profile Picture";
          toast.success(`Deleting ${photoType} Successful!`, {
            ...successNotificationOptions,
          });
          return {
            ...state,
            deletingGuidePhotoStatus: "succeeded",
            userGuidesById: {
              ...state.userGuidesById,
              [action.payload.userAccountId]: {
                ...state.userGuidesById[action.payload.userAccountId],
                [action.payload.photoType]: {
                  picture: null,
                  base64: null,
                },
              },
            },
          };
        }
      )
      .addCase(deleteGuidePhoto.rejected, (state) => {
        state.deletingGuidePhotoStatus = "failed";
      })
      .addCase(getEppPermissions.pending, (state, { meta: { arg } }) => {
        return {
          ...state,
          getEppPermissionsStatus: {
            ...state.getEppPermissionsStatus,
            [arg]: "loading",
          },
        };
      })
      .addCase(
        getEppPermissions.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: EppPermissions;
            userAccountId: number;
          }>
        ) => {
          return {
            ...state,
            eppPermissions: {
              ...state.eppPermissions,
              [userAccountId]: response,
            },
            getEppPermissionsStatus: {
              ...state.getEppPermissionsStatus,
              [userAccountId]: "succeeded",
            },
          };
        }
      )
      .addCase(getEppPermissions.rejected, (state, { meta: { arg } }) => {
        return {
          ...state,
          getEppPermissionsStatus: {
            ...state.getEppPermissionsStatus,
            [arg]: "failed",
          },
        };
      })
      .addCase(getCandidateProfile.pending, (state, { meta: { arg } }) => {
        return {
          ...state,
          getCandidateProfileStatusByUserId: {
            ...state.getCandidateProfileStatusByUserId,
            [arg.userAccountId]: "loading",
          },
        };
      })
      .addCase(
        getCandidateProfile.fulfilled,
        (state, { meta: { arg }, payload }) => {
          return {
            ...state,
            getCandidateProfileStatusByUserId: {
              ...state.getCandidateProfileStatusByUserId,
              [arg.userAccountId]: "succeeded",
            },
            connectedCandidates: {
              ...state.connectedCandidates,
              [arg.userAccountId]: payload,
            },
          };
        }
      )
      .addCase(getCandidateProfile.rejected, (state, { meta: { arg } }) => {
        return {
          ...state,
          getCandidateProfileStatusByUserId: {
            ...state.getCandidateProfileStatusByUserId,
            [arg.userAccountId]: "failed",
          },
        };
      });
  },
});

export const {
  addModuleTemplateIdToSet,
  removeModuleTemplateIdFromSet,
  removeSectionFromUserGuide,
  setUserGuide,
  clearDeleteStatus,
  clearAddModuleStatus,
  updateUserAbleToCreateTeams,
  updateUserAbleToInviteUsers,
} = userGuideSlice.actions;

// ------------------ Selectors ------------------
export const selectUserModuleTemplates = (state: RootState) =>
  state.userGuide.moduleTemplates;
export const selectGetModuleTemplatesStatus = (state: RootState) =>
  state.userGuide.getModuleTemplatesStatus;
export const selectGetUserGuideStatus = (state: RootState) =>
  state.userGuide.getUserGuideStatus;
export const selectAddToUserGuideStatus = (state: RootState) =>
  state.userGuide.addModuleToUserGuideStatus;
export const selectEditUserGuideStatus = (state: RootState) =>
  state.userGuide.editUserGuideModuleStatus;
export const selectUserGuides = (state: RootState) =>
  state.userGuide.userGuidesById;
export const selectUsedUserGuideModules = (state: RootState) =>
  state.userGuide.userUsedModuleTemplateIds;
export const selectDeletingStatus = (state: RootState) =>
  state.userGuide.deleting;
export const selectUpdatingGuidePhotoStatus = (state: RootState) =>
  state.userGuide.updatingGuidePhotoStatus;
export const selectDeletingGuidePhotoStatus = (state: RootState) =>
  state.userGuide.deletingGuidePhotoStatus;
export const selectGetUserTeams = (state: RootState) =>
  state.userGuide.userTeams;
export const selectGetUserTeamsStatus = (state: RootState) =>
  state.userGuide.getUserTeamsStatus;
export const selectEppPermissionsByUserId =
  (userAccountId: number) => (state: RootState) =>
    userAccountId ? state.userGuide.eppPermissions[userAccountId] : null;
export const selectUserIsAbleToCreateTeams = (state: RootState) =>
  state.userGuide.userIsAbleToCreateTeams;
export const selectUserIsAbleToInviteUsers = (state: RootState) =>
  state.userGuide.userIsAbleToInviteUsers;
export const selectConnectedCandidate =
  (userAccountId: number) => (state: RootState) =>
    state.userGuide.connectedCandidates[userAccountId];

export default userGuideSlice.reducer;
