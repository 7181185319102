import { useEffect, useState, useCallback } from "react";
import { LIKERT_SCALE } from "utils/constants";
import Button from "app/storybookComponents/Button";
import LikertScale from "./LikertScale";
import { LikertAssessmentAnswer, LikertAssessmentValue } from "./types";
import { getSingleLikerClassName } from "./helpers";

export interface Props {
  questions: { item: string; id: number }[];
  onComplete: () => void;
  storeResponses: (itemId: number, answer: LikertAssessmentAnswer) => void;
  storedResponses: {
    [itemId: number]: LikertAssessmentAnswer;
  };
  onFirstBackButtonPress: () => void;
  customLikertScale?: {
    label: string;
    value: number;
  }[];
  questionNumber?: number;
  onForwardButtonPress?: () => void; // Optional just incase we want to do something when the user clicks on the forward button
  onBackButtonPress?: () => void; // Optional just incase we want to do something when the user clicks on the back button
}

export default function SingleLineLikertAssessment({
  questions,
  onFirstBackButtonPress,
  onComplete,
  storeResponses,
  storedResponses,
  customLikertScale = LIKERT_SCALE,
  questionNumber,
  onForwardButtonPress,
  onBackButtonPress,
}: Readonly<Props>) {
  const [activeQuestion, setActiveQuestion] = useState(questionNumber ?? 0);
  const [activeQuestionId, setActiveQuestionId] = useState<number>(0);
  const [selectedValue, setSelectedValue] =
    useState<LikertAssessmentValue | null>(null);
  const [timeStarted, setTimeStarted] = useState(
    Math.ceil(performance.now() / 1000)
  ); // The first time started will be when the user opens the component.
  const [actionSubmitted, setActionSubmitted] = useState(false); // This state will be used to determine if the useEffect hook should be called or not.

  // ------------------------------------------------------------------------ useCallback functions ------------------------------------------------------------------------
  // This function will take in the question id and then check the stored responses, if the response is undefined then we set the selected value to null otherwise we set the selected value to the answer
  const onSetCurrentQuestionValue = useCallback(
    (questionId: number) => {
      const storedResponse = storedResponses[questionId]?.answer;
      if (storedResponse === undefined) {
        setSelectedValue(null);
      } else {
        setSelectedValue(storedResponse as LikertAssessmentValue);
      }
      // Once we set the new value we also need to reset the timer
      setTimeStarted(Math.ceil(performance.now() / 1000));
      setActionSubmitted(false);
    },
    [storedResponses]
  );

  // This function will save the current selected value and save it to stored responses under the active question id
  // if the user has already answered the question then we need to add the time taken to the previous time taken
  const onStoreResponse = useCallback(() => {
    if (selectedValue === null) return null;
    const storedResponse = storedResponses[activeQuestionId];
    const timeTaken = Math.ceil(performance.now() / 1000) - timeStarted;

    if (storedResponse === undefined) {
      storeResponses(activeQuestionId, { answer: selectedValue, timeTaken });
    } else {
      storeResponses(activeQuestionId, {
        answer: selectedValue,
        timeTaken: storedResponse.timeTaken + timeTaken,
      });
    }
  }, [
    selectedValue,
    activeQuestionId,
    storedResponses,
    storeResponses,
    timeStarted,
  ]);

  // When the user clicks on next then we need to store the current value and then go to the next question
  // If the user is on the last question then we need to call the onComplete function
  const handleNextClick = useCallback(() => {
    onStoreResponse();
    onForwardButtonPress?.();
    // If the user is on the last question then we want to call the onComplete function
    if (activeQuestion === questions.length - 1) {
      return onComplete();
    }

    setActiveQuestion((prev) => {
      const newVal = prev + 1;
      onSetCurrentQuestionValue(newVal);
      return newVal;
    });
  }, [
    onComplete,
    activeQuestion,
    questions.length,
    onStoreResponse,
    onSetCurrentQuestionValue,
    setActiveQuestion,
    onForwardButtonPress,
  ]);

  // If the user clicks back then we need to check if the it is the first question and if it is then we need to call the onFirstBackButtonPress function
  // if not then we need to go back to the previous question and call the onSetCurrentQuestionValue function
  const handleBackButton = useCallback(() => {
    // If the user is on the first question then we want to call the onFirstBackButtonPress function
    onStoreResponse();
    if (activeQuestion === 0) {
      return onFirstBackButtonPress();
    }

    onBackButtonPress?.();
    setActiveQuestion((prev) => {
      const newVal = prev - 1;
      onSetCurrentQuestionValue(newVal);
      return newVal;
    });
  }, [
    activeQuestion,
    onFirstBackButtonPress,
    onSetCurrentQuestionValue,
    onStoreResponse,
    onBackButtonPress,
  ]);

  // ------------------------------------------------------------------------ useEffect hooks ------------------------------------------------------------------------
  useEffect(() => {
    if (!actionSubmitted) return;
    const timer = setTimeout(() => {
      if (selectedValue === null) return null;
      handleNextClick();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedValue, actionSubmitted, handleNextClick]);

  useEffect(() => {
    if (questions[activeQuestion]?.id) {
      onSetCurrentQuestionValue(questions[activeQuestion]?.id);
      setActiveQuestionId(questions[activeQuestion]?.id);
    }
  }, [activeQuestion, questions, onSetCurrentQuestionValue]);

  useEffect(() => {
    if (questionNumber !== undefined) {
      setActiveQuestion(questionNumber);
    }
  }, [questionNumber]);

  // ------------------------------------------------------------------------ Render ------------------------------------------------------------------------
  const onQuestionSelect = (answer: number) => {
    if (selectedValue === answer) {
      setSelectedValue(null);
      setActionSubmitted(false);
    } else {
      setSelectedValue(answer as LikertAssessmentValue);
      setActionSubmitted(true);
    }
  };

  // We should only be showing the active question and the previous question and the next question, so this function should return at most 3 questions
  const getQuestions = () => {
    // Top question needs to be the active question
    const lowerQuestion = activeQuestion === 0 ? 0 : activeQuestion - 1;
    const upperQuestion = activeQuestion === 0 ? 2 : 3;
    return questions
      .slice(lowerQuestion, lowerQuestion + upperQuestion)
      .map((question, index) => {
        const questionIndex = lowerQuestion + index;
        return (
          <div
            className={getSingleLikerClassName(questionIndex, activeQuestion)}
            key={question.id}
          >
            <LikertScale
              questionTitle={question.item}
              likertScale={customLikertScale}
              onSelect={onQuestionSelect}
              selectedValue={selectedValue ?? undefined}
            />
          </div>
        );
      });
  };

  const isBackDisabled = () => {
    // If we are the last question then we want to return false
    if (activeQuestion === questions.length - 1) return false;
    // For the other values we want to make sure that the following question is stored if so then if that is the case and the user has not selected a value then we want to return true
    const nextQuestionId = questions[activeQuestion + 1]?.id;
    if (storedResponses[nextQuestionId] !== undefined && !selectedValue) {
      return true;
    }
  };

  if (questions.length === 0) {
    return null;
  }

  return (
    <>
      <div className="likert-single-scale-container">{getQuestions()}</div>
      <div className="responsive-button-footer">
        <div>
          <Button
            disabled={isBackDisabled()}
            onClick={() => {
              setTimeStarted(Math.ceil(performance.now() / 1000)); // If the user goes back then we need to reset the timer
              handleBackButton();
            }}
            variant="secondary-gray"
            size="lg"
            className="border-0"
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            disabled={selectedValue === null}
            onClick={handleNextClick}
            dataTestId="next-question"
            size="lg"
          >
            Next Question
          </Button>
        </div>
      </div>
    </>
  );
}
