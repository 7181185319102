//------------------ FontAwesome Library Import ------------------
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEdit,
  faCheck,
  faEllipsis,
  faLink,
  faPlus,
  faCheckCircle,
  faCircleXmark,
  faGear,
  faEnvelope,
  faXmark,
  faShare,
  faCircle,
  faArrowLeft,
  faCaretDown,
  faCaretUp,
  faUserPlus,
  faSearch,
  faDownload,
  faUpload,
  faSort,
  faSortUp,
  faSortDown,
  faThumbsUp,
  faThumbsDown,
  faUsers,
  faBell,
  faExternalLink,
  faBoltLightning,
  faGlasses,
  faSeedling,
  faBullseye,
  faBook,
  faArrowRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faStar,
  faUser,
  faBell as fasBell,
  faArrowsRotate,
  faDiamond,
  faWrench,
  faTriangleExclamation as fafsTriangleExclamation,
  faMap,
  faSquarePollVertical,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faLocationDot,
  faTrashCan,
  faBars,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHandshake,
  faDesktop,
  faUserTag,
  faBalanceScaleLeft,
  faBullseyeArrow,
  faCommentsAlt,
  faUsersClass,
  faQuoteLeft,
  faBookReader,
  faHandHoldingSeedling,
  faHandshakeAlt,
  faHouseUser,
  faDumbbell,
  faLightbulbOn,
  faBrain,
  faBatteryQuarter,
  faBadgeCheck,
  faMusic,
  faChartNetwork,
  faCoffee,
  faTrophy,
  faTrophyAlt,
  faPhoneLaptop,
  faBooks,
  faBook as faBookLight,
  faCameraMovie,
  faHandsHelping,
  faBatteryBolt,
  faObjectUngroup,
  faHands,
  faBookUser,
  faClock,
  faMonitorWaveform,
  faTriangleExclamation,
  faClipboardCheck,
  faInfo,
  faScaleUnbalanced,
  faQuestion,
  faMap as faMapLight,
  faUsers as faUsersLight,
  faFile,
  faBookmark as faBookmarkLight,
  faUser as faUserLight,
  faUserLock,
  faCommentTimes,
  faConstruction,
  faQuestionCircle as faQuestionCircleLight,
  faTreeAlt,
  faUsersMedical,
  faUserFriends,
  faClipboardListCheck,
  faGear as faGearLight,
  faPollPeople as farPollPeopleLight,
  faBookOpen as faBookOpenLight,
  faBookOpenReader,
  faSitemap,
  faScreenUsers as faScreenUsersLight,
  faMagnifyingGlassPlus,
  faChartMixed,
  faRuler,
  faClipboardList,
  faAtom,
  faMessages,
  faExclamationCircle as faExclamationCircleLight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faDownload as farDownload,
  faQuestionCircle as farQuestionCircle,
  faClock as farClock,
  faCircleInfo,
  faTriangleExclamation as farTriangleExclamation,
  faStar as farStar,
  faCircleArrowUp as farCircleArrowUp,
  faCircleArrowDown as farCircleArrowDown,
  faLightbulbOn as farLightBulbOn,
  faBookmark,
  faCog,
  faArrowUp,
  faComment,
  faArrowDown,
  faEye,
  faEyeSlash,
  faArrowCircleRight,
  faEdit as faEditRegular,
  faTag as faTagRegular,
  faGear as faGearRegular,
  faExclamationCircle as faExclamationCircleRegular,
  faCircleXmark as farCircleXmark,
  faCopy,
  faLockKeyhole as farLockKeyhole,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faLockKeyhole,
  faRocketLaunch,
  faPollPeople,
  faMedal,
  faFileChartPie,
  faScreenUsers,
  faStars,
  faCircleArrowDown,
  faCircleArrowUp,
} from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/sharp-solid-svg-icons";

library.add(
  faPhone,
  farCircleXmark,
  faScreenUsersLight,
  faStars,
  faScreenUsers,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faArrowUp,
  faBadgeCheck,
  faBalanceScaleLeft,
  faBatteryBolt,
  faBatteryQuarter,
  faBell,
  faBoltLightning,
  faBook,
  faBookLight,
  faBookOpenLight,
  faBookmark,
  faBookmarkLight,
  faBookReader,
  faBookReader,
  faBooks,
  faBookUser,
  faBrain,
  faBullseye,
  faBullseyeArrow,
  faCameraMovie,
  faCaretDown,
  faCaretUp,
  faChartNetwork,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleInfo,
  faCircleXmark,
  faClipboardCheck,
  faClipboardListCheck,
  faClock,
  faCoffee,
  faCog,
  faComment,
  faCopy,
  faCommentsAlt,
  faCommentTimes,
  faConstruction,
  faDesktop,
  faDiamond,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faDumbbell,
  faEdit,
  faEditRegular,
  faEllipsis,
  faEnvelope,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileChartPie,
  fafsTriangleExclamation,
  faGear,
  faGearLight,
  faGearRegular,
  faGlasses,
  faHandHoldingSeedling,
  faHands,
  faHandshake,
  faHandshakeAlt,
  faHandsHelping,
  faHandsHelping,
  faHouseUser,
  faInfo,
  faLightbulbOn,
  faLink,
  faLocationDot,
  faLockKeyhole,
  farLockKeyhole,
  faMap,
  faMapLight,
  faMedal,
  faMonitorWaveform,
  faMusic,
  faObjectUngroup,
  faPhoneLaptop,
  faPlus,
  farPollPeopleLight,
  faPollPeople,
  faQuestion,
  faQuestionCircleLight,
  faQuoteLeft,
  farCircleArrowDown,
  farCircleArrowUp,
  farClock,
  farDownload,
  farLightBulbOn,
  faRocketLaunch,
  farQuestionCircle,
  farStar,
  farTriangleExclamation,
  fasBell,
  faScaleUnbalanced,
  fasCheckCircle,
  faScreenUsers,
  faSearch,
  faSeedling,
  faShare,
  faSort,
  faSortDown,
  faSortUp,
  faSquarePollVertical,
  faStar,
  faStars,
  faTagRegular,
  faThumbsDown,
  faThumbsUp,
  faTreeAlt,
  faTriangleExclamation,
  faTrophy,
  faTrophyAlt,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserFriends,
  faUserLight,
  faUserLock,
  faUserPlus,
  faUsers,
  faUsersClass,
  faUsersLight,
  faUsersMedical,
  faUserTag,
  faWrench,
  faXmark,
  faBookOpenReader,
  faSitemap,
  faScreenUsers,
  faMagnifyingGlassPlus,
  faChartMixed,
  faRuler,
  faClipboardList,
  faAtom,
  faMessages,
  faTrashCan,
  faCircleArrowDown,
  faCircleArrowUp,
  faBars,
  faExclamationCircleRegular,
  faExclamationCircleLight
);
