import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageVisit } from "utils/trackingFunctions";

const useTrackPage = () => {
  const location = useLocation();
  useEffect(() => {
    trackPageVisit(location.pathname);
  }, [location]); // This will run when the location changes
};

export default useTrackPage;
