import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function useScrollToAnchor() {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      return window.scrollTo(0, 0);
    }

    const element = document.getElementById(hash.replace("#", ""));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);
}

export default useScrollToAnchor;
