import DatePicker from "react-datepicker";
import Button from "app/storybookComponents/Button";
import Toggle from "../Toggle";
import SimpleModal from "./SimpleModal";
import { Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useAppDispatch } from "utils/redux/hooks";
import { putRecurringChat } from "app/containers/Chatbot/slice";
import { RecurringChat } from "app/containers/Chatbot/types";

interface Props {
  show?: boolean;
  onHide: () => void;
  recurringChat?: RecurringChat | null;
}

const getLocaleTime = (timeInUTC: string) => {
  // Assuming the date is today, we need to get the current date in UTC to combine with the time
  const now = new Date();
  const currentDateUTC = now.toISOString().split("T")[0];

  // Combine the current date with the time string
  const dateTimeString = `${currentDateUTC}T${timeInUTC}Z`; // 'Z' denotes UTC time

  // Create a Date object
  return new Date(dateTimeString);
};

export default function ScheduleChatbotRemindersModal({
  show,
  onHide,
  recurringChat,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const [frequency, setFrequency] = useState("Weekly");
  const [dayOfTheWeek, setDayOfTheWeek] = useState("Monday");
  const [time, setTime] = useState(new Date());
  const [emailReminders, setEmailReminders] = useState(true);

  useEffect(() => {
    if (!recurringChat) {
      setFrequency("Weekly");
      setDayOfTheWeek("Monday");
      setTime(new Date());
      setEmailReminders(true);
      return;
    }

    const incomingDayOfWeek = recurringChat.dayOfWeek
      ? recurringChat.dayOfWeek[0].toUpperCase() +
        recurringChat.dayOfWeek.slice(1).toLowerCase()
      : "Monday";
    setDayOfTheWeek(incomingDayOfWeek);
    setFrequency(recurringChat.cadence === "daily" ? "Daily" : "Weekly");
    setEmailReminders(!!recurringChat.emailReminderOn);
    setTime(getLocaleTime(recurringChat.timeOfDayUTC));
  }, [recurringChat]);

  const onScheduleSubmit = async () => {
    await dispatch(
      putRecurringChat({
        cadence: frequency.toLowerCase() as "daily" | "weekly",
        dayOfWeek:
          frequency === "Weekly" ? dayOfTheWeek.toLocaleLowerCase() : null,
        timeOfDayUTC: time.toISOString().split("T")[1].slice(0, 8),
        emailReminderOn: emailReminders,
      })
    );
    onHide();
  };

  const getFrequencyString = () => {
    if (frequency === "Daily") {
      return "every day";
    }
    return `every ${dayOfTheWeek.toLowerCase()}`;
  };

  const getStartingTimeString = () => {
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const getStartingDateString = () => {
    const now = new Date();
    const isCurrentTimePastNow = time.getTime() < now.getTime();
    if (frequency === "Daily") {
      return isCurrentTimePastNow ? "tomorrow" : "today";
    }

    const daysOfTheWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const currentDay = new Date().getDay();
    const currentDayIndex = daysOfTheWeek.indexOf(dayOfTheWeek) + 1;
    const daysUntilNextDay = currentDayIndex - currentDay;
    const isCurrentDayPastNextDay = daysUntilNextDay < 0;
    const daysUntilNextDayAdjusted = isCurrentDayPastNextDay
      ? daysUntilNextDay + 7
      : daysUntilNextDay;
    // return the date of the next checkin
    const nextCheckinDate = new Date();
    nextCheckinDate.setDate(
      nextCheckinDate.getDate() + daysUntilNextDayAdjusted
    );
    return nextCheckinDate.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  const getDescriptionText = () => {
    return (
      <p>
        Your chat will be scheduled to occur{" "}
        <b>
          {getFrequencyString()} at {getStartingTimeString()},
        </b>{" "}
        starting <b>{getStartingDateString()}</b>
      </p>
    );
  };

  const getDropdown = (
    dropdownOptions: string[],
    selectedOption: string,
    setter: (value: string) => void
  ) => {
    return (
      <Dropdown className="full-width-dropdown w-100">
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          <div>{selectedOption}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {dropdownOptions.map((option) => (
            <Dropdown.Item onClick={() => setter(option)} key={option}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getDatePickerDropdowns = () => {
    const timePicker = (
      <DatePicker
        selected={time}
        onChange={(date: Date) => {
          setTime(date);
        }}
        showTimeInput
        showTimeSelectOnly
        dateFormat="h:mm aa"
        className="w-100 dropdown-toggle btn btn-light time-picker-dropdown"
        calendarClassName="time-picker-dropdown"
        wrapperClassName="temp"
        popperClassName="time-picker-dropdown"
      />
    );

    if (frequency === "Weekly") {
      const daysOfTheWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
      ];
      return (
        <div className="row-gap-20px">
          {getDropdown(daysOfTheWeek, dayOfTheWeek, setDayOfTheWeek)}{" "}
          {timePicker}
        </div>
      );
    }

    return (
      <div>
        <div>{timePicker}</div>
      </div>
    );
  };

  return (
    <SimpleModal show={show} onHide={onHide} title="Schedule a Coach Bo Chat">
      {getDescriptionText()}
      <div>
        Repeat:
        <div>{getDropdown(["Weekly", "Daily"], frequency, setFrequency)}</div>
      </div>
      <div>
        Schedule your {frequency.toLowerCase()} chats on:
        {getDatePickerDropdowns()}
      </div>
      <div className="warning-banner blue column-gap-12px border-0">
        <div className="row-gap-12px align-items-center">
          <Toggle
            isOn={emailReminders}
            handleToggle={() => {
              setEmailReminders(!emailReminders);
            }}
          />
          <p
            style={{
              color: "black",
            }}
          >
            Receive an email reminder?
          </p>
        </div>
        <p>
          You'll receive an email reminder when you have a scheduled chat with
          Coach Bo.
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <Button
          variant="secondary-gray"
          onClick={() => onHide()}
          className="border-0"
        >
          Cancel
        </Button>
        <Button onClick={onScheduleSubmit}>Schedule chat</Button>
      </div>
    </SimpleModal>
  );
}
