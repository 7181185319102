import SimpleModal from "app/components/Modals/SimpleModal";
import Button from "app/storybookComponents/Button";
import PermissionChart from "app/storybookComponents/Charts/PermissionChart";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import {
  ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP,
  VisibilitySettingsConstant,
} from "../constants";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  defaultVisibility: number | null;
  onSaveVisibilityDefaults: (selectedDefault: number | null) => void;
}

export default function VisibilityDefaultModal({
  isOpen = false,
  onClose,
  defaultVisibility,
  onSaveVisibilityDefaults,
}: Readonly<Props>) {
  const [visibilityActiveTab, setVisibilityActiveTab] =
    useState(defaultVisibility);

  useEffect(() => {
    setVisibilityActiveTab(defaultVisibility);
  }, [defaultVisibility]);

  const getPermissionChartProps = (visibilityActiveTab: number | null) => {
    switch (visibilityActiveTab) {
      case 1:
        return VisibilitySettingsConstant["Privacy-Focused"];
      case 3:
        return VisibilitySettingsConstant["Transparency-Focused"];
      case 2:
      default:
        return VisibilitySettingsConstant.Balanced;
    }
  };

  const getSaveButton = () => {
    const onSave = () => {
      onSaveVisibilityDefaults(visibilityActiveTab);
    };
    const buttonText = visibilityActiveTab
      ? ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP[visibilityActiveTab]
      : "Balanced";

    return <Button onClick={onSave}>Change defaults to {buttonText}</Button>;
  };

  const {
    IndividualReportPermissionData,
    TeamReportPermissionData,
    DepartmentReportData,
    TeamPersonalityReportPermissionData,
    OrganizationReportData,
  } = getPermissionChartProps(visibilityActiveTab);

  return (
    <SimpleModal
      show={isOpen}
      onHide={onClose}
      title="Visibility Defaults"
      size="xl"
    >
      <div className="column-gap-24px" style={{ marginTop: "4px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <Nav
            className="simple-nav"
            activeKey={visibilityActiveTab ?? ""}
            onSelect={(e) => {
              setVisibilityActiveTab(Number(e));
            }}
          >
            <Nav.Item key={1}>
              <Nav.Link eventKey={1}>Privacy-Focused</Nav.Link>
            </Nav.Item>
            <Nav.Item key={2}>
              <Nav.Link eventKey={2}>Balanced</Nav.Link>
            </Nav.Item>
            <Nav.Item key={3}>
              <Nav.Link eventKey={3}>Transparency-Focused</Nav.Link>
            </Nav.Item>
          </Nav>
          <div>{getSaveButton()}</div>
        </div>
        <div className="visibility-table-container">
          <h3>Individual Reports</h3>
          <PermissionChart {...IndividualReportPermissionData} />
        </div>
        <div className="visibility-table-container">
          <h3>Team Reports</h3>
          <PermissionChart {...TeamReportPermissionData} />
        </div>
        <div className="visibility-table-container">
          <h3>Department Reports</h3>
          <PermissionChart {...DepartmentReportData} />
        </div>
        <div className="visibility-table-container">
          <h3>Organization Reports</h3>
          <PermissionChart {...OrganizationReportData} />
        </div>
        <div className="visibility-table-container">
          <h3>Team Personality Reports</h3>
          <PermissionChart {...TeamPersonalityReportPermissionData} />
        </div>
        <div className="ms-auto">{getSaveButton()}</div>
      </div>
    </SimpleModal>
  );
}
