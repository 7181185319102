import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { Card, Dropdown } from "react-bootstrap";
import Toggle from "app/components/Toggle";
import { useEffect, useState } from "react";
import TurnOffAutomaticTeamsModal from "./Modals/TurnOffAutomaticTeamsModal";
import TurnOnAutomaticTeamsModal from "./Modals/TurnOnAutomaticTeamsModal";
import {
  getAutomaticTeamsSettings,
  turnAutomaticTeamsSettingOn,
  turnAutomaticTeamsSettingOff,
  updateAutomaticTeamsEditSetting,
  selectHasAutomaticTeamsEnabled,
  selectGetAutomaticTeamsSettingsStatus,
  selectAutomaticTeamsEditSetting,
  selectTurnOffAutomaticTeamsSettingStatus,
} from "./slice";
import Loading from "app/storybookComponents/Loading";
import {
  getAllTeamsByCompanyAccountId,
  selectCompanyInfo,
} from "app/containers/Global/slice";
import GeneratedLogo from "resources/icons/generated-logo.svg";

export default function AutomationSettings() {
  const dispatch = useAppDispatch();

  // ------------------------ Selectors ------------------------------------
  const getAutomaticTeamsSettingsStatus = useAppSelector(
    selectGetAutomaticTeamsSettingsStatus
  );
  const hasAutomaticTeamsEnabled = useAppSelector(
    selectHasAutomaticTeamsEnabled
  );
  const automaticTeamsEditSetting = useAppSelector(
    selectAutomaticTeamsEditSetting
  );
  const turnOffAutomaticTeamsSettingStatus = useAppSelector(
    selectTurnOffAutomaticTeamsSettingStatus
  );
  const companyInformation = useAppSelector(selectCompanyInfo);

  // ------------------------ Use Effects ------------------------------------

  useEffect(() => {
    if (getAutomaticTeamsSettingsStatus === "idle") {
      dispatch(getAutomaticTeamsSettings());
    }
  }, [getAutomaticTeamsSettingsStatus, dispatch]);
  const [showModal, setShowModal] = useState(false);

  if (getAutomaticTeamsSettingsStatus === "loading") {
    return <Loading />;
  }

  if (hasAutomaticTeamsEnabled === null) {
    return null;
  }

  const getModal = () => {
    if (!showModal) return null;
    if (hasAutomaticTeamsEnabled) {
      return (
        <TurnOffAutomaticTeamsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onSave={(deleteAutomaticTeams: boolean) => {
            dispatch(
              turnAutomaticTeamsSettingOff({
                deleteAutomaticTeams,
              })
            );
            if (companyInformation) {
              dispatch(
                getAllTeamsByCompanyAccountId({
                  companyAccountId: companyInformation.companyAccountId,
                })
              );
            }
          }}
          turnOffAutomaticTeamsSettingStatus={
            turnOffAutomaticTeamsSettingStatus
          }
        />
      );
    }
    return (
      <TurnOnAutomaticTeamsModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={() => {
          dispatch(turnAutomaticTeamsSettingOn());
        }}
      />
    );
  };

  const getDropdownText = () => {
    if (automaticTeamsEditSetting === 1) {
      return "Only Admins";
    }
    if (automaticTeamsEditSetting === 2) {
      return "Admins and Team Leaders";
    }
    return "No One";
  };

  return (
    <>
      {getModal()}
      <Card>
        <h2>Automated Teams</h2>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Automatically Create and Update Teams?</h3>
            <p>
              Teams will be generated and updated automatically based on
              manager-employee relationships. Managers will be automatically
              assigned as team leader. Automatic teams are indicated with a{" "}
              <img src={GeneratedLogo} alt="Generated Logo" />
              icon.
            </p>
          </div>
          <div className="setting-row-action">
            <Toggle
              isOn={hasAutomaticTeamsEnabled}
              handleToggle={() => {
                setShowModal(true);
              }}
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who Can Edit Team Information For Automatic Teams?</h3>
            <p>
              Specify which users can edit team name, department, and team
              visibility for an Automatic team.
            </p>
          </div>
          <div className="setting-row-action">
            <Dropdown
              onSelect={(eventKey) => {
                if (!eventKey) return;
                dispatch(
                  updateAutomaticTeamsEditSetting(
                    parseInt(eventKey, 10) as 1 | 2 | 3
                  )
                );
              }}
            >
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                {getDropdownText()}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">
                  <div className="d-flex align-items-center">Only Admins</div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="2">
                  <div className="d-flex align-items-center">
                    Admins and Team Leaders
                  </div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="3">
                  <div className="d-flex align-items-center">No One</div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Card>
    </>
  );
}
