import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { TestTaker, TestTakerMatches } from "./types";
import {
  getSuperpowersAndHiddenStrengths,
  getWorkPlaceInsightsReportByUserId,
  getPendingTests,
} from "../WorkplaceInsightsReport/slice";

// ------------------ State Type/Structure ------------------
export interface LinkResultsModalState {
  testTakerMatches: TestTakerMatches | null;
  getTestTakerMatchesStatus: responseStatus;
  linkTestTakerStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: LinkResultsModalState = {
  testTakerMatches: null,
  getTestTakerMatchesStatus: "idle",
  linkTestTakerStatus: "idle",
};

export const getTestTakerMatches = createAsyncThunk(
  "linkResultsModal/getTestTakerMatches",
  async ({
    searchString,
    exactMatch,
  }: {
    searchString: string;
    exactMatch: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/testTakers?searchString=${searchString}&exactMatch=${exactMatch}`;
    const response = (await request(requestUrl)) as { [id: number]: TestTaker };
    try {
      return Object.values(response);
    } catch (error) {
      return [];
    }
  }
);

export const linkTestTaker = createAsyncThunk(
  "linkResultsModal/linkTestTaker",
  async (
    {
      userAccountId,
      testTakerId,
    }: {
      userAccountId: number;
      testTakerId: number;
    },
    { getState, dispatch }
  ) => {
    const {
      global: { currentUserAccountId },
    } = getState() as RootState;

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/linkTestTaker/${testTakerId}`;
    await request(requestUrl, {
      method: "PUT",
    });

    if (currentUserAccountId === userAccountId) {
      dispatch(getWorkPlaceInsightsReportByUserId(currentUserAccountId));
      dispatch(getSuperpowersAndHiddenStrengths(currentUserAccountId));
      dispatch(getPendingTests());
    }
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const linkResultsModalSlice = createSlice({
  name: "linkResultsModal",
  initialState,
  reducers: {
    resetTestTakerMatches: (state) => {
      state.testTakerMatches = null;
      state.getTestTakerMatchesStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestTakerMatches.pending, (state) => {
        state.getTestTakerMatchesStatus = "loading";
      })
      .addCase(getTestTakerMatches.fulfilled, (state, action) => {
        state.testTakerMatches = action.payload;
        state.getTestTakerMatchesStatus = "succeeded";
      })
      .addCase(getTestTakerMatches.rejected, (state) => {
        state.getTestTakerMatchesStatus = "failed";
      })
      .addCase(linkTestTaker.pending, (state) => {
        state.linkTestTakerStatus = "loading";
      })
      .addCase(linkTestTaker.fulfilled, (state) => {
        state.linkTestTakerStatus = "succeeded";
      })
      .addCase(linkTestTaker.rejected, (state) => {
        state.linkTestTakerStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectTestTakerMatches = (state: RootState) =>
  state.linkResultsModal.testTakerMatches;
export const selectGetTestTakerMatchesStatus = (state: RootState) =>
  state.linkResultsModal.getTestTakerMatchesStatus;
export const selectLinkTestTakerStatus = (state: RootState) =>
  state.linkResultsModal.linkTestTakerStatus;

export const { resetTestTakerMatches } = linkResultsModalSlice.actions;

export default linkResultsModalSlice.reducer;
