import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCompletedTeam360AssessmentText } from "app/components/Team360Assessment/helpers";
import { CompletionInfoAnalyticsText } from "app/containers/AdminConsole/types";

interface Props {
  completionInfo: CompletionInfoAnalyticsText | undefined;
  departmentName?: string;
}

export default function InsightReportHeader({
  completionInfo,
  departmentName,
}: Readonly<Props>) {
  const overlay = (
    <Popover className="team-360-popover">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>
          <span className="fw-bold">What it is?</span> The TEAM survey is a
          scientifically valid survey that helps you measure teamwork in a
          reliable, repeatable way at the team, department and organization
          levels. All scores are based on the team members’ self-assessment of
          how their teams are doing across 17 scientifically validated behaviors
          of high-performing teams.
        </p>
        <br />
        <p>
          <span className="fw-bold">Why it matters?</span> Modern work requires
          highly coordinated teams, but measuring performance and using data to
          inform targeted improvements can be a challenge.
        </p>
      </div>
    </Popover>
  );

  const getDepartmentNameTag = () => {
    if (!departmentName) return null;
    return <div className="department-name-tag">{departmentName}</div>;
  };

  return (
    <>
      {getDepartmentNameTag()}
      <div className="team-360-full-page-card-title">
        <div className="row-gap-12px align-items-center">
          <h2>TEAMscan Insight Report</h2>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={overlay}
          >
            <FontAwesomeIcon icon={["far", "circle-info"]} />
          </OverlayTrigger>
        </div>
        {completionInfo
          ? getCompletedTeam360AssessmentText(completionInfo)
          : null}
      </div>
    </>
  );
}
