import { selectAllCompanyUsersById } from "app/containers/Global/slice";
import AvatarCircle from "app/components/AvatarCircle";
import { memo, useState } from "react";
import Select, { MultiValue } from "react-select";

import { useAppSelector } from "utils/redux/hooks";

interface Props {
  isMulti?: boolean;
  userAccountIds?: number[];
  setUserAccountIds?: (userAccountIds: number[]) => void;
}

const PeopleSearchDropdown = ({
  isMulti,
  userAccountIds = [],
  setUserAccountIds,
}: Readonly<Props>) => {
  // --------------------- Selectors ---------------------
  const usersById = useAppSelector(selectAllCompanyUsersById);

  // --------------------- State ---------------------
  const [inputText, setInputText] = useState("");

  // --------------------- helpers ---------------------

  const onMultiSelect = (
    newValue: MultiValue<{
      label: string;
      value: string;
      fullName: string;
      emailAddress: string;
    }>
  ) => {
    if (!isMulti) {
      const lastValue = newValue[newValue.length - 1];
      return setUserAccountIds?.([Number(lastValue.value)]);
    }

    const newValues = newValue.map((value) => Number(value.value));
    setUserAccountIds?.(newValues);
  };

  const onSingleSelect = (userAccountId: string) => {
    setUserAccountIds?.([Number(userAccountId)]);
  };

  const getSelectValues = () =>
    userAccountIds.map((userAccountId) => {
      const user = usersById[userAccountId];
      const fullName = `${user.firstName} ${user.lastName}`;
      return {
        label: fullName,
        value: String(userAccountId),
        fullName,
        emailAddress: user.emailAddress,
      };
    });

  const getOptions = () => {
    const options: {
      label: string;
      value: string;
      fullName: string;
      emailAddress: string;
    }[] = [];
    // We need to have all of the available users passed in as props and display them as options. Also check companySettings, if any user are already part of the list, we need to display them as selected options
    Object.keys(usersById).forEach((userAccountId) => {
      const user = usersById[userAccountId];
      const fullName = `${user.firstName} ${user.lastName}`;
      options.push({
        label: fullName,
        value: userAccountId,
        fullName,
        emailAddress: user.emailAddress,
      });
    });
    return options;
  };

  return (
    <Select
      noOptionsMessage={() => null}
      components={{
        DropdownIndicator: null,
      }}
      isClearable
      isMulti={isMulti}
      isSearchable
      placeholder="Enter email addresses..."
      styles={{
        menu: (base: any) => ({
          ...base,
          marginTop: 0,
        }),
      }}
      classNames={{
        multiValue: (base: any) =>
          `${base?.data?.className || ""} multi-value select-item`,
        input: () => `simple-select-input`,
      }}
      inputValue={inputText}
      options={getOptions()}
      onInputChange={(newValue) => setInputText(newValue)}
      formatOptionLabel={(memberInfo) => (
        <div className="member-option">
          <AvatarCircle userAccountId={Number(memberInfo.value)} size="small" />
          <div className="member-info">
            <span className="member-name">{memberInfo.fullName}</span>
            <span className="member-email">{memberInfo.emailAddress}</span>
          </div>
        </div>
      )}
      onChange={(e) => {
        if (!e) {
          return setUserAccountIds?.([]);
        }
        if (Array.isArray(e)) {
          onMultiSelect(e);
        } else if ("value" in e) {
          onSingleSelect(e.value);
        }
      }}
      value={getSelectValues()}
    />
  );
};

export default memo(PeopleSearchDropdown);
