import { IdentityProviderResponse } from "./types";

export const getInputType = (isShowing: boolean) => {
  return isShowing ? "text" : "password";
};
export const getInputPlaceholder = (isShowing: boolean) => {
  return isShowing ? "X$7#mP9&z@K!2qY" : "********";
};
export const getEyeSlashed = (isShowing: boolean) => {
  return isShowing ? "eye-slash" : "eye";
};
export const getSSOEnabledValue = (
  identityProviderStatus: IdentityProviderResponse | null
) => {
  switch (identityProviderStatus?.identityProviderMode) {
    case 1:
      return "soft";
    case 2:
      return "hard";
    case 0:
    default:
      return "none";
  }
};

export const saveQueryTokenInLocalStorage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("critToken") ?? localStorage.getItem("tmg-tkn");
  if (token) {
    localStorage.setItem("tmg-tkn", token);
  }
};
