import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";

interface Props {
  show: boolean;
  onHide: () => void;
  onRetake: () => void;
}

const RetakeEppModal = ({ show, onHide, onRetake }: Props) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" className={`simple-modal`}>
      <div className="modal-title-row">
        <div className="column-gap-12px">
          <h2>Retake the Personality Profile</h2>
        </div>
        <div>
          <Button
            onClick={() => onHide()}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
            xIcon
          />
        </div>
      </div>
      <div className="retake-epp-modal-body">
        <h3>
          Are you sure you want to retake the Employee Personality Profile
          (EPP)?
        </h3>
        <p>
          This will replace your current personality results with your new
          results once you’ve completed the personality profile assessment
          again. Your current personality results will be permanently replaced.
        </p>
      </div>
      <div className="retake-epp-modal-footer">
        <Button onClick={onHide} variant="secondary-blue">
          Cancel
        </Button>
        <Button onClick={onRetake}>Retake Personality Profile</Button>
      </div>
    </Modal>
  );
};

export default RetakeEppModal;
