import { Modal, Nav } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { useState } from "react";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import Select from "react-select";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  inviteUsersToEpp,
  inviteTeamsToEpp,
} from "app/components/WorkplaceInsightsReport/slice";

interface Props {
  show?: boolean;
  onHide: () => void;
  defaultId: number;
}

const InviteEppModal = ({ show, onHide, defaultId }: Props) => {
  const dispatch = useAppDispatch();
  const [tabShowing, setTabShowing] = useState<"team" | "people">("team");
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const peopleById = useAppSelector(selectAllCompanyUsersById);
  const [selectedIds, setSelectedIds] = useState<number[]>([defaultId]);

  const getModalDescription = () => {
    if (tabShowing === "team") {
      return `Enter the team(s) you want to invite. Only team members who haven’t completed the EPP will receive an invitation to complete the assessment.`;
    } else {
      return `Simply type in the email addresses of the people you want to invite. They'll receive an invitation to take the Employee Personality Profile (EPP).`;
    }
  };

  const getSearchPlaceholder = () => {
    if (tabShowing === "team") {
      return "Search by team name";
    } else {
      return "Search by name or email";
    }
  };

  const getSelectOptions = () => {
    if (tabShowing === "team") {
      return Object.values(teamsById).map((team) => ({
        label: team.teamName ?? "",
        value: `${team.teamId}`,
      }));
    }
    return Object.values(peopleById).map((person) => ({
      label: person.firstName + " " + person.lastName,
      value: `${person.userAccountId}`,
    }));
  };

  const getSelectedValues = () => {
    if (tabShowing === "team") {
      return selectedIds.map((id) => ({
        label: teamsById[id]?.teamName ?? "",
        value: `${id}`,
      }));
    }
    return selectedIds?.map((id) => ({
      label: peopleById[id].firstName + " " + peopleById[id].lastName,
      value: `${id}`,
    }));
  };

  const handleInvite = () => {
    if (tabShowing === "team") {
      dispatch(inviteTeamsToEpp(selectedIds));
    } else {
      dispatch(inviteUsersToEpp(selectedIds));
    }
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" className={`simple-modal`}>
      <div className="modal-title-row">
        <div className="column-gap-12px">
          <h2>Invite People to take the Employee Personality Profile</h2>
        </div>
        <div>
          <Button
            onClick={() => onHide()}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
            xIcon
          />
        </div>
      </div>
      <Nav
        className="simple-nav"
        activeKey={tabShowing || ""}
        onSelect={(e) => {
          setSelectedIds([]);
          setTabShowing(e as "team" | "people");
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey="team">Invite team</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="people">Invite individual people</Nav.Link>
        </Nav.Item>
      </Nav>
      <p>{getModalDescription()}</p>
      <Select
        noOptionsMessage={() => null}
        placeholder={getSearchPlaceholder()}
        options={getSelectOptions()}
        isMulti
        value={getSelectedValues()}
        onChange={(values) => {
          if (values) {
            setSelectedIds(values.map(({ value }) => Number(value)));
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleInvite} disabled={selectedIds.length === 0}>
          Send Invite
        </Button>
      </div>
    </Modal>
  );
};

export default InviteEppModal;
