import Button from "app/storybookComponents/Button";
import SimpleModal from "app/components/Modals/SimpleModal";

interface Props {
  show?: boolean;
  onClose: () => void;
  descriptionText?: string;
  onSave: () => void;
}

export default function TurnOnAutomaticTeamsModal({
  show,
  onClose,
  onSave,
}: Readonly<Props>) {
  return (
    <SimpleModal show={show} onHide={onClose} title="Turn On Automatic Teams">
      <div className="turn-on-automatic-teams-content">
        <h3>
          Are you sure you want to turn on Automatic teams for your
          organization?
        </h3>
        <p style={{ paddingTop: "8px" }}>
          Teams will be generated and updated automatically based on
          manager-employee relationships. Managers will be automatically
          assigned as team leader.
        </p>
        <div style={{ display: "flex", gap: "10px", paddingTop: "16px" }}>
          <Button onClick={onClose} variant="secondary-blue">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave();
              onClose();
            }}
          >
            Confirm and Turn On
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
}
