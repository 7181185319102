export const CoachbotDefaultPrompts = {
  CreateOneWeekGoal: "Set a 1-week goal",
  ReflectOnOverall: "Reflect on how things are going",
  SeeAllPromptTitle: "See all prompts",
} as const;

export const CHATBOT_OTHER_FEEDBACK = "Other";
export const CHATBOT_FEEDBACK_EXAMPLES = {
  helpful: [
    "Liked the style",
    "Relevant advice",
    "Engaging chat",
    "Factually correct",
    "Helpful responses",
    CHATBOT_OTHER_FEEDBACK,
  ],
  notHelpful: [
    "Don't like the style",
    "Not factually correct",
    "Didn't follow instructions",
    "Lazy",
    CHATBOT_OTHER_FEEDBACK,
  ],
};
