import { Card, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarCircle from "app/components/AvatarCircle";
import { useState } from "react";
import Button from "app/storybookComponents/Button";
import {
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";
import Team360SectionInSurvey from "../Team360SectionInSurvey";
import { getDateInMonthDDFormat } from "./helper";

interface Props {
  memberIds?: number[];
  sentToText?: string | null;
  frequency?: string | null;
  frequencyText?: string | null;
  userAccountIds?: number[] | null;
  userAccountId?: number | null;
  endDate?: string | Date | null;
  startDate?: string | Date | null;
  teamId?: number | null;
  onStartSurvey: () => void;
  teamName?: string | null;
  teamProfilePicture?: string | null;
  leaderName?: string | null;
}

export default function Overview({
  frequency,
  onStartSurvey,
  startDate,
  endDate,
  teamId,
  teamName,
  teamProfilePicture,
  leaderName,
}: Readonly<Props>) {
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);

  const getTimeAndQuestionCount = () => (
    <div className="time-and-question-count">
      <span>
        <div>
          <FontAwesomeIcon icon={["far", "question-circle"]} />
        </div>
        20 questions (+2 optional)
      </span>
      <span>
        <div>
          <FontAwesomeIcon icon={["far", "clock"]} />
        </div>
        fewer than 5 minutes to complete
      </span>
    </div>
  );

  const getAdditionalDetails = () => (
    <>
      <Card
        style={{
          border: "none",
          gap: "10px",
        }}
      >
        <h3>Getting started</h3>
        <p>
          Click “Start Survey” to begin. You must complete and submit all
          questions before leaving the survey. If you exit the survey without
          submitting, you will need to restart.
        </p>
      </Card>
      <hr />
      <Card
        style={{
          border: "none",
        }}
      >
        <h3>4 sections in this survey</h3>
        <p>
          There are 4-5 questions in each of these sections with optional
          feedback prompts at the end of the survey.
        </p>
        <div style={{ marginTop: "10px" }}>
          <Team360SectionInSurvey customGap={10} />
        </div>
      </Card>
    </>
  );

  const getLeaderName = () => {
    if (leaderName) {
      return <p className="grey-text">Team led by {leaderName}</p>;
    }

    if (!teamId || !teamsById[teamId]?.teamLeadUserAccountIds?.length) {
      return null;
    }

    const firstTeamLead = teamsById[teamId]
      ?.teamLeadUserAccountIds?.[0] as number;
    const foundUser = usersInfoById[firstTeamLead];

    if (!foundUser) {
      return null;
    }

    return (
      <p className="grey-text">
        Team led by {`${foundUser.firstName || ""} ${foundUser.lastName || ""}`}
      </p>
    );
  };

  const getDateText = () => {
    if (!startDate || !endDate) {
      return null;
    }

    const startDateText = getDateInMonthDDFormat(startDate);
    const endDateText = getDateInMonthDDFormat(endDate);

    if (frequency) {
      return `Occurs ${frequency}, open from ${startDateText} to ${endDateText}`;
    }

    return `Opened from ${startDateText} to ${endDateText}`;
  };

  const getBody = () => {
    const newList = [
      {
        title: "Discover Your Team’s Perspective",
        description:
          "Through the survey results, you'll see how you perceive your team's overall teamwork compared to the average of your teammates. These areas of agreement and disagreement can lead to insightful discussions and growth.",
      },
      {
        title: "Honesty Is Key",
        description:
          "Your honesty will help in accurately identifying where your team stands. Remember, all your responses are 100% anonymous, so trust your instincts and answer as honestly as possible.",
      },
    ];
    const text1 =
      "You're about to uncover your team's strengths and areas for improvement across 4 core teamwork factors and 17 dimensions. Here's what you need to know:";
    const teamInfo = teamId ? teamsById[teamId] : null;
    const name = teamInfo?.teamName ?? teamName ?? "";
    return (
      <div className="column-gap-20px survey-card">
        <p className="grey-text">You are taking this TEAMscan for:</p>
        <div className="row-gap-12px">
          <div className="d-flex align-items-center">
            <AvatarCircle
              profilePicture={teamInfo?.profilePicture ?? teamProfilePicture}
              avatarColor={teamInfo?.avatarColor}
              name={name}
              size="small"
            />
          </div>
          <div className="column-gap-4px">
            <p>
              <b>{name}</b>
            </p>
            {getLeaderName()}
          </div>
        </div>
        <hr className="m-0" />
        <p>{text1}</p>
        <ul className="team-360-overview-list column-gap-20px">
          {newList.map(({ title, description }) => {
            return (
              <li key={title}>
                <FontAwesomeIcon
                  icon="check-circle"
                  style={{ color: "#202d63" }}
                />
                <div>
                  <p>
                    <b>{title}</b>
                  </p>
                  <p>{description}</p>
                </div>
              </li>
            );
          })}
        </ul>
        <hr className="m-0" />
        <p className="grey-text">
          <FontAwesomeIcon icon={["far", "clock"]} /> {getDateText()}
        </p>
      </div>
    );
  };

  return (
    <>
      {getBody()}
      {getTimeAndQuestionCount()}
      <div className="responsive-button-footer inverse-direction">
        <Button onClick={onStartSurvey}>
          Start survey <FontAwesomeIcon icon="arrow-right" />
        </Button>
        <Button
          onClick={() => {
            setShowAdditionalDetails(!showAdditionalDetails);
          }}
          variant="secondary-blue"
          className="border-0"
        >
          {showAdditionalDetails ? "Hide" : "Show"} additional details{" "}
          <FontAwesomeIcon
            icon={`caret-${showAdditionalDetails ? "up" : "down"}`}
          />
        </Button>
      </div>
      <Collapse in={showAdditionalDetails}>
        <div>{getAdditionalDetails()}</div>
      </Collapse>
    </>
  );
}
