import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
} from "app/components/Modals/slice";
import { useAppDispatch } from "utils/redux/hooks";

interface Props {
  entity: "Team" | "Department" | "User";
  title: string;
  createButtonText: string;
  zeroStateTitle: string;
  zeroStateText: string;
  rows: (JSX.Element | null)[];
  count: number;
  isAdmin: boolean;
  onOpenInvitePeopleModal?: () => void;
}

export default function EntityCard({
  entity,
  title,
  rows,
  isAdmin,
  createButtonText,
  zeroStateTitle,
  zeroStateText,
  count,
  onOpenInvitePeopleModal,
}: Props) {
  const isUser = entity === "User";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClick = () => {
    if (isUser) {
      onOpenInvitePeopleModal?.();
    } else if (entity === "Team") {
      dispatch(openCreateTeamModal());
    } else {
      dispatch(openEditDepartmentModal());
    }
  };

  const getManageButtonsRow = () => {
    if (!isAdmin) return null;
    const url = getManageUrl();
    // set style for the row to display flex and gap 5px, if user add margin top 10px
    const style = { display: "flex", gap: "5px", marginTop: "15px" };
    if (isUser) {
      style.marginTop = "15px";
    }

    return (
      <div style={style}>
        <Button variant="primary" onClick={onClick}>
          {createButtonText}
        </Button>
        <Button
          variant="secondary-blue"
          className={!count ? "white-button" : ""}
          onClick={() => navigate(url)}
        >
          Manage
        </Button>
      </div>
    );
  };

  const getZeroStateContent = () => {
    return (
      <div className="content">
        <div className="empty-card">
          <span>{zeroStateTitle}</span>
          <p>{zeroStateText}</p>
          {getManageButtonsRow()}
        </div>
      </div>
    );
  };

  const getManageUrl = () => {
    switch (entity) {
      case "Team":
        return "/AdminConsole/Teams";
      case "Department":
        return "/AdminConsole/Departments";
      case "User":
        return "/AdminConsole/People";
    }
  };

  const getSeeAllUrl = () => {
    switch (entity) {
      case "Team":
        return "/Search/Teams";
      case "Department":
        return "/Search/Departments";
      case "User":
        return "/Search/People";
    }
  };

  const getSeeAllButton = () => {
    const url = getSeeAllUrl();
    return (
      <Button
        variant="secondary-blue"
        style={{ border: "none" }}
        onClick={() => navigate(url)}
      >
        See All
      </Button>
    );
  };

  const getContent = () => {
    if (count === 0) {
      return getZeroStateContent();
    }

    return (
      <>
        <div className={isUser ? "section-body" : "section-container"}>
          {rows}
        </div>
        {getManageButtonsRow()}
      </>
    );
  };

  const cardClassName = isUser ? "section team-members" : "section";

  return (
    <>
      <Card className={cardClassName}>
        <div className="section-header">
          <h2>{title}</h2>
          {count > 0 && getSeeAllButton()}
        </div>
        {getContent()}
      </Card>
    </>
  );
}
