import { Form } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import Toggle from "app/components/Toggle";
import { UserInfo } from "app/containers/Global/types"; // Might be troublesome if we decide to export this into storybook library
import SingleUserSelect from "app/storybookComponents/SearchableInput/SingleUserSelect";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PLATFORM_NAME } from "utils/constants";
import { responseStatus } from "utils/types";

interface Props {
  isLoading?: boolean;
  departmentMap: {
    [departmentTypeId: number]: {
      name: string;
    };
  };
  formInput: {
    name: string;
    leader: number | null;
    departmentTypeId: number | null;
    departmentLeaderEmail?: string;
    isLeaderToggled?: boolean;
  };
  setFormInput: (payload: {
    name: string;
    leader: number | null;
    departmentTypeId: number | null; // Might change this to be a department Id
    departmentLeaderEmail?: string;
    isLeaderToggled?: boolean;
  }) => void;
  onSave: (payload: {
    name: string;
    leader: number | null;
    departmentTypeId: number | null;
    departmentLeaderEmail?: string;
  }) => void;
  users: { [userAccountId: string | number]: UserInfo };
  loggedInUserId: number | null;
  isCreatedEmailValid?: boolean;
  checkIfCreatedEmailIsValid?: (payload: string) => void;
  checkIfCreatedEmailIsValidStatus?: responseStatus;
  invalidCreatedEmailMessage?: string | null;
}
// Create test case that will check if that if the user has leaderInputText as null then the user will not see the leader input?
export default function DepartmentForm({
  formInput,
  setFormInput,
  onSave,
  isLoading,
  departmentMap,
  users,
  loggedInUserId,
  checkIfCreatedEmailIsValid,
  invalidCreatedEmailMessage,
  checkIfCreatedEmailIsValidStatus,
}: Readonly<Props>) {
  const characterLimit = 50;
  const isCreatedEmailValid = !invalidCreatedEmailMessage;

  const onChangeHandler = (
    val: SingleValue<
      | {
          label: string;
          value: string;
        }
      | undefined
    >
  ) => {
    if (!val) return;
    setFormInput({
      ...formInput,
      departmentTypeId: Number(val.value),
    });
  };

  const getDepartmentTypeValue = () => {
    if (!formInput.departmentTypeId) return undefined;
    if (!departmentMap[formInput.departmentTypeId]?.name) {
      return undefined;
    }

    return {
      value: String(formInput.departmentTypeId),
      label: departmentMap[formInput.departmentTypeId].name,
    };
  };

  const isSaveDisabled = () => {
    if (!formInput.departmentTypeId) return true;
    if (!formInput.name) return true;
    if (formInput?.departmentLeaderEmail && !isCreatedEmailValid) return true;
    if (checkIfCreatedEmailIsValidStatus === "loading") return true;

    return isLoading;
  };

  const getWarningBanner = () => {
    if (!formInput?.departmentLeaderEmail && !formInput.leader) return null;

    // If the formInput has the departmentLeader email and the email is not valid show error
    if (formInput?.departmentLeaderEmail && !isCreatedEmailValid) {
      return (
        <div className="warning-banner red">
          <p>
            <strong>Warning:</strong> The email address you entered is not
            valid. Please enter a valid email address.
          </p>
        </div>
      );
    }

    if (formInput.leader === loggedInUserId) return null;

    return (
      <div className="snapshot-box">
        <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
        <p>
          {formInput?.departmentLeaderEmail
            ? `An invitation email will be sent to this user to join ${PLATFORM_NAME} as a member of this organization and to be a leader for this department.`
            : "An invitation email will be sent to this user to be a leader for this department."}
        </p>
      </div>
    );
  };
  const { isLeaderToggled = false } = formInput;

  return (
    <Form>
      <Form.Group controlId="departmentFormName" className="form-group">
        <Form.Label>Department Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Department Name"
          onChange={(event) =>
            setFormInput({
              ...formInput,
              name: event.target.value.slice(0, characterLimit),
            })
          }
          value={formInput.name}
        />
        <Form.Text id="departmentFormNameLimit" muted>
          {formInput.name.length}/50 Characters
        </Form.Text>
      </Form.Group>
      <Form.Group controlId="departmentFormPassword" className="form-group">
        <Form.Label>Department Type</Form.Label>
        <Select
          options={Object.entries(departmentMap)
            .sort(([, aVal], [, bVal]) => {
              if (aVal.name < bVal.name) return -1;
              if (aVal.name > bVal.name) return 1;
              return 0;
            })
            .map(([key, val]) => ({
              label: val.name,
              value: key,
            }))}
          onChange={onChangeHandler}
          value={getDepartmentTypeValue()}
        />
      </Form.Group>
      <Form.Group className="form-group mt-2">
        <div className="form-label-with-toggle">
          <Form.Label>Add Department Leader</Form.Label>
          <Toggle
            isOn={isLeaderToggled}
            handleToggle={() => {
              if (isLeaderToggled) {
                setFormInput({
                  ...formInput,
                  departmentLeaderEmail: "",
                  leader: null,
                  isLeaderToggled: !isLeaderToggled,
                });
              } else {
                setFormInput({
                  ...formInput,
                  isLeaderToggled: !isLeaderToggled,
                });
              }
            }}
          />
        </div>
        {/* TODO: We need to change this form control to select dropdown so that the user can select a leader from all of the available users. */}
        {/* Also in this select component we should only allow the user to select one lead and the select should not be creatable */}
        {isLeaderToggled ? (
          <SingleUserSelect
            users={users}
            selectedValue={formInput.leader}
            setSelectedValue={(payload) => {
              setFormInput({
                ...formInput,
                leader: payload,
                departmentLeaderEmail: "",
              });
            }}
            setCreatedUserEmail={(payload: string) => {
              if (payload) {
                checkIfCreatedEmailIsValid?.(payload);
              }
              setFormInput({
                ...formInput,
                leader: null,
                departmentLeaderEmail: payload,
              });
            }}
            clearAll={() => {
              setFormInput({
                ...formInput,
                departmentLeaderEmail: "",
                leader: null,
              });
            }}
            isCreatable
            selectedCreatedUserEmail={formInput.departmentLeaderEmail}
          />
        ) : null}
      </Form.Group>
      {getWarningBanner()}
      <Form.Group className="form-group">
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            onSave({
              ...formInput,
              leader: isLeaderToggled ? formInput.leader : null,
            });
          }}
          disabled={isSaveDisabled()}
        >
          Save
        </Button>
      </Form.Group>
    </Form>
  );
}
