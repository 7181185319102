import { useState, useRef } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

type Option = {
  label: string;
  value: number | string;
} & {
  [key: string]: any;
};

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

interface Props {
  options: Option[];
  onChange: (option: Option | null) => void;
  value?: Option | null;
  placeHolder?: string;
  onCaptureInputValue?: (inputValue: string) => void; // This value will be used incase the user changes the input value but does not hit enter or select an option
  creatableText?: string;
  isNotCreatable?: boolean;
}

export default function CreatableSingleSelect({
  options,
  placeHolder = "Enter email addresses...",
  onChange: onChangeProp,
  value = null,
  onCaptureInputValue,
  creatableText = "Invite",
  isNotCreatable,
}: Readonly<Props>) {
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef<any>(null);

  const onCreatableInputChange = (
    incomingInputValue: string,
    { action }: { action: string; prevInputValue: string }
  ) => {
    if (action === "input-change") {
      setInputValue(incomingInputValue);
      return;
    }

    // When we unfocus then we save whatever the user type in the input if the user passed in the onCaptureInputValue prop
    if (
      action === "menu-close" &&
      selectRef?.current?.inputRef?.value !== value?.label
    ) {
      onCaptureInputValue?.(inputValue);
    }
  };

  const onNonCreatableInputChange = (
    incomingInputValue: string,
    { action }: { action: string; prevInputValue: string }
  ) => {
    if (action === "input-change") {
      setInputValue(incomingInputValue);
      return;
    }

    // When we unfocus if we did not select an option then we clear the input value
    if (
      action === "menu-close" &&
      selectRef?.current?.inputRef?.value !== value?.label
    ) {
      setInputValue("");
      onChangeProp?.(null);
    }
  };

  const onChange = (option: Option | null) => {
    setInputValue(option?.label ?? "");
    onChangeProp?.(option);
  };

  const onFocus = () => value && selectRef.current?.select?.inputRef?.select();
  const props = {
    styles: {
      menu: (base: any) => ({
        ...base,
        marginTop: 0,
      }),
    },
    placeHolder,
    ref: selectRef,
    options,
    value,
    inputValue,
    onChange,
    onFocus,
    controlShouldRenderValue: !!value,
    components: {
      Input,
    },
    noOptionsMessage: () => null,
    isClearable: true,
    isSearchable: true,
  };

  if (isNotCreatable) {
    return <Select {...props} onInputChange={onNonCreatableInputChange} />;
  }

  return (
    <CreatableSelect<Option>
      {...props}
      formatCreateLabel={(e) => `${creatableText} "${e}"`}
      onInputChange={onCreatableInputChange}
    />
  );
}
