import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { Card, Collapse } from "react-bootstrap";
import StackBarChart from "app/storybookComponents/Charts/StackBarChart";
import {
  getConversationPartners,
  resetUpdateTeam360DateRangeStatus,
  selectGetTeamConversationSuggestionsStatus,
  selectHasTeamUpdatedSurveyDateRange,
  selectTeam360InstancePicked,
  selectTeam360StatusByTeamId,
  selectTeamAnalyticsText,
  selectTeamCommentsByTeamId,
  selectTeamsSuggestedConversations,
  selectTeamSurveyInstancesByTeamId,
  selectUpdateTeam360DateRangeStatus,
  selectUserTeam360AssessmentResultsByTeamId,
  updateTeam360DateRange,
  getTeamAssessmentInstances,
  clearGetConversationStatus,
  selectTeam360CardsExpanded,
  setTeam360CardsExpanded,
  selectGetTeamAnalyticsTextStatus,
  selectTeamVisibilitySettings,
} from "app/containers/TeamGuide/slice";
import { TEAM_SUMMARY_KEY_BREAKDOWN } from "app/containers/AdminConsole/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCompletedTeam360AssessmentText,
  getTeam360Score,
} from "app/components/Team360Assessment/helpers";
import {
  isTeam360Factor,
  TTeam360Factor,
} from "app/components/Team360Assessment/types";
import Team360ComparisonModal from "app/components/Team360Assessment/Team360ComparisonModal";
import { useEffect, useMemo, useState } from "react";
import ShortCompareGraph from "app/storybookComponents/Charts/ShortCompareGraph";
import WhatSkillsCanYourTeamWorkOn from "app/components/Team360Assessment/WhatSkillsCanYourTeamWorkOn";
import {
  getSkillGuide,
  selectGetDimensionGuideStatus,
  selectGetSkillsGuideStatus,
  getDimensionGuide,
} from "app/containers/SkillsGuide/slice";
import Team360ScoreBreakdownModal from "app/components/Team360Assessment/Team360ScoreBreakdownModal";
import Team360AgreementReportModal from "app/components/Team360Assessment/Team360AgreementReportModal";
import { useSearchParams, useNavigate } from "react-router-dom";
import TeamReflectionCard from "app/components/TEAM360InsightsModules/TeamReflectionCard";
import Team360CommentsByYourTeamModal from "app/components/Team360Assessment/Team360CommentsByYourTeamModal";
import Team360LowAgreementSkillCards from "app/components/Team360Assessment/Team360LowAgreementSkillCards";
import Team360WhoCanYouTalkTo from "app/components/Team360Assessment/Team360WhoCanYouTalkTo";
import ResultsOverviewCard from "app/components/TEAM360InsightsModules/ResultsOverviewCard";
import AvatarCircle from "app/components/AvatarCircle";
import {
  selectCurrentUserAccountId,
  selectIsCurrentUserAdmin,
  selectSampleTeamsByTeamId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  getAssessmentInformation,
  selectAssessmentInformation,
  selectCompanySettings,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import { showScheduleAssessmentModalForTeamId } from "app/components/LaunchAssessmentModal/slice";
import Team360WhatConversationsCanYouSuggest from "app/components/Team360Assessment/Team360WhatConversationsCanYouSuggest";
import EmptyTeam360ResultsCard from "app/components/Team360Assessment/EmptyTeam360ResultsCard";
import {
  postUserOnboardingTrackingForEntireSite,
  setShowModal as setShowOnboardingModal,
  showOnboardingModalForTeamId,
} from "app/components/Onboarding/slice";
import InstanceStickyFooter from "app/components/InstancePicker/InstanceStickyFooter";
import DateRangePicker from "app/components/InstancePicker/DateRangePicker";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";
import InstanceWarningBanner from "app/components/InstancePicker/InstanceWarningBanner";
import FreeTextSummary from "../AdminConsole/FreeTextSummary";
import TeamScanActionChecklistDrawer from "app/components/Checklists/TeamScanActionChecklistDrawer";
import { setIsQuickActionDrawerOpen } from "app/components/QuickActions/slice";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "../Chatbot/slice";
import CustomChatbotButton from "../Chatbot/CustomChatbotButton";
import { TEAM_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE } from "../CoachBotPrompts/constants";
import { hasPermissionToViewReport } from "./helpers";

interface Props {
  goBack: () => void;
  teamId: number;
  onFactorClick: (
    factor: TTeam360Factor,
    highOrLow: "high" | "low" | null
  ) => void;
  isLoading?: boolean;
  hasEditAccess?: boolean;
  isUserATeamLeader?: boolean;
}

export default function Team360FullPage({
  teamId,
  onFactorClick,
  isLoading,
  hasEditAccess,
  isUserATeamLeader,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const team360IntervalPicked = useAppSelector(
    selectTeam360InstancePicked(teamId)
  );
  const allTeam360Scores = useAppSelector(
    selectAllTeam360Results(team360IntervalPicked ?? undefined)
  );
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const teamAnalyticsText = useAppSelector(selectTeamAnalyticsText(teamId));
  const getSkillsGuideStatus = useAppSelector(selectGetSkillsGuideStatus);
  const getDimensionGuideStatus = useAppSelector(selectGetDimensionGuideStatus);

  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const userTeam360Results = useAppSelector(
    selectUserTeam360AssessmentResultsByTeamId(teamId)
  );
  const teamComments = useAppSelector(selectTeamCommentsByTeamId(teamId));
  const updatingTeam360DateRangeStatus = useAppSelector(
    selectUpdateTeam360DateRangeStatus
  );
  const displayStatus = useAppSelector(selectTeam360StatusByTeamId(teamId));
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const getTeamSuggestedConversationsStatus = useAppSelector(
    selectGetTeamConversationSuggestionsStatus
  );
  const teamSuggestedConversation = useAppSelector(
    selectTeamsSuggestedConversations(teamId)
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const availableSurveyInstances = useAppSelector(
    selectTeamSurveyInstancesByTeamId(teamId)
  );
  const hasTeam360SurveyBeenUpdated = useAppSelector(
    selectHasTeamUpdatedSurveyDateRange(teamId, 1) // 1 is Id for team360
  );
  const team360CardsExpanded = useAppSelector(
    selectTeam360CardsExpanded(teamId)
  );
  const companySettings = useAppSelector(selectCompanySettings);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const getTeamAnalyticsTextStatus = useAppSelector(
    selectGetTeamAnalyticsTextStatus
  );
  const teamVisibilitySettings = useAppSelector(
    selectTeamVisibilitySettings(teamId)
  );
  const teamsByTeamId = useMemo(
    () => ({ ...sampleTeamsById, ...allTeamsById }),
    [sampleTeamsById, allTeamsById]
  );

  // -------------------------------------------- State --------------------------------------------
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [showScoreBreakdown, setShowScoreBreakdown] = useState(false);
  const [showAgreementChart, setShowAgreementChart] = useState(false);
  const [showTeamCommentModal, setShowTeamCommentModal] = useState(false);
  const [showDateRangePickerModal, setShowDateRangePickerModal] =
    useState(false);
  const [isComparedToOrgCollapsed, setIsCompareToOrgCollapsed] = useState(true);
  const [isChecklistDrawerOpen, setIsChecklistDrawerOpen] = useState(false);

  // -------------------------------------------- Effects --------------------------------------------
  useEffect(() => {
    dispatch(getAssessmentInformation());
    // On component did unmount:
    return () => {
      dispatch(clearGetConversationStatus());
      setIsCompareToOrgCollapsed(true);
    };
  }, [dispatch]);

  useEffect(() => {
    // If team360AnalyticsTextStatus not successfull then return.
    if (
      !teamAnalyticsText ||
      !displayStatus ||
      hasTeam360SurveyBeenUpdated // If a specific instance was picked then we don't want to show the onboarding modal.
    ) {
      return;
    }

    const hasNotYetScheduledSurvey =
      teamAnalyticsText?.completionInfo?.totalInvited === 0 &&
      teamAnalyticsText?.completionInfo?.totalCompleted === 0 &&
      !displayStatus?.scheduledAssessment;

    // If the team does not have results then we show the onboarding message for that specific team
    if (hasNotYetScheduledSurvey) {
      dispatch(
        showOnboardingModalForTeamId({
          eventType: hasEditAccess
            ? "team360LeadViewNotScheduledYet"
            : "visitedTeam360TabWithoutResults",
          teamId,
        })
      );
      // If the team does have results then we show the generic onboarding message, that will only be displayed once.
    } else if (teamAnalyticsText?.overview) {
      dispatch(
        postUserOnboardingTrackingForEntireSite({
          eventType: "visitedTeam360TabWithResults",
        })
      );
    }
  }, [
    teamId,
    dispatch,
    teamAnalyticsText,
    hasEditAccess,
    hasTeam360SurveyBeenUpdated,
    displayStatus,
  ]);

  useEffect(() => {
    if (getSkillsGuideStatus === "idle") {
      dispatch(getSkillGuide());
    }
  }, [dispatch, getSkillsGuideStatus]);

  useEffect(() => {
    if (getDimensionGuideStatus === "idle") {
      dispatch(getDimensionGuide());
    }
  }, [dispatch, getDimensionGuideStatus]);

  useEffect(() => {
    // Upon receiving a successful update of the date range we close the modal.
    if (updatingTeam360DateRangeStatus === "succeeded") {
      setShowDateRangePickerModal(false);
      dispatch(resetUpdateTeam360DateRangeStatus());
    }
  }, [updatingTeam360DateRangeStatus, dispatch]);

  useEffect(() => {
    // If the query params is set for the tab and the tab is a valid tab then we set the active tab to the query param.
    const showScoreBreakdownModalBool = searchParams.get("showScoreBreakdown");
    setShowScoreBreakdown(showScoreBreakdownModalBool === "true");
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (availableSurveyInstances || !teamId) return;
    dispatch(getTeamAssessmentInstances({ teamId }));
  }, [dispatch, availableSurveyInstances, teamId]);

  useEffect(() => {
    // If we currently are updating the date range that means that the state hasn't updated the value for team360IntervalPicked so return early.
    if (updatingTeam360DateRangeStatus === "loading") return;
    // Since every time we change the date range we will reset getTeamSuggestedConversationsStatus to be idle so once idle then we know to fetch again
    if (getTeamSuggestedConversationsStatus !== "idle") return;
    // If we already have the suggested conversations then we return early.
    if (teamSuggestedConversation) return;
    // If we still don't have the teamInfo then we return early.
    if (!teamsByTeamId[teamId]) return;
    // TODO: we might also want to not make the request if the user is part of the team since they will be viewing the other view

    const teamInfo = teamsByTeamId[teamId];
    const teamsDepartmentId = teamsByTeamId[teamId].departmentId;
    const hasAccess =
      isAdmin ||
      (teamsDepartmentId &&
        // We then check if the user is a department leader for this team.
        !!teamAndDepartmentLeaderOf?.departments?.find(
          (departmentId) => departmentId === teamsDepartmentId
        ));

    // If the user doesn't have access then we return early.
    if (!hasAccess) return;
    const selectedMember =
      teamInfo.teamLeadUserAccountIds?.[0] ?? teamInfo.teamMemberIds?.[0] ?? 0;
    dispatch(
      getConversationPartners({
        teamId,
        userAccountId: selectedMember,
        startDate: team360IntervalPicked?.startDate,
        endDate: team360IntervalPicked?.endDate,
        instance: team360IntervalPicked?.instance ?? undefined,
      })
    );
  }, [
    teamAndDepartmentLeaderOf,
    teamSuggestedConversation,
    teamsByTeamId,
    teamId,
    isAdmin,
    dispatch,
    getTeamSuggestedConversationsStatus,
    team360IntervalPicked,
    updatingTeam360DateRangeStatus,
  ]);

  const getCardHeader = () => (
    <div className="team-360-full-page-card-title">
      <div className="row-gap-12px">
        <h2>TEAMscan Full Score Report</h2>
        {hasEditAccess ? (
          <div className="hide-on-991px">
            <Button
              variant="secondary-blue"
              onClick={() => {
                dispatch(showScheduleAssessmentModalForTeamId(teamId));
              }}
            >
              Relaunch TEAMscan
            </Button>
          </div>
        ) : null}
      </div>
      {teamAnalyticsText?.completionInfo
        ? getCompletedTeam360AssessmentText(teamAnalyticsText.completionInfo)
        : null}
    </div>
  );

  const getResultsOverviewFooter = () => {
    const onSeeScoreBreakdownClick = () => {
      setSearchParams((searchParams) => {
        searchParams.set("showScoreBreakdown", "true");
        return searchParams;
      });
      setShowScoreBreakdown(true);
    };

    if (isUserPartOfTeam || isSampleTeam) {
      return (
        <>
          <Button
            onClick={() => {
              dispatch(setIsQuickActionDrawerOpen(false));
              setIsChecklistDrawerOpen(true);
            }}
          >
            See discussion checklist
          </Button>
          <Button variant="secondary-blue" onClick={onSeeScoreBreakdownClick}>
            See score breakdown
          </Button>
        </>
      );
    }

    return (
      <>
        <Button onClick={onSeeScoreBreakdownClick}>See score breakdown</Button>
        <Button
          variant="secondary-blue"
          onClick={() => setShowTeamCommentModal(true)}
        >
          See comments
        </Button>
      </>
    );
  };

  const getResultsOverview = () => {
    if (!allTeam360Scores) return null;

    return (
      <ResultsOverviewCard
        teamScores={getTeamScore()}
        companyScores={getCompanyScore()}
        setShowTeam360ResultsModal={(factor, highOrLow) => {
          if (isTeam360Factor(factor)) {
            onFactorClick(factor, highOrLow ?? null);
          }
        }}
        type="team"
        overviewText={teamAnalyticsText?.overview?.text ?? ""}
        footerButtons={getResultsOverviewFooter()}
        hideActionButtons
      />
    );
  };

  const getTeamMemberLeaderViewBanner = () => {
    if (isSampleTeam) {
      return null;
    }
    return (
      <div className="warning-banner">
        <p>
          <b>Team Member / Leader View:</b> This is the report that team members
          on this team see.
        </p>
      </div>
    );
  };

  const getDepartmentLeaderAdminViewBanner = () => {
    if (isSampleTeam) {
      return null;
    }
    return (
      <div className="warning-banner">
        <p>
          <b>Department Leader / Admin View:</b> This is what you can do to help
          this team.
        </p>
      </div>
    );
  };

  const getTeamSummaryKey = () => (
    <div className="summary-chart-key">
      {TEAM_SUMMARY_KEY_BREAKDOWN.map(({ className, title, description }) => (
        <div className={`${className}`} key={title}>
          <FontAwesomeIcon
            icon="circle"
            style={{ fontSize: "8px" }}
            className="mt-1"
          />
          <div>
            <h4 className="breakdown-key">
              {title.replace("REPLACE_ME", "team")}
            </h4>
            <p>{description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  const getHowTeamComparesStarValue = (
    teamValue?: null | "high-performing" | "healthy" | "needs-help"
  ) => {
    switch (teamValue) {
      case "high-performing":
        return "high";
      case "healthy":
        return "medium";
      case "needs-help":
        return "low";
      default:
        return undefined;
    }
  };

  const getHowYourTeamComparesInThisOrganization = () => {
    if (!teamAnalyticsText?.vsOrganization) {
      return null;
    }

    let totalTeamCount = 0;
    if (
      teamAnalyticsText?.vsOrganization?.classificationArray["high-performing"]
    ) {
      totalTeamCount +=
        teamAnalyticsText?.vsOrganization?.classificationArray[
          "high-performing"
        ];
    }
    if (teamAnalyticsText?.vsOrganization?.classificationArray.healthy) {
      totalTeamCount +=
        teamAnalyticsText?.vsOrganization?.classificationArray.healthy;
    }

    if (teamAnalyticsText?.vsOrganization?.classificationArray["needs-help"]) {
      totalTeamCount +=
        teamAnalyticsText?.vsOrganization?.classificationArray["needs-help"];
    }

    return (
      <Card
        className="column-gap-20px"
        role={isComparedToOrgCollapsed ? "button" : undefined}
        onClick={() => {
          if (!isComparedToOrgCollapsed) return;
          setIsCompareToOrgCollapsed(false);
        }}
      >
        <div className="d-flex justify-content-between align-items-start">
          <div className="column-gap-8px">
            <h2>How Does Your Team Compare?</h2>
            {!isComparedToOrgCollapsed ? (
              <div>
                <p>{teamAnalyticsText?.vsOrganization?.breakdown}</p>
                <br></br>
                <p>{teamAnalyticsText?.vsOrganization?.description}</p>
              </div>
            ) : null}
          </div>

          <div>
            <Button
              onClick={() => {
                setIsCompareToOrgCollapsed(!isComparedToOrgCollapsed);
              }}
              variant="secondary-blue"
              className="border-0"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {isComparedToOrgCollapsed ? "See details" : "Collapse"}
              <FontAwesomeIcon
                icon={`caret-${isComparedToOrgCollapsed ? "down" : "up"}`}
                className="ms-2"
              />
            </Button>
          </div>
        </div>
        <Collapse in={!isComparedToOrgCollapsed}>
          <div className="column-gap-20px">
            <div className="column-gap-12px">
              <div className="d-flex align-items-center justify-content-between">
                <p>
                  <b>
                    {totalTeamCount} total team{totalTeamCount > 1 ? "s" : ""}
                  </b>
                </p>
                <p className="row-gap-8px">
                  <b>
                    <FontAwesomeIcon icon="star" />
                  </b>
                  This team
                </p>
              </div>
              <StackBarChart
                high={
                  teamAnalyticsText?.vsOrganization?.classificationArray[
                    "high-performing"
                  ]
                }
                medium={
                  teamAnalyticsText?.vsOrganization?.classificationArray.healthy
                }
                low={
                  teamAnalyticsText?.vsOrganization?.classificationArray[
                    "needs-help"
                  ]
                }
                starValue={getHowTeamComparesStarValue(
                  teamAnalyticsText?.vsOrganization?.teamClassification
                )}
              />
            </div>
            {getTeamSummaryKey()}
            <div>
              <Button
                variant="secondary-blue"
                onClick={() => setShowComparisonModal(true)}
              >
                See comparison report
              </Button>
            </div>
          </div>
        </Collapse>
      </Card>
    );
  };

  const getHowYouCompareWithYourTeammates = () => {
    const yourScore = userTeam360Results?.userScores?.overall;
    const averageScore = userTeam360Results?.teamScores?.overall;

    if (!yourScore || !averageScore) return null;
    const isExpanded = team360CardsExpanded?.["HowYouCompareWithYourTeammates"];
    const setIsExpanded = (value: boolean) => {
      dispatch(
        setTeam360CardsExpanded({
          teamId,
          cardTitle: "HowYouCompareWithYourTeammates",
          value,
        })
      );
    };

    return (
      <Card
        className="column-gap-20px"
        role={isExpanded ? undefined : "button"}
        onClick={() => {
          if (isExpanded) return;
          setIsExpanded(true);
        }}
      >
        <div className="d-flex justify-content-between align-items-start">
          <div className="column-gap-8px">
            <h2>How Do You Compare With Your Teammates?</h2>
            {isExpanded ? (
              <p>{teamAnalyticsText?.youVsYourTeammates?.text}</p>
            ) : null}
          </div>
          <div>
            <Button
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              variant="secondary-blue"
              className="border-0"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {!isExpanded ? "See details" : "Collapse"}
              <FontAwesomeIcon
                icon={`caret-${isExpanded ? "up" : "down"}`}
                className="ms-2"
              />
            </Button>
          </div>
        </div>
        <Collapse in={isExpanded}>
          <div className="column-gap-20px">
            <ShortCompareGraph
              bars={[
                {
                  label: "Your score for this team",
                  value: getTeam360Score(yourScore ?? 0),
                },
                {
                  label: "Average score by your teammates",
                  value: getTeam360Score(averageScore ?? 0),
                  dull: true,
                },
              ]}
            />
            <hr className="m-0" />
            <div>
              <Team360LowAgreementSkillCards
                score1={userTeam360Results?.teamScores}
                score2={userTeam360Results?.userScores}
              />
            </div>
            {teamAnalyticsText?.youVsYourTeammates?.tip ? (
              <div className="snapshot-box">
                <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
                <p>{teamAnalyticsText?.youVsYourTeammates?.tip}</p>
              </div>
            ) : null}
            <div>
              <Button
                variant="secondary-blue"
                onClick={() => setShowAgreementChart(true)}
              >
                See agreement report
              </Button>
            </div>
          </div>
        </Collapse>
      </Card>
    );
  };

  const getBody = () => {
    // Banner variables
    const hasPreviousInstances = totalInstances > 1;
    // If no instance were yet created or if the user does not have edit access then do not show the banner.
    const instanceBanner =
      availableSurveyInstances?.length && hasEditAccess ? (
        <InstanceWarningBanner
          instanceShowing={team360IntervalPicked}
          onConfigureResults={() => {
            setShowDateRangePickerModal(true);
          }}
          hasPreviousInstances={hasPreviousInstances}
          isLatestInstance={isLatestInstance}
          hasEnoughResults={hasEnoughResults}
        />
      ) : null;

    if (!hasEnoughResults) {
      return (
        <EmptyTeam360ResultsCard
          teamId={teamId}
          entityType="team"
          completionInfo={
            teamAnalyticsText?.completionInfo
              ? // If completionInfo exists then we want to add the scheduledAssessment to it.
                {
                  ...teamAnalyticsText?.completionInfo,
                  hasScheduledAssessment: displayStatus?.scheduledAssessment,
                }
              : undefined
          }
          hasEditAccess={hasEditAccess}
          onScheduleAssessment={() =>
            dispatch(showScheduleAssessmentModalForTeamId(teamId))
          }
          onShowLeaderOnboarding={() => {
            dispatch(
              setShowOnboardingModal({
                eventType: "team360LeadViewNotScheduledYet",
              })
            );
          }}
          isLoading={isLoading}
          instanceBanner={instanceBanner}
          isInstanceActive={
            !!assessmentInformation?.teams?.[teamId]?.activeAssessment
          }
          onSeeSurveySettings={() => {
            navigate(
              `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=surveys`
            );
          }}
          noPermission={
            !hasPermissionToViewReport(
              teamVisibilitySettings?.["Team TEAMscan"],
              !!isAdmin,
              !!isUserATeamLeader,
              !!isUserPartOfTeam
            )
          }
        />
      );
    }

    // This following is logic is to show different views for teams that are on the team vs how department leader or admin views the team.
    if (isUserPartOfTeam) {
      return (
        <>
          {instanceBanner}
          <Card className="team-360-full-page-card">
            <div className="column-gap" style={{ gap: "40px" }}>
              <div className="team-360-full-page-card__section">
                {getCardHeader()}
                {getResultsOverview()}
              </div>

              <div className="team-360-full-page-card__section">
                <h2 className="team-360-divider-title">
                  Where Do You Agree or Disagree with Teammates?
                </h2>
                {getHowYouCompareWithYourTeammates()}
              </div>
              <div className="team-360-full-page-card__section">
                <h2 className="team-360-divider-title">
                  What Can You Do Next?
                </h2>
                <WhatSkillsCanYourTeamWorkOn
                  strengthsAndWeaknesses={
                    teamAnalyticsText?.strengthsAndWeaknesses?.dimensions
                  }
                  team360Scores={allTeam360Scores?.teamScores[teamId]}
                  isExpanded={
                    team360CardsExpanded?.["WhatSkillsCanYourTeamWorkOn"]
                  }
                  setIsExpanded={(value) =>
                    dispatch(
                      setTeam360CardsExpanded({
                        teamId,
                        cardTitle: "WhatSkillsCanYourTeamWorkOn",
                        value,
                      })
                    )
                  }
                  title="What Skills Can Your Team Work On?"
                />
                <Team360WhoCanYouTalkTo
                  strengths={teamAnalyticsText?.conversationPartners?.strengths}
                  weaknesses={
                    teamAnalyticsText?.conversationPartners?.weaknesses
                  }
                  isExpanded={team360CardsExpanded?.["Team360WhoCanYouTalkTo"]}
                  setIsExpanded={(value) =>
                    dispatch(
                      setTeam360CardsExpanded({
                        teamId,
                        cardTitle: "Team360WhoCanYouTalkTo",
                        value,
                      })
                    )
                  }
                />
              </div>
              <div className="team-360-full-page-card__section">
                <h2 className="team-360-divider-title">More Insights</h2>
                {getHowYourTeamComparesInThisOrganization()}
                <TeamReflectionCard
                  entityType={"team"}
                  teamReflectionSummary={
                    teamAnalyticsText?.teamReflectionSummary ?? null
                  }
                  isCollapseByDefault
                />
                <FreeTextSummary teamId={teamId} />
              </div>
            </div>
          </Card>
        </>
      );
    } else if (userIsAnAdminOrDepartmentLeader) {
      return (
        <>
          {instanceBanner}
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {getTeamMemberLeaderViewBanner()}
              {getCardHeader()}
              {getResultsOverview()}
            </div>
          </Card>
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {getDepartmentLeaderAdminViewBanner()}
              <h2 className="team-360-divider-title">
                How Can You Help This Team?
              </h2>
              <WhatSkillsCanYourTeamWorkOn
                title="How Can You Help This Team?"
                strengthsAndWeaknesses={
                  teamAnalyticsText?.strengthsAndWeaknesses?.dimensions
                }
                team360Scores={getTeamScore()}
                stepsText={[
                  "Select either focus on growth areas or build on a strength to get ideas.",
                  "Choose 1-2 skills to help this team focus on doing or discussing over the next 2 weeks.",
                ]}
                twoSkillsToWorkOnText={
                  "Two skills you might suggest this team to focus on:"
                }
                isExpanded={
                  team360CardsExpanded?.["WhatSkillsCanYourTeamWorkOn"]
                }
                setIsExpanded={(value) =>
                  dispatch(
                    setTeam360CardsExpanded({
                      teamId,
                      cardTitle: "WhatSkillsCanYourTeamWorkOn",
                      value,
                    })
                  )
                }
              />
              <Team360WhatConversationsCanYouSuggest
                firstDropdownSelectedOption={
                  teamSuggestedConversation?.activeUserAccountId
                }
                secondDropdownSelectedOption={
                  teamSuggestedConversation?.activeComparedTo
                }
                storedConversationPartners={
                  teamSuggestedConversation?.conversationToBeHad?.[
                    teamSuggestedConversation?.activeUserAccountId
                  ]?.[teamSuggestedConversation?.activeComparedTo]
                }
                teamLeaderIds={teamInfo?.teamLeadUserAccountIds}
                teamMemberIds={teamInfo?.teamMemberIds}
                teamId={teamId}
                isLoading={getTeamSuggestedConversationsStatus === "loading"}
                dateRangeInstance={{
                  startDate: team360IntervalPicked?.startDate,
                  endDate: team360IntervalPicked?.endDate,
                  instance: team360IntervalPicked?.instance ?? undefined,
                }}
                isExpanded={
                  team360CardsExpanded?.[
                    "Team360WhatConversationsCanYouSuggest"
                  ]
                }
                setIsExpanded={(value) =>
                  dispatch(
                    setTeam360CardsExpanded({
                      teamId,
                      cardTitle: "Team360WhatConversationsCanYouSuggest",
                      value,
                    })
                  )
                }
              />
            </div>
          </Card>
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {getTeamMemberLeaderViewBanner()}
              <h2 className="team-360-divider-title">More Insights</h2>
              {getHowYouCompareWithYourTeammates()}
              {getHowYourTeamComparesInThisOrganization()}
              <TeamReflectionCard
                entityType={"team"}
                teamReflectionSummary={
                  teamAnalyticsText?.teamReflectionSummary ?? null
                }
                isCollapseByDefault
              />
              <FreeTextSummary teamId={teamId} />
            </div>
          </Card>
        </>
      );
    } else {
      return (
        <>
          {instanceBanner}
          <Card className="team-360-full-page-card">
            <div className="team-360-full-page-card__section">
              {getTeamMemberLeaderViewBanner()}
              {getCardHeader()}
              {getResultsOverview()}
              <h2 className="team-360-divider-title">More Insights</h2>
              {getHowYouCompareWithYourTeammates()}
              {getHowYourTeamComparesInThisOrganization()}
              <TeamReflectionCard
                entityType={"team"}
                teamReflectionSummary={
                  teamAnalyticsText?.teamReflectionSummary ?? null
                }
                isCollapseByDefault
              />
              <FreeTextSummary teamId={teamId} />
            </div>
          </Card>
        </>
      );
    }
  };

  const onCoachBotClick = () => {
    const {
      coachBotIntro,
      preSelectedPrompts: userPrompts,
      chatType,
    } = TEAM_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE;
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt({
        coachBotIntro,
        userPrompts,
        chatType,
        chatTypeId: teamId,
        type: "teamScan",
        report: teamAnalyticsText,
        comments: teamComments,
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  const getBottomRightElementForInstanceStickyFooter = () => {
    if (getTeamAnalyticsTextStatus !== "succeeded") return null;

    if (companySettings?.aiFeaturesEnabled && hasEnoughResults) {
      return (
        <div>
          <CustomChatbotButton
            onCoachbotClick={onCoachBotClick}
            text="Want help with these results?"
            className="assistant-bubble floating"
            includeButton
          />
        </div>
      );
    }

    if (hasEditAccess) {
      return (
        <Button onClick={() => setShowDateRangePickerModal(true)}>
          Configure results
        </Button>
      );
    }

    return null;
  };

  const getTeamScore = () => {
    if (isSampleTeam) {
      return allTeam360Scores?.sampleTeamScores[teamId];
    }

    return allTeam360Scores?.teamScores[teamId];
  };

  const getCompanyScore = () => {
    if (isSampleTeam) {
      return allTeam360Scores?.sampleCompanyScores;
    }
    return allTeam360Scores?.companyScores;
  };

  const teamInfo = teamsByTeamId[teamId];
  const isSampleTeam = teamInfo?.isSampleTeam;
  const teamScore = getTeamScore();
  const totalInstances = availableSurveyInstances?.length ?? 0;
  const isLatestInstance = !!(
    !team360IntervalPicked ||
    (totalInstances &&
      availableSurveyInstances?.[0]?.endDate ===
        team360IntervalPicked?.endDate &&
      availableSurveyInstances?.[0]?.startDate ===
        team360IntervalPicked?.startDate)
  );
  const isUserPartOfTeam = !!teamInfo?.teamMemberIds?.includes(
    currentUserAccountId ?? 0
  );
  const userIsAnAdminOrDepartmentLeader =
    isAdmin ||
    (teamInfo?.departmentId &&
      !!teamAndDepartmentLeaderOf?.departments?.find(
        (departmentId) => departmentId === teamInfo?.departmentId
      ));
  const totalCompleted = teamAnalyticsText?.completionInfo?.totalCompleted ?? 0;
  const hasEnoughResults =
    (displayStatus?.enoughResults || totalCompleted > 2) &&
    !!(teamScore?.overall ?? userTeam360Results?.teamScores?.overall) &&
    !!teamAnalyticsText;

  const latestInstance = useMemo(() => {
    // Will save the availableSurveyInstances inside of new array,
    // then sort the array by startDate in descending order
    // then return the first element in the array.
    return [...(availableSurveyInstances ?? [])].sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    })[0];
  }, [availableSurveyInstances]);

  return (
    <>
      <DateRangePicker
        show={showDateRangePickerModal}
        onHide={() => setShowDateRangePickerModal(false)}
        isLoading={updatingTeam360DateRangeStatus === "loading"}
        onSubmit={({ startDate, endDate, instanceType, instance }) => {
          dispatch(
            updateTeam360DateRange({
              teamId,
              startDate,
              endDate,
              instanceType,
              instance,
              throwErrorIfNoOverview: true,
            })
          );
        }}
        defaultInstanceSelected={{
          startDate: team360IntervalPicked?.startDate,
          endDate: team360IntervalPicked?.endDate,
        }}
        availableInstances={availableSurveyInstances ?? []}
        failedToUpdate={updatingTeam360DateRangeStatus === "failed"}
        onResetFailedToUpdate={() => {
          dispatch(resetUpdateTeam360DateRangeStatus());
        }}
      />
      <Team360ComparisonModal
        show={showComparisonModal}
        onHide={() => setShowComparisonModal(false)}
        teamId={teamId}
      />
      <Team360ScoreBreakdownModal
        show={showScoreBreakdown}
        modalSearchParam="showScoreBreakdown"
        onHide={() => {
          setShowScoreBreakdown(false);
          navigate(-2); // This is needed so that when the user clicks on the back button it does not open the modal again.
        }}
        teamId={teamId}
        instance={team360IntervalPicked?.instance ?? undefined}
        startDate={team360IntervalPicked?.startDate}
        endDate={team360IntervalPicked?.endDate}
      />
      <Team360AgreementReportModal
        show={showAgreementChart}
        onHide={() => setShowAgreementChart(false)}
        usersScore={userTeam360Results?.userScores}
        comparedUserScore={userTeam360Results?.teamScores}
        comparedUserAvatar={
          <AvatarCircle
            size="small"
            profilePicture={teamInfo?.profilePicture}
            avatarColor={teamInfo?.avatarColor}
            name={teamInfo?.teamName}
          />
        }
      />
      <Team360CommentsByYourTeamModal
        onHide={() => setShowTeamCommentModal(false)}
        show={showTeamCommentModal}
        teamId={teamId}
        teamComments={teamComments}
      />
      {getBody()}
      <TeamScanActionChecklistDrawer
        isOpen={isChecklistDrawerOpen}
        setIsOpen={(incomingBool) => setIsChecklistDrawerOpen(incomingBool)}
        onSeeCommentsClick={
          hasEditAccess || isSampleTeam
            ? () => setShowTeamCommentModal(true)
            : undefined
        }
        isLeader={isUserATeamLeader}
        type="Team"
      />
      <InstanceStickyFooter
        instanceType={team360IntervalPicked?.instanceType}
        startDate={team360IntervalPicked?.startDate}
        endDate={team360IntervalPicked?.endDate}
        latestAssessmentEndDate={
          latestInstance?.startDate ??
          teamAnalyticsText?.latestAssessmentEndDate
        }
        hideFooter={
          !(isSampleTeam || isUserPartOfTeam || userIsAnAdminOrDepartmentLeader)
        }
        bottomRightElement={getBottomRightElementForInstanceStickyFooter()}
      />
    </>
  );
}
