import { FC, useState } from "react";
import { NavDropdown, NavDropdownProps } from "react-bootstrap";

interface HoverNavDropdownProps extends NavDropdownProps {}

const HoverNavDropdown: FC<HoverNavDropdownProps> = ({
  children,
  onSelect,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <NavDropdown
      {...props} // Spread all additional props to NavDropdown
      show={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onSelect={(key, e) => {
        setIsHovered(false);
        onSelect?.(key, e);
      }}
    >
      {children}
    </NavDropdown>
  );
};

export default HoverNavDropdown;
