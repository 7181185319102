import Button from "app/storybookComponents/Button";

interface Props {
  key?: string | number;
  notificationTitle: string | React.ReactNode;
  primaryActionButton?: {
    label: string;
    onClick: () => void | Promise<any>;
    disabled?: boolean;
  };
  secondaryActionButton?: {
    label: string;
    onClick: () => void | Promise<any>;
    disabled?: boolean;
  };
}

export default function NotificationCard({
  key,
  notificationTitle,
  primaryActionButton,
  secondaryActionButton,
}: Readonly<Props>) {
  const getActionButtons = () => {
    if (!primaryActionButton && !secondaryActionButton) {
      return null;
    }
    return (
      <div className="notification-buttons">
        <div className="row-gap-8px">
          {primaryActionButton && (
            <Button
              onClick={primaryActionButton.onClick}
              variant="secondary-blue"
              disabled={primaryActionButton.disabled}
            >
              {primaryActionButton.label}
            </Button>
          )}
          {secondaryActionButton && (
            <Button
              onClick={secondaryActionButton.onClick}
              variant="secondary-gray"
              className="border-0"
              disabled={secondaryActionButton.disabled}
            >
              {secondaryActionButton.label}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div key={key} className="notification">
      {typeof notificationTitle === "string" ? (
        <p>{notificationTitle}</p>
      ) : (
        notificationTitle
      )}
      {getActionButtons()}
    </div>
  );
}
