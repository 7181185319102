import React, { forwardRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  readonly message: string;
  readonly isFullScreen: boolean;
  readonly setMessage: (message: string) => void;
  readonly handleSendMessage: (message: string) => void;
  readonly disabled: boolean;
}

const Input = forwardRef<HTMLTextAreaElement, Props>(
  ({ message, isFullScreen, setMessage, handleSendMessage, disabled }, ref) => {
    useEffect(() => {
      // Type guard to check if ref is a MutableRefObject with a 'current' property
      if (ref && "current" in ref && ref.current) {
        const textarea = ref.current;
        textarea.style.height = "inherit"; // Reset height - allows shrink if text is deleted
        const computed = window.getComputedStyle(textarea);
        // Calculate the height
        const height =
          textarea.scrollHeight +
          parseInt(computed.getPropertyValue("border-top-width"), 10) +
          parseInt(computed.getPropertyValue("border-bottom-width"), 10);
        textarea.style.height = `${height}px`;
      }
    }, [message, ref]); // Only re-run if message changes

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <textarea
          placeholder="Type a response..."
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          onKeyDown={(event) => {
            if (event.key !== "Enter") return;
            event.preventDefault();
            if (event.shiftKey) {
              const target = event.target as HTMLTextAreaElement;
              const { selectionStart, selectionEnd } = target;
              const newText =
                message.substring(0, selectionStart) +
                "\n" +
                message.substring(selectionEnd);
              setMessage(newText);
              return;
            }
            handleSendMessage(message);
          }}
          className="chatbot-input"
          style={{
            width: isFullScreen ? "90%" : "320px",
            overflow: "hidden", // Prevent default scrollbar
            resize: "none", // Prevent manual resizing
          }}
          disabled={disabled}
          ref={ref} // Attach the ref passed from the parent component
        />
        <FontAwesomeIcon
          icon="arrow-right"
          onClick={() => handleSendMessage(message)}
          style={{
            marginLeft: isFullScreen ? "20px" : "10px",
            color: message ? "#202D63" : "#D9DEF4",
            cursor: message ? "pointer" : "default",
          }}
        />
      </div>
    );
  }
);

export default Input;
