import { Card } from "react-bootstrap";

interface CardWrapperProps {
  children: React.ReactNode;
  hideCard?: boolean;
  className?: string;
}
const CardWrapper = ({
  children,
  hideCard = false,
}: Readonly<CardWrapperProps>) => {
  if (hideCard) {
    return <>{children}</>;
  }

  return <Card className="card-wrapper">{children}</Card>;
};

export default CardWrapper;
