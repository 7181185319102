import { Card } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  logUserIn,
  resetPasswordLink,
  selectGetLoginStatus,
  selectResetPasswordLinkStatus,
  resetPasswordLinkStatus,
  getIdentityProvider,
  selectIdentityProviderStatus,
  resetIdentityProviderStatus,
  resetLoginStatus,
  selectIdentityProviderResponse,
} from "./slice";
import LoginV2 from "./LoginV2";
import { getSSOEnabledValue } from "./helpers";
import { REACT_APP_HIRESELECT_URL } from "utils/environmentVariables";
import useSessionStorageDeepLink from "./useSessionStorageDeepLink";

export default function Login() {
  const dispatch = useAppDispatch();
  useSessionStorageDeepLink("develop_deepLinkSSO");

  // ------------------ Selectors ------------------

  const identityProviderStatus = useAppSelector(selectIdentityProviderStatus);
  const loginUserStatus = useAppSelector(selectGetLoginStatus);
  const resetPasswordStatus = useAppSelector(selectResetPasswordLinkStatus);
  const identityProviderResponse = useAppSelector(
    selectIdentityProviderResponse
  );

  // ------------------ State ------------------

  const onEmailSubmit = (emailAddress: string) => {
    dispatch(getIdentityProvider({ emailAddress }));
  };
  const onPasswordSubmit = (emailAddress: string, password: string) => {
    dispatch(logUserIn({ emailAddress, password }));
  };
  const onSSOSubmit = (emailAddress: string) => {
    sessionStorage.setItem(
      "develop_deepLinkSSO",
      window.location.pathname + window.location.search
    );
    window.location.href = `${REACT_APP_HIRESELECT_URL}?develop_ssoEmail=${encodeURIComponent(
      emailAddress
    )}`;
  };

  const onUnCheckEmail = () => {
    dispatch(resetIdentityProviderStatus());
  };

  const onResetPassword = (emailAddress: string) => {
    dispatch(resetPasswordLink(emailAddress));
  };

  const resetPasswordError = () => {
    dispatch(resetLoginStatus());
  };

  const onResetPasswordResetStatus = () => {
    dispatch(resetPasswordLinkStatus());
  };

  const getProviderName = () => {
    if (!identityProviderResponse?.identityProviderName) return;
    return identityProviderResponse.identityProviderName.split("-")[0];
  };

  return (
    <div className="login-page">
      <Card>
        <LoginV2
          onEmailSubmit={onEmailSubmit}
          onPasswordSubmit={onPasswordSubmit}
          onUnCheckEmail={onUnCheckEmail}
          onSSOSubmit={onSSOSubmit}
          onResetPassword={onResetPassword}
          resetPasswordError={resetPasswordError}
          resetPasswordStatus={resetPasswordStatus}
          loginUserStatus={loginUserStatus}
          identityProviderStatus={identityProviderStatus}
          onResetPasswordResetStatus={onResetPasswordResetStatus}
          ssoSetting={getSSOEnabledValue(identityProviderResponse)}
          ssoProvider={getProviderName()}
        />
      </Card>
    </div>
  );
}
