import "react-image-crop/dist/ReactCrop.css";
import { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "app/components/ImageEditor/canvasPreview";
import { imgPreview } from "app/components/ImageEditor/imgPreview";
import { useDebounceEffect } from "utils/hooks";
import Button from "app/storybookComponents/Button";
import PhotoUploadIllustration from "resources/images/photo-upload.png";

// Inspiration: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface Props {
  modalShowing: "profilePicture" | "coverPhoto" | null;
  picture?: string | null;
  closeModal: () => void;
  isLoading?: boolean;
  updateGuidePhoto: (photo: string, imgSrc: string, imageName: string) => void;
  deleteGuidePhoto: () => void;
}

export default function UploadPictureModal({
  modalShowing,
  closeModal,
  picture,
  isLoading,
  updateGuidePhoto,
  deleteGuidePhoto,
}: Readonly<Props>) {
  // ---------------- Refs ----------------
  const fileInputRef = useRef<HTMLInputElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  // ---------------- State ----------------
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [imageName, setImageName] = useState("temp.png");

  // ---------------- Variable ----------------
  const aspect = modalShowing === "profilePicture" ? 1 : 900 / 200;

  const compressImage = (file: File, maxWidth: number) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const elem = document.createElement("canvas");
          // don't compress if the image is already smaller than the desired width
          const scaleFactor = img.width < maxWidth ? 1 : maxWidth / img.width;
          elem.width = img.width * scaleFactor;
          elem.height = img.height * scaleFactor;
          const ctx = elem.getContext("2d");
          ctx?.drawImage(img, 0, 0, elem.width, elem.height);
          ctx?.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            1
          );
        };
      };
      reader.onerror = (error) => reject(error);
    });

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }
    // check if the image is a profile picture
    const maxWidth = modalShowing === "profilePicture" ? 400 : 1200;
    const file = e.target.files[0];

    try {
      const blob = (await compressImage(file, maxWidth)) as Blob;
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        if (typeof base64data !== "string") {
          return;
        }
        setImgSrc(base64data);
      };
      setImageName(file.name);
    } catch (error) {
      console.error(error);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    const percentageCrop = centerAspectCrop(width, height, aspect || 16 / 9);
    const pixelCrop = convertToPixelCrop(percentageCrop, width, height);
    setCrop(percentageCrop);
    setCompletedCrop(pixelCrop);
  };

  useEffect(() => {
    if (modalShowing === null || !picture) {
      setImgSrc("");
      setCompletedCrop(undefined);
      return;
    }
    setImgSrc(`data:image/jpeg;base64,${picture}`);
  }, [modalShowing, picture]);

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const onSave = async () => {
    if (
      !previewCanvasRef.current ||
      !imgRef.current ||
      !completedCrop ||
      modalShowing === null
    ) {
      return;
    }

    const [, picture] = previewCanvasRef.current.toDataURL().split(",");

    const imgSrcPreview = await imgPreview(imgRef.current, completedCrop);
    updateGuidePhoto(picture, imgSrcPreview, imageName);
  };

  const onCloseModal = () => {
    setImgSrc("");
    closeModal();
    setCompletedCrop(undefined);
    const canvas = previewCanvasRef.current;
    const context = canvas?.getContext("2d");
    if (context && canvas) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const getUploadLoadPhotoButton = (showIllustration: boolean) => (
    <div className="Crop-Controls">
      {showIllustration ? (
        <img src={PhotoUploadIllustration} alt="Departments" />
      ) : null}
      <Button
        variant="secondary-blue"
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        Upload {imgSrc ? "New Photo" : "a Photo"}
      </Button>
    </div>
  );

  const getCropInstructions = () => {
    if (!imgSrc) {
      return null;
    }
    return (
      <div
        style={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
        className="grey-text"
      >
        Click and drag to select a portion of the photo to upload
      </div>
    );
  };

  const getModalBody = () => {
    if (!imgSrc) {
      return (
        <div className="upload-picture-modal-body upload">
          {getUploadLoadPhotoButton(true)}
        </div>
      );
    }

    return (
      <div className={`upload-picture-modal-body ${modalShowing}`}>
        <div>
          <div className="crop-container">
            <ReactCrop
              crop={crop}
              onChange={(c, percentCrop) => {
                setCompletedCrop(c);
                setCrop(percentCrop);
              }}
              onComplete={(c, percentCrop) => {
                setCompletedCrop(c);
                setCrop(percentCrop);
              }}
              aspect={aspect}
              className="crop-area"
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ height: "auto" }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
          <div
            onClick={() => deleteGuidePhoto()}
            className="remove-guide-photo-button"
            role="button"
          >
            Remove {photoType} Photo
          </div>
        </div>
        {/* If we are cropping the profile picture we show the preview of how it would look, on the right of the crop */}
        {modalShowing === "profilePicture" ? (
          <div className={`preview-container`}>
            {completedCrop ? (
              <div>
                <canvas className="preview-canvas" ref={previewCanvasRef} />
              </div>
            ) : null}
            <span>Preview</span>
            {getUploadLoadPhotoButton(false)}
          </div>
        ) : (
          <canvas
            className="preview-canvas"
            ref={previewCanvasRef}
            style={{ display: "none" }}
          />
        )}
      </div>
    );
  };

  if (modalShowing === null) {
    return null;
  }

  const photoType = modalShowing === "coverPhoto" ? "Background" : "Profile";

  return (
    <Modal
      size="lg"
      show={modalShowing !== null}
      className={`image-editing-modal ${modalShowing || ""}`}
    >
      <div className="modal-title-row">
        <h2>{`${imgSrc ? "Upload" : "Edit"} ${photoType} Photo`}</h2>
        <Button
          onClick={() => onCloseModal()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        id="upload-actual-btn"
        hidden
        ref={fileInputRef}
      />
      <div className="modal-border" />
      {getCropInstructions()}
      {getModalBody()}
      <div className="upload-picture-modal-footer">
        {/* Since we are not showing the preview screen for the cover photo we show the button to upload a new picture by the footer */}
        {modalShowing === "coverPhoto" &&
          imgSrc &&
          getUploadLoadPhotoButton(false)}
        <Button
          variant="secondary-blue"
          onClick={onCloseModal}
          style={{ border: "none" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          disabled={!imgSrc || !completedCrop || isLoading}
        >
          Save Image
        </Button>
      </div>
    </Modal>
  );
}
