import {
  configureStore,
  PreloadedState,
  combineReducers,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import counterReducer from "example/counter/counterSlice";
import hiddenAdminConsoleReducer from "app/containers/HiddenAdminConsole/slice";
import adminConsoleReducer from "app/containers/AdminConsole/slice";
import teamGuideReducer from "app/containers/TeamGuide/slice";
import companyGuideReducer from "app/containers/CompanyGuide/slice";
import userGuideReducer from "app/containers/UserGuide/slice";
import globalReducer from "app/containers/Global/slice";
import dashboardReducer from "app/containers/Dashboard/slice";
import advancedCsvUploadReducer from "app/containers/AdvancedCsvUpload/slice";
import skillGuideReducer from "app/containers/SkillsGuide/slice";
import accountReducer from "app/containers/AccountSetup/slice";
import loginReducer from "app/containers/Login/slice";
import assessmentReducer from "app/containers/Assessment/slice";
import modulesReducer from "app/components/Modules/slice";
import onBoardingReducer from "app/components/Onboarding/slice";
import globalNavbarReducer from "app/components/GlobalNavbar/slice";
import quoteReducer from "app/components/OpenAIModules/QuoteOfTheDay/slice";
import tipReducer from "app/components/OpenAIModules/TipOfTheDay/slice";
import aiModuleRatingReducer from "app/components/OpenAIModules/AIModuleRating/slice";
import affirmationReducer from "app/components/OpenAIModules/Affirmation/slice";
import feedbackReducer from "app/components/OpenAIModules/Feedback/slice";
import surveyDataInstanceSlice from "app/components/SurveyDataInstances/slice";
import notificationsReducer from "app/components/Notifications/slice";
import openAiGeneratorReducer from "app/components/OpenAIInternalTools/OpenAIGenerator/slice";
import modalsReducer from "app/components/Modals/slice";
import launchAssessmentModalReducer from "app/components/LaunchAssessmentModal/slice";
import openAIModulesReducer from "app/components/OpenAIInternalTools/OpenAIModules/slice";
import openAIModuleReducer from "app/components/OpenAIInternalTools/OpenAIModule/slice";
import profileCompleteReducer from "app/components/ProfileComplete/slice";
import breadCrumbsReducer from "app/components/BreadCrumbs/slice";
import skillsCoachReducer from "app/components/OpenAIModules/SkillCoach/slice";
import promptEditorReducer from "app/components/OpenAIInternalTools/PromptEditor/slice";
import universalVariablesReducer from "app/components/OpenAIInternalTools/UniversalVariables/slice";
import team360AssessmentReducer from "app/components/Team360Assessment/slice";
import quickActionReducer from "app/components/QuickActions/slice";
import workplaceInsightsReducer from "app/components/WorkplaceInsightsReport/slice";
import departmentInsightReportReducer from "app/containers/DepartmentInsightReport/slice";
import userSettingsReducer from "app/containers/UserSettings/slice";
import chatbotReducer from "app/containers/Chatbot/slice";
import linkResultModalReducer from "app/components/LinkResultsModal/slice";
import automaticTeamsReducer from "app/containers/AutomationSettings/slice";

// IMPORTANT WHEN ADDING A REDUCER WE ALWAYS NEED TO ADD THEM TO THE STORE OTHERWISE WHEN FETCHING FOR A REQUEST DOES NOT EXECUTE THE extraReducers
const rootReducer = combineReducers({
  global: globalReducer,
  quote: quoteReducer,
  onBoarding: onBoardingReducer,
  tip: tipReducer,
  counter: counterReducer,
  hiddenAdminConsole: hiddenAdminConsoleReducer,
  adminConsole: adminConsoleReducer,
  userGuide: userGuideReducer,
  teamGuide: teamGuideReducer,
  companyGuide: companyGuideReducer,
  accountSetup: accountReducer,
  modules: modulesReducer,
  rating: aiModuleRatingReducer,
  affirmation: affirmationReducer,
  feedback: feedbackReducer,
  openAiGenerator: openAiGeneratorReducer,
  modals: modalsReducer,
  openAIModules: openAIModulesReducer,
  openAIModule: openAIModuleReducer,
  profileComplete: profileCompleteReducer,
  breadCrumbs: breadCrumbsReducer,
  skillsCoach: skillsCoachReducer,
  promptEditor: promptEditorReducer,
  surveyDataInstance: surveyDataInstanceSlice,
  universalVariables: universalVariablesReducer,
  teamScanAssessment: team360AssessmentReducer,
  skillsGuide: skillGuideReducer,
  workplaceInsights: workplaceInsightsReducer,
  dashboard: dashboardReducer,
  assessment: assessmentReducer,
  login: loginReducer,
  notifications: notificationsReducer,
  departmentInsightReport: departmentInsightReportReducer,
  userSettings: userSettingsReducer,
  launchAssessmentModal: launchAssessmentModalReducer,
  chatbot: chatbotReducer,
  advancedCsvUpload: advancedCsvUploadReducer,
  globalNavbar: globalNavbarReducer,
  quickActions: quickActionReducer,
  linkResultsModal: linkResultModalReducer,
  automaticTeams: automaticTeamsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type AppDispatch = AppStore["dispatch"];
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
