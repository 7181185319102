import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import {
  TTeam360Dimension,
  Team360AssessmentResult,
  TTeam360Factor,
  isTeam360Factor,
} from "../types";
import Team360AssessmentResultsCard from "app/components/Team360Assessment/Team360AssessmentResultsCard";
import TeamAssessmentIcon from "app/components/Team360Assessment/TeamAssessmentIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "utils/redux/hooks";
import { selectTeamAnalyticsText } from "app/containers/TeamGuide/slice";
import { DimensionsAndFactorsTipsAndInterpretiveText } from "app/containers/TeamGuide/types";

interface Props {
  show?: boolean;
  hideModal: () => void;
  activeFactor: TTeam360Factor;
  tipsAndInterpretiveText?: null | DimensionsAndFactorsTipsAndInterpretiveText;
  departmentScores?: Team360AssessmentResult;
  teamScores?: Team360AssessmentResult;
  companyScores?: Team360AssessmentResult;
  userScores?: Team360AssessmentResult;
  compareTo?: "team" | "company";
  teamId?: number;
  modalDescription?: string | null;
}

export default function Team360ResultsModal({
  hideModal,
  show,
  activeFactor,
  teamScores,
  companyScores,
  userScores,
  compareTo = "team",
  teamId,
  tipsAndInterpretiveText,
  modalDescription,
  departmentScores,
}: Props) {
  const navigate = useNavigate();
  const teamAnalyticsText = useAppSelector(selectTeamAnalyticsText(teamId));
  const onFactorOrDimensionClick = (
    factorOrDimension: TTeam360Factor | TTeam360Dimension
  ) => {
    let stemPath = "/SkillsGuide?";
    // Only add teamId if it exists
    if (teamId) {
      stemPath += `teamId=${teamId}&`;
    }

    // If we are showing the factor view, don't show the dimension
    if (isTeam360Factor(factorOrDimension)) {
      navigate(`${stemPath}factor=${factorOrDimension}`);
    } else {
      navigate(
        `${stemPath}factor=${activeFactor}&dimension=${factorOrDimension}`
      );
    }
  };

  const description =
    modalDescription ??
    teamAnalyticsText?.overview?.scoreBreakDowns?.factors.find(
      ({ name }) => name === activeFactor
    )?.text ??
    "";

  return (
    <Modal show={show} onHide={hideModal} size="lg" className="simple-modal">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div className="small-square-icon">
            <TeamAssessmentIcon name={activeFactor} color="navy" />
          </div>
          <h2 className="mb-0">{activeFactor}</h2>
        </div>

        <Button
          onClick={() => hideModal()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <p>{description}</p>
      <Team360AssessmentResultsCard
        {...{
          userScores,
          teamScores,
          companyScores,
          compareTo,
          teamId,
          onFactorOrDimensionClick,
          activeFactor,
          tipsAndInterpretiveText,
          departmentScores,
        }}
      />
    </Modal>
  );
}
