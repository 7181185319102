import {
  TChecklistInstruction,
  TChecklist,
  TAllChecklistTemplates,
  TChecklistTemplate,
} from "./types";

// ----------------- ACTION CHECKLIST FOR TEAMSCAN(OrganizationGuide) ----------------- //

export const ORGANIZATION_GUIDE_ACTION_CHECKLIST_OVER_ALL_RESULTS_TEMPLATE: TChecklist =
  {
    1: "Look at your organization’s overall score. Does this reflect how you feel this organization is doing? Does this score feel too high or too low overall?",
    2: "Look at your organization’s highest scoring dimension. Think of 1-2 times your organization has done this well. What did you do? If you could do this more, what would be better?",
    3: "Look at your organization’s lowest scoring dimension. In what ways does this potentially limit your organization?",
  };

export const ORGANIZATION_GUIDE_ACTION_CHECKLIST_REVIEW_COMMENTS_TEMPLATE: TChecklist =
  {
    1: "What stands out to you looking at the comments?",
    2: "What comments do you most agree (or disagree) with?",
    3: "What’s 1 specific action you can take based on these comments?",
  };

export const ORGANIZATION_GUIDE_ACTION_CHECKLIST_SELF_REFLECTION_TEMPLATE: TChecklist =
  {
    1: "What have you done as a leader to help improve teamwork that you want to keep doing?",
    2: "What have you done as a leader that you want to try differently?",
    3: "What’s one change you can make to an existing meeting or process to improve how your organization is doing on its teamwork?",
  };

export const ORGANIZATION_GUIDE_ACTION_CHECKLIST_DISCUSSION_TEMPLATE: TChecklist =
  {
    1: "What are 2-3 potential things this organization (leadership group) wants to start doing or do more?",
    2: "What are 2-3 potential things this organization wants to stop doing?",
    3: "What’s 1 thing you’ll commit to as a group over the next 2 weeks? Why is this important? How will you connect this to an existing habit or process?",
  };

export const ORGANIZATION_GUIDE_ACTION_CHECKLIST_DISCUSSION_INSTRUCTIONS: TChecklistInstruction[] =
  [
    {
      title: "Discussion Ground Rules",
      descriptions: [
        "Allow 30-60 minutes for the discussion where everyone is focused and can give their full attention.",
        "Take a few minutes to make sure everyone is comfortable and knows the ground rules:",
      ],
      bulletPoints: [
        "This is a safe space to try and learn and improve as an organization",
        "Describe things without blaming",
        "Be future focused",
        "This is not a place for conflict resolution or arguing",
        "Take turns and invite everyone into the conversation",
        "As a conversation leader, ask “clarifying” and “extending” questions, summarize as needed, and encourage everyone to participate",
      ],
    },
  ];

// ----------------- ACTION CHECKLIST FOR TEAMSCAN(DepartmentGuide) ----------------- //
export const DEPARTMENT_GUIDE_ACTION_CHECKLIST_OVER_ALL_RESULTS_TEMPLATE: TChecklist =
  {
    1: "Look at your department’s overall score. Does this reflect how you feel this department is doing? Does this score feel too high or too low overall?",
    2: "Look at your department’s highest scoring dimension. Think of 1-2 times your department has done this well. What did you do? If you could do this more, what would be better?",
    3: "Look at your department’s lowest scoring dimension. In what ways does this potentially limit your department?",
  };

export const DEPARTMENT_GUIDE_ACTION_CHECKLIST_REVIEW_COMMENTS_TEMPLATE: TChecklist =
  {
    1: "What stands out to you looking at the comments?",
    2: "What comments do you most agree (or disagree) with?",
    3: "What’s 1 specific action you can take based on these comments?",
  };

export const DEPARTMENT_GUIDE_ACTION_CHECKLIST_SELF_REFLECTION_TEMPLATE: TChecklist =
  {
    1: "What have you done as a department leader that you want to keep doing?",
    2: "What have you done as a department leader that you want to try differently?",
    3: "What’s one change you can make to an existing meeting or process to improve how your department is doing?",
  };

export const DEPARTMENT_GUIDE_ACTION_CHECKLIST_DISCUSSION_TEMPLATE: TChecklist =
  {
    1: "What are 2-3 potential things this department (leadership group) wants to start doing or do more?",
    2: "What are 2-3 potential things this department wants to stop doing?",
    3: "What’s 1 thing you’ll commit to as a group over the next 2 weeks? Why is this important? How will you connect this to an existing habit or process?",
  };

export const DEPARTMENT_GUIDE_ACTION_CHECKLIST_DISCUSSION_INSTRUCTIONS: TChecklistInstruction[] =
  [
    {
      title: "Discussion Ground Rules",
      descriptions: [
        "Allow 30-60 minutes for the discussion where everyone is focused and can give their full attention.",
        "Take a few minutes to make sure everyone is comfortable and knows the ground rules:",
      ],
      bulletPoints: [
        "A safe space to try and learn and improve as a department",
        "Describe things without blaming",
        "Be future focused",
        "This is not a place for conflict resolution or arguing",
        "Take turns and invite everyone into the conversation",
        "As a conversation leader, ask “clarifying” and “extending” questions, summarize as needed, and encourage everyone to participate",
      ],
    },
  ];

// ----------------- ACTION CHECKLIST FOR TEAMSCAN(TeamGuide) ----------------- //
export const TEAM_GUIDE_ACTION_CHECKLIST_OVER_ALL_RESULTS_TEMPLATE: TChecklist =
  {
    1: "Look at your team’s overall score. Does this reflect how you feel this team is doing? Does this score feel too high or too low overall?",
    2: "Look at your team’s highest scoring dimension. Think of 1-2 times your team has done this well. What did you do? If you could do this more, what would be better?",
    3: "Look at your team’s lowest scoring dimension. In what ways does this potentially limit your team?",
  };
export const TEAM_GUIDE_ACTION_CHECKLIST_REVIEW_COMMENTS_TEMPLATE: TChecklist =
  {
    1: "What stands out to you looking at the comments?",
    2: "What comments do you most agree (or disagree) with?",
    3: "What’s 1 specific action you can take as a team based on these comments?",
  };

export const TEAM_GUIDE_ACTION_CHECKLIST_REVIEW_AGREEMENT_TEMPLATE: TChecklist =
  {
    1: "Review the three dimensions where you have relatively lower agreement with your teammates and then pick one to focus on.",
    2: "What are 2-3 potential reasons that you may have different views than your teammates on this dimension?",
    3: "In what ways is this disagreement healthy for the team? In what ways does it hurt performance?",
  };

export const TEAM_GUIDE_ACTION_CHECKLIST_SELF_REFLECTION_TEMPLATE: TChecklist =
  {
    1: "What have you done as a team leader or member that you want to keep doing?",
    2: "What have you done as a team leader or member that you want to try differently?",
    3: "What’s one change the team can make to an existing meeting or process to improve how your team is doing?",
  };

export const TEAM_GUIDE_ACTION_CHECKLIST_TEAM_DISCUSSION_TEMPLATE = {
  1: "What are 2-3 potential things this team (leadership group) wants to start doing or do more?",
  2: "What are 2-3 potential things this team wants to stop doing?",
  3: "What’s 1 thing you’ll commit to as a group over the next 2 weeks? Why is this important? How will you connect this to an existing habit or process?",
};

export const TEAM_GUIDE_ACTION_CHECKLIST_TEAM_DISCUSSION_INSTRUCTIONS: TChecklistInstruction[] =
  [
    {
      title: "Discussion Ground Rules",
      descriptions: [
        "Allow 30-60 minutes for the discussion where everyone is focused and can give their full attention.",
        "Take a few minutes to make sure everyone is comfortable and knows the ground rules:",
      ],
      bulletPoints: [
        "A safe space to try and learn and improve as a team",
        "Describe things without blaming",
        "Be future focused",
        "This is not a place for conflict resolution or arguing",
        "Take turns and invite everyone into the conversation",
        "As a conversation leader, ask “clarifying” and “extending” questions, summarize as needed, and encourage everyone to participate",
      ],
    },
  ];

// ----------------- ACTION CHECKLIST FOR TEAMSCAN ----------------- //
export const TEAM_GUIDE_ACTION_CHECKLIST_TEMPLATE: TChecklistTemplate = new Map(
  [
    [
      "Discussion Guidelines",
      {
        instructions: TEAM_GUIDE_ACTION_CHECKLIST_TEAM_DISCUSSION_INSTRUCTIONS,
      },
    ],
    [
      "Review Overall Results",
      {
        checklist: TEAM_GUIDE_ACTION_CHECKLIST_OVER_ALL_RESULTS_TEMPLATE,
      },
    ],
    [
      "Review Comments",
      {
        checklist: TEAM_GUIDE_ACTION_CHECKLIST_REVIEW_COMMENTS_TEMPLATE,
      },
    ],
    [
      "Review Agreement",
      {
        checklist: TEAM_GUIDE_ACTION_CHECKLIST_REVIEW_AGREEMENT_TEMPLATE,
      },
    ],
    [
      "Self-Reflect",
      {
        checklist: TEAM_GUIDE_ACTION_CHECKLIST_SELF_REFLECTION_TEMPLATE,
      },
    ],

    [
      "Commit to an Action",
      {
        checklist: TEAM_GUIDE_ACTION_CHECKLIST_TEAM_DISCUSSION_TEMPLATE,
      },
    ],
  ]
);

export const DEPARTMENT_GUIDE_ACTION_CHECKLIST_TEMPLATE: TChecklistTemplate =
  new Map([
    [
      "Discussion Guidelines",
      {
        instructions: DEPARTMENT_GUIDE_ACTION_CHECKLIST_DISCUSSION_INSTRUCTIONS,
      },
    ],
    [
      "Review Overall Results",
      {
        checklist: DEPARTMENT_GUIDE_ACTION_CHECKLIST_OVER_ALL_RESULTS_TEMPLATE,
      },
    ],
    [
      "Review Comments",
      {
        checklist: DEPARTMENT_GUIDE_ACTION_CHECKLIST_REVIEW_COMMENTS_TEMPLATE,
      },
    ],
    [
      "Reflect",
      {
        checklist: DEPARTMENT_GUIDE_ACTION_CHECKLIST_SELF_REFLECTION_TEMPLATE,
      },
    ],

    [
      "Commit to an Action",
      {
        checklist: DEPARTMENT_GUIDE_ACTION_CHECKLIST_DISCUSSION_TEMPLATE,
      },
    ],
  ]);

export const ORGANIZATION_GUIDE_ACTION_CHECKLIST_TEMPLATE: TChecklistTemplate =
  new Map([
    [
      "Discussion Guidelines",
      {
        instructions:
          ORGANIZATION_GUIDE_ACTION_CHECKLIST_DISCUSSION_INSTRUCTIONS,
      },
    ],
    [
      "Review Overall Results",
      {
        checklist:
          ORGANIZATION_GUIDE_ACTION_CHECKLIST_OVER_ALL_RESULTS_TEMPLATE,
      },
    ],
    [
      "Review Comments",
      {
        checklist: ORGANIZATION_GUIDE_ACTION_CHECKLIST_REVIEW_COMMENTS_TEMPLATE,
      },
    ],
    [
      "Reflect",
      {
        checklist: ORGANIZATION_GUIDE_ACTION_CHECKLIST_SELF_REFLECTION_TEMPLATE,
      },
    ],
    [
      "Commit to an Action",
      {
        checklist: ORGANIZATION_GUIDE_ACTION_CHECKLIST_DISCUSSION_TEMPLATE,
      },
    ],
  ]);

export const ACTION_CHECKLIST_TEMPLATE: TAllChecklistTemplates = {
  Team: TEAM_GUIDE_ACTION_CHECKLIST_TEMPLATE,
  Department: DEPARTMENT_GUIDE_ACTION_CHECKLIST_TEMPLATE,
  Organization: ORGANIZATION_GUIDE_ACTION_CHECKLIST_TEMPLATE,
};
