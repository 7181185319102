import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  FullTeamPersonalityReport,
  MemberScores,
  PendingTestsAndInvitationsData,
  SuperpowersAndHiddenStrengths,
  TCollaborationGuide,
  TManagerGuide,
  WorkplaceInsightsReport,
  EppSimilarityScores,
  EppCompletionRate,
} from "./types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import { getS, numberToWord } from "utils/helperFunctions";
import { removeTestTaker } from "app/containers/AdvancedCsvUpload/slice";

// ------------------ State Type/Structure ------------------
export interface WorkplaceInsightState {
  workPlaceInsightsReports: {
    [userAccountId: number]: WorkplaceInsightsReport | undefined;
  };
  managerGuides: {
    [userAccountId: number]: TManagerGuide;
  };
  collaborationGuides: {
    [userAccountId: number]: TCollaborationGuide;
  };
  superpowersAndHiddenStrengths: {
    [userAccountId: number]: SuperpowersAndHiddenStrengths;
  };
  teamPersonalityScores: {
    [teamId: number]: MemberScores | null;
  };
  teamPersonalityReports: {
    [teamId: number]: FullTeamPersonalityReport;
  };
  eppSimilarityScores: {
    [userAccountId: number]: EppSimilarityScores;
  };
  getSuperpowersAndWeaknessesStatus: {
    [userAccountId: number]: responseStatus;
  };
  getEppSimilarityScoresStatus: {
    [userAccountId: number]: responseStatus;
  };
  pendingTestsAndInvitations: PendingTestsAndInvitationsData | null;
  getTeamPersonalityScoresByIdStatus: {
    [teamId: number]: responseStatus;
  };
  getTeamPersonalityReportsStatus: responseStatus;
  getPendingTestsStatus: responseStatus;
  getCollaborationGuideStatus: responseStatus;
  getManagerGuideStatus: responseStatus;
  getWorkPlaceInsightsReportByUserIdStatus: {
    [userAccountId: number]: responseStatus;
  };
  newEventId: string | null;
  eppCompletionRate: EppCompletionRate | null;
  getEppCompletionRateStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: WorkplaceInsightState = {
  workPlaceInsightsReports: {},
  managerGuides: {},
  collaborationGuides: {},
  teamPersonalityScores: {},
  teamPersonalityReports: {},
  pendingTestsAndInvitations: null,
  superpowersAndHiddenStrengths: {},
  eppSimilarityScores: {},
  getSuperpowersAndWeaknessesStatus: {},
  getEppSimilarityScoresStatus: {},
  getTeamPersonalityScoresByIdStatus: {},
  getTeamPersonalityReportsStatus: "idle",
  getPendingTestsStatus: "idle",
  getCollaborationGuideStatus: "idle",
  getManagerGuideStatus: "idle",
  getWorkPlaceInsightsReportByUserIdStatus: {},
  newEventId: null,
  eppCompletionRate: null,
  getEppCompletionRateStatus: "idle",
};

// ------------------------------------ Gets ------------------------------------
export const getWorkPlaceInsightsReportByUserId = createAsyncThunk(
  "workplaceInsights/getWorkPlaceInsightsReportByUserId",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/workplaceInsights`;
    const getWorkPlaceInsightsReportByUserIdResponse = (await request(
      requestUrl
    )) as WorkplaceInsightsReport;

    if (Object.keys(getWorkPlaceInsightsReportByUserIdResponse).length === 0) {
      throw new Error("No workplace insights report found for this user");
    }

    return {
      userAccountId,
      response: getWorkPlaceInsightsReportByUserIdResponse,
    };
  },
  {
    condition: (userAccountId: number, { getState }) => {
      const {
        workplaceInsights: {
          getWorkPlaceInsightsReportByUserIdStatus,
          workPlaceInsightsReports,
        },
      } = getState() as RootState;

      if (
        workPlaceInsightsReports[userAccountId] ||
        getWorkPlaceInsightsReportByUserIdStatus[userAccountId] === "loading"
      ) {
        return false;
      }
    },
  }
);

export const getManagerGuide = createAsyncThunk(
  "workplaceInsights/getManagerGuide",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/managerGuide`;
    const getManagerGuideResponse = (await request(
      requestUrl
    )) as TManagerGuide;

    if (Object.keys(getManagerGuideResponse).length === 0) {
      throw new Error("No manager guide found for this user");
    }

    return {
      userAccountId,
      response: getManagerGuideResponse,
    };
  }
);

export const getManagerGuidePdf = createAsyncThunk(
  "workplaceInsights/getManagerGuidePdf",
  async (userAccountId: number, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/teaming/users/${userAccountId}/managerGuide/pdf`;
    const getManagerGuidePdfResponse = (await request(requestUrl)) as {
      data: {
        0: string;
        title: string;
      };
    };
    thunkAPI.dispatch(
      downloadPDF({
        rawPdf: getManagerGuidePdfResponse?.data?.[0],
        pdfName: getManagerGuidePdfResponse?.data?.title,
      })
    );
    return getManagerGuidePdfResponse;
  }
);

export const getCollaborationGuidePdf = createAsyncThunk(
  "workplaceInsights/getCollaborationGuidePdf",
  async (userAccountId: number, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/teaming/users/${userAccountId}/collaboration`;
    const getCollaborationGuidePdfResponse = (await request(requestUrl)) as {
      data: {
        0: string;
      };
    };

    // We pull the name from the global state.
    const {
      global: { usersInfoById },
    } = thunkAPI.getState() as RootState;

    const user = usersInfoById[userAccountId];
    const pdfName = `Collaboration Guide - ${user?.firstName || ""} ${
      user?.lastName || ""
    }`;

    thunkAPI.dispatch(
      downloadPDF({
        rawPdf: getCollaborationGuidePdfResponse?.data?.[0],
        pdfName,
      })
    );
    return getCollaborationGuidePdfResponse;
  }
);

export const getWorkplaceInsightsPdf = createAsyncThunk(
  "workplaceInsights/getWorkplaceInsightsPdf",
  async (userAccountId: number, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/teaming/users/${userAccountId}/workplaceInsights/format/pdf`;
    const getWorkplaceInsightsPdfResponse = (await request(requestUrl)) as {
      name: string;
      report: string;
    };

    thunkAPI.dispatch(
      downloadPDF({
        rawPdf: getWorkplaceInsightsPdfResponse.report,
        pdfName: getWorkplaceInsightsPdfResponse.name,
      })
    );
    return getWorkplaceInsightsPdfResponse;
  }
);

export const getCollaborationGuide = createAsyncThunk(
  "workplaceInsights/getCollaborationGuide",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/personality`;
    const getCollaborationGuideResponse = (await request(
      requestUrl
    )) as TCollaborationGuide;
    if (Object.keys(getCollaborationGuideResponse).length === 0) {
      throw new Error("No collaboration guide found for this user");
    }
    return {
      userAccountId,
      response: getCollaborationGuideResponse,
    };
  }
);

export const getPendingTests = createAsyncThunk(
  "workplaceInsights/getPendingTests",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/teaming/dashboard`;
    const getPendingTestsResponse = (await request(
      requestUrl
    )) as PendingTestsAndInvitationsData;
    return getPendingTestsResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        workplaceInsights: { getPendingTestsStatus },
      } = getState() as RootState;
      return getPendingTestsStatus !== "loading";
    },
  }
);

export const getTeamPersonalityReports = createAsyncThunk(
  "workplaceInsights/getTeamPersonalityReports",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/personalityReport`;
    const response = (await request(requestUrl)) as FullTeamPersonalityReport;
    return { response, teamId };
  }
);

export const getTeamPersonalityScores = createAsyncThunk(
  "workplaceInsights/getTeamPersonalityScores",
  async ({ teamId }: { teamId: number; forceRefresh?: boolean }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/personalityScores`;
    const getTeamPersonalityScoresResponse = (await request(
      requestUrl
    )) as MemberScores | null;

    return { response: getTeamPersonalityScoresResponse, teamId };
  },
  {
    condition: ({ teamId, forceRefresh }, { getState }) => {
      const {
        workplaceInsights: {
          getTeamPersonalityScoresByIdStatus,
          teamPersonalityScores,
        },
      } = getState() as RootState;

      if (
        (!teamPersonalityScores[teamId] &&
          getTeamPersonalityScoresByIdStatus[teamId] !== "loading") ||
        forceRefresh
      ) {
        return true;
      }
    },
  }
);

export const getEppCompletionRate = createAsyncThunk(
  "workplaceInsights/getEppCompletionRate",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/eppCompletionRate`;
    const getEppCompletionRateResponse = (await request(
      requestUrl
    )) as EppCompletionRate;
    return getEppCompletionRateResponse;
  }
);

export const inviteUsersToEpp = createAsyncThunk(
  "workplaceInsights/inviteUsersToEpp",
  async (userAccountIds: number[]) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/inviteUsersToTakeEPP`;
    const inviteUsersToEppResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ userAccountIds }),
    })) as { invitedUsers: number };
    return inviteUsersToEppResponse;
  }
);

export const inviteTeamsToEpp = createAsyncThunk(
  "workplaceInsights/inviteTeamsToEpp",
  async (teamIds: number[]) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/inviteTeamsToTakeEPP`;
    const inviteTeamsToEppResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ teamIds }),
    })) as { invitedTeams: number };
    return inviteTeamsToEppResponse;
  }
);

export const getSuperpowersAndHiddenStrengths = createAsyncThunk(
  "workplaceInsights/getSuperpowersAndHiddenStrengths",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/superPowerAndHiddenStrength`;
    const getSuperpowersAndHiddenStrengthsResponse = (await request(
      requestUrl
    )) as SuperpowersAndHiddenStrengths;

    return {
      response: getSuperpowersAndHiddenStrengthsResponse,
      userAccountId,
    };
  },
  {
    condition: (userAccountId: number, { getState }) => {
      const {
        workplaceInsights: { getSuperpowersAndWeaknessesStatus },
      } = getState() as RootState;
      return getSuperpowersAndWeaknessesStatus[userAccountId] !== "loading";
    },
  }
);

export const getEppSimilarityScores = createAsyncThunk(
  "workplaceInsights/getEppSimilarityScores",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/eppSimilarityScores`;
    const getEppSimilarityScoresResponse = (await request(
      requestUrl
    )) as EppSimilarityScores;
    return { response: getEppSimilarityScoresResponse, userAccountId };
  },
  {
    condition: (userAccountId: number, { getState }) => {
      const {
        workplaceInsights: {
          getEppSimilarityScoresStatus,
          eppSimilarityScores,
        },
      } = getState() as RootState;
      return (
        getEppSimilarityScoresStatus[userAccountId] !== "loading" ||
        !!eppSimilarityScores[userAccountId]
      );
    },
  }
);

// ------------------------------------ Posts ------------------------------------
export const remindMembersFromTeamToTakePersonalityTest = createAsyncThunk(
  "workplaceInsights/remindMembersFromTeamToTakePersonalityTest",
  async ({
    teamIds,
    customMessage = null,
  }: {
    teamIds: number[];
    customMessage?: string | null;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/inviteTeamsToTakeEPP`;
    const response = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ teamIds, customMessage }),
    });
    return response;
  }
);

// ------------------------------------ Puts ------------------------------------
// ------------------------------------ Deletes ------------------------------------
export const unlinkTestTaker = createAsyncThunk(
  "workplaceInsights/unlinkTestTaker",
  async (userAccountId: number | undefined, { getState, dispatch }) => {
    const {
      global: { currentUserAccountId },
    } = getState() as RootState;
    const userId = userAccountId ?? currentUserAccountId;

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userId}/linkedTestTaker`;
    const response = (await request(requestUrl, {
      method: "DELETE",
    })) as {
      eventId?: string;
    };

    if (userId) {
      dispatch(removeTestTaker(userId));
    }

    return {
      isCurrentUser: userId === currentUserAccountId,
      eventId: response.eventId,
    };
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const workplaceInsightsSlice = createSlice({
  name: "workplaceInsights",
  initialState,
  reducers: {
    downloadPDF: (
      _,
      {
        payload: { rawPdf, pdfName },
      }: PayloadAction<{ rawPdf: string; pdfName: string }>
    ) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/pdf;base64,${rawPdf}`;
      downloadLink.download = pdfName;
      downloadLink.click();
      downloadLink.remove();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWorkPlaceInsightsReportByUserId.pending,
        (state, { meta: { arg } }) => {
          return {
            ...state,
            getWorkPlaceInsightsReportByUserIdStatus: {
              ...state.getWorkPlaceInsightsReportByUserIdStatus,
              [arg]: "loading",
            },
          };
        }
      )
      .addCase(
        getWorkPlaceInsightsReportByUserId.fulfilled,
        (
          state,
          action: PayloadAction<{
            userAccountId: number;
            response: WorkplaceInsightsReport;
          }>
        ) => {
          return {
            ...state,
            workPlaceInsightsReports: {
              ...state.workPlaceInsightsReports,
              [action.payload.userAccountId]: {
                ...state.workPlaceInsightsReports[action.payload.userAccountId],
                ...action.payload.response,
              },
            },
            getWorkPlaceInsightsReportByUserIdStatus: {
              ...state.getWorkPlaceInsightsReportByUserIdStatus,
              [action.payload.userAccountId]: "succeeded",
            },
          };
        }
      )
      .addCase(
        getWorkPlaceInsightsReportByUserId.rejected,
        (state, { meta: { arg } }) => {
          return {
            ...state,
            getWorkPlaceInsightsReportByUserIdStatus: {
              ...state.getWorkPlaceInsightsReportByUserIdStatus,
              [arg]: "failed",
            },
          };
        }
      )
      .addCase(getManagerGuide.pending, (state) => {
        state.getManagerGuideStatus = "loading";
      })
      .addCase(
        getManagerGuide.fulfilled,
        (
          state,
          action: PayloadAction<{
            userAccountId: number;
            response: TManagerGuide;
          }>
        ) => {
          return {
            ...state,
            getManagerGuideStatus: "succeeded",
            managerGuides: {
              ...state.managerGuides,
              [action.payload.userAccountId]: {
                ...state.managerGuides[action.payload.userAccountId],
                ...action.payload.response,
              },
            },
          };
        }
      )
      .addCase(getManagerGuide.rejected, (state) => {
        state.getManagerGuideStatus = "failed";
      })
      .addCase(getCollaborationGuide.pending, (state) => {
        state.getCollaborationGuideStatus = "loading";
      })
      .addCase(
        getCollaborationGuide.fulfilled,
        (
          state,
          action: PayloadAction<{
            userAccountId: number;
            response: TCollaborationGuide;
          }>
        ) => {
          return {
            ...state,
            getCollaborationGuideStatus: "succeeded",
            collaborationGuides: {
              ...state.collaborationGuides,
              [action.payload.userAccountId]: {
                ...state.collaborationGuides[action.payload.userAccountId],
                ...action.payload.response,
              },
            },
          };
        }
      )
      .addCase(getCollaborationGuide.rejected, (state) => {
        state.getCollaborationGuideStatus = "failed";
      })
      .addCase(getPendingTests.pending, (state) => {
        state.getPendingTestsStatus = "loading";
      })
      .addCase(
        getPendingTests.fulfilled,
        (state, { payload }: PayloadAction<PendingTestsAndInvitationsData>) => {
          return {
            ...state,
            getPendingTestsStatus: "succeeded",
            pendingTestsAndInvitations: payload,
          };
        }
      )
      .addCase(getPendingTests.rejected, (state) => {
        state.getPendingTestsStatus = "failed";
      })
      .addCase(
        getTeamPersonalityScores.rejected,
        (state, { meta: { arg } }) => {
          return {
            ...state,
            getTeamPersonalityScoresByIdStatus: {
              ...state.getTeamPersonalityScoresByIdStatus,
              [arg.teamId]: "failed",
            },
          };
        }
      )
      .addCase(getTeamPersonalityScores.pending, (state, { meta: { arg } }) => {
        return {
          ...state,
          getTeamPersonalityScoresByIdStatus: {
            ...state.getTeamPersonalityScoresByIdStatus,
            [arg.teamId]: "loading",
          },
        };
      })
      .addCase(
        getTeamPersonalityScores.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: MemberScores | null;
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            getTeamPersonalityScoresByIdStatus: {
              ...state.getTeamPersonalityScoresByIdStatus,
              [teamId]: "succeeded",
            },
            teamPersonalityScores: {
              ...state.teamPersonalityScores,
              [teamId]: response,
            },
          };
        }
      )
      .addCase(getTeamPersonalityReports.rejected, (state) => {
        state.getTeamPersonalityReportsStatus = "failed";
      })
      .addCase(getTeamPersonalityReports.pending, (state) => {
        state.getTeamPersonalityReportsStatus = "loading";
      })
      .addCase(
        getTeamPersonalityReports.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: FullTeamPersonalityReport;
            teamId: number;
          }>
        ) => ({
          ...state,
          getTeamPersonalityReportsStatus: "succeeded",
          teamPersonalityReports: {
            ...state.teamPersonalityReports,
            [teamId]: response,
          },
        })
      )
      .addCase(
        getSuperpowersAndHiddenStrengths.rejected,
        (state, { meta: { arg } }) => {
          return {
            ...state,
            getSuperpowersAndWeaknessesStatus: {
              ...state.getSuperpowersAndWeaknessesStatus,
              [arg]: "failed",
            },
          };
        }
      )
      .addCase(
        getSuperpowersAndHiddenStrengths.pending,
        (state, { meta: { arg } }) => {
          return {
            ...state,
            getSuperpowersAndWeaknessesStatus: {
              ...state.getSuperpowersAndWeaknessesStatus,
              [arg]: "loading",
            },
          };
        }
      )
      .addCase(
        getSuperpowersAndHiddenStrengths.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: SuperpowersAndHiddenStrengths;
            userAccountId: number;
          }>
        ) => {
          return {
            ...state,
            superpowersAndHiddenStrengths: {
              ...state.superpowersAndHiddenStrengths,
              [userAccountId]: response,
            },
            getSuperpowersAndWeaknessesStatus: {
              ...state.getSuperpowersAndWeaknessesStatus,
              [userAccountId]: "succeeded",
            },
          };
        }
      )
      .addCase(getEppSimilarityScores.rejected, (state, { meta: { arg } }) => {
        return {
          ...state,
          getEppSimilarityScoresStatus: {
            ...state.getEppSimilarityScoresStatus,
            [arg]: "failed",
          },
        };
      })
      .addCase(getEppSimilarityScores.pending, (state, { meta: { arg } }) => {
        return {
          ...state,
          getEppSimilarityScoresStatus: {
            ...state.getEppSimilarityScoresStatus,
            [arg]: "loading",
          },
        };
      })
      .addCase(
        getEppSimilarityScores.fulfilled,
        (
          state,
          {
            payload: { response, userAccountId },
          }: PayloadAction<{
            response: EppSimilarityScores;
            userAccountId: number;
          }>
        ) => {
          return {
            ...state,
            eppSimilarityScores: {
              ...state.eppSimilarityScores,
              [userAccountId]: response,
            },
            getEppSimilarityScoresStatus: {
              ...state.getEppSimilarityScoresStatus,
              [userAccountId]: "succeeded",
            },
          };
        }
      )
      .addCase(unlinkTestTaker.fulfilled, (state, { payload }) => {
        if (payload.isCurrentUser) {
          state.newEventId = payload.eventId ?? null;
        }
      })
      .addCase(getEppCompletionRate.fulfilled, (state, { payload }) => {
        state.eppCompletionRate = payload;
        state.getEppCompletionRateStatus = "succeeded";
      })
      .addCase(getEppCompletionRate.rejected, (state) => {
        state.getEppCompletionRateStatus = "failed";
      })
      .addCase(inviteUsersToEpp.fulfilled, (state, { payload }) => {
        const message = createSuccessMessage(payload.invitedUsers, "user");
        toast.success(message, successNotificationOptions);
      })
      .addCase(inviteTeamsToEpp.fulfilled, (state, { payload }) => {
        const message = createSuccessMessage(payload.invitedTeams, "team");
        toast.success(message, successNotificationOptions);
      });
  },
});

function createSuccessMessage(count: number, entity: string): string {
  const number = numberToWord(count);
  const s = getS(count);
  const invitations = entity === "team" ? "Invitations" : `Invitation${s}`;
  return `${invitations} successfully scheduled for ${number} ${entity}${s}`;
}

export const { downloadPDF } = workplaceInsightsSlice.actions;

// ------------------ Selectors ------------------
export const selectWorkPlaceInsightsReports = (state: RootState) =>
  state.workplaceInsights.workPlaceInsightsReports;
export const selectPendingTests = (state: RootState) =>
  state.workplaceInsights.pendingTestsAndInvitations?.pendingTests;
export const selectPendingInvitations = (state: RootState) =>
  state.workplaceInsights.pendingTestsAndInvitations?.pendingInvitations;
export const selectGetPendingTestsStatus = (state: RootState) =>
  state.workplaceInsights.getPendingTestsStatus;
export const selectWorkPlaceInsightsReportsById =
  (userAccountId: number) => (state: RootState) =>
    state.workplaceInsights.workPlaceInsightsReports[userAccountId];
export const selectManagerGuideById =
  (userAccountId: number) => (state: RootState) =>
    state.workplaceInsights.managerGuides[userAccountId];
export const selectCollaborationGuideById =
  (userAccountId: number) => (state: RootState) =>
    state.workplaceInsights.collaborationGuides[userAccountId];
export const selectAllTeamPersonalityReports = (state: RootState) =>
  state.workplaceInsights.teamPersonalityReports;
export const selectPersonalityScoresByTeamId =
  (teamId: number) => (state: RootState) =>
    state.workplaceInsights.teamPersonalityScores[teamId];
export const selectAllTeamPersonalityScores = (state: RootState) =>
  state.workplaceInsights.teamPersonalityScores;
export const selectGetTeamPersonalityScoresStatusByTeamId =
  (teamId: number) => (state: RootState) =>
    state.workplaceInsights.getTeamPersonalityScoresByIdStatus[teamId];

export const selectGetTeamPersonalityReportsStatus = (state: RootState) =>
  state.workplaceInsights.getTeamPersonalityReportsStatus;
export const selectTeamPersonalityReportsByTeamId =
  (teamId?: number) => (state: RootState) =>
    teamId ? state.workplaceInsights.teamPersonalityReports[teamId] : null;
export const selectGetSuperpowersAndHiddenStrengthsStatusById =
  (userAccountId?: number) => (state: RootState) => {
    if (!userAccountId) {
      return null;
    }
    return (
      state.workplaceInsights.getSuperpowersAndWeaknessesStatus[
        userAccountId
      ] ?? "idle"
    );
  };
export const selectSuperpowersAndHiddenStrengthsById =
  (userAccountId?: number) => (state: RootState) =>
    userAccountId
      ? state.workplaceInsights.superpowersAndHiddenStrengths[userAccountId]
      : null;
export const selectGetEppSimilarityScoresStatusById =
  (userAccountId?: number) => (state: RootState) => {
    if (!userAccountId) {
      return null;
    }
    return (
      state.workplaceInsights.getEppSimilarityScoresStatus[userAccountId] ??
      "idle"
    );
  };
export const selectEppSimilarityScoresById =
  (userAccountId?: number) => (state: RootState) =>
    userAccountId
      ? state.workplaceInsights.eppSimilarityScores[userAccountId]
      : null;
export const selectGetCollaborationGuideStatus = (state: RootState) =>
  state.workplaceInsights.getCollaborationGuideStatus;
export const selectGetManagerGuideStatus = (state: RootState) =>
  state.workplaceInsights.getManagerGuideStatus;
export const selectNewEventId = (state: RootState) => {
  return state.workplaceInsights.newEventId;
};
export const selectEppCompletionRate = (state: RootState) => {
  return state.workplaceInsights.eppCompletionRate;
};
export const selectGetEppCompletionRateStatus = (state: RootState) => {
  return state.workplaceInsights.getEppCompletionRateStatus;
};

export default workplaceInsightsSlice.reducer;
