import React, { useState, useRef, useEffect, useCallback } from "react";
import { Dropdown } from "react-bootstrap";

interface SearchableDropdownProps {
  items: string[];
  placeholder: string;
  onSelect: (value: string) => void;
  required?: boolean;
  forceDanger?: boolean;
  showDanger?: boolean;
  dangerClassName?: string;
  className?: string;
  displayText?: string;
  onUnFocus?: (updatedString?: string) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  items,
  placeholder,
  onSelect,
  showDanger,
  forceDanger,
  required,
  dangerClassName = "danger",
  className,
  displayText,
  onUnFocus,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<string[]>(items);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [hasBeenValidated, setHasBeenValidated] = useState(false);
  const [isMouseInsideDropdown, setIsMouseInsideDropdown] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  //-----------------------------------Callback hooks---------------------------------------------
  // this function should be triggered:
  // 1. when the user clicks on an item in the dropdown
  // 2. when the user presses enter
  // 3. when the user clicks outside the dropdown
  const onValidateInput = useCallback(
    (item: string) => {
      if (!showDanger) {
        return;
      }
      let newInvalid = false;
      if (!item && required) {
        newInvalid = true;
      }

      if (
        item &&
        items.some((i) => i.toLowerCase() === item.toLowerCase()) === false
      ) {
        newInvalid = true;
      }
      setIsInvalid(newInvalid);
      setHasBeenValidated(true);
    },
    [items, required, showDanger]
  );

  useEffect(() => {
    if (showDropdown) {
      searchInputRef.current?.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    if (displayText) {
      setSearchTerm(displayText);
    }
  }, [displayText]);

  useEffect(() => {
    // If the displayText is empty do nothing
    // If items is empty, do nothing
    // if both are not empty, then validate the input
    if (!displayText || !items.length) {
      return;
    }
    onValidateInput(displayText);
  }, [items, displayText, onValidateInput]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueLower = value.toLowerCase();
    setSearchTerm(value);
    const filtered = items.filter((item) =>
      item.toLowerCase().includes(valueLower)
    );
    setFilteredItems(filtered);
    setHasBeenValidated(false);
    setShowDropdown(true);
    setIsInvalid(false);
  };

  const handleSelect = (item: string | null) => {
    const incomingValue = item ?? searchTerm;
    onSelect(incomingValue);
    setSearchTerm(incomingValue);
    onValidateInput(incomingValue);
    setShowDropdown(false);
  };

  const handleToggle = (isOpen: boolean) => {
    setShowDropdown(isOpen);
  };

  const handleOnExitFocus = () => {
    if (isMouseInsideDropdown) {
      return;
    }
    if (!hasBeenValidated) {
      handleSelect(searchTerm);
    }

    onUnFocus?.(searchTerm);
    setShowDropdown(false);
  };

  const getDropdownMenuClassName = () => {
    let className = "searchable-dropdown-menu";

    // need this here so that we don't show an empty dropdown, could potentially change this to show a message instead
    if (!filteredItems.length) {
      return `${className} hide`;
    }
    return className;
  };

  const getDropdownClassName = () => {
    let dropdownClassName = "searchable-dropdown";

    if (isInvalid || forceDanger) {
      dropdownClassName += ` ${dangerClassName}`;
    }
    if (className) {
      dropdownClassName += ` ${dropdownClassName}`;
    }
    return dropdownClassName;
  };

  return (
    <Dropdown show={showDropdown} className={getDropdownClassName()}>
      <Dropdown.Toggle
        variant="secondary-blue"
        id="dropdown-basic"
        className="searchable-dropdown-toggle"
      >
        <input
          ref={searchInputRef}
          type="text"
          placeholder={placeholder}
          className="form-control"
          value={searchTerm}
          onChange={handleSearch}
          onClick={() => {
            handleToggle(true);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSelect(filteredItems?.[0] || null);
            }
          }}
          onBlur={handleOnExitFocus}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={getDropdownMenuClassName()}
        onMouseEnter={() => setIsMouseInsideDropdown(true)}
        onMouseLeave={() => setIsMouseInsideDropdown(false)}
      >
        {filteredItems.map((item, idx) => (
          <Dropdown.Item
            key={idx}
            onClick={() => {
              handleSelect(item);
              onUnFocus?.(item);
              setShowDropdown(false);
            }}
            eventKey={item}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchableDropdown;
