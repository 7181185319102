import { VALID_DASHBOARD_ROLES } from "./constants";

export interface AssessmentEvent {
  assessmentEventId: number;
  testId: number;
  userAccountId: number | null;
  teamId: number;
  dateStarted: string | null;
  dateCompleted: string | null;
  droppedOffAt: string | null;
  assessmentInvitationMessageId: number | null;
  customMessage: string | null;
  assessmentId: number;
  assessmentStartDate: string;
  frequency: string;
  inviteOrg: number;
  teamIds: number[] | null;
  userAccountIds: number[] | null;
  departmentIds: number[] | null;
  sentToText: string;
  frequencyText: string;
  startDate: string;
  endDate: string;
}

export interface DepartmentCardInfo {
  departmentSizeInfo: DepartmentInfo | null;
  departmentParticipationInfo: DepartmentInfo | null;
  departmentScoreInfo: DepartmentInfo | null;
}

export interface DepartmentInfo {
  thisDepartment: number;
  averageDepartment: number;
  insight: string;
  differenceType: string;
}

export interface PendingUser {
  emailAddress: string;
  userAccountId: number;
  firstName?: string;
  lastName?: string;
}

export interface PendingTeamLeader extends PendingUser {
  departmentId: number;
  isTeamLead?: number;
  ti_onboardingDepartmentId?: number;
  isReminded?: boolean;
}

export interface PendingDepartmentLeader extends PendingUser {
  departmentId: number;
}

export interface DepartmentTrackingEvent {
  id: number;
  departmentId: number;
  event: DepartmentEvent;
  userAccountId: number;
}

export interface AssessmentInfo {
  teamId: number;
  assessmentId: number;
  startDate: string;
  endDate: string;
  completionPercentage: number;
  status: string;
}

export interface AllDepartmentAssessmentInfo {
  [departmentId: number]: {
    departmentId: number;
    assessments: {
      [teamId: number]: AssessmentInfo;
    };
  };
}

export interface DepartmentResultsInfoByDepartmentId {
  [departmentId: number]: DepartmentResultsInfo;
}

export interface DepartmentResultsInfo {
  teamsWithEnoughResults: number[];
  teamsInDepartmentCount: number;
  departmentHasActiveAssessment: boolean;
}

export type DepartmentEvent =
  | "invitedTeamLeaders"
  | "launchedTeam360"
  | "dashboardTeamsInDepartmentPopup"
  | "dashboardTeam360ResultsPopup"
  | "dashboardTeam360ForDepartmentPopup";

export type ValidDashboardRole = (typeof VALID_DASHBOARD_ROLES)[number];
export const isDashboardRole = (role: any): role is ValidDashboardRole =>
  VALID_DASHBOARD_ROLES.includes(role as ValidDashboardRole);
