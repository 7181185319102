import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { Card, Nav } from "react-bootstrap";
import {
  SkillGuideNavSchema,
  TDimension,
  TSkill,
  isTDimensionProp,
  isTSkillProp,
} from "./types";
import {
  SKILL_GUIDE_TEMPLATE,
  CONSTRUCTS_GUIDE_TEMPLATE,
  CONSTRUCT_SKILLS_IN_THIS_AREA_KEY,
  SKILLS_COACHBOT_PROMPT,
} from "./constants";
import { getText } from "./helpers";
import AddSkillModal from "./Modals/AddSkillModal";
import EditSkillContentModal from "./Modals/EditSkillContentModal";
import {
  submitSkillFeedback,
  submitDimensionFeedback,
  selectSubmitSkillFeedbackStatus,
  selectDimensionSectionFeedbackSubmitted,
  selectSubmitDimensionFeedbackStatus,
  selectSkillSectionFeedbackSubmitted,
  selectUpdateSkillGuideStatus,
  updateSkillGuide,
  updateDimensionGuide,
  selectUpdateDimensionGuideStatus,
  selectSkillsGuide,
  selectHasLibraryEditAccess,
} from "./slice";
import { useNavigate } from "react-router-dom";
import {
  resetChatbotState,
  setCoachBotPrompt,
  setIsCoachbotOpen,
} from "app/containers/Chatbot/slice";
import CustomChatbotButton from "../Chatbot/CustomChatbotButton";

interface Props {
  factor: string;
  skill: TSkill | TDimension;
  type?: "Skill" | "Dimension";
  includeChatbotButton?: boolean;
}

const SkillPage = ({
  skill,
  type = "Skill",
  factor,
  includeChatbotButton,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const submitFeedbackStatus = useAppSelector(
    type === "Skill"
      ? selectSubmitSkillFeedbackStatus
      : selectSubmitDimensionFeedbackStatus
  );
  const feedbackSubmitted = useAppSelector(
    type === "Skill"
      ? selectSkillSectionFeedbackSubmitted
      : selectDimensionSectionFeedbackSubmitted
  );
  const updateSkillGuideStatus = useAppSelector(selectUpdateSkillGuideStatus);
  const updateDimensionGuideStatus = useAppSelector(
    selectUpdateDimensionGuideStatus
  );
  const userHasLibraryEditAccess = useAppSelector(selectHasLibraryEditAccess);
  const skillsGuide = useAppSelector(selectSkillsGuide);
  const [showEditSkillModal, setShowEditSkillModal] = useState<null | {
    propertyKey: string;
    propertyTitle: string;
  }>(null);
  const [showAddSkillModal, setShowAddSkillModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState<string>(
    Object.keys(SKILL_GUIDE_TEMPLATE)[0]
  );
  const [selectedSchema, setSelectedSchema] = useState<SkillGuideNavSchema>({});

  useLayoutEffect(() => {
    if (type === "Skill") {
      setSelectedSchema(SKILL_GUIDE_TEMPLATE);
    } else if (type === "Dimension") {
      setSelectedSchema(CONSTRUCTS_GUIDE_TEMPLATE);
    }
  }, [type]);

  useEffect(() => {
    setSelectedSection(Object.keys(selectedSchema)[0]);
  }, [selectedSchema]);

  useEffect(() => {
    if (updateSkillGuideStatus === "succeeded") {
      setShowEditSkillModal(null);
    }
  }, [updateSkillGuideStatus]);

  useEffect(() => {
    if (updateDimensionGuideStatus === "succeeded") {
      setShowEditSkillModal(null);
    }
  }, [updateDimensionGuideStatus]);

  const getNavContent = () => {
    if (!selectedSection) return null;
    let content: null | React.JSX.Element[] | React.JSX.Element = null;

    // Here we need to add a guard clause such that if the selectedSection is Skills in this area and the skill is a dimension, we need to render all of the skills in the dimension
    if (
      type === "Dimension" &&
      selectedSection === CONSTRUCT_SKILLS_IN_THIS_AREA_KEY &&
      "name" in skill
    ) {
      content = getDimensionSkillCards(skill.name);
    } else {
      content = selectedSchema[selectedSection]?.map((section) =>
        getSection(section)
      );
    }

    return <div className="skill-guide-content">{content}</div>;
  };

  const getDimensionSkillCards = (dimensionName: string) => {
    const returnArr: React.JSX.Element[] = [];
    if (skillsGuide[factor][dimensionName]) {
      Object.values(skillsGuide[factor][dimensionName]).forEach((skill) => {
        returnArr.push(getSkillsCard(skill));
      });
    }
    return <div className="skills-cards-container">{returnArr}</div>;
  };

  const onSkillClick = (skillObj: TSkill) =>
    navigate(
      `/SkillsGuide?factor=${skillObj.factor}&dimension=${skillObj.construct}&skillId=${skillObj.id}`
    );

  const getSkillsCard = (skillObj: TSkill) => (
    <Card key={skillObj.skill}>
      <h4>{skillObj.skill}</h4>
      <p>{skillObj.description}</p>
      <Button variant="secondary-blue" onClick={() => onSkillClick(skillObj)}>
        See skill guide <FontAwesomeIcon icon="arrow-right" />
      </Button>
    </Card>
  );

  const getSection = ({
    key,
    title,
    icon,
    isList,
    hasBoldedHeader,
  }: {
    title: string;
    key: string;
    icon?: IconName;
    isList?: boolean;
    hasBoldedHeader?: boolean;
  }) => {
    const text = getText(skill, key, isList, hasBoldedHeader);

    return (
      <div key={key} className="skill-guide-section">
        <div className="skill-guide-section-header">
          <div>
            {icon ? (
              <div className="small-square-icon">
                <FontAwesomeIcon icon={["fal", icon]} />
              </div>
            ) : null}
            <h2 style={{ color: "black" }}>{title}</h2>
          </div>
        </div>
        <div className="skill-guide-section-description">
          {typeof text === "string" ? <p>{text}</p> : text}
        </div>
        {userHasLibraryEditAccess ? (
          <div className="edit-circle-container">
            <div
              className="edit-circle"
              onClick={() =>
                setShowEditSkillModal({
                  propertyKey: key,
                  propertyTitle: title,
                })
              }
              role="button"
            >
              <FontAwesomeIcon icon="edit" />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const getQuote = () => {
    if ("quote" in skill) {
      return (
        <div
          className="row-gap-20px align-items-center"
          style={{ marginBottom: "16px" }}
        >
          <p>{skill.quote}</p>
          {userHasLibraryEditAccess ? (
            <div
              className="edit-circle"
              onClick={() =>
                setShowEditSkillModal({
                  propertyKey: "quote",
                  propertyTitle: "Quote",
                })
              }
              role="button"
            >
              <FontAwesomeIcon icon="edit" />
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const getNav = () => {
    const items = Object.keys(selectedSchema).map((key) => (
      <Nav.Item key={key}>
        <Nav.Link eventKey={key}>{key}</Nav.Link>
      </Nav.Item>
    ));
    return (
      <Nav
        className="simple-nav"
        activeKey={selectedSection ?? ""}
        onSelect={(tab) => {
          if (tab) setSelectedSection(tab);
        }}
        style={{ gap: "20px", marginBottom: "20px" }}
      >
        {items}
      </Nav>
    );
  };

  const getGuideTag = () => {
    if (type === "Skill") {
      return (
        <span
          className="module-type List"
          style={{ padding: "3px 4px", whiteSpace: "nowrap" }}
        >
          Skills Guide
        </span>
      );
    }
    if (type === "Dimension") {
      return (
        <span
          className="module-type FreeText"
          style={{ padding: "3px 4px", whiteSpace: "nowrap" }}
        >
          Dimension Guide
        </span>
      );
    }
    return null;
  };

  const onThumbClick = (isUp: boolean) => {
    if (type === "Skill") {
      dispatch(
        submitSkillFeedback({
          skillGuideId: skill.id,
          payload: {
            isHelpful: isUp ? 1 : 0,
            section: selectedSection,
          },
        })
      );
    } else if (type === "Dimension") {
      dispatch(
        submitDimensionFeedback({
          dimensionGuideId: skill.id,
          payload: {
            isHelpful: isUp ? 1 : 0,
            section: selectedSection,
          },
        })
      );
    }
  };

  const onEditSave = (updatedContent: string) => {
    if (!showEditSkillModal?.propertyKey) return;
    if (type === "Skill" && isTSkillProp(showEditSkillModal.propertyKey)) {
      dispatch(
        updateSkillGuide({
          payload: { [showEditSkillModal.propertyKey]: updatedContent },
          skillGuideId: skill.id,
        })
      );
    }

    if (
      type === "Dimension" &&
      isTDimensionProp(showEditSkillModal.propertyKey)
    ) {
      dispatch(
        updateDimensionGuide({
          payload: { [showEditSkillModal.propertyKey]: updatedContent },
          dimensionId: skill.id,
          dimensionName: (skill as TDimension).name,
        })
      );
    }
  };

  const onCoachBotClick = () => {
    const selectedPrompt = SKILLS_COACHBOT_PROMPT[type];
    dispatch(resetChatbotState());
    dispatch(
      setCoachBotPrompt({
        userPrompts: selectedPrompt.preSelectedPrompts ?? [],
        coachBotIntro: selectedPrompt.coachBotIntro,
        chatType: selectedPrompt.chatType ?? "",
        chatTypeId: skill.id,
      })
    );
    dispatch(setIsCoachbotOpen(true));
  };

  return (
    <div className="skills-page">
      {type === "Skill" ? (
        <AddSkillModal
          skill={skill as TSkill}
          showModal={showAddSkillModal}
          hideModal={() => setShowAddSkillModal(false)}
        />
      ) : null}
      <EditSkillContentModal
        showModal={!!showEditSkillModal}
        propertyKey={showEditSkillModal?.propertyKey}
        propertyTitle={showEditSkillModal?.propertyTitle}
        hideModal={() => setShowEditSkillModal(null)}
        skill={skill}
        onEditSave={onEditSave}
        isLoading={
          updateSkillGuideStatus === "loading" ||
          updateDimensionGuideStatus === "loading"
        }
      />
      <div
        className="row-gap-16px align-items-center"
        style={{ margin: "8px 0" }}
      >
        <h1>{skill.title}</h1>
        {getGuideTag()}
      </div>
      {getQuote()}
      {getNav()}
      <div className="skills-page-content">
        <div className="nav-content">
          {getNavContent()}
          <div className="buttons-container">
            {!feedbackSubmitted[skill.id]?.includes(selectedSection) ? (
              <div className="thumb-buttons">
                <Button
                  variant="secondary-blue"
                  onClick={() => onThumbClick(true)}
                  disabled={submitFeedbackStatus === "loading"}
                >
                  <FontAwesomeIcon icon="thumbs-up" /> I found this helpful
                </Button>
                <Button
                  variant="secondary-blue"
                  disabled={submitFeedbackStatus === "loading"}
                  onClick={() => onThumbClick(false)}
                >
                  <FontAwesomeIcon icon="thumbs-down" /> I didn't find this
                  helpful
                </Button>
              </div>
            ) : (
              <div>Thanks for your feedback</div>
            )}
          </div>
        </div>
      </div>
      {includeChatbotButton ? (
        <CustomChatbotButton
          onCoachbotClick={onCoachBotClick}
          text={`Need help understanding this ${
            type === "Skill" ? " skill" : " dimension"
          }?`}
        />
      ) : null}
    </div>
  );
};

export default memo(SkillPage);
