// This component will be used to take the TEAMscan assessment.
// The url will be /survey/team360?eventId=123
// If there is no eventId, then we will redirect to Dashboard
// If the is a change in eventId, then we need to start the assessment
// If the eventId is invalid, then we need to redirect to Dashboard (The way to verify if it is a valid eventId is to check the currentAssessments and see if any of them have the eventId)
// If the user hits then we just do history.back()
// Once the user is completed then if they have a history.back() then we do that, otherwise we redirect to Dashboard and open the TEAMscan Assessment success modal

import { useEffect, useLayoutEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Team360Assessment from "app/components/Team360Assessment";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { useAppDispatch } from "utils/redux/hooks";
import { getPendingAssessments } from "./slice";
import {
  getTEAMAssessmentItems,
  setIsSampleAssessment as setIsSampleAssessmentAction,
} from "app/components/Team360Assessment/slice";
import { reOpenScheduleAssessmentModal } from "app/components/LaunchAssessmentModal/slice";
import { setTemporarilyHideOnboardingModal } from "app/components/Onboarding/slice";

export default function Assessment() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname, key: locationKey } = useLocation();
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");
  const teamId = searchParams.get("teamId");

  // ------------ State Variables ------------
  const [assessmentType, setAssessmentType] = useState<string>("");
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isSampleAssessment, setIsSampleAssessment] = useState(false);

  // Example URL: /survey/team360?eventId=123
  // Might change "team360" to be the assessmentId or surveyId
  useLayoutEffect(() => {
    const [, , type] = pathname.split("/");
    if (type === "team360") {
      setAssessmentType(type);
    }
  }, [pathname]);

  useLayoutEffect(() => {
    if (eventId === "sample") {
      setIsSampleAssessment(true);
      dispatch(setIsSampleAssessmentAction(true));
    }
  }, [eventId, dispatch]);

  useEffect(() => {
    dispatch(getTEAMAssessmentItems());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPendingAssessments());
  }, [dispatch]);

  const onExitAssessment = () => {
    if (locationKey !== "default") {
      navigate(-1);
    } else {
      navigate("/");
    }

    // Only when the assessment is a sample assement do we want to set the isSampleAssessment to false
    if (isSampleAssessment) {
      dispatch(setIsSampleAssessmentAction(false));
      dispatch(reOpenScheduleAssessmentModal());
      dispatch(setTemporarilyHideOnboardingModal(false));
    }
  };

  const onSuccessfulExit = () => {
    const path = teamId ? `/TeamGuide/${teamId}?tab=TEAMscan` : "/";
    window.location.href = path;
  };

  const getAssessment = () => {
    if (assessmentType === "team360") {
      return (
        <Team360Assessment
          showWarningModal={() => setShowWarningModal(true)}
          onClose={() => setShowWarningModal(true)}
          isSampleAssessment={isSampleAssessment}
          closeWithOutWarning={() => onExitAssessment()}
          onSuccessfulExit={() => onSuccessfulExit()}
        />
      );
    }
  };

  const getWarningTitle = () => {
    if (assessmentType === "team360") {
      return "Are you sure you want to leave this survey?";
    }
    return "";
  };

  const getCustomWarningButtonText = () => {
    if (assessmentType === "team360") {
      return "Leave TEAMscan";
    }
    return "";
  };

  // If the assessment we are viewing does not exist as part of our pending assessments then we need to show an error message and redirect to Dashboard OR just redirect to Dashboard
  return (
    <div className="d-flex justify-content-center">
      <WarningModal
        isDanger
        modalTitle="Leave Survey"
        hideModal={() => setShowWarningModal(false)}
        isOpen={showWarningModal}
        warningTitle={getWarningTitle()}
        onConfirmClick={() => {
          onExitAssessment();
        }}
        warningMessage="If you leave, you will need to restart this survey. Your progress will not be saved."
        customButtonText={getCustomWarningButtonText()}
      />
      <div className="assessment-container">
        <Card>{getAssessment()}</Card>
        {/* <p className="assessment-copy-right-text">
          Copyright ©2020 Criteria Corp. |
          <AssessmentLink text="Copyright Notices" /> |
          <AssessmentLink text="Terms of Use" /> |
          <AssessmentLink text="Privacy Policy" />|
          <AssessmentLink text="Disability/Accommodations" />
        </p> */}
      </div>
    </div>
  );
}
