interface Props {
  title?: string;
  bodyText?: string;
  buttons?: JSX.Element;
}

export default function EmptyCard({
  title,
  bodyText,
  buttons,
}: Readonly<Props>) {
  return (
    <div className="empty-card">
      <div className="column-gap-12px">
        <span>
          <b>{title}</b>
        </span>
        <p>{bodyText}</p>
        {buttons ? (
          <div className="row-gap-6px justify-content-center">{buttons}</div>
        ) : null}
      </div>
    </div>
  );
}
