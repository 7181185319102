import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components, InputActionMeta } from "react-select";

interface Props {
  inputText: string | null;
  setInputText: (inputText: string) => void;
  options?: { value: string; label: string }[];
  noDropdownIndicator?: boolean;
  placeholder?: string;
}

const selectStyles = {
  control: (base: any) => ({
    ...base,
    flexDirection: "row-reverse",
  }),
  clearIndicator: (base: any) => ({
    ...base,
    position: "absolute",
    right: 0,
  }),
};

export default function SearchableInput({
  options,
  inputText,
  setInputText,
  noDropdownIndicator,
  placeholder,
}: Props) {
  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      setInputText(inputText);
    }
  };
  return (
    <Select
      options={options}
      isClearable={true}
      isSearchable={true}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: (props) =>
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon icon="search" />
            </components.DropdownIndicator>
          ),
      }}
      inputValue={inputText || ""}
      onInputChange={handleInputChange}
      styles={selectStyles}
      menuIsOpen={noDropdownIndicator ? false : undefined}
      placeholder={placeholder}
    />
  );
}
