import Button from "app/storybookComponents/Button";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { ConflictArea } from "./types";
import SectionHeader from "./SectionHeader";
import AvatarCircle from "app/components/AvatarCircle";

interface Props {
  readonly conflictAreas: ConflictArea[];
}

export default function TeamPotentialConflictAreas({ conflictAreas }: Props) {
  const [conflictAreaIndex, setConflictAreaIndex] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const conflictArea = conflictAreas[conflictAreaIndex];

  const toggleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const setNewConflictAreaIndex = () => {
    const newIndex = (conflictAreaIndex + 1) % conflictAreas.length;
    setConflictAreaIndex(newIndex);
  };

  if (!conflictAreas.length) {
    return null;
  }

  const numberOfPotentialConflicts = conflictAreas.length;
  const areaOrAreas = numberOfPotentialConflicts === 1 ? "area" : "areas";
  let reflectionQuestionsCount = 0;
  let actionItemsCount = 0;
  if (isCollapsed) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Learning Opportunities"
          isCollapsed={isCollapsed}
        />
      </Card>
    );
  }
  return (
    <Card
      style={{
        minHeight: "410px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="column-gap-20px">
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Learning Opportunities"
          isCollapsed={isCollapsed}
        />
        <p>
          These are combinations of teammates with wide differences in
          personality traits and how that may impact your team. Your team has{" "}
          {numberOfPotentialConflicts} learning opportunities {areaOrAreas}.
        </p>
        <div
          className="card-with-left-strip column-gap-8px dark-blue card"
          style={{ padding: "10px" }}
        >
          <div style={{ marginLeft: "10px" }}>
            <h3>{conflictArea.conflictArea.trait}</h3>
            <p>{conflictArea.conflictArea.description}</p>
            <div style={{ display: "flex" }}>
              <AvatarCircle userAccountId={conflictArea.userAccountId} />
              <AvatarCircle userAccountId={conflictArea.userAccountId2} />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Card style={{ padding: "10px", width: "49%" }}>
            <h3>Reflection Questions</h3>
            {conflictArea.conflictArea.reflectionQuestions.map(
              (question, index) => {
                reflectionQuestionsCount += 1;
                return (
                  <p key={reflectionQuestionsCount}>
                    {index + 1}. {question}
                  </p>
                );
              }
            )}
          </Card>
          <Card style={{ padding: "10px", width: "49%" }}>
            <h3>Action Items</h3>
            {conflictArea.conflictArea.actionItems.map((actionItem, index) => {
              actionItemsCount += 1;
              return (
                <p key={actionItemsCount}>
                  {index + 1}. {actionItem}
                </p>
              );
            })}
          </Card>
        </div>
        <Button
          onClick={setNewConflictAreaIndex}
          style={{ alignSelf: "flex-start" }}
          disabled={conflictAreas.length === 1}
          variant="secondary-blue"
        >
          See another learning opportunity
        </Button>
      </div>
    </Card>
  );
}
