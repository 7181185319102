import { UserInfo } from "app/containers/Global/types";

export const getChatWithDirectReportIntroMessage = (
  usersById: { [id: number]: UserInfo },
  userAccountId: number | string | null,
  subjectUserAccountId: number | string | null
) => {
  const user = usersById[Number(userAccountId)];
  const userName = `${user.firstName} ${user.lastName}`;

  const subjectUser = usersById[Number(subjectUserAccountId)];

  const subjectUserName = subjectUser
    ? `${subjectUser.firstName} ${subjectUser.lastName}`
    : "your direct report?";

  return `Hi ${userName}. How can I help you engage with ${subjectUserName}?`;
};
