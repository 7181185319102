import { CompanySettings } from "app/containers/AdminConsole/types";
import { UserVisibilitySetting, UserVisibilitySettings } from "./types";

export const getUserVisibilityBannerText = ({
  companySettings,
  userVisibilitySettings,
  isAdmin,
  isCurrentUser,
  usersName,
  userVisibilityKey,
  reportOrGuide = "report",
}: {
  companySettings: CompanySettings | null;
  userVisibilitySettings: UserVisibilitySettings | null;
  isAdmin: boolean;
  isCurrentUser: boolean;
  usersName: string;
  userVisibilityKey: keyof UserVisibilitySettings;
  reportOrGuide?: "report" | "guide";
}) => {
  const { allowVisibilityPermissionOverride } = companySettings || {};
  const userVisibilitySetting = userVisibilitySettings?.[userVisibilityKey];

  const visibilitySet = getUserVisibilitySetText({
    userVisibilitySetting,
    hideSelf: userVisibilityKey !== "Workplace Insights",
    selfName: usersName,
    isCurrentUser,
  });

  if (isAdmin) {
    return (
      <p>
        This {reportOrGuide} is visible to <b>{visibilitySet}</b>.{" "}
        {isCurrentUser && allowVisibilityPermissionOverride
          ? "You can change these visibility settings."
          : "As an admin, you can configure organization-wide visibility defaults."}
      </p>
    );
  }

  if (isCurrentUser) {
    return (
      <p>
        This {reportOrGuide} is visible to <b>{visibilitySet}</b>.{" "}
        {allowVisibilityPermissionOverride
          ? "You can change these visibility settings."
          : "Only admins can configure organization-wide visibility defaults."}
      </p>
    );
  }

  return (
    <p>
      This {reportOrGuide} is visible to <b>{visibilitySet}</b>.{" "}
      {allowVisibilityPermissionOverride
        ? `${usersName} can change these visibility settings. Additionally admins can configure organization-wide visibility defaults.`
        : "Only admins can configure organization-wide visibility defaults."}
    </p>
  );
};

export const getUserVisibilitySetText = ({
  userVisibilitySetting,
  hideSelf = false,
  selfName = "you",
  hideManager = false,
  isCurrentUser,
}: {
  userVisibilitySetting: UserVisibilitySetting | null | undefined;
  hideSelf?: boolean;
  selfName?: string;
  hideManager?: boolean;
  isCurrentUser?: boolean;
}) => {
  if (!userVisibilitySetting) {
    return "";
  }

  if (userVisibilitySetting["Organization Member"]) {
    return "everyone in my organization";
  }

  const visibilityArr: string[] = [];
  if (userVisibilitySetting.Self && !hideSelf) {
    visibilityArr.push(isCurrentUser ? "you" : selfName);
  }
  const prefix = isCurrentUser ? "your" : `${selfName}'s`;

  if (userVisibilitySetting.Manager && !hideManager) {
    visibilityArr.push(`${prefix} manager`);
  }
  if (userVisibilitySetting["Team Leader"]) {
    visibilityArr.push(`${prefix} team leader(s)`);
  }
  if (userVisibilitySetting["Team Member"]) {
    visibilityArr.push(`${prefix} team member(s)`);
  }
  if (userVisibilitySetting["Admin"]) {
    visibilityArr.push(`admin(s)`);
  }

  // on the last item inside of the array pop off, add "and" and push back on
  if (visibilityArr.length > 1) {
    visibilityArr.push(`and ${visibilityArr.pop()}`);
  }

  return visibilityArr.join(visibilityArr.length > 2 ? ", " : " ");
};

export const getYourVisibilityInTeamPersonalityBanner = ({
  userVisibilitySettings,
}: {
  userVisibilitySettings?: UserVisibilitySettings | null;
}) => {
  const userVisibilitySetting =
    userVisibilitySettings?.["Team Personality Continuum"];
  const visibilitySet = getUserVisibilitySetText({
    userVisibilitySetting,
    hideManager: true,
    hideSelf: true,
  });

  return (
    <p>
      Your personality traits are visible to this team's {visibilitySet} as well
      as admins. You can change these visibility settings.
    </p>
  );
};
