import { selectTeamsByTeamId } from "app/containers/Global/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import AvatarCircle from "../AvatarCircle";
import { getS } from "utils/helperFunctions";
import Button from "app/storybookComponents/Button";
import MultiCardCollapsibleContainer from "app/storybookComponents/CollapsibleCards/MultiCardCollapsibleContainer";
import { useNavigate } from "react-router-dom";
import SimpleModal from "../Modals/SimpleModal";
import { AssessmentResultsNotification } from "./types";
import {
  dismissNotification,
  selectAllAssessmentResultsNotifications,
  selectShowViewResultsReadyModal,
  setShowViewResultsReadyModal,
} from "./slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ViewResultsReadyModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const show = useAppSelector(selectShowViewResultsReadyModal);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const allAssessmentResultsNotifications = useAppSelector(
    selectAllAssessmentResultsNotifications
  );

  const onHide = () => {
    dispatch(setShowViewResultsReadyModal(false));
  };

  const getRows = () =>
    allAssessmentResultsNotifications.map((notification) =>
      getRow(notification)
    );

  const getRow = ({
    teamId,
    notificationId,
  }: AssessmentResultsNotification) => {
    const teamInfo = teamsById[teamId];
    if (!teamInfo) return null;
    const {
      teamName,
      profilePicture,
      avatarColor,
      teamMemberIds = [],
    } = teamInfo;

    return (
      <div className="my-team-360-card">
        <div className="my-team-360-card-action-info">
          <AvatarCircle
            profilePicture={profilePicture}
            avatarColor={avatarColor}
            name={teamName}
            size="small"
          />
          <div className="my-team-360-card-info-text">
            <p>
              <b>{teamName}</b>
            </p>
            <p className="small-body-text">
              {teamMemberIds.length} member{getS(teamMemberIds.length)}
            </p>
          </div>
        </div>
        <div className="my-team-360-card-action">
          <span className="results-ready-list-span">Results ready</span>
          <Button
            variant="secondary-blue"
            onClick={() => {
              navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
              dispatch(dismissNotification(notificationId));
              onHide();
            }}
            className="border-0 button-with-chevron nowrap"
          >
            View results <FontAwesomeIcon icon="chevron-right" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <SimpleModal
      show={show}
      onHide={onHide}
      title="Teams with new results available"
    >
      <p>These are the teams with new results available:</p>
      <MultiCardCollapsibleContainer
        items={getRows()}
        className="manage-survey-container"
      />
    </SimpleModal>
  );
}
