import { UserInfo } from "app/containers/Global/types";
import AvatarCircle from "../AvatarCircle";
import { GuideType } from "../Modules/types";
import { ReactNode } from "react";

export const getUserOption = (
  usersById: {
    [userAccountId: number | string]: UserInfo;
  },
  userAccountId: number
) => {
  if (!usersById[userAccountId]) return null;

  const user = usersById[userAccountId];
  return {
    label: `${user.firstName} ${user.lastName}`,
    value: String(user.userAccountId),
    avatarCircle: (
      <AvatarCircle
        name={`${user.firstName} ${user.lastName}`}
        userAccountId={user.userAccountId}
        size="small"
      />
    ),
    guideType: "user" as GuideType,
  };
};

export const getMemberOption = (
  memberInfo: {
    label: string;
    avatarCircle?: ReactNode;
  } | null
) => {
  if (!memberInfo) return null;
  const { label, avatarCircle } = memberInfo;
  if (!avatarCircle) {
    return (
      <div
        style={{
          padding: "4px",
          fontSize: "14px",
        }}
      >
        <span>{label}</span>
      </div>
    );
  }

  return (
    <div className="member-option" style={{ alignItems: "center" }}>
      {avatarCircle}
      <div className="member-info">
        <span className="member-name">{label}</span>
      </div>
    </div>
  );
};
