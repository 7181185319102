import { setCookieItem } from "./helperFunctions";
import Cookies from "js-cookie";
import {
  useState,
  useEffect,
  DependencyList,
  useCallback,
  useRef,
} from "react";

export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => Cookies.get(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());
  const updateCookie = (value: string, numberOfDays: number) => {
    setCookie(value);
    setCookieItem(key, value, numberOfDays);
  };
  return [cookie, updateCookie];
};

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      // @ts-ignore
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

// Custom debounce hook
export function useDebounceCallback<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
  immediate: boolean = false
): T {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const isInitialMount = useRef(true);

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      const invokeNow = immediate && isInitialMount.current;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (invokeNow) {
        callback(...args);
        isInitialMount.current = false;
      } else {
        timerRef.current = setTimeout(() => {
          callback(...args);
        }, delay);
      }
    },
    [callback, delay, immediate]
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return debouncedCallback as T;
}
