import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { OpenAIGeneratedText } from "app/components/OpenAIInternalTools/OpenAIModule/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";

export interface OpenAIModuleState {
  openAIGeneratedText: null | OpenAIGeneratedText;
  gettingTextGenerationresponseStatus: responseStatus;
}

const initialState: OpenAIModuleState = {
  openAIGeneratedText: null,
  gettingTextGenerationresponseStatus: "idle",
};

export const getGeneratedText = createAsyncThunk(
  "generateText",
  async (payload: { promptId: number; userInput: object }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/generate`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ payload }),
    })) as OpenAIGeneratedText;
  }
);

export const openAIModuleSlice = createSlice({
  name: "openAIModule",
  initialState,
  reducers: {
    resetGeneratedText: (state) => {
      state.openAIGeneratedText = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneratedText.pending, (state) => {
        state.gettingTextGenerationresponseStatus = "loading";
      })
      .addCase(
        getGeneratedText.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingTextGenerationresponseStatus: "succeeded",
            openAIGeneratedText: { ...action.payload },
          };
        }
      );
  },
});

export const selectGeneratedText = (state: RootState) =>
  state.openAIModule.openAIGeneratedText;

export const selectGettingTextGenerationresponseStatus = (state: RootState) =>
  state.openAIModule.gettingTextGenerationresponseStatus;

export const { resetGeneratedText } = openAIModuleSlice.actions;

export default openAIModuleSlice.reducer;
