import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import Toggle from "app/components/Toggle";
import { PLATFORM_NAME } from "utils/constants";

import {
  selectCvsUploadOptions,
  selectPreviewDataResponse,
  selectUploadingUsersViaCSVStatus,
} from "./slice";
import { useSelector } from "react-redux";
import { getS } from "utils/helperFunctions";

interface Props {
  onReviewPeople: () => void;
  isInviteAllMembersToggleOn?: boolean;
  setInviteAllMembersToggle?: (value: boolean) => void;
  onConfirm: () => void;
}

export default function PeopleConfirmationScreen({
  onReviewPeople,
  isInviteAllMembersToggleOn = false,
  setInviteAllMembersToggle,
  onConfirm,
}: Readonly<Props>) {
  const csvUploadOptions = useSelector(selectCvsUploadOptions);
  const previewDataResponse = useSelector(selectPreviewDataResponse);
  const uploadStatus = useSelector(selectUploadingUsersViaCSVStatus);

  const getInviteAllMembersSection = () => {
    if (!setInviteAllMembersToggle) {
      return null;
    }
    const newMembersCount = previewDataResponse.usersToAdd?.length ?? 0;

    return (
      <div className="setting-row">
        <div className="setting-row-description">
          <h3>Invite all members to join this organization</h3>
          {isInviteAllMembersToggleOn ? (
            <p>
              {newMembersCount} {newMembersCount === 1 ? "person" : "people"}{" "}
              will receive an email to join Develop by Criteria and any teams
              they are a member of.
            </p>
          ) : (
            <p>
              No members will receive an email to join {PLATFORM_NAME} and any
              teams they are a member of.
            </p>
          )}
        </div>
        <div className="setting-row-action">
          <Toggle
            isOn={isInviteAllMembersToggleOn}
            handleToggle={() =>
              setInviteAllMembersToggle(!isInviteAllMembersToggleOn)
            }
          />
        </div>
      </div>
    );
  };

  const getSummaryTags = () => {
    const tags: JSX.Element[] = [];
    if (csvUploadOptions.addNewEmployees) {
      const tagLength = previewDataResponse.usersToAdd?.length ?? 0;
      tags.push(
        <span
          className="label-tag m-0 blue"
          key="totalMembersToCreate"
          style={{ fontSize: "14px" }}
        >
          {previewDataResponse.usersToAdd?.length ?? 0} total member
          {getS(tagLength)} will be added
        </span>
      );
    }
    if (csvUploadOptions.updateExistingEmployees) {
      const tagLength = previewDataResponse.usersToUpdate?.length ?? 0;
      tags.push(
        <span
          className="label-tag m-0 yellow"
          key="totalMembersToUpdate"
          style={{ fontSize: "14px" }}
        >
          {previewDataResponse.usersToUpdate?.length ?? 0} total member
          {getS(tagLength)} will be updated
        </span>
      );
    }
    if (csvUploadOptions.deactivateEmployeesNotInCSV) {
      const tagLength = previewDataResponse.usersToDeactivate?.length ?? 0;
      tags.push(
        <span
          className="label-tag m-0 red"
          key="totalMembersToDeactivate"
          style={{ fontSize: "14px" }}
        >
          {previewDataResponse.usersToDeactivate?.length ?? 0} total member
          {getS(tagLength)} will be deactivated
        </span>
      );
    }

    return <div className="row-gap-8px">{tags}</div>;
  };

  return (
    <div>
      <h2>Summary</h2>
      <p>Review the members being created for your organization.</p>
      <hr />
      <div className="column-gap-16px">
        <span className="grey-text">
          <b>People</b>
        </span>
        {getSummaryTags()}
        {getInviteAllMembersSection()}
        <div>
          <Button
            onClick={onReviewPeople}
            className="border-0"
            variant="secondary-blue"
          >
            Review People
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </div>
      </div>
      <hr />
      <div className="d-flex">
        <div className="ms-auto">
          <Button onClick={onConfirm} disabled={uploadStatus === "loading"}>
            Confirm changes
            {isInviteAllMembersToggleOn ? " and send invites" : null}
          </Button>
        </div>
      </div>
    </div>
  );
}
