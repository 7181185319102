import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  readonly entity: string;
}

export default function SampleBanner({ entity }: Props) {
  return (
    <div
      className="sample-data-banner"
      style={{
        marginBottom: "16px",
        gap: "8px",
        fontSize: "14px",
      }}
    >
      <FontAwesomeIcon icon={["far", "circle-info"]} />
      <strong>Explore with a sample {entity}:</strong> This is a sample {entity}
    </div>
  );
}
