import React from "react";

interface CirclePieProps {
  percentage: number;
  color?: string;
  pixelSize?: number;
}

const CirclePie = ({
  percentage,
  color = "orange",
  pixelSize = 16,
}: Readonly<CirclePieProps>) => {
  // Ensure the percentage is within 0-100 degrees
  const normalizedAngle = Math.min(Math.max(percentage, 0), 100);
  const angle = (normalizedAngle * 360) / 100;

  // Calculate the end point of the arc
  const pizzaRadius = 50; // Radius of the pizza slice
  const radians = (angle * Math.PI) / 180;
  const x = pizzaRadius + pizzaRadius * Math.sin(radians);
  const y = pizzaRadius - pizzaRadius * Math.cos(radians);

  // Determine if the arc should be the large-arc (1) or small-arc (0)
  const largeArcFlag = angle > 180 ? 1 : 0;

  // Create the SVG path for the pizza slice
  const pathData = `M ${pizzaRadius},${pizzaRadius} L ${pizzaRadius},${0} A ${pizzaRadius},${pizzaRadius} 0 ${largeArcFlag} 1 ${x},${y} Z`;

  // Define the surrounding circle properties
  const circleStrokeWidth = 10; // Width of the circle stroke
  const circlePadding = 10; // Space between the pizza and the circle
  const circleRadius = pizzaRadius + circlePadding + circleStrokeWidth / 2; // Radius of the surrounding circle

  const sizeInPx = `${pixelSize}px`;
  return (
    <svg width={sizeInPx} height={sizeInPx} viewBox="0 0 150 150">
      <circle
        cx="75" // Center x-coordinate of the circle
        cy="75" // Center y-coordinate of the circle
        r={circleRadius} // Radius of the circle
        fill="none" // No fill for the circle
        strokeWidth={circleStrokeWidth} // Width of the circle stroke
        stroke={color}
      />
      {/* Render the pizza slice */}
      <path d={pathData} fill={color} transform="translate(25, 25)" />
    </svg>
  );
};

export default CirclePie;
