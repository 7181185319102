import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { NavProp, SideNavigationProps } from "./types";
import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { getNavIcon } from "./helpers";

export default function MobileSideNav({
  navs = [],
  setNavSelected,
  header = null,
  footer = null,
  navSelected = [],
}: Readonly<SideNavigationProps>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isNested, setIsNested] = useState(false);

  useEffect(() => {
    const nested = navs.some((nav) => nav.nestedNavs);
    setIsNested(nested);
  }, [navs]);

  const getClassName = () => {
    let baseClassName = "simple-side-nav-mobile";
    if (isExpanded) {
      baseClassName += " expanded";
    }
    return baseClassName;
  };

  const getNavsElms = (navs: NavProp[], nestedCount = 0) => (
    <div className={`nested-container nested-count-${nestedCount}`}>
      {navs.map((nav, idx) => {
        const isNavSelected = navSelected[nestedCount] === nav.title;
        return (
          <>
            <Button
              key={nav.title}
              className={`nav-item ${
                isNavSelected && navSelected.length === nestedCount + 1
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                setNavSelected?.(nav.title);
                nav.onClick?.();
              }}
              variant="secondary-blue"
            >
              <p>{nav.title}</p>
              {getNavIcon(nestedCount, isNested, isNavSelected)}
            </Button>
            {isNavSelected && nav.nestedNavs && (
              <div className="nested-navs">
                {getNavsElms(nav.nestedNavs, nestedCount + 1)}
              </div>
            )}
            {nestedCount === 0 && idx !== navs.length - 1 && (
              <hr className="m-0" />
            )}
          </>
        );
      })}
    </div>
  );

  return (
    <div className={getClassName()}>
      <div>
        <Button
          variant="tertiary-blue"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <FontAwesomeIcon icon="bars" size="2x" className="hamburger" />
        </Button>
      </div>
      <Collapse in={isExpanded}>
        <div className="column-gap-16px">
          {header}
          <div>{getNavsElms(navs)}</div>
          {footer}
        </div>
      </Collapse>
    </div>
  );
}
