import { useLayoutEffect, useState } from "react";
import { Dropdown, Collapse } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import HelpPopover from "app/components/Team360Assessment/HelpPopover";
import { TEAM_360_KEEP_ASSESSMENT_OPEN_OPTIONS } from "app/components/Team360Assessment/constants";
import {
  SurveyEventFrequency,
  SurveyEventTypes,
  SurveyKeepAssessmentOpenOption,
} from "app/components/Team360Assessment/types";
import DatePicker from "react-datepicker";
import { getTeam360KeepOpenForText } from "app/components/Team360Assessment/helpers";

interface Props {
  parentEventType: SurveyEventTypes;
  parentFrequency: SurveyEventFrequency;
  parentCustomMessage: string | false;
  parentKeepOpenFor?: SurveyKeepAssessmentOpenOption;
  advancedOpened: boolean;
  setAdvancedOpened: (opened: boolean) => void;
  parentDates: {
    startDate: Date;
    endDate: Date;
  };
  onCancel: () => void;
  onSave: (payload: {
    eventType: SurveyEventTypes;
    frequency: SurveyEventFrequency;
    customMessage: string | false;
    keepOpenFor: SurveyKeepAssessmentOpenOption;
    dates: { startDate: Date; endDate: Date };
  }) => void;
  isEditingExistingAssessment?: boolean;
}

export default function LaunchAssessmentConfigBody({
  parentEventType,
  parentFrequency,
  parentCustomMessage,
  parentKeepOpenFor,
  setAdvancedOpened,
  advancedOpened = true,
  parentDates,
  onCancel,
  onSave,
  isEditingExistingAssessment,
}: Readonly<Props>) {
  const [eventType, setEventType] = useState<SurveyEventTypes>(parentEventType);
  const [frequency, setFrequency] =
    useState<SurveyEventFrequency>(parentFrequency);
  const [customMessage, setCustomMessage] = useState<false | string>(
    parentCustomMessage
  );
  const [dates, setDates] = useState(parentDates);
  const [keepOpenFor, setKeepOpenFor] =
    useState<SurveyKeepAssessmentOpenOption>(parentKeepOpenFor ?? "1 week");

  useLayoutEffect(() => {
    setEventType(parentEventType);
    setFrequency(parentFrequency);
    setCustomMessage(parentCustomMessage);
    setDates(parentDates);
    setKeepOpenFor(parentKeepOpenFor ?? "1 week");
  }, [
    parentEventType,
    parentFrequency,
    parentCustomMessage,
    parentDates,
    parentKeepOpenFor,
  ]);

  // ------------------------ functions ------------------------------
  const getAdvancedCollapse = () => {
    return (
      <Collapse in={advancedOpened}>
        <div className="column-gap-20px">
          {!isEditingExistingAssessment ? (
            <div>
              <div>
                <div className="d-flex gap-2">
                  <span className="form-label-dark">
                    Make survey available on:
                  </span>
                  <HelpPopover
                    title="Make Survey Available On"
                    text="Choose the date and time the survey will begin."
                  />
                </div>
                <div>
                  <DatePicker
                    selected={dates.startDate}
                    onChange={(date: Date) =>
                      setDates({ ...dates, startDate: date })
                    }
                    showTimeSelect
                    dateFormat="Pp"
                  />
                </div>
              </div>
            </div>
          ) : null}
          {isEditingExistingAssessment ? (
            <div>
              <div className="d-flex gap-2">
                <span className="form-label-dark">
                  Keep survey opened until:
                </span>
                <HelpPopover
                  title="Keep survey opened until"
                  text="Choose when the due date for the survey will be."
                />
              </div>
              <div>
                <DatePicker
                  selected={dates.endDate}
                  onChange={(date: Date) =>
                    setDates({ ...dates, endDate: date })
                  }
                  showTimeSelect
                  dateFormat="Pp"
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="d-flex gap-2">
                <span className="form-label-dark">Keep survey open for:</span>
                <HelpPopover
                  title="Keep Survey Open For"
                  text="Select the number of days the survey will be open for responses. Default is 7 days."
                />
              </div>
              <div>
                <Dropdown className="full-width-dropdown">
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <div>
                      {keepOpenFor}
                      {TEAM_360_KEEP_ASSESSMENT_OPEN_OPTIONS.find(
                        (freq) => freq.label === keepOpenFor
                      )?.recommended && (
                        <span className="list-span ms-2">Recommended</span>
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {TEAM_360_KEEP_ASSESSMENT_OPEN_OPTIONS.map((freq) => (
                      <Dropdown.Item
                        onClick={() => setKeepOpenFor(freq.label)}
                        key={freq.label}
                      >
                        {`${freq.label} `}
                        {freq.recommended ? (
                          <span className="list-span">Recommended</span>
                        ) : null}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}

          {eventType === "Recurring event" ? (
            <div>
              <div>
                <div className="d-flex gap-2">
                  <span className="form-label-dark">Stop recurring on:</span>
                  <HelpPopover
                    title="Stop Recurring On"
                    text="Choose the date to stop the recurring surveys."
                  />
                </div>
                <div>
                  <DatePicker
                    selected={dates.startDate}
                    onChange={(date: Date) =>
                      setDates({ ...dates, startDate: date })
                    }
                    showTimeSelect
                    dateFormat="Pp"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Collapse>
    );
  };

  const now = new Date();
  const startingDate =
    dates.startDate.getDate() === now.getDate()
      ? "today"
      : dates.startDate.toLocaleDateString();

  return (
    <>
      <div className="survey-config-form">
        {/* {!isEditingExistingAssessment ? (
          <div>
            <div className="d-flex gap-2">
              <span className="form-label-dark">Event Type</span>
              <HelpPopover
                title="Event Type"
                text="Choose whether this is a one-time survey or a recurring survey."
              />
            </div>
            <div>
              <Dropdown className="full-width-dropdown">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <div>
                    {eventType}
                    {TEAM_360_EVENT_TYPES.find(
                      (type) => type.label === eventType
                    )?.recommended ? (
                      <span className="list-span ms-2">Recommended</span>
                    ) : null}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {TEAM_360_EVENT_TYPES.map((type) => (
                    <Dropdown.Item
                      onClick={() => setEventType(type.label)}
                      key={type.label}
                    >
                      {`${type.label} `}
                      {type.recommended ? (
                        <span className="list-span ms-2">Recommended</span>
                      ) : null}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ) : null} */}
        {/* {eventType === "Recurring event" ? (
          <div>
            <div className="d-flex gap-2">
              <span className="form-label-dark">Choose Frequency type</span>
              <HelpPopover
                title="Frequency Type"
                text="Select how often the recurring survey will occur."
              />
            </div>
            <div>
              <Dropdown className="full-width-dropdown">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  <div>
                    {frequency}
                    {TEAM_360_EVENT_FREQUENCY.find(
                      (freq) => freq.label === frequency
                    )?.recommended && (
                      <span className="list-span ms-2">Recommended</span>
                    )}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {TEAM_360_EVENT_FREQUENCY.map((freq) => (
                    <Dropdown.Item
                      onClick={() => setFrequency(freq.label)}
                      key={freq.label}
                    >
                      {`${freq.label} `}
                      {freq.recommended ? (
                        <span className="list-span">Recommended</span>
                      ) : null}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ) : null} */}
        {/* <div>
          <div className="d-flex gap-2">
            <span className="form-label-grey">Set custom message</span>
            <HelpPopover
              title="Set Custom Message"
              text="You can add a custom message to the invite for your team."
            />
            <Toggle
              isOn={customMessage !== false}
              handleToggle={() =>
                setCustomMessage(customMessage === false ? "" : false)
              }
            />
          </div>
          <Collapse in={customMessage !== false}>
            <div className="mt-2">
              <TextArea
                inputText={customMessage || ""}
                onTextChange={setCustomMessage}
                controlId="customMessage"
                inputLabel=""
                placeholder="Enter custom message"
              />
            </div>
          </Collapse>
        </div> */}
        <div className="d-flex justify-content-between align-items-center">
          <p>
            {eventType === "Recurring event" ? (
              <>
                This survey will be available to complete at the start of each{" "}
                <strong>{frequency.toLowerCase()}</strong>. The first instance
                of this
              </>
            ) : (
              "This"
            )}{" "}
            survey will be available to complete for{" "}
            <strong>{getTeam360KeepOpenForText(keepOpenFor)}</strong>, starting{" "}
            <strong>{startingDate}</strong>.
          </p>
          {/* <Button
            variant="tertiary"
            className="border-0 d-flex gap-2 align-items-center"
            onClick={() => setAdvancedOpened(!advancedOpened)}
          >
            Advanced
            <FontAwesomeIcon
              icon={`caret-${advancedOpened ? "up" : "down"}`}
              size="xs"
            />
          </Button> */}
        </div>
        {getAdvancedCollapse()}
      </div>
      <div className="d-flex justify-content-between">
        <Button variant="secondary-gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSave({ eventType, frequency, customMessage, keepOpenFor, dates });
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}
