import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  TSkill,
  TSkillsUpdated,
  EditSkillPayload,
  SkillFocusPayload,
  TSkillFocus,
  TDimension,
  EditDimensionPayload,
  TFactor,
} from "./types";
import { TEAM_360_FACTOR_ID_TO_NAME_MAP } from "./constants";

// ------------------ State Type/Structure ------------------
export interface SkillsGuideState {
  skillsGuide: TSkillsUpdated;
  skillsById: {
    [skillId: number]: TSkill;
  };
  dimensionsById: {
    [name: string]: TDimension;
  };
  factorsByFactorName: {
    [factorName: string]: TFactor;
  };
  teamSkillFocus: {
    [teamId: number]: TSkillFocus;
  };
  skillSectionFeedbackSubmitted: {
    [skillId: number]: string[];
  };
  dimensionSectionFeedbackSubmitted: {
    [skillId: number]: string[];
  };
  hasLibraryEditAccess: boolean;
  createdSkillFocus: TSkillFocus | null;
  getHasLibraryEditAccessStatus: responseStatus;
  getDimensionGuideStatus: responseStatus;
  getSkillsGuideStatus: responseStatus;
  getFactorGuideStatus: responseStatus;
  getTeamSkillFocusStatus: responseStatus;
  submitSkillFeedbackStatus: responseStatus;
  updateSkillGuideStatus: responseStatus;
  updateDimensionGuideStatus: responseStatus;
  submitTeamSkillFocusStatus: responseStatus;
  submitDimensionFeedbackStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: SkillsGuideState = {
  skillsGuide: {},
  skillsById: {},
  teamSkillFocus: {},
  dimensionsById: {},
  skillSectionFeedbackSubmitted: {},
  dimensionSectionFeedbackSubmitted: {},
  factorsByFactorName: {},
  createdSkillFocus: null,
  hasLibraryEditAccess: false,
  getHasLibraryEditAccessStatus: "idle",
  updateDimensionGuideStatus: "idle",
  getDimensionGuideStatus: "idle",
  getFactorGuideStatus: "idle",
  getSkillsGuideStatus: "idle",
  getTeamSkillFocusStatus: "idle",
  submitSkillFeedbackStatus: "idle",
  updateSkillGuideStatus: "idle",
  submitTeamSkillFocusStatus: "idle",
  submitDimensionFeedbackStatus: "idle",
};

// ------------------------------------ GET's ------------------------------------
export const getSkillGuide = createAsyncThunk(
  "skillsGuide/getSkillGuide",
  async (_, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/skillGuides`;
    const getSkillGuideResponse = (await request(requestUrl)) as TSkill[];
    dispatch(setSkillsGuide(getSkillGuideResponse));
    return getSkillGuideResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        skillsGuide: { getSkillsGuideStatus },
      } = getState() as RootState;
      return getSkillsGuideStatus === "idle";
    },
  }
);

export const getTeamSkillFocus = createAsyncThunk(
  "skillsGuide/getTeamSkillFocus",
  async (teamId: number) => {
    // TODO: NEED TO ADD GUARD CLAUSE TO CHECK IF WE ALREADY HAVE THE SKILL FOCUS FOR THIS TEAM
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/skillFocus`;
    const getTeamSkillFocusResponse = (await request(
      requestUrl
    )) as TSkillFocus;

    return { response: getTeamSkillFocusResponse, teamId };
  }
);

export const getDimensionGuide = createAsyncThunk(
  "skillsGuide/getDimensionGuide",
  async (_, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/dimensionGuides`;
    const getDimensionGuideResponse = (await request(
      requestUrl
    )) as TDimension[];
    dispatch(setDimensions(getDimensionGuideResponse));
    return getDimensionGuideResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        skillsGuide: { getDimensionGuideStatus },
      } = getState() as RootState;
      return getDimensionGuideStatus === "idle";
    },
  }
);

export const getFactorGuide = createAsyncThunk(
  "skillsGuide/getFactorGuide",
  async (_, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/factorGuides`;
    const getFactorGuideResponse = (await request(requestUrl)) as TFactor[];
    dispatch(setFactorGuide(getFactorGuideResponse));
    return getFactorGuideResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        skillsGuide: { getFactorGuideStatus },
      } = getState() as RootState;
      return getFactorGuideStatus === "idle";
    },
  }
);

export const getHasLibraryEditAccess = createAsyncThunk(
  "skillsGuide/getHasLibraryEditAccess",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/hasLibraryEditAccess`;
    const getHasLibraryEditAccessResponse = (await request(requestUrl)) as
      | {
          hasLibraryEditAccess: boolean;
        }
      | undefined;
    return !!getHasLibraryEditAccessResponse?.hasLibraryEditAccess;
  },
  {
    condition: (_, { getState }) => {
      const {
        skillsGuide: { getHasLibraryEditAccessStatus },
      } = getState() as RootState;
      return getHasLibraryEditAccessStatus === "idle";
    },
  }
);

// ------------------------------------ POST's ------------------------------------
export const submitSkillFeedback = createAsyncThunk(
  "skillsGuide/submitSkillFeedback",
  async ({
    payload,
    skillGuideId,
  }: {
    payload: { isHelpful: 0 | 1; section: string };
    skillGuideId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/skillGuides/${skillGuideId}/feedback`;
    const submitFeedbackResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as { success: boolean };
    if (!submitFeedbackResponse.success) {
      throw new Error("Error submitting feedback");
    }

    return { skillGuideId, section: payload.section };
  }
);

export const submitDimensionFeedback = createAsyncThunk(
  "skillsGuide/submitDimensionFeedback",
  async ({
    payload,
    dimensionGuideId,
  }: {
    payload: { isHelpful: 0 | 1; section: string };
    dimensionGuideId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/dimensionGuides/${dimensionGuideId}/feedback`;
    const submitFeedbackResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as { success: boolean };
    if (!submitFeedbackResponse.success) {
      throw new Error("Error submitting feedback");
    }

    return { dimensionGuideId, section: payload.section };
  }
);

export const submitTeamSkillFocus = createAsyncThunk(
  "skillsGuide/submitTeamSkillFocus",
  async ({
    payload,
    teamId,
  }: {
    payload: SkillFocusPayload;
    teamId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/skillFocus`;
    const submitTeamSkillFocusResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as TSkillFocus;
    // Once the api request is successful we need to make another api call to get the teams updated skill focus
    return { ...payload, ...submitTeamSkillFocusResponse };
  }
);

// ------------------------------------ PUT's ------------------------------------
export const updateSkillGuide = createAsyncThunk(
  "skillsGuide/updateSkillGuide",
  async ({
    payload,
    skillGuideId,
  }: {
    payload: EditSkillPayload;
    skillGuideId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/skillGuides/${skillGuideId}`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    });

    return { response: payload, skillGuideId };
  }
);

export const updateDimensionGuide = createAsyncThunk(
  "skillsGuide/updateDimensionGuide",
  async ({
    payload,
    dimensionId,
    dimensionName,
  }: {
    payload: EditDimensionPayload;
    dimensionId: number;
    dimensionName: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/dimensionGuides/${dimensionId}`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    });
    return { response: payload, dimensionName };
  }
);
// ------------------------------------ DELETE's ------------------------------------

// ------------------ Beginning of Slice Definition ------------------
export const skillsGuideSlice = createSlice({
  name: "skillsGuide",
  initialState,
  reducers: {
    setSkillsGuide: (state, action: PayloadAction<TSkill[]>) => {
      const skillsGuideObj: TSkillsUpdated = {};
      const skillsById: { [skillId: number]: TSkill } = {};

      action.payload.forEach((skill) => {
        skillsById[skill.id] = skill;
        if (!skillsGuideObj[skill.factor]) {
          skillsGuideObj[skill.factor] = {};
        }
        if (!skillsGuideObj[skill.factor][skill.construct]) {
          skillsGuideObj[skill.factor][skill.construct] = {};
        }
        skillsGuideObj[skill.factor][skill.construct][skill.id] = skill;
      });

      return {
        ...state,
        skillsGuide: {
          ...state.skillsGuide,
          ...skillsGuideObj,
        },
        skillsById,
      };
    },
    setDimensions: (state, action: PayloadAction<TDimension[]>) => {
      const dimensionsById: { [dimensionId: string]: TDimension } = {};
      action.payload.forEach((dimension) => {
        dimensionsById[dimension.name] = dimension;
      });
      return {
        ...state,
        dimensionsById,
      };
    },
    setFactorGuide: (state, action: PayloadAction<TFactor[]>) => {
      const factorsByFactorName: { [factorName: string]: TFactor } = {};
      action.payload.forEach((factor) => {
        const factorName = TEAM_360_FACTOR_ID_TO_NAME_MAP[factor.id];
        factorsByFactorName[factorName] = factor;
      });
      return {
        ...state,
        factorsByFactorName,
      };
    },
    clearCreatedSkillFocus: (state) => {
      state.createdSkillFocus = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkillGuide.pending, (state) => {
        state.getSkillsGuideStatus = "loading";
      })
      .addCase(getSkillGuide.fulfilled, (state) => {
        state.getSkillsGuideStatus = "succeeded";
      })
      .addCase(getSkillGuide.rejected, (state) => {
        state.getSkillsGuideStatus = "failed";
      })
      .addCase(getTeamSkillFocus.pending, (state) => {
        state.getTeamSkillFocusStatus = "loading";
      })
      .addCase(
        getTeamSkillFocus.fulfilled,
        (
          state,
          {
            payload: { response, teamId },
          }: PayloadAction<{
            response: TSkillFocus;
            teamId: number;
          }>
        ) => {
          return {
            ...state,
            teamSkillFocus: {
              ...state.teamSkillFocus,
              [teamId]: {
                ...state.teamSkillFocus[teamId],
                ...response,
              },
            },
          };
        }
      )
      .addCase(getTeamSkillFocus.rejected, (state) => {
        state.getTeamSkillFocusStatus = "failed";
      })
      .addCase(submitSkillFeedback.pending, (state) => {
        state.submitSkillFeedbackStatus = "loading";
      })
      .addCase(
        submitSkillFeedback.fulfilled,
        (
          state,
          {
            payload: { skillGuideId, section },
          }: PayloadAction<{ skillGuideId: number; section: string }>
        ) => {
          return {
            ...state,
            submitSkillFeedbackStatus: "succeeded",
            skillSectionFeedbackSubmitted: {
              ...state.skillSectionFeedbackSubmitted,
              [skillGuideId]: [
                ...(state.skillSectionFeedbackSubmitted[skillGuideId] || []),
                section,
              ],
            },
          };
        }
      )
      .addCase(submitSkillFeedback.rejected, (state) => {
        state.submitSkillFeedbackStatus = "failed";
      })
      .addCase(submitDimensionFeedback.pending, (state) => {
        state.submitDimensionFeedbackStatus = "loading";
      })
      .addCase(
        submitDimensionFeedback.fulfilled,
        (
          state,
          {
            payload: { dimensionGuideId, section },
          }: PayloadAction<{ dimensionGuideId: number; section: string }>
        ) => {
          return {
            ...state,
            submitDimensionFeedbackStatus: "succeeded",
            dimensionSectionFeedbackSubmitted: {
              ...state.dimensionSectionFeedbackSubmitted,
              [dimensionGuideId]: [
                ...(state.dimensionSectionFeedbackSubmitted[dimensionGuideId] ||
                  []),
                section,
              ],
            },
          };
        }
      )
      .addCase(submitDimensionFeedback.rejected, (state) => {
        state.submitDimensionFeedbackStatus = "failed";
      })
      .addCase(submitTeamSkillFocus.pending, (state) => {
        state.submitTeamSkillFocusStatus = "loading";
      })
      .addCase(
        submitTeamSkillFocus.fulfilled,
        (state, { payload }: PayloadAction<TSkillFocus>) => {
          state.submitTeamSkillFocusStatus = "succeeded";
          state.createdSkillFocus = payload;
        }
      )
      .addCase(submitTeamSkillFocus.rejected, (state) => {
        state.submitTeamSkillFocusStatus = "failed";
      })
      .addCase(updateSkillGuide.pending, (state) => {
        state.updateSkillGuideStatus = "loading";
      })
      .addCase(
        updateSkillGuide.fulfilled,
        (
          state,
          {
            payload: { response, skillGuideId },
          }: PayloadAction<{
            response: EditSkillPayload;
            skillGuideId: number;
          }>
        ) => {
          // We need to pull the current skillGuide out of state, and update it with the new skill
          const copyOfCurrentSkillGuide = { ...state.skillsById[skillGuideId] };
          const factor = copyOfCurrentSkillGuide.factor;
          const construct = copyOfCurrentSkillGuide.construct;
          toast.success("Updating Skill Successful!", {
            ...successNotificationOptions,
          });

          return {
            ...state,
            updateSkillGuideStatus: "succeeded",
            skillsGuide: {
              ...state.skillsGuide,
              [factor]: {
                ...state.skillsGuide[factor],
                [construct]: {
                  ...state.skillsGuide[factor][construct],
                  [skillGuideId]: {
                    ...copyOfCurrentSkillGuide,
                    ...response,
                  },
                },
              },
            },
            skillsById: {
              ...state.skillsById,
              [skillGuideId]: {
                ...copyOfCurrentSkillGuide,
                ...response,
              },
            },
          };
        }
      )
      .addCase(updateSkillGuide.rejected, (state) => {
        state.updateSkillGuideStatus = "failed";
      })
      .addCase(getDimensionGuide.pending, (state) => {
        state.getDimensionGuideStatus = "loading";
      })
      .addCase(getDimensionGuide.fulfilled, (state) => {
        state.getDimensionGuideStatus = "succeeded";
      })
      .addCase(getDimensionGuide.rejected, (state) => {
        state.getDimensionGuideStatus = "failed";
      })
      .addCase(updateDimensionGuide.pending, (state) => {
        state.updateDimensionGuideStatus = "loading";
      })
      .addCase(
        updateDimensionGuide.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{
            response: EditDimensionPayload;
            dimensionName: string;
          }>
        ) => {
          toast.success("Updating Dimension Successful!", {
            ...successNotificationOptions,
          });

          return {
            ...state,
            updateDimensionGuideStatus: "succeeded",
            dimensionsById: {
              ...state.dimensionsById,
              [payload.dimensionName]: {
                ...state.dimensionsById[payload.dimensionName],
                ...payload.response,
              },
            },
          };
        }
      )
      .addCase(updateDimensionGuide.rejected, (state) => {
        state.updateDimensionGuideStatus = "failed";
      })
      .addCase(getHasLibraryEditAccess.pending, (state) => {
        state.getHasLibraryEditAccessStatus = "loading";
      })
      .addCase(
        getHasLibraryEditAccess.fulfilled,
        (state, { payload }: PayloadAction<boolean>) => {
          state.getHasLibraryEditAccessStatus = "succeeded";
          state.hasLibraryEditAccess = payload;
        }
      )
      .addCase(getHasLibraryEditAccess.rejected, (state) => {
        state.getHasLibraryEditAccessStatus = "failed";
      })
      .addCase(getFactorGuide.pending, (state) => {
        state.getFactorGuideStatus = "loading";
      })
      .addCase(getFactorGuide.fulfilled, (state) => {
        state.getFactorGuideStatus = "succeeded";
      })
      .addCase(getFactorGuide.rejected, (state) => {
        state.getFactorGuideStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectSkillsGuide = (state: RootState) =>
  state.skillsGuide.skillsGuide;
export const selectGetSkillsGuideStatus = (state: RootState) =>
  state.skillsGuide.getSkillsGuideStatus;
export const selectSubmitSkillFeedbackStatus = (state: RootState) =>
  state.skillsGuide.submitSkillFeedbackStatus;
export const selectSkillSectionFeedbackSubmitted = (state: RootState) =>
  state.skillsGuide.skillSectionFeedbackSubmitted;
export const selectDimensionSectionFeedbackSubmitted = (state: RootState) =>
  state.skillsGuide.dimensionSectionFeedbackSubmitted;
export const selectSubmitDimensionFeedbackStatus = (state: RootState) =>
  state.skillsGuide.submitDimensionFeedbackStatus;
export const selectSubmitTeamSkillFocusStatus = (state: RootState) =>
  state.skillsGuide.submitTeamSkillFocusStatus;
export const selectCreatedSkillFocus = (state: RootState) =>
  state.skillsGuide.createdSkillFocus;
export const selectTeamSkillFocusById =
  (teamId: number) => (state: RootState) =>
    state.skillsGuide.teamSkillFocus[teamId];
export const selectSkillsById = (state: RootState) =>
  state.skillsGuide.skillsById;

export const selectAllDimensionsById = (state: RootState) =>
  state.skillsGuide.dimensionsById;
export const selectUpdateSkillGuideStatus = (state: RootState) =>
  state.skillsGuide.updateSkillGuideStatus;
export const selectGetDimensionGuideStatus = (state: RootState) =>
  state.skillsGuide.getDimensionGuideStatus;
export const selectUpdateDimensionGuideStatus = (state: RootState) =>
  state.skillsGuide.updateDimensionGuideStatus;
export const selectFactorsByFactorName = (state: RootState) =>
  state.skillsGuide.factorsByFactorName;
export const selectGetFactorGuideStatus = (state: RootState) =>
  state.skillsGuide.getFactorGuideStatus;
export const selectGetHasLibraryEditAccessStatus = (state: RootState) =>
  state.skillsGuide.getHasLibraryEditAccessStatus;
export const selectHasLibraryEditAccess = (state: RootState) =>
  state.skillsGuide.hasLibraryEditAccess;

export const {
  setSkillsGuide,
  clearCreatedSkillFocus,
  setDimensions,
  setFactorGuide,
} = skillsGuideSlice.actions;
export default skillsGuideSlice.reducer;
