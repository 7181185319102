import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useEffect } from "react";
import { RowContent, SortDirection, TableHeader } from "./types";
import { renderTableRows } from "./helpers";
import Paginator from "./Paginator";

interface Props {
  columnHeaders: TableHeader[];
  rows: RowContent[];
  tableClassName?: string;
  itemsPerPage?: number;
  lastTableHeaderClassName?: string;
}

// TODO: Down the line, refactor so that it only sorts the rows once when the user clicks on the header
// because right now it calls the sort function again when the user clicks on the next page
export default function SortableTable({
  columnHeaders = [],
  rows,
  tableClassName = "",
  itemsPerPage = 10,
  lastTableHeaderClassName,
}: Readonly<Props>) {
  const [currentPage, setCurrentPage] = useState(1);
  const [toggledSort, setToggledSort] = useState<{
    headerName: string;
    sortDirection: SortDirection;
    sortInverse?: boolean;
    isDate?: boolean;
  } | null>(null);

  const tableRows = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return renderTableRows({
      columnHeaders,
      rows: rows,
      toggledSort,
      startIndex,
      endIndex,
    });
  }, [rows, toggledSort, columnHeaders, currentPage, itemsPerPage]);

  // Every time the length of the rows changes, reset the current page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [rows.length]);

  const onSort = (
    headerName: string,
    sortInverse?: boolean,
    isDate?: boolean
  ) => {
    if (
      !toggledSort ||
      (toggledSort && toggledSort.headerName !== headerName)
    ) {
      return setToggledSort({
        headerName,
        sortDirection: "up",
        sortInverse,
        isDate,
      });
    }

    if (toggledSort.sortDirection === "up") {
      return setToggledSort({
        ...toggledSort,
        sortDirection: "down",
        sortInverse,
      });
    }

    setToggledSort(null);
  };

  const getSortIconName = (headerName: string) => {
    if (
      !toggledSort ||
      (toggledSort && toggledSort.headerName !== headerName)
    ) {
      return "sort";
    }

    if (toggledSort.sortDirection === "up") {
      return "sort-up";
    }
    return "sort-down";
  };

  const getTableHeader = () => {
    return columnHeaders.map(({ label, key, sortInverse, isDate }, idx) => (
      <th
        onClick={() => onSort(key, sortInverse, isDate)}
        role="button"
        key={key}
        className={
          idx === columnHeaders.length - 1
            ? lastTableHeaderClassName
            : undefined
        }
      >
        {label}
        {label ? (
          <FontAwesomeIcon icon={getSortIconName(key)} className="ms-2" />
        ) : null}
      </th>
    ));
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex =
    startIndex + itemsPerPage > rows.length
      ? rows.length
      : startIndex + itemsPerPage;

  return (
    <>
      <table className={`sortable-table ${tableClassName}`}>
        <thead>
          <tr>{getTableHeader()}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
      {tableRows?.length ? (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <Paginator
              pageRangeDisplayed={5}
              pageCount={Math.ceil(rows.length / itemsPerPage)}
              onPageChange={(selectedItem) => {
                setCurrentPage(selectedItem.selected + 1);
              }}
              forcePage={currentPage - 1}
            />
            <p className="grey-text">
              Page <b>{currentPage}</b> of{" "}
              {Math.ceil(rows.length / itemsPerPage)} | Showing {startIndex + 1}{" "}
              - {endIndex} of {rows.length}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}
