import { Link } from "react-router-dom";

interface Props {
  readonly title: string;
  readonly description: string;
  readonly buttonText: string;
  readonly link: string;
  readonly onHide?: () => void;
}

export default function ReportCard({
  title,
  description,
  buttonText,
  link,
  onHide = () => {},
}: Props) {
  return (
    <div className="section-body-row">
      <div
        className="d-flex gap-2 align-items-center"
        style={{ color: "#53565a" }}
      >
        <div className="section-body-name-and-description">
          <span className="section-body-row-name">{title}</span>
          <span className="section-body-row-description">{description}</span>
        </div>
      </div>
      <div className="d-flex gap-2 align-items-center">
        <Link to={link} className="sample-data-link" onClick={onHide}>
          {buttonText}
        </Link>
      </div>
    </div>
  );
}
