import { ReactNode } from "react";
interface Props {
  type?: "red" | "blue" | "green" | "light-red" | "gold";
  children?: ReactNode;
  className?: string;
}
export default function index({
  type = "red",
  children,
  className = "",
}: Props) {
  return (
    <div
      className={`warning-banner ${type} ${className}`}
      style={{
        padding: "12px 16px",
      }}
    >
      {children || null}
    </div>
  );
}
