import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";

// ------------------ State Type/Structure ------------------
export interface GlobalNavBarState {
  isNotificationDropdownOpen: boolean;
}

// ------------------ InitialState ------------------
const initialState: GlobalNavBarState = {
  isNotificationDropdownOpen: false,
};

// ------------------ Asynchronous API calls ------------------

// ------------------ Beginning of Slice Definition ------------------
export const globalNavbarSlice = createSlice({
  name: "globalNavbar",
  initialState,
  reducers: {
    setIsNotificationDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotificationDropdownOpen = action.payload;
    },
  },
});

// ------------------ Selectors ------------------

export const { setIsNotificationDropdownOpen } = globalNavbarSlice.actions;

export const selectIsNotificationDropdownOpen = (state: RootState) =>
  state.globalNavbar.isNotificationDropdownOpen;

export default globalNavbarSlice.reducer;
