import React from "react";

// Define a type for the component props
type ClickableElementProps = {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  role?: string;
  tabIndex?: number;
  style?: React.CSSProperties;
  as?: keyof JSX.IntrinsicElements; // Define the 'as' prop to specify the element type
};

const ClickableElement: React.FC<ClickableElementProps> = ({
  onClick,
  children,
  className,
  role = "button",
  tabIndex = 0,
  style,
  as: Component = "div", // Default to 'div' if no 'as' prop is provided
}) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick();
    }
  };

  return (
    <Component
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role={role}
      tabIndex={tabIndex}
      style={{ cursor: "pointer", ...style }}
    >
      {children}
    </Component>
  );
};

export default ClickableElement;
