import { useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  putCoachingPlan,
  selectActiveCoachingPlan,
  selectPutCoachingPlanStatus,
} from "app/containers/Chatbot/slice";
import SimpleModal from "./SimpleModal";
import TextArea from "app/storybookComponents/Forms/TextArea";

interface EditSkillFocusProps {
  show?: boolean;
  onHide: () => void;
}

export default function EditSkillFocusModal({
  show,
  onHide,
}: Readonly<EditSkillFocusProps>) {
  const dispatch = useAppDispatch();
  const activeCoachingPlan = useAppSelector(selectActiveCoachingPlan);
  const putSkillFocusStatus = useAppSelector(selectPutCoachingPlanStatus);
  const [currentFocus, setCurrentFocus] = useState("");

  useEffect(() => {
    try {
      const currFocus = JSON.parse(activeCoachingPlan?.currentFocus ?? "");
      setCurrentFocus(currFocus);
    } catch (e) {
      setCurrentFocus(activeCoachingPlan?.currentFocus ?? "");
    }
  }, [activeCoachingPlan]);

  const onSave = async () => {
    if (!activeCoachingPlan) return;
    await dispatch(
      putCoachingPlan({
        currentFocus,
        id: activeCoachingPlan.id,
      })
    );
    onHide();
  };

  return (
    <SimpleModal show={show} onHide={onHide} title="Edit Current Focus">
      <div
        style={{
          marginTop: "-16px",
        }}
      >
        <div>
          <TextArea
            controlId="currentFocus"
            inputLabel="Current Focus:"
            inputText={currentFocus}
            onTextChange={(e) => setCurrentFocus(e)}
            maxLength={500}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <Button variant="secondary-gray" className="border-0" onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={onSave} disabled={putSkillFocusStatus === "loading"}>
          Save changes
        </Button>
      </div>
    </SimpleModal>
  );
}
