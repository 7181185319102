import React from "react";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  title: string;
  stepNumber?: number;
  body: React.JSX.Element | null;
  emptyState?: React.JSX.Element | null;
  stepNumberHoverOver?: {
    title: string;
    body: string;
    timeToComplete: string;
  } | null;
  isActive?: boolean;
  isChecked?: boolean;
  primaryButton?: {
    text: string;
    onClick: () => void;
    isDisabled?: boolean;
    isSecondaryColor?: boolean;
    caret?: "up" | "down";
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
    isDisabled?: boolean;
  };
  headerButton?: {
    text: string;
    onClick: () => void;
    isDisabled?: boolean;
  };
  hideNumber?: boolean;
  footerText?: string;
}
export default function DashboardOnboardingCard({
  stepNumberHoverOver,
  title,
  isActive,
  isChecked,
  stepNumber,
  body,
  emptyState = null,
  primaryButton,
  secondaryButton,
  headerButton,
  footerText = "",
  hideNumber,
}: Readonly<Props>) {
  const getStatus = (): "completed" | "active" | "pending" => {
    if (isChecked) {
      return "completed";
    }

    if (isActive) {
      return "active";
    }

    return "pending";
  };

  const getFooter = () => {
    if ((!primaryButton && !secondaryButton && !footerText) || !body) {
      return null;
    }

    return (
      <div className="dashboard-onboard-card-footer">
        <div className="dashboard-onboard-card-footer-buttons">
          {primaryButton ? (
            <Button
              onClick={primaryButton.onClick}
              disabled={!!primaryButton.isDisabled}
              variant={
                primaryButton.isSecondaryColor ? "secondary-blue" : undefined
              }
            >
              {primaryButton.text}
              {primaryButton.caret ? (
                <FontAwesomeIcon
                  icon={`caret-${primaryButton.caret}`}
                  className="ms-2"
                />
              ) : null}
            </Button>
          ) : null}

          {secondaryButton ? (
            <Button
              variant="secondary-blue"
              onClick={secondaryButton.onClick}
              disabled={!!secondaryButton.isDisabled}
            >
              {secondaryButton.text}
            </Button>
          ) : null}
        </div>
        <div className="dashboard-onboard-card-footer-text">
          <span>{footerText}</span>
        </div>
      </div>
    );
  };

  const getCircleNumber = (
    number: number,
    state = "pending",
    size = "medium"
  ) => {
    return (
      <div className={`circle-number ${state} ${size}`}>
        {state === "completed" ? <FontAwesomeIcon icon="check" /> : number}
      </div>
    );
  };

  const overlay = (
    <Popover
      className="team-360-popover"
      style={{ minWidth: "400px", padding: "16px" }}
    >
      {stepNumberHoverOver ? (
        <div className="column-gap-16px">
          <p>
            <b>{stepNumberHoverOver.title}</b>
          </p>
          <p>{stepNumberHoverOver.body}</p>
          <div className="row-gap-8px align-items-center">
            <FontAwesomeIcon icon={["far", "clock"]} />
            <p>{stepNumberHoverOver.timeToComplete}</p>
          </div>
        </div>
      ) : null}
    </Popover>
  );

  return (
    <div className="dashboard-onboard-card-holder">
      {!hideNumber && stepNumber ? (
        <OverlayTrigger
          rootClose
          placement="right"
          overlay={overlay}
          trigger={"click"}
        >
          {getCircleNumber(stepNumber, getStatus())}
        </OverlayTrigger>
      ) : null}
      <Card className="dashboard-onboard-card">
        <div className="dashboard-onboard-card-header">
          <h2>{title}</h2>
          {headerButton ? (
            <Button
              onClick={headerButton.onClick}
              disabled={!!headerButton.isDisabled}
              variant="secondary-blue"
              className="border-0"
            >
              {headerButton.text}
            </Button>
          ) : null}
        </div>
        {body ?? emptyState}
        {getFooter()}
      </Card>
    </div>
  );
}
