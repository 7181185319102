import ProgressOverviewImg from "resources/images/progress_overview.svg";
import SharedGoalsImg from "resources/images/shared_goals.svg";
import ProjectCompletedImg from "resources/images/project_completed.svg";
import WorkFromAnywhereImg from "resources/images/work_from_anywhere.svg";
import ManageStressImg from "resources/images/searching.svg";
import CreativeImg from "resources/images/creativity.svg";
import NegotiateImg from "resources/images/interview.svg";
import CreateWorkLifeBalanceImg from "resources/images/meditation.svg";
import RunGreatMeetingsImg from "resources/images/meeting.svg";
import CreateAPremortemImg from "resources/images/detailed_analysis.svg";
import DraftAFutureImg from "resources/images/happy_announcement.svg";
import BuildOneYearPlanImg from "resources/images/navigator.svg";
import DelegateWellImg from "resources/images/add_tasks.svg";
import OptimisticImg from "resources/images/happy_news.svg";
import ProConListImg from "resources/images/contrast.svg";
import BuildResilienceImg from "resources/images/team_collaboration.svg";
import CommunicateBetterImg from "resources/images/group_chat.svg";
import GiveOrReceiveFeedbackImg from "resources/images/work_time.svg";
import MasterChallengingConversationsImg from "resources/images/scrum_board.svg";
import { CoachbotCardTemplate } from "./types";

export const ONE_WEEK_SMART_GOAL_TEMPLATE: CoachbotCardTemplate = {
  title: "Create a 1-week SMART goal",
  description:
    "SMART goals increase the likelihood of success by making goals specific, measurable, achievable, relevant, and time-bound.",
  image: ProgressOverviewImg,
  coachBotIntro:
    "SMART goals truly make a difference. Let's pinpoint an achievable objective for the upcoming week. Where shall we begin?",
  preSelectedPrompts: [
    "SMART goals at work",
    "SMART goals outside work",
    "SMART goals for someone else",
  ],
  chatType: "smartGoals",
};

export const REFLECT_OR_DEBRIEF_TEMPLATE: CoachbotCardTemplate = {
  title: "Reflect or Debrief",
  description:
    "Research shows that regular reflection improves performance — whether it’s formal analysis of sales calls, after-action reviews in the military and hospitals, or just a simple journal.",
  image: ProgressOverviewImg,
  coachBotIntro:
    "Reflection is crucial for growth. Let's take a moment to consider recent experiences or feelings. Ready to dive in?",
  preSelectedPrompts: [
    "Reflect on a recent performance (e.g. a pitch, sprint, sales call)",
    "Reflect on how I’m feeling (e.g. mood or emotional wellbeing)",
    "Reflect on a relationship",
  ],
  chatType: "reflectOrDebrief",
};

export const BUILD_A_1_YEAR_PERSONAL_DEVELOPMENT_PLAN_TEMPLATE: CoachbotCardTemplate =
  {
    title: "Build a 1-Year Personal Development Plan",
    description:
      "In this chat, we'll chart a course for your personal and professional development over the next year.",
    image: BuildOneYearPlanImg,
    coachBotIntro:
      "Personal development plans help you envision who you want to become over the next year. Let's create a roadmap for your success. Ready to start?",
    preSelectedPrompts: [
      "Build Your Personal Development Plan",
      "Reflect on Your Progress",
    ],
    chatType: "personalDevelopment",
  };

export const GIVE_A_PERFORMANCE_REVIEW_TEMPLATE: CoachbotCardTemplate = {
  title: "Give a Performance Review",
  description:
    "Performance reviews, when conducted effectively, provide essential feedback that fuels personal growth and strengthens organizational alignment.",
  image: ProgressOverviewImg,
  coachBotIntro:
    "Performance reviews are pivotal moments for feedback and growth. They require careful consideration and empathy to ensure they're constructive and well-received. How can we begin our preparation today?",
  preSelectedPrompts: [
    "Develop a Performance Review",
    "Apply a Performance Review",
    "Role Play a Performance Review",
  ],
  chatType: "performanceReview",
};

export const NEGOTIATION_COACH_TEMPLATE: CoachbotCardTemplate = {
  title: "Negotiation Coach",
  description:
    "Enhance your negotiation skills with tailored coaching that prepares you for various scenarios, from formal business negotiations to personal discussions.",
  image: NegotiateImg,
  coachBotIntro:
    "Negotiation is an art that involves understanding, strategy, and sometimes, compromise. Let's sharpen your skills to effectively navigate any negotiation. What aspect would you like to focus on first?",
  preSelectedPrompts: [
    "Prepare for a Negotiation",
    "Role Play a Negotiation",
    "Get Unstuck During a Negotiation",
  ],
  chatType: "negotiation",
};

export const GET_CREATIVE_TEMPLATE: CoachbotCardTemplate = {
  title: "Get Creative",
  description: "Have a chat with your personal creativity coach.",
  image: CreativeImg,
  coachBotIntro:
    "Okay, so it’s time for us to get creative. I love it. How should we begin?",
  preSelectedPrompts: [
    "Brainstorm around a product or project",
    "Brainstorm around a process or way of doing things",
    "Brainstorm around a person or relationship",
  ],
  chatType: "creativity",
};

const PRIORITIZE_TASKS_TEMPLATE: CoachbotCardTemplate = {
  title: "Prioritize Tasks",
  description:
    "Enhance your (or your team's) focus and efficiency by mastering the art of prioritization.",
  image: ProjectCompletedImg,
  coachBotIntro:
    "Alright, so we’re chatting about enhancing efficiency by mastering the art of prioritization. How should we start?",
  preSelectedPrompts: [
    "Help me identify my most important tasks",
    "Help me manage lower-priority tasks",
    "Help me say no to things",
    "Help me communicate task priorities",
  ],
  chatType: "taskPrioritization",
};

const THINK_BETTER_TEMPLATE: CoachbotCardTemplate = {
  title: "Think better",
  description:
    "This chat helps you improve decision-making and avoid being influenced by common fallacies such as anchoring, loss aversion, and sunk-cost.",
  image: NegotiateImg,
  coachBotIntro:
    "We all use what’s called heuristics (or simple rules) to make decisions. But sometimes these rules get us in trouble. Let’s explore a decision you’re making and see if any thinking biases might be getting in the way.",
  preSelectedPrompts: [
    "Help me explore a decision for common thinking biases",
    "Help me understand thinking biases more",
  ],
  chatType: "thinkBetter",
};

const GET_MOTIVATED_TEMPLATE: CoachbotCardTemplate = {
  title: "Get Motivated",
  description:
    "Need to get inspired? Let's chat and I'll do my best to get you motivated.",
  image: WorkFromAnywhereImg,
  coachBotIntro:
    "Motivation is so important. Where’s the best place for us to start?",
  preSelectedPrompts: [
    "Get motivated to start doing something",
    "Get motivated to stop doing something",
    "Get motivated to learn something",
  ],
  chatType: "motivation",
};

const MANAGE_YOUR_TIME_TEMPLATE: CoachbotCardTemplate = {
  title: "Manage Your Time",
  description:
    "Unlock strategies to improve time management and keep yourself (or your team) focused on high-impact activities.",
  image: ProgressOverviewImg,
  coachBotIntro:
    "Alright, so we want to think about managing your time better. Excellent. What should we focus on?",
  preSelectedPrompts: [
    "Help me set up an experiment to allocate my time effectively.",
    "Help me create a strategy to minimize distractions and maximize productivity.",
    "Help me find an area where I can improve my time management.",
  ],
  chatType: "timeManagement",
};

const RUN_GREAT_MEETINGS_TEMPLATE: CoachbotCardTemplate = {
  title: "Run Great Meetings",
  description:
    "This chat helps you think about, plan, and prepare for high-impact meetings.",
  image: RunGreatMeetingsImg,
  coachBotIntro:
    "Meetings are the foundation of teamwork. A little planning can boost engagement and effectiveness. How should we start?",
  preSelectedPrompts: [
    "Get clear on what the meeting is about",
    "Create a good agenda",
    "Logistics: who, where, and when of the meeting",
  ],
  chatType: "runGreatMeetings",
};

export const MANAGE_STRESS_TEMPLATE: CoachbotCardTemplate = {
  title: "Manage Stress",
  description:
    "Equip yourself and your team with strategies to navigate stress, maintaining high morale and performance.",
  image: ManageStressImg,
  coachBotIntro:
    "Thanks for checking in. Stress can be tough. What would be the best place for us to begin?",
  preSelectedPrompts: [
    "Help me identify the sources of my stress.",
    "Help me design an experiment to try this week to reduce my stress.",
    "Help me sustain motivation and well-being under pressure.",
  ],
  chatType: "wellBeing",
};

export const CREATE_WORK_LIFE_BALANCE_TEMPLATE: CoachbotCardTemplate = {
  title: "Create Work-Life Balance",
  description:
    "Cultivate a culture that supports a healthy balance between work demands and personal life.",
  image: CreateWorkLifeBalanceImg,
  coachBotIntro:
    "It can be hard to manage all the things in life. What would be the best place for us to start talking?",
  preSelectedPrompts: [
    "Help me design an experiment to establish boundaries that promote work-life balance.",
    "Help me design an experiment to use my downtime to recharge and recover.",
    "Help me come up with an experiment to maintain high productivity while ensuring personal well-being.",
  ],
  chatType: "workLifeBalance",
};

export const BUILD_RESILIENCE_TEMPLATE: CoachbotCardTemplate = {
  title: "Build Resilience",
  description:
    "Foster resilience within yourself and your team to effectively respond to challenges and changes.",
  image: BuildResilienceImg,
  coachBotIntro:
    "Resilience is like a muscle. The more we flex it, the stronger it gets. What should we start with?",
  preSelectedPrompts: [
    "Help me bounce back from a set-back.",
    "Help me positively reframe a challenge I’m facing and learn from it.",
    "Help me develop a plan to ask for help or support from someone I trust.",
  ],
  chatType: "resilience",
};

export const COMMUNICATE_BETTER_TEMPLATE: CoachbotCardTemplate = {
  title: "Communicate Better",
  description: "Explore how to communicate more effectively at work.",
  image: CommunicateBetterImg,
  coachBotIntro:
    "Communication is the “glue” of high-performing teams. Where’s a good place to start?",
  preSelectedPrompts: [
    "Help me create an experiment to improve how I communicate with a teammate.",
    "Help me explore clarifying and extending questions I can ask around a project.",
    "Help me create an experiment to improve communication in remote team settings.",
  ],
  chatType: "communication",
};

export const GIVE_OR_RECEIVE_FEEDBACK_TEMPLATE: CoachbotCardTemplate = {
  title: "Give or Receive Feedback",
  description:
    "Practice giving and receiving feedback for growth and continuous improvement.",
  image: GiveOrReceiveFeedbackImg,
  coachBotIntro: "Great feedback is such a gift. What can I help with?",
  preSelectedPrompts: [
    "Help me give actionable, constructive feedback to a team member.",
    "Help me learn from feedback I’ve received and create an experiment to act on it.",
    "Help me ask for feedback so I can learn and grow.",
  ],
  chatType: "feedback",
};

export const MASTER_CHALLENGING_CONVERSATIONS_TEMPLATE: CoachbotCardTemplate = {
  title: "Master Challenging Conversations",
  description:
    "Learn to navigate challenging conversations with empathy and assertiveness, leading to positive outcomes.",
  image: MasterChallengingConversationsImg,
  coachBotIntro:
    "Challenging conversations can be a lot easier when we do some preparation, understand the other person’s perspective, and pick the right time and place to have them. Where should we start?",
  preSelectedPrompts: [
    // "Help me prepare for a challenging conversation so I understand the other person’s perspective.",
    // "Help me reach out to someone and think through best setting or approach to a challenging conversation.",
    // "Help me move forward positively after a challenging conversation.",
    "Help me prepare for a challenging conversation",
    "Help me role-play a challenging conversation",
    "Help me move forward after a challenging conversation",
  ],
  chatType: "challengingConversations",
};

export const CONDUCT_A_PREMORTEM_TEMPLATE: CoachbotCardTemplate = {
  title: "Conduct a Premortem",
  description:
    "By imagining it’s the future and our project has failed, we free ourselves (and our team) up to voice hidden concerns.",
  image: CreateAPremortemImg,
  coachBotIntro:
    "So, getting creative about what could go wrong is an excellent way to improve our projects chances of success. How should we start?",
  preSelectedPrompts: [
    "Let’s brainstorm “failure risks” around project size or complexity",
    "Let’s brainstorm “failure risks” around missing resources",
    "Let’s brainstorm “failure risks” around people or processes",
  ],
  chatType: "preMortem",
};

export const DRAFT_A_FUTURE_TEMPLATE: CoachbotCardTemplate = {
  title: "Draft a “Success Release”",
  description:
    "Imagine your project has succeeded and draft a 1-page press release announcing your success.",
  image: DraftAFutureImg,
  coachBotIntro:
    "Amazon is famous for imagining each new project has succeeded in advance and drafting a press release announcing what they did, why it matters, and who it’s impacting. Let’s get started!",
  preSelectedPrompts: [
    "Draft a press release for a new product",
    "Draft a press release for a new process",
    "Draft a press release for a positive impact on people or the environment",
  ],
  chatType: "successRelease",
};

export const CRAFT_EFFECTIVE_OKRS_TEMPLATE: CoachbotCardTemplate = {
  title: "Craft Effective OKRs",
  description:
    "Learn how to set clear objectives and measurable key results to drive team performance.",
  image: SharedGoalsImg,
  coachBotIntro:
    "Okay, so we’re focusing on building effective OKR’s. That’s great. How do you want to get started?",
  preSelectedPrompts: [
    "Help me write 3 motivating and achievable objectives.",
    "Help me develop 2-3 clear, specific key results for one objective.",
    "Help me evaluate and adjust my OKRs.",
  ],
  chatType: "OKRs",
};

export const PRO_CON_LIST_TEMPLATE: CoachbotCardTemplate = {
  title: "Pro-Con List",
  description:
    "Research shows that generating a number of reasons both for and against decisions improves decision quality.",
  image: ProConListImg,
  coachBotIntro:
    "Let’s generate your pro-con list now. How do you want to start?",
  preSelectedPrompts: [
    "Walk through the pro’s and cons and help me make a decision",
    "Help me generate the pro’s (or reasons for) doing something",
    "Help me generate the con’s (or reasons against) doing something",
  ],
  chatType: "proConList",
};
export const DELEGATE_WELL_TEMPLATE: CoachbotCardTemplate = {
  title: "Delegate Well",
  description:
    "This chat helps you think about both what to delegate, who to delegate to, and when to delegate",
  image: DelegateWellImg,
  coachBotIntro:
    "Great managers and leaders delegate effectively, but also stay involved. This is a learned skill. How should we start?",
  preSelectedPrompts: [
    "Talk about what to delegate",
    "Talk about who to delegate to and how",
    "Talk about when to delegate",
  ],
  chatType: "delegateWell",
};

export const PRACTICE_GRATITUDE_AND_OPTIMISM_TEMPLATE: CoachbotCardTemplate = {
  title: "Practice Gratitude and Optimism",
  description:
    "This Bo chat focuses on developing the (daily) habit of identifying and appreciating the positive in life.",
  coachBotIntro:
    "Embracing gratitude and optimism can transform our daily experiences. Ready to discover the positives in your day? Let's start reflecting on the good things around us.",
  preSelectedPrompts: [
    "Find Something You’re Optimistic About",
    "Find Something You’re Grateful For",
  ],
  image: OptimisticImg,
  chatType: "gratitudeAndOptimism",
};

export const CELEBRATE_YOUR_WINS_TEMPLATE: CoachbotCardTemplate = {
  title: "Celebrate Your Wins",
  description:
    "Coach Bo will turn cheerleader and help you celebrate practice celebrating a win (or the wins of your team or someone you work with).",
  coachBotIntro:
    "Celebrating our wins isn’t a luxury, it’s a necessity if we want to stay engaged and motivated. How should we start?",
  preSelectedPrompts: [
    "Celebrate your wins",
    "Celebrate someone else’s wins",
    "Celebrate a team’s wins",
  ],
  image: ProjectCompletedImg,
  chatType: "celebrateWins",
};

export const PRODUCTIVITY_COACHBOT_PROMPT_TEMPLATE: CoachbotCardTemplate[] = [
  GET_MOTIVATED_TEMPLATE,
  GET_CREATIVE_TEMPLATE,
  MANAGE_YOUR_TIME_TEMPLATE,
  RUN_GREAT_MEETINGS_TEMPLATE,
  PRIORITIZE_TASKS_TEMPLATE,
];

export const GOALS_AND_REFLECTION_COACHBOT_PROMPT_TEMPLATE: CoachbotCardTemplate[] =
  [
    BUILD_A_1_YEAR_PERSONAL_DEVELOPMENT_PLAN_TEMPLATE,
    CRAFT_EFFECTIVE_OKRS_TEMPLATE,
    ONE_WEEK_SMART_GOAL_TEMPLATE,
    REFLECT_OR_DEBRIEF_TEMPLATE,
  ];

export const WELL_BEING_COACHBOT_PROMPT_TEMPLATE: CoachbotCardTemplate[] = [
  PRACTICE_GRATITUDE_AND_OPTIMISM_TEMPLATE,
  CELEBRATE_YOUR_WINS_TEMPLATE,
  MANAGE_STRESS_TEMPLATE,
  CREATE_WORK_LIFE_BALANCE_TEMPLATE,
  BUILD_RESILIENCE_TEMPLATE,
];

export const COMMUNICATION_COACHBOT_PROMPT_TEMPLATE: CoachbotCardTemplate[] = [
  COMMUNICATE_BETTER_TEMPLATE,
  GIVE_OR_RECEIVE_FEEDBACK_TEMPLATE,
  MASTER_CHALLENGING_CONVERSATIONS_TEMPLATE,
  GIVE_A_PERFORMANCE_REVIEW_TEMPLATE,
];

export const WORK_SKILLS_COACHBOT_PROMPT_TEMPLATE: CoachbotCardTemplate[] = [
  NEGOTIATION_COACH_TEMPLATE,
  CONDUCT_A_PREMORTEM_TEMPLATE,
  DRAFT_A_FUTURE_TEMPLATE,
  DELEGATE_WELL_TEMPLATE,
];

export const DECISION_MAKING_COACHBOT_PROMPT_TEMPLATE: CoachbotCardTemplate[] =
  [PRO_CON_LIST_TEMPLATE, THINK_BETTER_TEMPLATE];

export const ALL_COACHBOT_PROMPT_TEMPLATES: {
  [key: string]: {
    promptTemplates: CoachbotCardTemplate[];
    description?: string;
  };
} = {
  Productivity: {
    promptTemplates: PRODUCTIVITY_COACHBOT_PROMPT_TEMPLATE,
  },
  "Well-Being": {
    promptTemplates: WELL_BEING_COACHBOT_PROMPT_TEMPLATE,
  },
  "Goals & Reflection": {
    promptTemplates: GOALS_AND_REFLECTION_COACHBOT_PROMPT_TEMPLATE,
  },
  Communication: {
    promptTemplates: COMMUNICATION_COACHBOT_PROMPT_TEMPLATE,
  },
  "Work Skills": {
    promptTemplates: WORK_SKILLS_COACHBOT_PROMPT_TEMPLATE,
    description: "Power chats to help you be your best at work.",
  },
  "Decision Making": {
    promptTemplates: DECISION_MAKING_COACHBOT_PROMPT_TEMPLATE,
  },
};

// ----------------------- TEAMSCAN COACHBOT PROMPTS -----------------------
type TeamScanCoachbotCardTemplate = Pick<
  CoachbotCardTemplate,
  "coachBotIntro" | "chatType" | "preSelectedPrompts"
>;
export const TEAM_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE: TeamScanCoachbotCardTemplate =
  {
    coachBotIntro:
      "I'm here to help you understand your team's TEAMscan results. Where would you like to start?",
    chatType: "team",
    preSelectedPrompts: [
      "Understand this team's overall score",
      "Understand this team's highest and lowest scoring dimensions",
      "Help me build an action plan based on this team's results",
    ],
  };
export const DEPARTMENT_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE: TeamScanCoachbotCardTemplate =
  {
    coachBotIntro:
      "I'm here to help you understand your department's TEAMscan results. Where would you like to start?",
    chatType: "department",
    preSelectedPrompts: [
      "Understand this department's overall score",
      "Understand this department's highest and lowest scoring dimensions",
      "Help me build an action plan based on this department's results",
    ],
  };
export const ORG_TEAMSCAN_COACHBOT_PROMPT_TEMPLATE: TeamScanCoachbotCardTemplate =
  {
    coachBotIntro:
      "I'm here to help you understand your organization's TEAMscan results. Where would you like to start?",
    chatType: "company",
    preSelectedPrompts: [
      "Understand this org's overall score",
      "Understand this org's highest and lowest scoring dimensions",
      "Help me build an action plan based on this org's results",
    ],
  };
