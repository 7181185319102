import MailSentIllustration from "resources/images/Illustrations-Teams_TeamConfirmation.png";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  resetSuccessCount,
  selectInvitationSuccessCount,
  selectTimeToCompleteCSVUpload,
} from "./slice";
import SimpleModal from "app/components/Modals/SimpleModal";
import { getS } from "utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CSVInvitationSuccessModal() {
  const dispatch = useAppDispatch();
  const csvSuccessCount = useAppSelector(selectInvitationSuccessCount);
  const timeToCompleteCSVUpload = useAppSelector(selectTimeToCompleteCSVUpload);

  const onHide = () => {
    dispatch(resetSuccessCount());
  };

  const getTimeToCompleteCSVUpload = () => {
    if (!timeToCompleteCSVUpload) return null;
    return (
      <p>
        It may take up to{" "}
        <b>
          {timeToCompleteCSVUpload} minute{getS(timeToCompleteCSVUpload)}
        </b>{" "}
        to fully upload all user information and make changes.
      </p>
    );
  };

  const getHaveOrHas = (count: number) => {
    return count === 1 ? "has" : "have";
  };

  const getCountText = () => {
    if (!csvSuccessCount) return "";
    return (
      <>
        <span>
          {csvSuccessCount.added ? (
            <FontAwesomeIcon icon="check-circle" className="me-2" />
          ) : null}
          {csvSuccessCount.added} user{getS(csvSuccessCount.added)}{" "}
          {getHaveOrHas(csvSuccessCount.added)} been added
        </span>
        <span>
          {csvSuccessCount.updated ? (
            <FontAwesomeIcon icon="check-circle" className="me-2" />
          ) : null}
          {csvSuccessCount.updated} user{getS(csvSuccessCount.updated)}{" "}
          {getHaveOrHas(csvSuccessCount.updated)} been updated
        </span>
        <span>
          {csvSuccessCount.deactivated ? (
            <FontAwesomeIcon icon="check-circle" className="me-2" />
          ) : null}
          {csvSuccessCount.deactivated} user{getS(csvSuccessCount.deactivated)}{" "}
          {getHaveOrHas(csvSuccessCount.deactivated)} been deactivated
        </span>
      </>
    );
  };

  const getContent = () => {
    return (
      <div className="invitation-sent column-gap-16px">
        <div>
          <img src={MailSentIllustration} alt="People" />
        </div>
        {getCountText()}
        {getTimeToCompleteCSVUpload()}
        <div>
          <Button onClick={onHide} variant="secondary-blue">
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <SimpleModal
      show={csvSuccessCount !== null}
      onHide={onHide}
      title="Successfully Uploaded"
    >
      {getContent()}
    </SimpleModal>
  );
}
