import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { ReactNode, useState } from "react";
import { Collapse } from "react-bootstrap";

interface Props {
  items: ReactNode[];
  maxShowing?: number;
  className?: string;
  collapsedText?: string;
  expandedText?: string;
}

export default function MultiCardCollapsibleContainer({
  items,
  maxShowing = 5,
  className,
  collapsedText = "See all surveys",
  expandedText = "Collapse",
}: Readonly<Props>) {
  const [isExpanded, setIsExpanded] = useState(false);

  const getCollapseButton = () => {
    if (secondArray?.length === 0) {
      return null;
    }

    if (isExpanded) {
      return (
        <Button
          variant="tertiary-blue"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {expandedText}
          <FontAwesomeIcon icon={`caret-up`} className="ms-2" />
        </Button>
      );
    }
    return (
      <Button
        variant="tertiary-blue"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {collapsedText}
        <FontAwesomeIcon icon={`caret-down`} className="ms-2" />
      </Button>
    );
  };

  const firstArray = items.slice(0, maxShowing);
  const secondArray = items.slice(maxShowing);

  return (
    <>
      <div className={`section-container mb-0 ${className}`}>
        {firstArray}
        <Collapse in={isExpanded}>
          <div className="collapse-container">{secondArray}</div>
        </Collapse>
      </div>
      {getCollapseButton()}
    </>
  );
}
