import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { Card } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { ButtonVariant } from "app/storybookComponents/Button/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  iconName?: IconName;
  iconPrefix?: IconPrefix;
  imageIcon?: React.ReactNode;
  listSpan?: string;
  title: string;
  description: string;
  actionButton?: {
    onClick: () => void;
    text: string;
    disabled?: boolean;
    variant?: ButtonVariant;
  };
  secondaryButton?: {
    onClick: () => void;
    text: string;
    disabled?: boolean;
  };
  isWholeCardDisabled?: boolean;
  cornerTag?: JSX.Element | null;
  className?: string;
}

export default function QuickActionCard({
  iconName,
  iconPrefix = "fas",
  title,
  actionButton,
  secondaryButton,
  description,
  listSpan,
  isWholeCardDisabled,
  imageIcon,
  cornerTag,
  className,
}: Readonly<Props>) {
  const getCardClassName = () => {
    let cardClassName = "quick-action-card";
    if (isWholeCardDisabled) {
      cardClassName += " disabled";
    }
    if (className) {
      cardClassName += ` ${className}`;
    }
    return cardClassName;
  };

  const getIconSquare = () => {
    if (imageIcon) {
      return <div className="small-square-icon">{imageIcon}</div>;
    }

    if (iconName) {
      return (
        <div className="small-square-icon">
          <FontAwesomeIcon icon={[iconPrefix, iconName]} className="icon" />
        </div>
      );
    }
    return null;
  };

  const getButton = (
    buttonObj?: {
      onClick: () => void;
      text: string;
      disabled?: boolean;
      variant?: ButtonVariant;
    },
    className?: string
  ) => {
    if (!buttonObj) {
      return null;
    }

    return (
      <Button
        variant={buttonObj.variant ?? "secondary-blue"}
        className={className}
        onClick={() => {
          if (isWholeCardDisabled) {
            return;
          }
          buttonObj.onClick();
        }}
        disabled={buttonObj.disabled || isWholeCardDisabled}
      >
        {buttonObj.text}
      </Button>
    );
  };

  return (
    <div>
      {cornerTag ? (
        <div className="quick-action-card-corner-tag">{cornerTag}</div>
      ) : null}
      <Card className={getCardClassName()}>
        {getIconSquare()}
        {listSpan ? <span className="list-span">{listSpan}</span> : null}
        <div className="column-gap-8px">
          <p>
            <b>{title}</b>
          </p>
          <p
            style={{
              color: "#53565a",
            }}
          >
            {description}
          </p>
        </div>
        {getButton(actionButton, "mt-auto")}
        {getButton(secondaryButton, "border-0")}
      </Card>
    </div>
  );
}
