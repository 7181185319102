import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { UserVisibilitySettings } from "./types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";

export interface UserSettingsState {
  userVisibilitySetting: {
    [userAccountId: number]: UserVisibilitySettings;
  };
  getUserSettingsStatus: responseStatus;
  editUserSettingsStatus: responseStatus;
}

export const initialState: UserSettingsState = {
  userVisibilitySetting: {},
  getUserSettingsStatus: "idle",
  editUserSettingsStatus: "idle",
};

export const getUserVisibilitySettings = createAsyncThunk(
  "userSettings/getUserVisibilitySettings",
  async ({
    userAccountId,
  }: {
    userAccountId: number;
    forceRefresh?: boolean;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/visibility`;
    const response = (await request(requestUrl)) as UserVisibilitySettings;
    return {
      userAccountId,
      visibilitySettings: response,
    };
  },
  {
    condition: ({ userAccountId, forceRefresh }, { getState }) => {
      const userVisibilitySetting = (getState() as RootState).userSettings
        .userVisibilitySetting[userAccountId];
      if (userVisibilitySetting && !forceRefresh) {
        return false;
      }
    },
  }
);

export const updateUserVisibilitySettings = createAsyncThunk(
  "userSettings/updateUserVisibilitySettings",
  async (
    {
      payload,
      userAccountId,
    }: {
      payload: Partial<UserVisibilitySettings>;
      userAccountId: number;
    },
    { getState }
  ) => {
    const existingVisibilitySettings = (getState() as RootState).userSettings
      .userVisibilitySetting[userAccountId];
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/visibility`;
    await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ ...existingVisibilitySettings, ...payload }),
    });

    return {
      userAccountId,
      visibilitySettings: {
        ...payload,
      },
    };
  }
);

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    resetUserVisibilitySettings: (state) => {
      state.userVisibilitySetting = {};
      state.getUserSettingsStatus = "idle";
      state.editUserSettingsStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserVisibilitySettings.pending, (state) => {
        state.getUserSettingsStatus = "loading";
      })
      .addCase(getUserVisibilitySettings.fulfilled, (state, action) => {
        return {
          ...state,
          getUserSettingsStatus: "succeeded",
          userVisibilitySetting: {
            ...state.userVisibilitySetting,
            [action.payload.userAccountId]: action.payload.visibilitySettings,
          },
        };
      })
      .addCase(getUserVisibilitySettings.rejected, (state) => {
        state.getUserSettingsStatus = "failed";
      })
      .addCase(updateUserVisibilitySettings.pending, (state) => {
        state.editUserSettingsStatus = "loading";
      })
      .addCase(updateUserVisibilitySettings.fulfilled, (state, action) => {
        toast.success(
          "User settings updated successfully",
          successNotificationOptions
        );
        return {
          ...state,
          editUserSettingsStatus: "succeeded",
          userVisibilitySetting: {
            ...state.userVisibilitySetting,
            [action.payload.userAccountId]: {
              ...state.userVisibilitySetting[action.payload.userAccountId],
              ...action.payload.visibilitySettings,
            },
          },
        };
      })
      .addCase(updateUserVisibilitySettings.rejected, (state) => {
        state.editUserSettingsStatus = "failed";
      });
  },
});

export const selectGetUserSettingsStatus = (state: RootState) =>
  state.userSettings.getUserSettingsStatus;
export const selectLoggedInUsersVisibilitySettings = (state: RootState) => {
  const { currentUserAccountId } = state.global;
  return currentUserAccountId
    ? state.userSettings.userVisibilitySetting[currentUserAccountId]
    : null;
};
export const selectUserVisibilitySettingsByUserAccountId =
  (userAccountId: number) => (state: RootState) =>
    state.userSettings.userVisibilitySetting[userAccountId];

export const { resetUserVisibilitySettings } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
