import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadCSV } from "utils/helperFunctions";
import { useAppDispatch } from "utils/redux/hooks";
import UploadCsvButton from "../Button/UploadCsvButton";
import { PEOPLE_COLUMNS } from "app/containers/AdvancedCsvUpload/constants";
import { CSVUploadTableStructureError } from "../Button/types";
import { ClipLoader } from "react-spinners";
import { RawRowData, StoredRows } from "app/containers/AdvancedCsvUpload/types";
import { useNavigate } from "react-router-dom";
import {
  getErrorsByType,
  previewInviteUsersViaCSV,
  // setActiveCSVUploadHeaders,
  setUploadedData,
} from "app/containers/AdvancedCsvUpload/slice";
import { Collapse } from "react-bootstrap";
import Toggle from "app/components/Toggle";

interface Props {
  onHide?: () => void;
  teamId?: number;
  isLoading?: boolean;
  onCSVUploadSuccess?: () => void;
}

export default function UploadCSVForm(props: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [structureError, setStructureError] =
    useState<CSVUploadTableStructureError | null>(null);
  const [error, setError] = useState<"file-size-error" | "empty-file" | null>(
    null
  );
  const [isUploadingLoading, setIsUploadingLoading] = useState(false);
  const [checkingTableStructure, setCheckingTableStructure] = useState(false);
  const [isViewCSVRequirementsOpenned, setIsViewCSVRequirementsOpenned] =
    useState(false);
  const [addNewEmployees, setAddNewEmployees] = useState(true);
  const [updateExistingEmployees, setUpdateExistingEmployees] = useState(false);
  const [deactivateEmployeesNotInCSV, setDeactivateEmployeesNotInCSV] =
    useState(false);

  const downloadTemplateCSV = () => {
    const TEMPLATE_COLUMNS_MAP = {
      firstName: "First Name",
      lastName: "Last Name",
      emailAddress: "Email",
      jobTitle: "Job Title",
      gender: "Gender",
      managerEmailAddress: "Manager Email",
    };
    const data: string[][] = [Object.values(TEMPLATE_COLUMNS_MAP)];
    data.push(Object.values(PEOPLE_COLUMNS).map(() => "")); // Add an empty row
    downloadCSV("template", data);
  };

  const handleCSVUploadSuccess = async (
    payload: RawRowData[],
    headers?: string[]
  ) => {
    setIsUploadingLoading(true);
    const newUpdatedData: StoredRows = {};
    payload.forEach((row, index) => {
      const gender =
        typeof row.gender === "string" ? row.gender?.toLowerCase() : row.gender;
      newUpdatedData[index] = {
        firstName: row.firstName,
        lastName: row.lastName,
        emailAddress: row.emailAddress,
        jobTitle: row.jobTitle,
        gender,
        managerEmailAddress: row.managerEmailAddress,
        id: Number(index),
      };
    });

    // if (headers) {
    //   dispatch(setActiveCSVUploadHeaders(headers));
    // }
    // We need to make the request to the backend so we can validate the emails
    dispatch(setUploadedData(newUpdatedData));
    await dispatch(
      previewInviteUsersViaCSV({
        rows: newUpdatedData,
        options: {
          addNewEmployees: addNewEmployees ? 1 : 0,
          updateExistingEmployees: updateExistingEmployees ? 1 : 0,
          deactivateEmployeesNotInCSV: deactivateEmployeesNotInCSV ? 1 : 0,
        },
      })
    );
    dispatch(getErrorsByType());
    setIsUploadingLoading(false);
    navigate(`/AdvancedCsvUpload`);
  };

  const getCSVUploadButton = (buttonText: string = "Upload .csv") => (
    <UploadCsvButton
      resetUpload={() => {
        setStructureError(null);
        setError(null);
      }}
      onCSVUploadSuccess={handleCSVUploadSuccess}
      templateColumns={PEOPLE_COLUMNS}
      setStructureError={setStructureError}
      setError={setError}
      setLoading={setCheckingTableStructure}
      buttonText={
        <>
          <FontAwesomeIcon icon="upload" className="me-2" />
          {buttonText}
        </>
      }
      maxRows={5000}
      disabled={
        !(
          addNewEmployees ||
          updateExistingEmployees ||
          deactivateEmployeesNotInCSV
        )
      }
    />
  );

  const getCheckingComponent = () => {
    if (isUploadingLoading || checkingTableStructure) {
      return (
        <div className="column-gap-12px">
          <h3>Checking File Structure...</h3>
          <ClipLoader color="#36d7b7" speedMultiplier={0.5} />
        </div>
      );
    }

    if (structureError) {
      return (
        <div className="warning-banner red">
          <p>
            Please ensure your file has the following required columns: "First
            Name", "Last Name", "Email"
          </p>
        </div>
      );
    }

    if (error === "empty-file") {
      return (
        <div className="warning-banner red">
          <p>
            Sorry, the file you uploaded is empty. Please double check your file
            and try again.
          </p>
        </div>
      );
    }

    if (error === "file-size-error") {
      return (
        <div className="warning-banner red">
          <p>
            <FontAwesomeIcon icon="exclamation-triangle" className="me-2" />
            You can not process more than 5,000 users at one time. Please
            re-upload your file and try again.
          </p>
        </div>
      );
    }

    return null;
  };

  const getToggleOption = (
    label: string,
    state: boolean,
    setState: (v: boolean) => void
  ) => {
    return (
      <div className="d-flex">
        <div>
          <Toggle
            isOn={state}
            handleToggle={() => setState(!state)}
            size="sm"
          />
        </div>
        <p className="ms-2">{label}</p>
      </div>
    );
  };

  const getImportOptions = () => {
    return (
      <div className="column-gap-16px">
        <p style={{ marginTop: "-4px", fontWeight: "bold" }}>Import Options</p>
        {getToggleOption(
          "Add new employees",
          addNewEmployees,
          setAddNewEmployees
        )}
        {getToggleOption(
          "Update existing employees",
          updateExistingEmployees,
          setUpdateExistingEmployees
        )}
        {getToggleOption(
          "Deactivate employees who are not found in import",
          deactivateEmployeesNotInCSV,
          setDeactivateEmployeesNotInCSV
        )}
      </div>
    );
  };

  const getViewCsvRequirements = () => {
    return (
      <div className="column-gap-20px">
        <button
          className="button-link"
          onClick={() =>
            setIsViewCSVRequirementsOpenned(!isViewCSVRequirementsOpenned)
          }
        >
          View CSV Format Requirements
        </button>
        <Collapse
          in={isViewCSVRequirementsOpenned}
          onExited={() => setIsViewCSVRequirementsOpenned(false)}
        >
          <div>
            <p>CSV files should be formatted with 5 columns:</p>
            <ul>
              <li>First Name</li>
              <li>Last Name</li>
              <li>Work Email Address</li>
              <li>Job Title (Optional)</li>
              <li>Gender (Optional)</li>
              <li>Manager Email</li>
            </ul>
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <div>
      <div className="column-gap-20px">
        <div>
          <p className="mb-0">
            To invite users via .csv file, please download this{" "}
            <span
              onClick={() => downloadTemplateCSV()}
              role="button"
              className="link-primary"
            >
              template (.csv)
            </span>
          </p>
        </div>
        <p>Then, upload the completed file below:</p>
        <div>
          {getCSVUploadButton()}
          <p style={{ color: "#86888b", marginTop: "10px" }}>
            File size limit: 5 MB
          </p>
        </div>
        {getViewCsvRequirements()}
        {getImportOptions()}
        {getCheckingComponent()}
      </div>
    </div>
  );
}
