import { Card } from "react-bootstrap";
import { CoachbotCardTemplate } from "./types";

interface Props {
  template: CoachbotCardTemplate;
  isSelected?: boolean;
  onSelect: (template: CoachbotCardTemplate) => void;
}
export default function CoachbotPromptCard({
  template,
  isSelected,
  onSelect,
}: Readonly<Props>) {
  const getClassName = () => {
    let baseClassName = "coachbot-prompt-card";
    if (isSelected) {
      baseClassName += " selected";
    }
    return baseClassName;
  };

  return (
    <Card
      className={getClassName()}
      onClick={() => {
        onSelect(template);
      }}
    >
      <img
        src={template.image}
        alt="Criteria"
        className="coachbot-prompt-card-illustration"
      />
      <div className="coachbot-prompt-description">
        <h3>{template.title}</h3>
        <p>{template.description}</p>
      </div>
    </Card>
  );
}
