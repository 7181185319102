export const TEAM_TABS = {
  ABOUT: "About",
  TEAMSCAN: "TEAMscan",
  TEAM_PERSONALITY: "Team Personality",
  TEAM_SETTINGS: "Team Settings",
} as const;

export const SAMPLE_TEAM_IDS = [1104, 1105] as const;

interface TeamSettingOption {
  value: string;
  label: string;
}

export const TEAM_SCAN_VISIBILITY_OPTIONS: {
  [key: string | number]: TeamSettingOption;
} = {
  1: { value: "1", label: "Anyone in this organization" },
  2: {
    value: "2",
    label:
      "Only team members on this team (Including department leaders and admin)",
  },
  3: {
    value: "3",
    label: "Only me (Including department leaders and admin)",
  },
};

export const TeamVisibilitySettingsLabelMap: Record<string, string> = {
  Admin: "Admin",
  "Department Leader": "This team's department leader",
  "Team Leader": "This team's team leader(s)",
  "Team Member": "Team members on this team",
  "Organization Member": "Everyone in this organization",
};
