import { ConversationPartner } from "app/containers/TeamGuide/types";
import { ReactNode, useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import AvatarCircle from "app/components/AvatarCircle";
import DimensionLinkedText from "app/components/TextWithLink/DimensionLinkedText";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  comparingUserAccountId: number | null;
  conversationPartners?: ConversationPartner[] | null;
  deckLabel?: string;
  deckLabelElement?: ReactNode;
}

export default function Team360ConversationDeck({
  conversationPartners,
  deckLabel = "Two conversations you might find interesting:",
  deckLabelElement,
  comparingUserAccountId,
}: Props) {
  const navigate = useNavigate();

  const [storedConversations, setStoredConversations] = useState<
    ConversationPartner[]
  >([]);

  useEffect(() => {
    setStoredConversations(conversationPartners ?? []);
  }, [conversationPartners]);

  const onGetAnotherConversation = () => {
    if (!conversationPartners) return;
    // If there are more than 3 cards remaining then splice off the first two
    if (storedConversations.length > 2) {
      const newStoredConversations = storedConversations.slice(2);

      // So that we always have at least 2 cards if there is only one card left then we need to add the original array to the end of the new array
      if (newStoredConversations.length === 1) {
        setStoredConversations([
          ...newStoredConversations,
          ...conversationPartners,
        ]);
      } else {
        setStoredConversations(newStoredConversations);
      }
      return;
    }

    // If there are less than 3 cards then we need to reset the skillIds to the original array
    setStoredConversations(conversationPartners);
  };

  const getConversationCard = ({
    instructions,
    userAccountId,
    analysis,
    tag,
  }: ConversationPartner) => {
    const firstUserUrl = comparingUserAccountId
      ? "/UserGuide"
      : `/UserGuide/${userAccountId}`;

    return (
      <Card key={userAccountId + analysis}>
        <div className="d-flex">
          <Link to={firstUserUrl} className="no-underline">
            <AvatarCircle userAccountId={comparingUserAccountId ?? undefined} />
          </Link>
          <div
            style={{
              marginLeft: "-5px",
            }}
            role="button"
            onClick={() => navigate(`/UserGuide/${userAccountId}`)}
            tabIndex={0}
          >
            <AvatarCircle userAccountId={userAccountId} />
          </div>
        </div>
        <h4>{instructions}</h4>
        <DimensionLinkedText linkText={analysis} />
        <span className="label-tag m-0 grey">{tag}</span>
      </Card>
    );
  };

  if (!conversationPartners) return null;
  return (
    <>
      <div>
        {deckLabelElement ?? (
          <p className="grey-text">
            <b>{deckLabel}:</b>
          </p>
        )}
        <div
          className="skills-cards-container"
          style={{
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          {storedConversations
            .map((obj) => getConversationCard(obj))
            .splice(0, 2)}
        </div>
      </div>
      <div>
        <Button variant="secondary-blue" onClick={onGetAnotherConversation}>
          Get another conversation
          <FontAwesomeIcon icon="arrows-rotate" className="ms-2" />
        </Button>
      </div>
    </>
  );
}
