import { memo } from "react";

interface Props {
  isOn: boolean;
  handleToggle: () => void;
  size?: "sm" | "md" | "lg";
  loading?: boolean;
}

const Toggle = ({ isOn, handleToggle, size = "md", loading }: Props) => {
  const getToggleClassName = () => {
    let baseText = `toggle ${size}`;
    if (isOn) {
      baseText += " active";
    }
    if (loading) {
      baseText += " loading";
    }

    return baseText;
  };

  return (
    <div className={getToggleClassName()} onClick={handleToggle}>
      <div className="toggle-thumb" />
    </div>
  );
};

export default memo(Toggle);
