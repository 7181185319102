import { Team } from "app/containers/Global/types";
import AvatarCircle from "../AvatarCircle";
import { Department } from "app/containers/AdminConsole/types";

export const getTeamPreviewSentTo = (
  teamsByTeamId: { [teamId: number]: Team },
  teamIds: number[]
): {
  avatars: React.JSX.Element[];
  title: string;
  description: string;
} => {
  const avatars: React.JSX.Element[] = [];
  let title = "";
  let description = "";
  if (teamIds.length === 1) {
    const teamObj = teamsByTeamId[teamIds[0]];
    const totalMemberSet = new Set([
      ...(teamObj?.teamMemberIds ?? []),
      ...(teamObj?.pendingTeamMemberIds ?? []),
    ]);
    const membersInvited = totalMemberSet.size;
    title = teamObj?.teamName || "";
    description = `Sending to ${membersInvited} member${
      membersInvited > 1 ? "s" : ""
    }`;
    avatars.push(
      <AvatarCircle
        name={teamObj?.teamName}
        profilePicture={teamObj?.profilePicture}
        avatarColor={teamObj?.avatarColor}
        size="small"
      />
    );
    return { avatars, title, description };
  }

  // Setting avatars
  if (teamIds.length <= 3) {
    teamIds.forEach((teamId) => {
      const teamObj = teamsByTeamId[teamId];
      if (teamObj) {
        avatars.push(
          <AvatarCircle
            name={teamObj.teamName}
            profilePicture={teamObj.profilePicture}
            avatarColor={teamObj.avatarColor}
            size="small"
          />
        );
      }
    });
  } else {
    teamIds.slice(0, 2).forEach((teamId) => {
      const teamObj = teamsByTeamId[teamId];
      if (teamObj) {
        avatars.push(
          <AvatarCircle
            name={teamObj.teamName}
            profilePicture={teamObj.profilePicture}
            avatarColor={teamObj.avatarColor}
            size="small"
          />
        );
      }
    });
    avatars.push(
      <AvatarCircle
        name={`${teamIds.length - 2} +`}
        avatarColor="53565A"
        size="small"
      />
    );
  }

  // Setting title
  if (teamIds.length === 2) {
    title = `${teamsByTeamId[teamIds[0]]?.teamName} and ${
      teamsByTeamId[teamIds[1]]?.teamName
    }`;
  } else if (teamIds.length === 3) {
    title = `${teamsByTeamId[teamIds[0]]?.teamName}, ${
      teamsByTeamId[teamIds[1]]?.teamName
    } and ${teamsByTeamId[teamIds[2]]?.teamName}`;
  } else if (teamIds.length > 3) {
    title = `${teamsByTeamId[teamIds[0]]?.teamName}, ${
      teamsByTeamId[teamIds[1]]?.teamName
    } and ${teamIds.length - 2} other teams`;
  }

  // Setting description
  description = `Sending to ${teamIds.length} teams`;

  return { avatars, title, description };
};

export const getDepartmentPreviewSentTo = (
  departments: { [departmentId: number]: Department },
  departmentIds: number[]
): {
  avatars: React.JSX.Element[];
  title: string;
  description: string;
} => {
  const avatars: React.JSX.Element[] = [];
  let title = "";
  let description = "";
  if (departmentIds.length === 1) {
    const departmentObj = departments[departmentIds[0]];
    const membersInvited = departmentObj?.teamMembers?.length || 0;
    title = departmentObj?.name || "";
    if (membersInvited !== 0) {
      description = `Sending to ${membersInvited} member${
        membersInvited > 1 ? "s" : ""
      }`;
    } else {
      description = `There are currently no members in this department. Once team members join this department, they will be invited to this survey.`;
    }
    avatars.push(
      <AvatarCircle
        name={departmentObj?.name}
        // profilePicture={departmentObj?.profilePicture}
        // avatarColor={departmentObj?.avatarColor}
        size="small"
      />
    );
    return { avatars, title, description };
  }

  // Setting avatars
  if (departmentIds.length <= 3) {
    departmentIds.forEach((teamId) => {
      const departmentObj = departments[teamId];
      if (departmentObj) {
        avatars.push(
          <AvatarCircle
            name={departmentObj.name}
            // profilePicture={departmentObj.profilePicture}
            // avatarColor={departmentObj.avatarColor}
            size="small"
          />
        );
      }
    });
  } else {
    departmentIds.slice(0, 2).forEach((teamId) => {
      const departmentObj = departments[teamId];
      if (departmentObj) {
        avatars.push(
          <AvatarCircle
            name={departmentObj.name}
            // profilePicture={departmentObj.profilePicture}
            // avatarColor={departmentObj.avatarColor}
            size="small"
          />
        );
      }
    });
    avatars.push(
      <AvatarCircle
        name={`${departmentIds.length - 2} +`}
        avatarColor="53565A"
        size="small"
      />
    );
  }

  // Setting title
  if (departmentIds.length === 2) {
    title = `${departments[departmentIds[0]]?.name} and ${
      departments[departmentIds[1]]?.name
    }`;
  } else if (departmentIds.length === 3) {
    title = `${departments[departmentIds[0]]?.name}, ${
      departments[departmentIds[1]]?.name
    } and ${departments[departmentIds[2]]?.name}`;
  } else if (departmentIds.length > 3) {
    title = `${departments[departmentIds[0]]?.name}, ${
      departments[departmentIds[1]]?.name
    } and ${departmentIds.length - 2} other departments`;
  }

  // Setting description
  description = `Sending to ${departmentIds.length} departments`;

  return { avatars, title, description };
};
