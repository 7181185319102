import Button from "app/storybookComponents/Button";
import SimpleModal from "app/components/Modals/SimpleModal";
import { useState } from "react";
import Toggle from "app/components/Toggle";
import { responseStatus } from "utils/types";
import Loading from "app/storybookComponents/Loading";

interface Props {
  show?: boolean;
  onClose: () => void;
  onSave: (deleteAutomaticTeams: boolean) => void;
  turnOffAutomaticTeamsSettingStatus: responseStatus;
}

export default function TurnOnAutomaticTeamsModal({
  show,
  onClose,
  onSave,
  turnOffAutomaticTeamsSettingStatus,
}: Readonly<Props>) {
  const [saveExistingAutomaticTeams, setSaveExistingAutomaticTeams] =
    useState(true);

  const getTextExplainingBehavior = () => {
    return (
      <p style={{ textAlign: "center" }}>
        {saveExistingAutomaticTeams ? (
          <>
            All existing teams will be saved and converted to manual teams.
            Admins and team leaders can configure team members and team profile
            information for manual teams. These teams will no longer be
            automatically updated based on manager-employee relationship.
          </>
        ) : (
          <>
            All Automatic teams will be <strong>deactivated</strong>.
          </>
        )}
      </p>
    );
  };

  const getContent = () => {
    if (turnOffAutomaticTeamsSettingStatus === "loading") {
      return <Loading />;
    }

    return (
      <div className="turn-off-automatic-teams-content">
        <h3 style={{ paddingBottom: "8px" }}>
          Are you sure you want to turn off Automatic teams for your
          organization?
        </h3>
        <p>
          Choose either to deactivate all Automatic teams in your organization
          or save all existing Automatic teams and convert them to manual teams.
          Note: If Automatic teams is turned on again, this will generate new
          teams.
        </p>
        <div className="automatic-team-info">
          <div className="toggle-row">
            <Toggle
              isOn={saveExistingAutomaticTeams}
              handleToggle={() => {
                setSaveExistingAutomaticTeams(!saveExistingAutomaticTeams);
              }}
            />
            <div>Save all existing Automatic teams?</div>
          </div>
          {getTextExplainingBehavior()}
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button onClick={onClose} variant="secondary-blue">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSave(!saveExistingAutomaticTeams);
              onClose();
            }}
          >
            Confirm and Turn Off
          </Button>
        </div>
      </div>
    );
  };

  return (
    <SimpleModal show={show} onHide={onClose} title="Turn Off Automatic Teams">
      {getContent()}
    </SimpleModal>
  );
}
