import { USER_TABS } from "./constants";
export type UserGuideTabs = (typeof USER_TABS)[number];
export const isUserGuideTabs = (value: any): value is UserGuideTabs => {
  return USER_TABS.includes(value);
};

export type EppPermissions = {
  canSeeCollaborationGuide: boolean;
  canSeeWorkplaceInsights: boolean;
  canSeeOnTeamReport: boolean;
  canSeeManagerGuide: boolean;
};

export type ConnectedCandidate = {
  candidateStartDate: string;
  userAccountId: number;
  testTakerId: number;
  shareManagerGuide: 0 | 1;
  shareWorkplaceInsightReport: 0 | 1;
  shareResume: 0 | 1;
  shareCoverLetter: 0 | 1;
  noteToManager: string;
  resumeUrl?: string;
  coverLetterUrl?: string;
};
