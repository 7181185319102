import { useSearchParams } from "react-router-dom";
import React from "react";
import PeopleCsvUpload from "./PeopleCsvUpload";

interface AdvancedCsvUploadProps {
  // Define your prop types here
}

const AdvancedCsvUpload: React.FC<AdvancedCsvUploadProps> = (props) => {
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("teamId")
    ? parseInt(searchParams.get("teamId")!)
    : null;

  return (
    <div className="page guide">
      <PeopleCsvUpload teamId={teamId} />
    </div>
  );
};

export default AdvancedCsvUpload;
