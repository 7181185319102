import { useLayoutEffect } from "react";
import Button from "app/storybookComponents/Button";
import Robot from "resources/images/robot.svg";
import { useAppDispatch } from "utils/redux/hooks";
import { setHidingChatbotButtonFromRoot } from "./slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onCoachbotClick: () => void;
  text?: string;
  className?: string;
  includeButton?: boolean;
}
export default function CustomChatbotButton({
  onCoachbotClick,
  text = "Need help understanding this report?",
  className = "floating-chatbot-button",
  includeButton,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(setHidingChatbotButtonFromRoot(true));
    return () => {
      dispatch(setHidingChatbotButtonFromRoot(false));
    };
  }, [dispatch]);

  return (
    <button className={className} onClick={onCoachbotClick}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={Robot} alt="Criteria" className="assistant-avatar" />
        <p>{text}</p>
        {includeButton && (
          <Button variant="secondary-blue" className="border-0">
            Chat with Coach Bo <FontAwesomeIcon icon="arrow-right" />
          </Button>
        )}
      </div>
    </button>
  );
}
