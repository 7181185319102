import { Section } from "app/components/Modules/types";
import { UserInfo, Team } from "app/containers/Global/types";
import { ManagerAndDirectReports } from "app/containers/AdvancedCsvUpload/types";
import { userGuideSectionOrderPriority } from "./constants";

export const sortSections = (sections: Section[]): Section[] => {
  return sections.sort((a, b) => {
    const aHasPriority = a.title.toLowerCase() in userGuideSectionOrderPriority;
    const bHasPriority = b.title.toLowerCase() in userGuideSectionOrderPriority;

    if (aHasPriority && bHasPriority) {
      return (
        userGuideSectionOrderPriority[a.title.toLowerCase()] -
        userGuideSectionOrderPriority[b.title.toLowerCase()]
      );
    } else if (aHasPriority && !bHasPriority) {
      return -1; // move section a higher in the sort order
    } else if (!aHasPriority && bHasPriority) {
      return 1; // move section b higher in the sort order
    } else {
      return a.sectionId - b.sectionId; // maintain the original order
    }
  });
};

export const isAtLeastAMangerOf = ({
  userInfo,
  isAdmin,
  teamAndDepartmentsLeaderOf,
  teamsById,
  manageeMangerAndDirectReports,
  loggedInUserAccountId,
}: {
  isAdmin: boolean;
  loggedInUserAccountId: number | null;
  teamAndDepartmentsLeaderOf?: {
    teams: number[];
    departments?: number[];
  } | null;
  userInfo?: UserInfo;
  teamsById: {
    [teamId: number]: Team;
  };
  manageeMangerAndDirectReports?: ManagerAndDirectReports | null;
}) => {
  if (
    isAdmin ||
    manageeMangerAndDirectReports?.managerRelationship?.managerUserAccountId ===
      loggedInUserAccountId
  ) {
    return true;
  }

  if (!teamAndDepartmentsLeaderOf) {
    return false;
  }

  return userInfo?.teamIds?.some((teamId) => {
    const team = teamsById[teamId];
    if (
      teamAndDepartmentsLeaderOf.teams.includes(teamId) ||
      (team?.departmentId &&
        teamAndDepartmentsLeaderOf.departments?.includes(team.departmentId))
    ) {
      return true;
    }
    return false;
  });
};

export const getWelcomeChatPrompt = (
  firstName: string,
  userAccountId: number
) => ({
  userPrompts: [
    `Make ${firstName} feel wanted and needed`,
    `Create a "map" for ${firstName}'s success in this role and in your organization`,
    `Help ${firstName} build social connections`,
    `Make sure ${firstName} has the tools they need to succeed`,
  ],
  coachBotIntro: "",
  fullBotIntro: `Welcoming a new team member is a critical investment in your team's future success. Let's create a strong onboarding experience that makes ${firstName} feel needed, valued, and equipped to contribute. Where shall we begin?`,
  chatType: "",
  type: "onboardingChat" as const,
  chatTypeId: userAccountId,
});
// 1. First look if the override is on, if override is on then we shall check if the user is the logged in user, if so then we should have the fn redirect the user to the user settings.
// 2. Given the override is off or the user is not the logged in user, then we check if the user an admin. If they are an admin then the fn should take them to the admin console settings.
// 3. Else fn should be undefined
export const getUserVisibilityOnClick = ({
  navigate,
  isAdmin,
  isCurrentUser,
}: {
  navigate: (path: string) => void;
  isAdmin: boolean;
  isCurrentUser: boolean;
}):
  | undefined
  | {
      onClick: () => void;
      text: string;
    } => {
  if (isCurrentUser) {
    return {
      onClick: () => navigate("/UserSettings"),
      text: "Change your visibility settings",
    };
  }

  if (isAdmin) {
    return {
      onClick: () => navigate("/AdminConsole/Settings#visibility-settings"),
      text: "Change organization visibility settings",
    };
  }
};
