import Button from "app/storybookComponents/Button";
import SimplePopover from "app/components/Popovers/SimplePopover";
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  isAdmin?: boolean;
}

export default function AutomaticTeamPopover({
  children,
  isAdmin,
}: Readonly<Props>) {
  const navigate = useNavigate();
  if (!children) return null;
  return (
    <SimplePopover
      popoverTitle="Automatic Team"
      popoverContent={
        <div className="column-gap-16px">
          <p>
            This team was automatically generated and is automatically updated
            based on manager-employee relationships. Managers are automatically
            assigned as team leader.
          </p>
          {isAdmin ? (
            <div>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(`/AdminConsole/Automations`);
                }}
                variant="secondary-blue"
              >
                Configure automations
              </Button>
            </div>
          ) : null}
        </div>
      }
      trigger="hover"
    >
      <>{children}</>
    </SimplePopover>
  );
}
