import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectSampleUsersInfoById,
} from "app/containers/Global/slice";

interface Props {
  avatarColor?: string;
  profilePicture?: string | null;
  name?: string;
  onAddPicture?: () => void;
  hasAddPictureIcon?: boolean;
  userAccountId?: number | null;
  size?: "small" | "medium" | "large" | "extra-large";
  type?: "user" | "team" | "company";
  className?: string;
  icon?: JSX.Element;
}

export default function AvatarCircle({
  avatarColor,
  name,
  profilePicture,
  hasAddPictureIcon,
  onAddPicture,
  userAccountId,
  size = "medium",
  type = "user",
  className = "",
  icon,
}: Props) {
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const allCompanyUsersById = useAppSelector(selectAllCompanyUsersById);
  const userInfoById = { ...sampleUsersById, ...allCompanyUsersById };

  const getInitials = () => {
    if (icon) {
      return icon;
    }

    if (type === "user" && userAccountId && userInfoById[userAccountId]) {
      // If the user exist inside of userInfoById, then we use the initials from there
      return getUserInitialsFromUserInfo();
    }

    if (!name) return "";
    let initials = "";
    name.split(" ").forEach((word, idx) => {
      if (idx > 1 || !word[0]) return;
      initials += word[0];
    });

    return initials.toUpperCase();
  };

  const getUserInitialsFromUserInfo = () => {
    if (!userAccountId || !userInfoById[userAccountId]) {
      return "";
    }
    let initials = `${userInfoById[userAccountId]?.firstName[0] || ""}${
      userInfoById[userAccountId]?.lastName[0] || ""
    }`;
    // If the initials are empty, then we use the first two letters of the email
    if (!initials) {
      initials = userInfoById[userAccountId].emailAddress?.substring(0, 2);
    }
    return initials.toUpperCase();
  };

  // If the user exist inside of userInfoById, then we use the profile picture from there
  // This function might be overkill and might not actually need it or even the isValidHttpUrl function
  // Because we are still going to be storing the url but only using the base64 image if we are trying to edit the image
  const getImageSource = () => {
    let imageSource = null;
    if (type === "user" && userAccountId && userInfoById[userAccountId]) {
      imageSource = userInfoById[userAccountId]?.profilePicture;
    } else if (profilePicture) {
      imageSource = profilePicture;
    }
    return imageSource;
  };

  const getClassName = () => {
    let customClassName = `avatar-circle ${size}`;

    if (!profilePicture) {
      customClassName += " initials border-white";
    }

    if (hasAddPictureIcon) {
      customClassName += " position-relative";
    }

    if (className) {
      customClassName += ` ${className}`;
    }

    return customClassName;
  };

  const imgSrc = getImageSource();
  const imgBkgColor =
    avatarColor || (userAccountId && userInfoById[userAccountId]?.avatarColor);

  const style: { [styleName: string]: string } = {};

  if (!imgSrc) {
    style.backgroundColor = `#${imgBkgColor}`;
  }

  return (
    <div className={getClassName()} style={style}>
      {imgSrc ? (
        <img src={imgSrc} alt="Profile" className="border-white" />
      ) : (
        <span>{getInitials()}</span>
      )}

      {hasAddPictureIcon && onAddPicture ? (
        <div
          className="edit-circle"
          role="button"
          onClick={() => onAddPicture()}
        >
          <FontAwesomeIcon icon="edit" style={{ fontSize: "16px" }} />
        </div>
      ) : null}
    </div>
  );
}
