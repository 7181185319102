import Button from "app/storybookComponents/Button";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  pageRangeDisplayed?: number;
  pageCount: number;
  onPageChange?: (selectedItem: { selected: number }) => void;
  forcePage?: number;
}

export default function Paginator({
  pageRangeDisplayed = 5,
  pageCount,
  onPageChange,
  forcePage,
}: Readonly<Props>) {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <Button
          variant="secondary-blue"
          className="border-0"
          disabled={pageCount <= 1}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </Button>
      }
      previousLabel={
        <Button
          variant="secondary-blue"
          className="border-0"
          disabled={pageCount <= 1}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </Button>
      }
      pageLabelBuilder={(page) => (
        <Button variant="secondary-blue" className="border-0">
          {page}
        </Button>
      )}
      onPageChange={onPageChange}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      className="simple-pagination"
      pageClassName="page-item"
      forcePage={forcePage}
    />
  );
}
