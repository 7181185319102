import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";
import {
  TTeam360Factor,
  Team360AssessmentResult,
  TTeam360Dimension,
  isTeam360Factor,
} from "./types";
import Button from "app/storybookComponents/Button";
import { useState } from "react";
import TeamAssessmentIcon from "app/components/Team360Assessment/TeamAssessmentIcon";
import {
  ALL_TEAM_360_FACTORS,
  TEAM360_DIMENSIONS_MAP,
  CONSTRUCT_NAME_KEY,
} from "./constants";
import {
  getTeam360FactorOrDimensionDescription,
  getTeam360Score,
  getVerbalTag,
} from "./helpers";
import HelpPopover from "./HelpPopover";
import {
  DimensionsAndFactorsTipsAndInterpretiveText,
  TipsAndInterpretiveText,
} from "app/containers/TeamGuide/types";
// import Team360AccordionSnapshotTip from '../LaunchAssessmentModal/Team360AccordionSnapshotTip';
import ShortCompareGraph from "app/storybookComponents/Charts/ShortCompareGraph";

interface Props {
  type?: TTeam360Factor;
  // TODO: Need to replace currentScores with teamScores and replace comparedScores with organizationScores
  // Then also add an optional prop for userScores
  teamScores?: Team360AssessmentResult;
  companyScores?: Team360AssessmentResult;
  departmentScores?: Team360AssessmentResult;
  userScores?: Team360AssessmentResult;
  tipsAndInterpretiveText?: null | DimensionsAndFactorsTipsAndInterpretiveText;
  onAccordionClick: (type: TTeam360Factor | TTeam360Dimension) => void; // This is for button inside of the accordion
}

export default function Team360ResultsCardAccordion({
  type,
  companyScores,
  teamScores,
  departmentScores,
  userScores,
  onAccordionClick,
  tipsAndInterpretiveText,
}: Props) {
  // If no type is passed then we should be showing the the TEAM Factors.
  // if a specific type is passed then we should be showing the results for that type.
  const [openedAccordion, setOpenedAccordion] = useState<number | null>(null);

  const getAccordions = (): JSX.Element[] => {
    const accordions: React.JSX.Element[] = [];

    // If a type is passed in then we need to show the dimensions for that factor
    if (type) {
      TEAM360_DIMENSIONS_MAP[type]?.forEach((dimension, idx) => {
        const internalName = CONSTRUCT_NAME_KEY[dimension] ?? dimension;

        accordions.push(
          getDescriptionBox(
            idx,
            dimension,
            teamScores?.dimensions?.[internalName],
            companyScores?.dimensions?.[internalName],
            userScores?.dimensions?.[internalName],
            departmentScores?.dimensions?.[internalName] ??
              departmentScores?.dimensions?.[dimension],
            internalName
          )
        );
      });
      return accordions;
    }

    ALL_TEAM_360_FACTORS.forEach((factor, idx) => {
      accordions.push(
        getDescriptionBox(
          idx,
          factor,
          teamScores?.factors?.[factor],
          companyScores?.factors?.[factor],
          userScores?.factors?.[factor],
          departmentScores?.factors?.[factor]
        )
      );
    });

    return accordions;
  };

  const getAverageScoreLabel = (isFactor: boolean, score: number) => {
    const factorOrDimension = isFactor ? "factor" : "dimension";
    // If teamScores are available then we use 'This teams'
    if (teamScores) {
      return `This team's average for this ${factorOrDimension}: ${score} out of 100`;
    }
    if (departmentScores) {
      return `This department's average for this ${factorOrDimension}: ${score} out of 100`;
    }
    return `Organization average for this ${factorOrDimension}: ${score} out of 100`;
  };

  const getTipAndInterpretedText = (
    title: string,
    internalDimensionName?: string
  ) => {
    let tipAndInterpretiveText: TipsAndInterpretiveText | null = null;
    const isFactor = isTeam360Factor(title);
    if (isFactor) {
      tipAndInterpretiveText =
        tipsAndInterpretiveText?.factors?.[title] ?? null;
    } else if (internalDimensionName) {
      tipAndInterpretiveText =
        tipsAndInterpretiveText?.dimensions?.[internalDimensionName] ?? null;
    }
    return tipAndInterpretiveText;
  };

  const getBars = (
    rawTeamScore?: number,
    rawDepartmentScore?: number,
    rawUserScore?: number,
    rawCompanyScore?: number
  ) => {
    const teamScore = Math.round(getTeam360Score(rawTeamScore ?? 0));
    const companyScore = Math.round(getTeam360Score(rawCompanyScore ?? 0));
    const userScore = Math.round(getTeam360Score(rawUserScore ?? 0));
    const departmentScore = Math.round(
      getTeam360Score(rawDepartmentScore ?? 0)
    );

    const bars: {
      label: string;
      value: number;
      dull?: boolean;
    }[] = [];

    if (rawTeamScore) {
      bars.push({
        label: "This team's average",
        value: teamScore,
        dull: false,
      });
    }
    if (rawDepartmentScore) {
      bars.push({
        label: "This department's average",
        value: departmentScore,
        dull: false,
      });
    }
    if (rawUserScore) {
      bars.push({
        label: "Your score for this team",
        value: userScore,
        dull: true,
      });
    }
    if (rawCompanyScore) {
      bars.push({
        label: "This organization's average",
        value: companyScore,
        dull: true,
      });
    }

    return bars;
  };

  const getDescriptionBox = (
    idx: number,
    title: TTeam360Factor | TTeam360Dimension,
    rawTeamScore?: number,
    rawCompanyScore?: number,
    rawUserScore?: number,
    rawDepartmentScore?: number,
    internalDimensionName?: string
  ): React.JSX.Element => {
    const isExpanded = openedAccordion === idx;
    const tipAndInterpretiveText = getTipAndInterpretedText(
      title,
      internalDimensionName
    );
    const description = tipAndInterpretiveText?.interpretiveText ?? "";

    const bars = getBars(
      rawTeamScore,
      rawDepartmentScore,
      rawUserScore,
      rawCompanyScore
    );

    const mainScore =
      rawTeamScore ??
      rawDepartmentScore ??
      rawUserScore ??
      rawCompanyScore ??
      0;

    const myScore = Math.round(getTeam360Score(mainScore));

    return (
      <div className="team-360-accordion-container" key={title}>
        <div
          className="team-360-accordion-header"
          onClick={() => setOpenedAccordion(isExpanded ? null : idx)}
          role="button"
        >
          <div className="row-gap-12px align-items-center">
            {isTeam360Factor(title) ? (
              <div className="assessment-icon-holder">
                <TeamAssessmentIcon name={title} color="navy" />
              </div>
            ) : null}
            <h3>{title}</h3>
            <div>
              <HelpPopover
                title={title}
                text={getTeam360FactorOrDimensionDescription(title)}
              />
            </div>
          </div>
          <div className="row-gap-12px align-items-center">
            {getVerbalTag(myScore)}
            <p>
              <b>{myScore}</b>
            </p>
            <FontAwesomeIcon icon={`caret-${isExpanded ? "up" : "down"}`} />
          </div>
        </div>
        <Collapse in={isExpanded}>
          <div className="column-gap-16px">
            {description ? <p>{description}</p> : null}
            {bars.length > 1 ? (
              <ShortCompareGraph bars={bars} />
            ) : (
              <p>
                <b>{getAverageScoreLabel(isTeam360Factor(title), myScore)}</b>
              </p>
            )}
            {/* <Team360AccordionSnapshotTip
              tips={tipAndInterpretiveText?.userTips ?? []}
            /> */}
            <div>
              <Button
                variant="secondary-blue"
                style={{ border: "none" }}
                onClick={() => onAccordionClick(title)}
              >
                {type
                  ? "See ways to build on this score"
                  : `See ${title} details`}
                <FontAwesomeIcon icon="arrow-right" className="ms-2" />
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    );
  };
  return <Card className="team-360-accordion">{getAccordions()}</Card>;
}
