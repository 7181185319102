import SimpleModal from "./SimpleModal";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  closeSampleTEAMscanModal,
  selectSampleTEAMscanModalIsOpen,
} from "app/components/Modals/slice";
import {
  selectCurrentUserAccountId,
  selectUserInfoById,
} from "app/containers/Global/slice";
import {
  selectIsDepartmentsHidden,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import ReportCard from "./ReportCard";
import { SampleDataInfo } from "./types";

interface Props {
  sampleDataInfo: SampleDataInfo | null;
}

export default function SampleTEAMscanModal({
  sampleDataInfo,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const usersInfo = useAppSelector(
    selectUserInfoById(currentUserAccountId ?? 0)
  );
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const isDepartmentsHidden = useAppSelector(selectIsDepartmentsHidden);

  const show = useAppSelector(selectSampleTEAMscanModalIsOpen);

  const onHide = () => {
    dispatch(closeSampleTEAMscanModal());
  };

  if (!sampleDataInfo) {
    return null;
  }

  const isAdmin = usersInfo?.tmgRoleId === 1;
  const isDepartmentLeader = !!teamAndDepartmentLeaderOf?.departments?.length;

  return (
    <SimpleModal show={show} onHide={onHide} title="Sample TEAMscan Report">
      <div className="section-container">
        <ReportCard
          title="Sample team-level TEAMscan report"
          description="See a sample team-level TEAMscan report"
          buttonText="View sample report"
          link={`TeamGuide/${sampleDataInfo?.teamScanTeamId}?tab=TEAMscan`}
          onHide={onHide}
        />
        {isAdmin || (isDepartmentLeader && !isDepartmentsHidden) ? (
          <ReportCard
            title="Sample department-level TEAMscan report"
            description="See a sample department-level TEAMscan report"
            buttonText="View sample report"
            link={`DepartmentInsightReport/${sampleDataInfo?.teamScanDepartmentId}`}
            onHide={onHide}
          />
        ) : null}
        {isAdmin ? (
          <ReportCard
            title="Sample organization-level TEAMscan report"
            description="See a sample organization-level TEAMscan report"
            buttonText="View sample report"
            link="AdminConsole/Analytics?isSample=true"
            onHide={onHide}
          />
        ) : null}
      </div>
    </SimpleModal>
  );
}
