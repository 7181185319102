import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, HTMLAttributes } from "react";
import { Button } from "react-bootstrap";
import { ButtonVariant } from "./types";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  variant?: ButtonVariant;
  xIcon?: boolean;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  dataTestId?: string;
  size?: "sm" | "lg"; // 'md' is default
  active?: boolean;
}

export default function index(props: Props) {
  const { children, variant, xIcon, dataTestId } = props;
  const getBootstrapVariant = () => {
    switch (variant) {
      case "secondary-danger":
        return "outline-danger";
      case "danger":
        return "danger";
      case "link":
      case "secondary-blue":
      case "tertiary":
      case "tertiary-blue":
        return "outline-primary";
      case "grey":
        return "secondary";
      case "secondary-gray":
        return "outline-secondary";
      case "primary":
      default:
        return "primary";
    }
  };

  const getClassName = () => {
    let className: string = `${props.className ?? ""} ${props.size ?? "sm"}`;
    if (variant === "tertiary-blue" || variant === "link") {
      className += ` ${variant}`;
    }
    return className;
  };

  const buttonProps = {
    ...props,
    variant: getBootstrapVariant(),
  };
  delete buttonProps.size;
  delete buttonProps.xIcon;
  delete buttonProps.dataTestId;
  return (
    <Button
      {...buttonProps}
      data-testid={dataTestId}
      className={getClassName()}
    >
      {children || null}
      {xIcon ? <FontAwesomeIcon icon="xmark" size="lg" /> : null}
    </Button>
  );
}
