import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { simpleFetch, request } from "utils/request";
import { toast } from "react-toastify";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { successNotificationOptions } from "utils/constants";
import {
  Team360FeedbackPayload,
  TTeam360Factor,
  TTeam360FactorResponse,
  Team360StoredResponses,
  getPreviousAnswersResponse,
  SurveyStatus,
  TeamScanStatusError,
} from "./types";
import { constructPayload } from "./helpers";
import { decryptSurveyInfo } from "utils/helperFunctions";

export interface Team360AssessmentState {
  showSurveyModal: null | "Overview" | "Product Feedback"; // Survey is the same as assessment
  surveysTeamId: number | null;
  $360AssessmentFactors: { [factorName: string]: TTeam360FactorResponse[] };
  storedResponses: Team360StoredResponses;
  teamScanFeedbackPayload: Partial<Team360FeedbackPayload>;
  teamScanSurveyStatus: SurveyStatus | null;
  surveyProgress: number;
  totalQuestionCount: number;
  currentQuestionIndex: number;
  activeEventId: string | null;
  reviewEventId: string | null;
  previousAnswers: getPreviousAnswersResponse | null;
  teamScanActionByTeamId: {
    [teamId: number]: "extended" | "reminded" | null | undefined;
  };
  teamScanSurveyStatusError: TeamScanStatusError;
  isSampleAssessment: boolean;
  getAllTeam360AssessmentResultsStatus: responseStatus;
  getTEAMAssessmentItemsStatus: responseStatus;
  getTeam360ScoresStatus: responseStatus;
  submitting360AssessmentResultsStatus: responseStatus;
  feedbackSentStatus: responseStatus;
  getPreviousAnswersStatus: responseStatus;
  getSurveyScanStatus: responseStatus;
  surveyAuthenticationToken: string | null;
}

const initialState: Team360AssessmentState = {
  showSurveyModal: null,
  activeEventId: null,
  reviewEventId: null,
  surveysTeamId: null,
  storedResponses: {
    Target: {},
    Empower: {},
    Align: {},
    Monitor: {},
  },
  teamScanActionByTeamId: {},
  surveyProgress: 2,
  totalQuestionCount: 0,
  currentQuestionIndex: 0,
  $360AssessmentFactors: {},
  teamScanFeedbackPayload: {
    assessmentFeedback: "",
    doMoreResponse: "",
    doDifferentlyResponse: "",
  },
  teamScanSurveyStatus: null,
  surveyAuthenticationToken: null,
  isSampleAssessment: false,
  previousAnswers: null,
  teamScanSurveyStatusError: null,
  getAllTeam360AssessmentResultsStatus: "idle",
  getTeam360ScoresStatus: "idle",
  feedbackSentStatus: "idle",
  submitting360AssessmentResultsStatus: "idle",
  getPreviousAnswersStatus: "idle",
  getSurveyScanStatus: "idle",
  getTEAMAssessmentItemsStatus: "idle",
};

// ------------------------------------ GETS ------------------------------------

export const getPreviousAnswers = createAsyncThunk(
  "teamScanAssessment/getPreviousAnswers",
  async ({
    eventId,
    surveyAuthenticationToken,
  }: {
    eventId: string;
    surveyAuthenticationToken?: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessmentEvents/${eventId}/answers`;
    const response = (await simpleFetch(
      requestUrl,
      { method: "GET" },
      surveyAuthenticationToken
    )) as getPreviousAnswersResponse;
    return response;
  }
);

// This returns back the assessment questions for the TEAMscan assessment
export const getTEAMAssessmentItems = createAsyncThunk(
  "teamScanAssessment/getTEAMAssessmentItems",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/TEAMAssessmentItems`;
    const getTEAMAssessmentItemsResponse = (await request(
      requestUrl
    )) as string;
    try {
      const decodedValue = decryptSurveyInfo(getTEAMAssessmentItemsResponse);
      return decodedValue?.items as TTeam360FactorResponse[];
    } catch (e) {}

    return [];
  },
  {
    condition: (_, { getState }) => {
      const {
        teamScanAssessment: {
          $360AssessmentFactors,
          getTEAMAssessmentItemsStatus,
        },
      } = getState() as RootState;
      return (
        Object.keys($360AssessmentFactors).length === 0 &&
        getTEAMAssessmentItemsStatus !== "loading"
      );
    },
  }
);

export const getSurveyScanStatus = createAsyncThunk(
  "teamScanAssessment/getSurveyScanStatus",
  async (eventId: string, { dispatch, rejectWithValue }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamScanStatus/${eventId}`;
    const getSurveyScanStatusResponse = (await request(
      requestUrl,
      {
        method: "GET",
      },
      true
    )) as {
      response: string;
      status: number;
    };
    const decodedValue = decryptSurveyInfo(
      getSurveyScanStatusResponse?.response
    ) as SurveyStatus;

    if (decodedValue.error) {
      return rejectWithValue(decodedValue.error);
    }

    dispatch(
      getPreviousAnswers({
        eventId,
        surveyAuthenticationToken: decodedValue.assessmentJWT,
      })
    );

    return decodedValue;
  },
  {
    condition: (_, { getState }) => {
      // if loading then don't fetch again
      const {
        teamScanAssessment: { getSurveyScanStatus },
      } = getState() as RootState;
      return getSurveyScanStatus !== "loading";
    },
  }
);

// ------------------------------------ POSTS ------------------------------------
export const send360AssessmentResults = createAsyncThunk(
  "teamScanAssessment/send360AssessmentResults",
  async (
    payload: {
      completedAssessment: 0 | 1;
    },
    thunkAPI
  ) => {
    const {
      teamScanAssessment: {
        activeEventId,
        storedResponses,
        isSampleAssessment,
        surveyAuthenticationToken,
      },
    } = thunkAPI.getState() as RootState;

    if (isSampleAssessment) {
      thunkAPI.dispatch(resetStoredResponses());
      throw new Error("No need to submit sample assessment");
    }

    if (!activeEventId) {
      throw new Error("No active assessment event id or already submitting");
    }

    const answers = constructPayload(storedResponses);
    if (Object.keys(answers).length === 0) {
      thunkAPI.dispatch(resetStoredResponses());
      throw new Error("No answers to submit");
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/answers`;
    await simpleFetch(
      requestUrl,
      {
        method: "POST",
        body: JSON.stringify({
          ...payload,
          answers,
          eventId: activeEventId,
        }),
        keepalive: true,
      },
      surveyAuthenticationToken
    );
    thunkAPI.dispatch(resetStoredResponses(activeEventId)); // sending back the eventId to set the reviewEventId

    return activeEventId;
  },
  {
    condition: (_, { getState }) => {
      const {
        teamScanAssessment: { submitting360AssessmentResultsStatus },
      } = getState() as RootState;
      return submitting360AssessmentResultsStatus !== "loading";
    },
  }
);

export const send360TeamFeedback = createAsyncThunk(
  "teamScanAssessment/send360TeamFeedback",
  async (_, thunkAPI) => {
    const {
      teamScanAssessment: {
        reviewEventId,
        activeEventId,
        teamScanFeedbackPayload,
        surveyAuthenticationToken,
      },
    } = thunkAPI.getState() as RootState;
    const eventId = reviewEventId || activeEventId;

    if (!eventId || !surveyAuthenticationToken) {
      throw new Error("No active assessment event id");
    }

    const payloadBody = {
      eventId,
      teamEffectivenessRating: teamScanFeedbackPayload.teamEffectivenessRating,
      likelihoodToLeaveThisTeam:
        teamScanFeedbackPayload.likelihoodToLeaveThisTeam,
      primaryWorkStyle: teamScanFeedbackPayload.primaryWorkStyle,
      doMoreResponse: teamScanFeedbackPayload.doMoreResponse,
      doDifferentlyResponse: teamScanFeedbackPayload.doDifferentlyResponse,
    };

    const send360TeamFeedbackResponse = await simpleFetch(
      `${REACT_APP_API_URL}/talentInsights/teamFeedback`,
      {
        method: "POST",
        body: JSON.stringify({
          ...payloadBody,
        }),
        keepalive: true,
      },
      surveyAuthenticationToken
    );

    return send360TeamFeedbackResponse;
  }
);

export const sendTeamScanAssessmentFeedback = createAsyncThunk(
  "teamScanAssessment/sendTeamScanAssessmentFeedback",
  async (_, thunkAPI) => {
    const {
      teamScanAssessment: {
        reviewEventId,
        activeEventId,
        teamScanFeedbackPayload,
        surveyAuthenticationToken,
      },
    } = thunkAPI.getState() as RootState;

    const eventId = reviewEventId || activeEventId;

    if (!eventId || !surveyAuthenticationToken) {
      throw new Error("No active assessment event id");
    }

    const payloadBody = {
      eventId,
      assessmentExperienceRating:
        teamScanFeedbackPayload.assessmentExperienceRating,
      assessmentFeedback: teamScanFeedbackPayload.assessmentFeedback,
    };

    const sendTeamScanAssessmentFeedbackResponse = await simpleFetch(
      `${REACT_APP_API_URL}/talentInsights/assessmentFeedback`,
      {
        method: "POST",
        body: JSON.stringify(payloadBody),
      },
      surveyAuthenticationToken
    );
    return sendTeamScanAssessmentFeedbackResponse;
  }
);

export const scheduleTeam360 = createAsyncThunk(
  "teamScanAssessment/scheduleTeam360",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/scheduleTEAM360`;
    const scheduleTeam360Response = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ teamId, testId: 1 }),
    });

    return scheduleTeam360Response;
  },
  {
    condition: (_, { getState }) => {
      const {
        teamScanAssessment: { activeEventId },
      } = getState() as RootState;
      return !activeEventId;
    },
  }
);

// ------------------------------------ PUTS ------------------------------------

// ------------------------------------ DELETES ------------------------------------

export const teamScanAssessmentSlice = createSlice({
  name: "teamScanAssessment",
  initialState,
  reducers: {
    setActiveAssessmentEventId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.activeEventId = action.payload;
    },
    setShowSurveyModal: (
      state,
      action: PayloadAction<null | "Overview" | "Product Feedback">
    ) => {
      state.showSurveyModal = action.payload;
    },
    setSurveysTeamId: (state, action: PayloadAction<number | null>) => {
      state.surveysTeamId = action.payload;
    },
    resetReviewedAssessmentEventId: (state) => {
      state.reviewEventId = null;
    },
    setSurveyProgress: (state, action: PayloadAction<number>) => {
      state.surveyProgress = action.payload;
    },
    setStoredResponses: (
      state,
      action: PayloadAction<{
        factorName: TTeam360Factor;
        itemId: number;
        answer: number;
        timeTaken: number;
      }>
    ) => {
      const { factorName, itemId, answer, timeTaken } = action.payload;

      const updatedStoredResponses = {
        ...state.storedResponses,
        [factorName]: {
          ...state.storedResponses[factorName],
          [itemId]: { answer, timeTaken },
        },
      };
      return {
        ...state,
        storedResponses: updatedStoredResponses,
      };
    },
    setCurrentQuestionIndexToLastAnswered: (state) => {
      const answersLength = state.previousAnswers
        ? state.previousAnswers.length
        : 0;
      if (answersLength === 0) return;
      const newIndex = answersLength - 1;
      const progress = (newIndex / state.totalQuestionCount) * 100;
      return {
        ...state,
        currentQuestionIndex: newIndex,
        surveyProgress: progress === 100 ? 98 : progress,
      };
    },
    increaseCurrentQuestionIndex: (state) => {
      const newIndex = state.currentQuestionIndex + 1;
      const progress = (newIndex / state.totalQuestionCount) * 100;
      return {
        ...state,
        currentQuestionIndex: newIndex,
        surveyProgress: progress === 100 ? 98 : progress,
      };
    },
    decreaseCurrentQuestionIndex: (state) => {
      const newIndex = state.currentQuestionIndex - 1;
      const progress = (newIndex / state.totalQuestionCount) * 100;
      return {
        ...state,
        currentQuestionIndex: newIndex,
        surveyProgress: progress === 0 ? 2 : progress,
      };
    },
    resetCurrentQuestionIndex: (state) => {
      state.currentQuestionIndex = 0;
    },
    resetStoredResponses: (
      state,
      { payload }: PayloadAction<number | null | undefined | string>
    ) => {
      return {
        ...state,
        storedResponses: {
          Target: {},
          Empower: {},
          Align: {},
          Monitor: {},
        },
        surveyProgress: 2,
        currentQuestionIndex: 0,
        activeEventId: null,
        reviewEventId: payload ? String(payload) : null,
      };
    },
    resetTeam360FeedbackPayload: (state) => {
      state.teamScanFeedbackPayload = initialState.teamScanFeedbackPayload;
    },
    setTeam360FeedbackPayload: (
      state,
      action: PayloadAction<Partial<Team360FeedbackPayload>>
    ) => {
      return {
        ...state,
        teamScanFeedbackPayload: {
          ...state.teamScanFeedbackPayload,
          ...action.payload,
        },
      };
    },
    setTeam360ActionByTeamId: (
      state,
      {
        payload,
      }: PayloadAction<{
        teamId: number;
        action: "extended" | "reminded" | null;
      }>
    ) => {
      state.teamScanActionByTeamId[payload.teamId] = payload.action;
    },
    setIsSampleAssessment: (state, action: PayloadAction<boolean>) => {
      // if it is then we set the totalQuestion count to 4,
      // otherwise iteratire through $360AssessmentFactors and count the number of items
      if (action.payload) {
        state.totalQuestionCount = 4;
      } else {
        let count = 0;
        Object.keys(state.$360AssessmentFactors).forEach((factor) => {
          count += state.$360AssessmentFactors[factor].length;
        });
        state.totalQuestionCount = count;
      }
      state.isSampleAssessment = action.payload;
    },
    setTeamScanSurveyStatusError: (
      state,
      action: PayloadAction<TeamScanStatusError>
    ) => {
      state.teamScanSurveyStatusError = action.payload;
    },
    clearAuthenticationToken: (state) => {
      state.surveyAuthenticationToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(send360AssessmentResults.pending, (state) => {
        state.submitting360AssessmentResultsStatus = "loading";
      })
      .addCase(send360AssessmentResults.fulfilled, (state) => {
        return {
          ...state,
          submitting360AssessmentResultsStatus: "succeeded",
          activeEventId: null,
        };
      })
      .addCase(send360AssessmentResults.rejected, (state, action) => {
        if (action.error.message === "No answers to submit") {
          return {
            ...state,
            submitting360AssessmentResultsStatus: "succeeded",
          }; // Returning state so that we don't show the error toast
        }
        state.submitting360AssessmentResultsStatus = "failed";
      })
      .addCase(sendTeamScanAssessmentFeedback.fulfilled, (state) => {
        toast.success(
          "Feedback submitted successfully",
          successNotificationOptions
        );
        state.feedbackSentStatus = "succeeded";
        state.teamScanFeedbackPayload = {};
        state.reviewEventId = null;
      })
      .addCase(sendTeamScanAssessmentFeedback.rejected, (state) => {
        state.feedbackSentStatus = "failed";
      })
      .addCase(sendTeamScanAssessmentFeedback.pending, (state) => {
        state.feedbackSentStatus = "loading";
      })
      .addCase(getPreviousAnswers.pending, (state) => {
        state.getPreviousAnswersStatus = "loading";
      })
      .addCase(getPreviousAnswers.fulfilled, (state, { payload }) => {
        // set storedResponses to the previous answers
        state.getPreviousAnswersStatus = "succeeded";
        state.previousAnswers = payload;
        state.storedResponses = {
          Target: {},
          Empower: {},
          Align: {},
          Monitor: {},
        };
        payload.forEach((answer) => {
          state.storedResponses[answer.factorName][answer.itemId] = {
            answer: Number(answer.answer),
            timeTaken: answer.timeTaken,
          };
        });
      })
      .addCase(getPreviousAnswers.rejected, (state) => {
        state.getPreviousAnswersStatus = "failed";
      })
      .addCase(getTEAMAssessmentItems.pending, (state) => {
        state.getTEAMAssessmentItemsStatus = "loading";
      })
      .addCase(
        getTEAMAssessmentItems.fulfilled,
        (state, { payload }: PayloadAction<TTeam360FactorResponse[]>) => {
          const itemByFactor: {
            [factorName: string]: TTeam360FactorResponse[];
          } = {};
          payload.forEach((item) => {
            if (itemByFactor[item.factor]) {
              itemByFactor[item.factor].push(item);
            } else {
              itemByFactor[item.factor] = [item];
            }
          });
          state.$360AssessmentFactors = itemByFactor;
          state.totalQuestionCount = state.isSampleAssessment
            ? 4
            : payload.length;
          state.getTEAMAssessmentItemsStatus = "succeeded";
        }
      )
      .addCase(getTEAMAssessmentItems.rejected, (state) => {
        state.getTEAMAssessmentItemsStatus = "failed";
      })
      .addCase(
        getSurveyScanStatus.fulfilled,
        (state, { payload }: PayloadAction<SurveyStatus>) => {
          state.getSurveyScanStatus = "succeeded";
          state.teamScanSurveyStatus = payload;
          state.surveyAuthenticationToken = payload.assessmentJWT;
        }
      )
      .addCase(getSurveyScanStatus.rejected, (state, { payload }) => {
        state.getSurveyScanStatus = "failed";
        switch (payload) {
          case "This assessment is no longer active.":
            state.teamScanSurveyStatusError = "inactiveSurvey";
            break;
          case "The expiration date has already passed.":
            state.teamScanSurveyStatusError = "pastDue";
            break;
          case "This event has already been completed.":
            state.teamScanSurveyStatusError = "completed";
            break;
          case "Invalid Assessment Event Id.":
          default:
            state.teamScanSurveyStatusError = "invalidSurvey";
        }
      })
      .addCase(getSurveyScanStatus.pending, (state) => {
        state.getSurveyScanStatus = "loading";
        state.teamScanSurveyStatus = null;
        state.surveyAuthenticationToken = null;
      });
  },
});

export const selectSubmitting360AssessmentResultsStatus = (state: RootState) =>
  state.teamScanAssessment.submitting360AssessmentResultsStatus;
export const selectFeedbackSentStatus = (state: RootState) =>
  state.teamScanAssessment.feedbackSentStatus;
export const selectActiveAssessmentEventId = (state: RootState) =>
  state.teamScanAssessment.activeEventId;
export const selectShowSurveyModal = (state: RootState) =>
  state.teamScanAssessment.showSurveyModal;
export const selectSurveysTeamId = (state: RootState) =>
  state.teamScanAssessment.surveysTeamId;
export const selectReviewAssessmentEventId = (state: RootState) =>
  state.teamScanAssessment.reviewEventId;
export const select360AssessmentFactors = (state: RootState) =>
  state.teamScanAssessment.$360AssessmentFactors;
export const selectStoredResponses = (state: RootState) =>
  state.teamScanAssessment.storedResponses;
export const selectSurveyProgress = (state: RootState) =>
  state.teamScanAssessment.surveyProgress;
export const selectTeam360FeedbackPayload = (state: RootState) =>
  state.teamScanAssessment.teamScanFeedbackPayload;
export const selectCurrentQuestionIndex = (state: RootState) =>
  state.teamScanAssessment.currentQuestionIndex;
export const selectPreviousAnswers = (state: RootState) =>
  state.teamScanAssessment.previousAnswers;
export const selectGetPreviousAnswersStatus = (state: RootState) =>
  state.teamScanAssessment.getPreviousAnswersStatus;
export const selectTeam360ActionByTeamId =
  (teamId?: number | null) => (state: RootState) =>
    teamId ? state.teamScanAssessment.teamScanActionByTeamId[teamId] : null;
export const selectGetSurveyScanStatus = (state: RootState) =>
  state.teamScanAssessment.getSurveyScanStatus;
export const selectSurveyScanStatus = (state: RootState) =>
  state.teamScanAssessment.teamScanSurveyStatus;
export const selectGetSurveyScanStatusError = (state: RootState) =>
  state.teamScanAssessment.teamScanSurveyStatusError;
export const selectSurveyAuthenticationToken = (state: RootState) =>
  state.teamScanAssessment.surveyAuthenticationToken;

export const {
  setActiveAssessmentEventId,
  setSurveysTeamId,
  setShowSurveyModal,
  resetReviewedAssessmentEventId,
  setStoredResponses,
  setSurveyProgress,
  setTeam360FeedbackPayload,
  resetTeam360FeedbackPayload,
  resetStoredResponses,
  increaseCurrentQuestionIndex,
  setCurrentQuestionIndexToLastAnswered,
  decreaseCurrentQuestionIndex,
  resetCurrentQuestionIndex,
  setTeam360ActionByTeamId,
  setIsSampleAssessment,
  setTeamScanSurveyStatusError,
  clearAuthenticationToken,
} = teamScanAssessmentSlice.actions;

export default teamScanAssessmentSlice.reducer;
