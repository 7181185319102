import { memo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";

export interface ChecklistItem {
  id: string | number;
  label: string;
  disabled?: boolean;
}

export type SelectedItems = {
  [key: string | number]: boolean | 0 | 1;
};

type ChecklistDropdownProps = {
  checklistItems: ChecklistItem[];
  selectedItems: SelectedItems;
  setSelectedItems: (
    selectedItems: SelectedItems,
    changedKey?: string | number
  ) => void;
  disabled?: boolean;
  placeholder?: string;
  variant?: ButtonVariant;
  forcedDropdownText?: string;
  onUnFocus?: (selectedItems: SelectedItems) => void;
};

const ChecklistDropdown: React.FC<ChecklistDropdownProps> = ({
  checklistItems,
  selectedItems,
  setSelectedItems,
  disabled,
  variant = "light",
  placeholder,
  forcedDropdownText,
  onUnFocus,
}) => {
  const [hasChanged, setHasChanged] = useState(false);

  const getSelectedText = (selectedItems: SelectedItems) => {
    const filteredLabels: string[] = [];
    Object.entries(selectedItems).forEach(([key, value]) => {
      if (!value) return;
      const label = checklistItems.find(
        (item) => String(item.id) === String(key)
      )?.label;
      if (label) {
        filteredLabels.push(label);
      }
    });

    return filteredLabels.join(", ");
  };

  const getDropdownText = () => {
    if (forcedDropdownText) {
      return forcedDropdownText;
    }
    if (Object.keys(selectedItems).length !== 0) {
      return getSelectedText(selectedItems);
    }
    if (placeholder) {
      return placeholder;
    }
    return "Select Items";
  };

  const onChecklistItemClick = (item: ChecklistItem) => {
    if (item.disabled) return;
    setHasChanged(true);
    setSelectedItems(
      {
        ...selectedItems,
        [item.id]: !selectedItems[item.id],
      },
      item.id
    );
  };

  const getDropdownItems = () =>
    checklistItems.map((item) => (
      <Dropdown.Item
        key={item.id}
        onClick={() => {
          onChecklistItemClick(item);
        }}
        className="row-gap-8px"
      >
        <input
          type="checkbox"
          checked={!!selectedItems[item.id]}
          disabled={item.disabled}
          onClick={(e) => {
            e.stopPropagation();
            onChecklistItemClick(item);
          }}
        />
        <p>{item.label}</p>
      </Dropdown.Item>
    ));

  const onSaveDropdownSelectedItems = () => {
    // we should compare all of the items if no items were change then we should not update the selected items.
    if (!hasChanged) return;
    setSelectedItems(selectedItems);
    onUnFocus?.(selectedItems);
    setHasChanged(false);
  };

  // when the user selects an item we dont want to close the dropdown incase they want to select multiple items.
  return (
    <Dropdown
      autoClose="outside"
      onToggle={(isOpen) => {
        if (isOpen) return;
        onSaveDropdownSelectedItems();
      }}
    >
      <Dropdown.Toggle
        variant={variant}
        id="dropdown-basic"
        disabled={disabled}
        className="checklist-dropdown"
      >
        {getDropdownText()}
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">{getDropdownItems()}</Dropdown.Menu>
    </Dropdown>
  );
};

export default memo(ChecklistDropdown);
