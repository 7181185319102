import {
  getAssessmentInformation,
  selectAssessmentInformation,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import { showScheduleAssessmentModalForDepartmentId } from "app/components/LaunchAssessmentModal/slice";
import {
  selectAllCompanyUsersById,
  selectConfigCatFlag,
} from "app/containers/Global/slice";
import { memo, useEffect, useMemo, useState } from "react";
import { Card, Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import {
  getDepartmentInsightReport,
  selectLatestDepartmentInsightReportById,
} from "app/containers/DepartmentInsightReport/slice";
import { setShowModal } from "app/components/Onboarding/slice";
import Loading from "app/storybookComponents/Loading";
import {
  getDepartmentTrackingEvents,
  getDepartmentCardInfo,
  selectDepartmentCardInfoById,
  selectGetDepartmentTrackingEventsStatus,
  getDepartmentPendingTeamLeaders,
  selectDepartmentTeamLeadersByDepartmentId,
  getDepartmentAssessmentCompletionInfo,
  selectGetDepartmentAssessmentInfoStatus,
  getDepartmentResultsInfo,
  checkIfAllPendingLeadersHaveBeenReminded,
  selectCheckIfAllPendingLeadersHaveBeenRemindedStatus,
} from "../slice";
import { selectTeamsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import InfoCardV2 from "app/components/InfoCard/InfoCardV2";
import HalfDonutChart from "app/storybookComponents/Charts/HalfDonutChart";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import QuickActionCard from "app/components/QuickActions/QuickActionCard";
import SurveyResultsModal from "app/components/Modals/SurveyResultsModal";
import PendingTeamsModal from "app/components/Modals/PendingTeamsModal";
import { AssessmentMap } from "app/containers/Assessment/constants";
import ToDoCard from "app/components/Notifications/ToDoCard";

interface Props {
  selectedDepartmentId: number | null;
  onShowInviteTeamLeaderModal: () => void;
}

const DepartmentLeaderDashboard = ({
  selectedDepartmentId,
  onShowInviteTeamLeaderModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ------------------------ Redux Selectors ------------------------
  const departments = useAppSelector(selectDepartments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const departmentInsightReport = useAppSelector(
    selectLatestDepartmentInsightReportById(selectedDepartmentId)
  );

  const departmentCardInfo = useAppSelector(
    selectDepartmentCardInfoById(selectedDepartmentId)
  );
  const departmentTrackingEventsStatus = useAppSelector(
    selectGetDepartmentTrackingEventsStatus
  );
  const departmentTeamLeaders = useAppSelector(
    selectDepartmentTeamLeadersByDepartmentId(selectedDepartmentId)
  );
  const departmentAssessmentInfoStatus = useAppSelector(
    selectGetDepartmentAssessmentInfoStatus
  );
  const checkIfAllPendingLeadersHaveBeenRemindedStatus = useAppSelector(
    selectCheckIfAllPendingLeadersHaveBeenRemindedStatus
  );
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const coachBoConversationHistoryFeatureFlag = useAppSelector(
    selectConfigCatFlag("develop_coachboconversationhistory")
  );

  // ------------------------ States ------------------------
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showPendingTeamsModal, setShowPendingTeamsModal] = useState(false);
  const [isUnderStandTeamworkCollapsed, setIsUnderStandTeamworkCollapsed] =
    useState(true);

  // ------------------------ Effects ------------------------
  useEffect(() => {
    if (!selectedDepartmentId) return;
    dispatch(getDepartmentPendingTeamLeaders(selectedDepartmentId));
    dispatch(getDepartmentResultsInfo(selectedDepartmentId));
    dispatch(getDepartmentTrackingEvents(selectedDepartmentId));
  }, [dispatch, selectedDepartmentId]);

  useEffect(() => {
    if (!selectedDepartmentId || departmentInsightReport) return; // If we don't have a department selected then we don't want to call this or if we already have the report
    dispatch(
      getDepartmentInsightReport({ departmentId: Number(selectedDepartmentId) })
    );
  }, [dispatch, selectedDepartmentId, departmentInsightReport]);

  useEffect(() => {
    if (!selectedDepartmentId || departmentCardInfo) return;
    dispatch(getDepartmentCardInfo(selectedDepartmentId));
  }, [dispatch, selectedDepartmentId, departmentCardInfo]);

  useEffect(() => {
    if (departmentAssessmentInfoStatus === "idle") {
      dispatch(getDepartmentAssessmentCompletionInfo());
    }
  }, [departmentAssessmentInfoStatus, dispatch]);

  useEffect(() => {
    if (!selectedDepartmentId) return;
    if (checkIfAllPendingLeadersHaveBeenRemindedStatus === "idle") {
      dispatch(
        checkIfAllPendingLeadersHaveBeenReminded({
          departmentId: selectedDepartmentId,
        })
      );
    }
  }, [
    checkIfAllPendingLeadersHaveBeenRemindedStatus,
    dispatch,
    selectedDepartmentId,
  ]);

  // These function calls have conditionals to prevent infinite loops
  useEffect(() => {
    dispatch(getAssessmentInformation());
  }, [dispatch]);

  // ------------------------ Variables ------------------------
  const chosenDepartment = selectedDepartmentId
    ? departments[selectedDepartmentId]
    : null;

  // ------------------------ Helper Functions ------------------------

  const teamsThatCompletedTeam360 = useMemo(() => {
    const chosenDepartment = selectedDepartmentId
      ? departments[selectedDepartmentId]
      : null;
    let teamCount = 0;
    chosenDepartment?.teams?.forEach((teamId) => {
      if (!teamsMostRecentAssessments[teamId]?.totalInvited) return;
      teamCount++;
    });
    return teamCount;
  }, [teamsMostRecentAssessments, selectedDepartmentId, departments]);

  const getInfoCardTooltipContent = (type: "teams" | "people") => {
    return null;
  };

  const getInfoCardsV2 = () => {
    // If no teams or departments then we need to show the empty card to allow the admin to create a team or department
    if (
      !chosenDepartment?.teams?.length &&
      !chosenDepartment?.teamMembers?.length &&
      !departmentTeamLeaders?.length
    ) {
      return (
        <Card className="setup-empty-card">
          <div className="card-content">
            <h4>Set Up Your First Team</h4>
            <p
              style={{
                maxWidth: "320px",
              }}
            >
              Invite team leaders of teams in this department to get your
              department up and running.
            </p>
          </div>
          <div className="column-gap-20px">
            <Button
              onClick={() => {
                onShowInviteTeamLeaderModal();
              }}
            >
              Invite team leaders
            </Button>
          </div>
        </Card>
      );
    }

    // This should not be the pending members that have not joined the org
    // it should be all of the users who have not accepted to join their team and that team belongs to this department
    const pendingMembersForDepartment =
      chosenDepartment?.teamMembers?.filter((userAccountId) => {
        const user = usersById[userAccountId];
        if (!user) return false;
        const { firstTeamsLogin, lastName, firstName, jobTitle } = user;
        return !(firstTeamsLogin || firstName || lastName || jobTitle);
      })?.length ?? 0;
    const totalTeams = chosenDepartment?.teams?.length ?? 0;
    const totalMembersInDepartment = chosenDepartment?.teamMembers?.length ?? 0;
    const totalAcceptedMembersInDepartment =
      totalMembersInDepartment - pendingMembersForDepartment;

    return (
      <div className="two-card-container">
        <InfoCardV2
          title="Teams In This Department"
          body={totalTeams}
          actions={[
            {
              onClick: () => onShowInviteTeamLeaderModal(),
              text: "Invite team leader",
              iconName: "plus",
            },
          ]}
          toolTipContent={getInfoCardTooltipContent("teams")}
          onPendingTextClick={
            departmentTeamLeaders?.length
              ? () => setShowPendingTeamsModal(true)
              : () => onShowInviteTeamLeaderModal()
          }
          pendingText={
            departmentTeamLeaders?.length
              ? `${departmentTeamLeaders?.length} pending team leaders`
              : "No pending team leaders"
          }
          onCardClick={
            totalTeams
              ? () => {
                  navigate(
                    `/Search/Teams?departmentId=${selectedDepartmentId}`
                  );
                }
              : undefined
          }
        />
        <InfoCardV2
          title="People In This Department"
          body={totalAcceptedMembersInDepartment}
          actions={[
            {
              onClick: () => {
                navigate(`/Search/People?departmentId=${selectedDepartmentId}`);
              },
              text: "View people",
              iconName: "arrow-right",
            },
          ]}
          pendingText={
            pendingMembersForDepartment
              ? `${pendingMembersForDepartment} invited but haven't joined yet`
              : "No pending invitations"
          }
          toolTipContent={getInfoCardTooltipContent("people")}
          onCardClick={
            totalMembersInDepartment
              ? () => {
                  navigate(
                    `/Search/People?departmentId=${selectedDepartmentId}`
                  );
                }
              : undefined
          }
        />
      </div>
    );
  };

  const getOrgTeam360Results = () => {
    // This means that no TEAMscan has been scheduled yet so we should not show this card at all.
    if (!departmentInsightReport?.completionInfo?.totalInvited) {
      return null;
    }

    if (!departmentInsightReport?.departmentScores?.overall) {
      const overlay = (
        <Popover className="team-360-popover">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <strong>No Results Available</strong>
            <p>
              Unfortunately, not enough responses were received to show results.
              In order for results to be anonymous, at least 3 teams from this
              department must have at least 3 members complete the TEAMscan
              survey.
            </p>
          </div>
        </Popover>
      );
      return (
        <Card className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-square-icon dark-grey">
              <FontAwesomeIcon icon="users" className="icon" />
            </div>
            <div className="column-gap-8px">
              <div className="row-gap-8px">
                <h3>
                  {chosenDepartment?.name ?? ""} TEAMscan results not available
                </h3>
                <div className="label-tag grey m-0">Full department report</div>
              </div>
              <p>Not enough responses collected</p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <p>No results available</p>
            <OverlayTrigger rootClose placement="auto" overlay={overlay}>
              <FontAwesomeIcon icon={["far", "circle-info"]} />
            </OverlayTrigger>
          </div>
        </Card>
      );
    }

    // If pending should show ard indicating that its pending and should show the count of teams/departments invited
    // If we already scheduled a TEAMscan but no results are available, and the expiration date is passed then we just show not enough responses collected
    const companyTeam360Results =
      departmentInsightReport?.departmentScores?.overall;
    const smallSpeedChart = (
      <div style={{ height: "70px", width: "150px" }}>
        <HalfDonutChart
          canvasId="small-team360-preview-card-chart"
          currentVal={getTeam360Score(companyTeam360Results)}
          size="extra-small"
          borderWidth={2}
        />
      </div>
    );

    return (
      <Card className="dashboard-container-header">
        <div className="dashboard-container-header__info">
          <div className="small-square-icon blue">
            <FontAwesomeIcon icon="users" className="icon" />
          </div>
          <div className="column-gap-8px">
            <div className="row-gap-8px">
              <h3>{chosenDepartment?.name ?? ""} TEAMscan results available</h3>
              <div className="label-tag green m-0">Full department report</div>
            </div>
            <p>
              {departmentInsightReport?.completionInfo?.totalCompleted}{" "}
              completed responses from {teamsThatCompletedTeam360} teams
            </p>
          </div>
        </div>
        <div
          className="row-gap-20px align-items-center"
          style={{ gap: "40px" }}
        >
          {smallSpeedChart}
          <Button
            onClick={() => {
              navigate(
                coachBoConversationHistoryFeatureFlag
                  ? `/DepartmentGuide/${selectedDepartmentId}?tab=TEAMscan`
                  : `/DepartmentInsightReport/${selectedDepartmentId}`
              );
            }}
            style={{
              height: "fit-content",
            }}
          >
            View results
          </Button>
        </div>
      </Card>
    );
  };

  const getTeam360Card = () => {
    const isLaunchTeam360Disabled = !!(
      selectedDepartmentId &&
      assessmentInformation?.departments?.[selectedDepartmentId]
        ?.activeAssessment
    );

    return (
      <Card className="column-gap-20px">
        <div className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-square-icon">
              <FontAwesomeIcon icon={["fas", "poll-people"]} className="icon" />
            </div>
            <div>
              <h3>
                Launch and manage the TEAMscan teamwork survey and view results
              </h3>
              <p>
                Understand teamwork and empower higher-performing managers.{" "}
                <Link
                  to="#"
                  onClick={() => {
                    dispatch(
                      setShowModal({
                        eventType: "generalTeam360Information",
                      })
                    );
                  }}
                >
                  What is the TEAMscan?
                </Link>
              </p>
            </div>
          </div>
          <div className="row-gap-16px">
            <Button
              onClick={() => {
                setIsUnderStandTeamworkCollapsed(
                  !isUnderStandTeamworkCollapsed
                );
              }}
            >
              {isUnderStandTeamworkCollapsed ? "See" : "Hide"} actions
            </Button>
          </div>
        </div>
        <Collapse in={!isUnderStandTeamworkCollapsed}>
          <div className="three-card-container">
            <QuickActionCard
              title="Launch TEAMscan survey"
              description="Launch the survey for one or more teams or your entire department."
              actionButton={{
                onClick: () => {
                  selectedDepartmentId &&
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(
                        selectedDepartmentId
                      )
                    );
                },
                text: "Launch survey",
              }}
              iconName="rocket-launch"
              isWholeCardDisabled={isLaunchTeam360Disabled}
              cornerTag={
                isLaunchTeam360Disabled ? (
                  <div className="label-tag green">Already active</div>
                ) : null
              }
              imageIcon={
                <img
                  alt={AssessmentMap[1].name}
                  src={AssessmentMap[1].assessmentIcon}
                />
              }
            />
            <QuickActionCard
              title="View TEAMscan results"
              description="View results from past surveys for teams in this department."
              actionButton={{
                onClick: () => {
                  setShowResultsModal(true);
                },
                text: "View results",
              }}
              iconName="square-poll-vertical"
            />
            <QuickActionCard
              title="Manage TEAMscan Surveys"
              description="View 1-time and recurring surveys. Adjust survey period, reminders, and more."
              actionButton={{
                onClick: () => {
                  navigate(
                    coachBoConversationHistoryFeatureFlag
                      ? `/DepartmentGuide/${selectedDepartmentId}?tab=Manage+Surveys`
                      : `/Department/${selectedDepartmentId}/Surveys`
                  );
                },
                text: "Manage surveys",
              }}
              iconName="wrench"
            />
          </div>
        </Collapse>
      </Card>
    );
  };

  if (!selectedDepartmentId) return null;

  if (departmentTrackingEventsStatus === "loading") return <Loading />;

  return (
    <>
      <SurveyResultsModal
        onHide={() => setShowResultsModal(false)}
        show={showResultsModal}
        teamIds={chosenDepartment?.teams ?? []}
        departmentIds={[selectedDepartmentId]}
        getEmptyCard={() => (
          <div className="empty-card">
            <span>No TEAMscan Results In This Department</span>
            <p>
              No teams have an active TEAMscan currently. Launch a TEAMscan for
              your department or one or more teams now.
            </p>
            <div className="action-buttons">
              <Button
                onClick={() => {
                  if (selectedDepartmentId) {
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(
                        selectedDepartmentId
                      )
                    );
                    setShowResultsModal(false);
                  }
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                variant="secondary-blue"
                onClick={() => {
                  dispatch(
                    setShowModal({
                      eventType: "generalTeam360Information",
                      hideActionButton: true,
                    })
                  );
                  setShowResultsModal(false);
                }}
              >
                See details
              </Button>
            </div>
          </div>
        )}
      />
      <PendingTeamsModal
        onHide={() => setShowPendingTeamsModal(false)}
        show={showPendingTeamsModal}
        onShowInviteTeamLeaderModal={() => {
          setShowPendingTeamsModal(false);
          onShowInviteTeamLeaderModal();
        }}
        pendingTeamLeaders={departmentTeamLeaders ?? []}
      />
      <ToDoCard />
      <div className="dashboard-container">
        {getInfoCardsV2()}
        <h2>Surveys</h2>
        {getOrgTeam360Results()}
        {getTeam360Card()}
      </div>
    </>
  );
};

export default memo(DepartmentLeaderDashboard);
