import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from "app/components/Toggle";
import Button from "app/storybookComponents/Button";
import { useState } from "react";
import { Card } from "react-bootstrap";
import VisibilityDefaultModal from "../Modals/VisibilityDefaultModal";
import ConfirmSaveVisibilityDefaultsModal from "../Modals/ConfirmSaveVisibilityDefaultsModal";
import { ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP } from "../constants";
import { useAppDispatch } from "utils/redux/hooks";
import { getVisibilityPermissionDifferCount } from "../slice";

interface Props {
  visibilitySetting?: number;
  visibilityOverrideToggle?: boolean;
  setVisibilityOverrideToggle?: (payload: boolean) => void;
  onSaveVisibilityDefaults?: (payload: {
    visibilityPermissionDefaultId: 1 | 2 | 3;
    reconfigureVisibilityPermissions: 1 | 0;
  }) => void | Promise<void>;
  isChangingDefaultsLoading?: boolean;
}

export default function VisibilitySettings({
  visibilitySetting,
  visibilityOverrideToggle,
  setVisibilityOverrideToggle,
  onSaveVisibilityDefaults,
  isChangingDefaultsLoading,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const [showVisibilityModal, setShowVisibilityModal] = useState<
    1 | 2 | 3 | null
  >(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    isOpen: boolean;
    visibilitySettingSelected: number | null;
  }>({
    isOpen: false,
    visibilitySettingSelected: null,
  });

  const getBannerText = () => {
    if (visibilityOverrideToggle) {
      return (
        <p>
          Users will be able to change visibility settings for their individual
          reports. Team leaders will be able to change visibility settings for
          their team reports. If visibility defaults are changed, you’ll have
          the option to automatically reconfigure all individual and team
          settings.
        </p>
      );
    }
    return (
      <p>
        Users will <u>not</u> be able to change visibility settings for their
        individual reports. Team leaders will <u>not</u> be able to change
        visibility settings for their team reports. Only admins will be able to
        change visibility settings defaults across this organization.
      </p>
    );
  };

  const getOption = ({
    title,
    description,
    recommended,
    callToActionText = "View defaults",
    iconProp = "user-plus",
    value,
  }: {
    title: string;
    description: string;
    recommended?: boolean;
    callToActionText?: string;
    iconProp?: IconProp;
    value: 1 | 2 | 3;
  }) => (
    <Card
      onClick={() => {
        setShowVisibilityModal(value);
      }}
      className={`clickable-card visibility-setting-card ${
        visibilitySetting === value ? "selected" : ""
      }`}
    >
      <div className="icon-container">
        <FontAwesomeIcon icon={iconProp} />
      </div>
      <div className="column-gap-4px">
        <div className="row-gap-8px align-items-center flex-wrap">
          <h4>{title}</h4>
          {recommended ? (
            <span className="label-tag green m-0">Recommended</span>
          ) : null}
        </div>
        <p className="description-text">{description}</p>
        <div className="mt-auto">
          <Button variant="link" className="border-0">
            {callToActionText}
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </div>
      </div>
    </Card>
  );

  return (
    <>
      <VisibilityDefaultModal
        isOpen={!!showVisibilityModal}
        onClose={() => setShowVisibilityModal(null)}
        defaultVisibility={showVisibilityModal}
        onSaveVisibilityDefaults={(value) => {
          setShowConfirmationModal({
            isOpen: true,
            visibilitySettingSelected: value,
          });
          setShowVisibilityModal(null);
          if (visibilitySetting) {
            dispatch(getVisibilityPermissionDifferCount(visibilitySetting));
          }
        }}
      />
      <ConfirmSaveVisibilityDefaultsModal
        isOpen={showConfirmationModal.isOpen}
        onClose={() => {
          setShowConfirmationModal({
            isOpen: false,
            visibilitySettingSelected: null,
          });
        }}
        visibilitySettingSelected={visibilitySetting ?? null}
        onSave={async (isToggleOn: boolean) => {
          if (showConfirmationModal.visibilitySettingSelected) {
            await onSaveVisibilityDefaults?.({
              visibilityPermissionDefaultId:
                showConfirmationModal.visibilitySettingSelected as 1 | 2 | 3,
              reconfigureVisibilityPermissions: isToggleOn ? 1 : 0,
            });
          }
          setShowConfirmationModal({
            isOpen: false,
            visibilitySettingSelected: null,
          });
        }}
        isLoading={isChangingDefaultsLoading}
      />
      <div className="column-gap-20px">
        <div>
          <p
            style={{
              marginBottom: "4px",
            }}
          >
            <b>
              Choose the option that best describes your organization's approach
              to privacy and visibility:
            </b>
          </p>
          <p className="grey-text">
            This will set privacy defaults for each report type across your
            organization. Configure whether to allow individual users and teams
            to override these defaults below.
          </p>
        </div>
        <div className="visibility-settings-container">
          <div
            className="multi-card-container-same-width"
            style={{
              gap: "12px",
            }}
          >
            {getOption({
              title: ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP[1],
              description:
                "Individual privacy with little visibility by others",
              value: 1,
              iconProp: ["fal", "user-lock"],
            })}
            {getOption({
              title: ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP[2],
              description: "Some visibility by team members and managers",
              recommended: true,
              value: 2,
              iconProp: ["fal", "balance-scale-left"],
            })}
            {getOption({
              title: ADMIN_CONSOLE_VISIBILITY_DEFAULT_NAME_MAP[3],
              description:
                "Complete visibility by all members in this organization",
              iconProp: ["fal", "users"],
              value: 3,
            })}
          </div>
        </div>
        <div className="warning-banner lighter-blue column-gap-12px border-0">
          <div className="row-gap-12px align-items-center">
            <Toggle
              handleToggle={() => {
                setVisibilityOverrideToggle?.(!visibilityOverrideToggle);
              }}
              isOn={!!visibilityOverrideToggle}
            />
            <p style={{ color: "black" }}>Allow individual override?</p>
          </div>
          {getBannerText()}
        </div>
      </div>
    </>
  );
}
