import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTable from "app/components/SortableTable";
import { useCallback, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectConfigCatFlag,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  getAssessmentInstances,
  selectAllTeamAssessmentsInstances,
  getAssessmentSeries,
  selectAllTeamAssessmentsSeries,
  selectGettingAssessmentInstancesStatus,
  selectTeamAssessmentsInstancesEntities,
  deactivateTeamScan,
} from "app/containers/Assessment/slice";
import {
  getTeamMembersWithPendingTeamScanSurvey,
  selectDepartments,
} from "../slice";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { getStringDate } from "app/components/InstancePicker/helpers";
import PickInstanceFromMultipleModal from "../Modals/PickInstanceFromMultipleModal";
import Loading from "app/storybookComponents/Loading";
import { Link, useNavigate } from "react-router-dom";
import { sendReminder } from "app/containers/Dashboard/slice";
import EditSurveyModal from "../Modals/EditSurveyModal";
import { RowContent, TableHeader } from "app/components/SortableTable/types";
import {
  AssessmentInformation,
  AssessmentInstance,
  AssessmentSeries,
} from "app/containers/Assessment/types";
import Select from "react-select";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import { getSelectProps } from "utils/helperFunctions";
import {
  getScoreClassName,
  getDaysRemaining,
  splitSurveys,
  getTeamIdsFromSurvey,
} from "app/containers/Assessment/helpers";
import { updateTeam360DateRange as updateTeamsTeam360DateRange } from "app/containers/TeamGuide/slice";
import { updateTeam360DateRange as updateDepartmentsTeam360DateRange } from "app/containers/DepartmentInsightReport/slice";
import { getSurveyTableInviteeToolTip, isAssessmentInstance } from "../helpers";

interface Props {
  teamId?: number;
  departmentId?: number;
  emptyStateActions?: React.ReactNode;
  elementBetweenSearchAndTable?: React.ReactNode;
}

// Might add a prop to only show the results for a given department or team
export default function SurveysTable({
  teamId,
  departmentId,
  emptyStateActions,
  elementBetweenSearchAndTable,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // ------------------------- States ----------------------------------//
  const [showRemindWarningModal, setShowRemindWarningModal] = useState<
    false | number
  >(false);
  const [extendExpirationWarningModal, setExtendExpirationWarningModal] =
    useState<false | number>(false);
  const [showDeactivateWarningModal, setShowDeactivateWarningModal] = useState<
    | false
    | {
        assessmentInstanceId: number;
      }
  >(false);
  const [showSelectMultiResultModal, setShowSelectMultiResultModal] =
    useState<null | {
      surveyId: number;
      teamIds: number[];
      departmentIds: number[];
    }>(null);
  const surveyTabSelected = "Instances";
  const [searchInput, setSearchInput] = useState("");
  const [displayedInput, setDisplayedInput] = useState("");
  const [filteredRows, setFilteredRows] = useState<RowContent[]>([]);

  // ------------------------- App selectors ----------------------------------//
  const departments = useAppSelector(selectDepartments);
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const allTeamAssessmentInstances = useAppSelector(
    selectAllTeamAssessmentsInstances
  );
  const allTeamAssessmentInstancesEntities = useAppSelector(
    selectTeamAssessmentsInstancesEntities
  );
  const allTeamAssessmentSeries = useAppSelector(
    selectAllTeamAssessmentsSeries
  );
  const getAssessmentInstancesStatus = useAppSelector(
    selectGettingAssessmentInstancesStatus
  );
  const coachBoConversationHistoryFeatureFlag = useAppSelector(
    selectConfigCatFlag("develop_coachboconversationhistory")
  );

  // ------------------------- Local variables ----------------------------------//
  const entityName = "instances";

  // ------------------------- Effects ----------------------------------//

  useEffect(() => {
    dispatch(getAssessmentInstances());
    dispatch(getAssessmentSeries());
    dispatch(getTeamMembersWithPendingTeamScanSurvey());
  }, [dispatch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchInput(displayedInput);
    }, 500); // 300ms debounce delay

    return () => clearTimeout(delayDebounceFn);
  }, [displayedInput]);

  // ------------------------- Callback Functions ----------------------------------//
  // We need to make sure to update the date range for the instance
  const onViewResults = useCallback(
    (teamAssessment?: AssessmentSeries | AssessmentInstance) => {
      if (!teamAssessment) return;
      const { departmentIds, inviteOrg } = teamAssessment;
      const teamIds = getTeamIdsFromSurvey(teamAssessment);
      const isOrgWide = !!inviteOrg;
      // We need to make sure that when we select the instance we also update the date range for the instance
      if (isOrgWide) {
        // Redirect to org guide page
        navigate("/AdminConsole/Analytics");
        return;
      }
      if (teamIds?.length === 1) {
        // Redirect to TEAMscan page for that team and additionally will need to call updateTeam360DateRange(team) so that the correct instance is selected
        dispatch(
          updateTeamsTeam360DateRange({
            teamId: teamIds[0],
            startDate: teamAssessment.startDate,
            endDate: teamAssessment.endDate,
            instance: teamAssessment.startDate,
          })
        );
        navigate(`/TeamGuide/${teamIds[0]}?tab=TEAMscan`);
        return;
      }
      if (departmentIds?.length === 1) {
        // Redirect to department guide page and additionally will need to call updateTeam360DateRange(department) so that the correct instance is selected
        dispatch(
          updateDepartmentsTeam360DateRange({
            departmentId: departmentIds[0],
            startDate: teamAssessment.startDate,
            endDate: teamAssessment.endDate,
            instance: teamAssessment.startDate,
          })
        );
        return navigate(
          coachBoConversationHistoryFeatureFlag
            ? `/DepartmentGuide/${departmentIds[0]}?tab=TEAMscan`
            : `/DepartmentInsightReport/${departmentIds[0]}`
        );
      }
    },
    [navigate, dispatch, coachBoConversationHistoryFeatureFlag]
  );

  const onDepartmentTableDropdownSelect = useCallback(
    (
      e: string | null,
      selectedSurvey: AssessmentSeries | AssessmentInstance
    ) => {
      const assessmentInstanceId = isAssessmentInstance(selectedSurvey)
        ? selectedSurvey.assessmentInstanceId
        : undefined;
      const teamIds = getTeamIdsFromSurvey(selectedSurvey);
      const id = assessmentInstanceId ?? selectedSurvey.assessmentId;
      switch (e) {
        case "viewResults": {
          // Might extract this into a function
          // If orgWide then redirect to org guide page
          // If a single teamId then redirect to TEAMscan page for that team
          // If multiple teams then we show the modal that allows the user to select which team they want to view the results for
          // If a single departmentId then redirect to department guide page
          // If multiple departments then we show the modal that allows the user to select which department they want to view the results for
          return onViewResults(selectedSurvey);
        }
        case "remindMembers": {
          if (!teamIds?.length) return;
          const teamId = teamIds[0];
          // Will open the remindWarningModal and set the state to the scheduledSurveyId
          return setShowRemindWarningModal(teamId);
        }
        // Will open the extendExpirationWarningModal and set the state to the scheduledSurveyId
        // Will open the editSurveySettingsModal and set the state to the scheduledSurveyId
        case "extendSurvey":
        case "editSurveySettings": {
          return setExtendExpirationWarningModal(id);
        }
        case "deactivateSurvey":
          // Will open the deactivateWarningModal and set the state to the scheduledSurveyId
          // note: we will be setting the id of the teamId since we are only allowing one active instance per team
          return setShowDeactivateWarningModal({
            assessmentInstanceId: id,
          });
        case "viewBreakdown": {
          navigate(`/AdminConsole/SurveyBreakdown?assessmentId=${id}`);
        }
      }
    },
    [onViewResults, navigate]
  );

  // Cell getter
  const getInviteesCell = useCallback(
    (
      assessmentId: number,
      invitedTeamIds: number[] = [],
      invitedDepartments?: number[],
      isOrgWide?: boolean
    ) => {
      const selectedSurveysArr = allTeamAssessmentInstancesEntities;
      const selectedSurvey = selectedSurveysArr[assessmentId];
      const navigatePath = `/AdminConsole/SurveyBreakdown?assessmentId=${assessmentId}`;
      const firstTeamId = invitedTeamIds?.[0];
      const helpIcon = selectedSurvey
        ? getSurveyTableInviteeToolTip({
            assessment: selectedSurvey,
            onSeeSettings: () => {
              setExtendExpirationWarningModal(assessmentId);
            },
            onRemind: () => {
              if (!firstTeamId) return;
              setShowRemindWarningModal(firstTeamId);
            },
          })
        : null;

      // If orgWide then we know that the survey was scheduled for the entire organization
      if (isOrgWide) {
        return {
          displayValue: (
            <div className="invitee-cell">
              <Link to={navigatePath}>Full Organization</Link>
              {helpIcon}
            </div>
          ),
          sortValue: "Full Organization",
        };
      }

      // If departmentId list array is not empty than we know that the survey was scheduled for a department
      if (invitedDepartments?.length && invitedDepartments.length >= 1) {
        // If more than one department then we can just display the number of departments
        if (invitedDepartments.length > 1) {
          return {
            displayValue: (
              <div className="invitee-cell">
                <Link to={navigatePath}>
                  {`${invitedDepartments.length} Departments`}
                </Link>
                {helpIcon}
              </div>
            ),
            sortValue: `${invitedDepartments.length} Departments`,
          };
        }

        // If only one invited department then we can just display the department name
        let departmentName = departments[invitedDepartments[0]]?.name ?? "";
        if (!departmentName.toLowerCase().includes("department")) {
          departmentName += " Department";
        }

        return {
          displayValue: (
            <div className="invitee-cell">
              <Link to={navigatePath}>{departmentName}</Link>
              {helpIcon}
            </div>
          ),
          sortValue: departmentName,
        };
      }

      if (invitedTeamIds?.length) {
        // If more than one team then we can just display the number of teams
        if (invitedTeamIds.length > 1) return `${invitedTeamIds.length} Teams`;

        // If only one invited team then we can just display the team name
        let teamName = teamInfoById[invitedTeamIds[0]]?.teamName ?? "";
        if (!teamName.toLowerCase().includes("team")) {
          teamName += " Team";
        }
        return {
          displayValue: (
            <div className="invitee-cell">
              <p>{teamName}</p>
              {helpIcon}
            </div>
          ),
          sortValue: teamName,
        };
      }
    },
    [departments, teamInfoById, allTeamAssessmentInstancesEntities]
  );

  // Cell getter
  const getTeamworkScore = useCallback((score?: AssessmentInformation) => {
    const { team360Scores } = score ?? {};
    if (score === undefined || !team360Scores) {
      return {
        displayValue: "N/A",
        sortValue: 0,
      };
    }

    let scoreNum = 0;
    // If company exist then just use the overall score for the company
    if (team360Scores?.company) {
      scoreNum = team360Scores.company?.overall || 0;

      // If no company score but has the teams score iterate through all the teams and get the average
    } else if (team360Scores?.teams) {
      let length = 0;
      Object.values(team360Scores.teams).forEach((team) => {
        scoreNum += team?.overall || 0;
        length++;
      });
      scoreNum = scoreNum / length;

      // If no teams or company but it does have departments then iterate through all the departments and get the average
    } else if (team360Scores?.departments) {
      const departmentObject = Object.values(team360Scores.departments);
      departmentObject.forEach((department) => {
        scoreNum += department?.overall || 0;
      });
      scoreNum = scoreNum / departmentObject.length;
    }
    scoreNum = scoreNum && getTeam360Score(scoreNum);
    const daysRemaining = getDaysRemaining(score.endDate);
    const emptyScoreString =
      daysRemaining && daysRemaining > 0 ? "Results Pending" : "No Results";
    return {
      displayValue: (
        <div
          className={`verbal-tag bigger ${getScoreClassName(scoreNum)}`}
          style={{ width: "fit-content" }}
        >
          {scoreNum || emptyScoreString}
        </div>
      ),
      sortValue: scoreNum,
    };
  }, []);

  // Cell getter
  const getCompletionRateCell = useCallback(
    (score?: number, invitedCount?: number, completedCount?: number) => {
      if (score === undefined)
        return {
          displayValue: "N/A",
          sortValue: 0,
        };

      const getClassName = (score: number) => {
        if (score > 75) {
          return "green";
        } else if (score > 50) {
          return "yellow";
        } else if (score > 25) {
          return "poppy";
        } else {
          return "red";
        }
      };
      const className = `status-dot ${getClassName(score || 1)}`;

      return {
        displayValue: (
          <div className="row-gap-8px align-items-center">
            <p>
              {score}% ({completedCount}/{invitedCount})
            </p>
            <div className={className} />
          </div>
        ),
        sortValue: score,
      };
    },
    []
  );

  const getRows = useCallback(
    (selectedSurveys: (AssessmentSeries | AssessmentInstance)[]) => {
      return selectedSurveys.map((selectedSurvey) => {
        const {
          startDate,
          endDate,
          status,
          completionRate,
          departmentIds,
          inviteOrg,
          assessmentId,
          totalInvited,
          totalCompleted,
        } = selectedSurvey;
        const teamIds = getTeamIdsFromSurvey(selectedSurvey);

        let trueAssessmentId = assessmentId;
        if (isAssessmentInstance(selectedSurvey)) {
          trueAssessmentId = selectedSurvey.assessmentInstanceId;
        }

        return {
          name: "TEAMscan",
          startDate: getStartAndEndDate(startDate) ?? "N/A",
          endDate: getStartAndEndDate(endDate) ?? "N/A",
          invitees: getInviteesCell(
            trueAssessmentId,
            teamIds,
            departmentIds,
            !!inviteOrg
          ),
          status: {
            displayValue: (
              <div className="row-gap-12px">
                <div
                  className={`label-tag bigger ${getStatusColor(status)} m-0`}
                >
                  {status}
                </div>
                <Dropdown
                  onSelect={(e) =>
                    onDepartmentTableDropdownSelect(e, selectedSurvey)
                  }
                  style={{ marginLeft: "auto" }}
                >
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    className="no-caret"
                  >
                    <FontAwesomeIcon icon="ellipsis" />
                  </Dropdown.Toggle>
                  {getActionDropdownOptions(selectedSurvey)}
                </Dropdown>
              </div>
            ),
            sortValue: getStatusSortValue(status),
          },
          completionRate: getCompletionRateCell(
            completionRate,
            totalInvited,
            totalCompleted
          ),
          teamworkScore: getTeamworkScore(selectedSurvey),
        };
      });
    },
    [
      getInviteesCell,
      getCompletionRateCell,
      getTeamworkScore,
      onDepartmentTableDropdownSelect,
    ]
  );
  const getSurveysById = useCallback(() => {
    // If departmentId is passed in then we filter the selectedSurveys to only include the departmentId
    if (departmentId) {
      const departmentInfo = departments[departmentId];
      const departmentTeams = departmentInfo?.teams ?? [];

      return allTeamAssessmentInstances.filter((survey) => {
        const teamIds = getTeamIdsFromSurvey(survey);
        return (
          survey.departmentIds?.includes(departmentId) ||
          teamIds.some((teamId) => departmentTeams.includes(teamId))
        );
      });
      // If teamId is passed in then we filter the selectedSurveys to only include the teamId
    } else if (teamId) {
      return allTeamAssessmentInstances.filter((survey) => {
        const teamIds = getTeamIdsFromSurvey(survey);
        return teamIds.includes(teamId);
      });
    }

    return allTeamAssessmentInstances;
  }, [allTeamAssessmentInstances, teamId, departmentId, departments]);

  useEffect(() => {
    const selectedSurveys = getSurveysById();

    // If no search input no need to filter
    if (!searchInput) {
      return setFilteredRows(getRows(selectedSurveys));
    }

    const filteredSurveys: (AssessmentSeries | AssessmentInstance)[] = [];
    selectedSurveys.forEach((selectedSurvey) => {
      const {
        teams,
        departments: departmentSurveys,
        org,
      } = splitSurveys({
        selectedSurvey,
        searchTerm: searchInput,
        departments,
        teamInfoById,
      });
      if (org) {
        filteredSurveys.push(org);
      }
      filteredSurveys.push(...teams);
      filteredSurveys.push(...departmentSurveys);
    });

    setFilteredRows(getRows(filteredSurveys));
  }, [
    searchInput,
    allTeamAssessmentSeries,
    allTeamAssessmentInstances,
    getRows,
    teamInfoById,
    departments,
    teamId,
    departmentId,
    getSurveysById,
  ]);

  // ------------------------- Cell Getters ----------------------------------//
  const getActionDropdownOptions = (
    assessment: AssessmentSeries | AssessmentInstance
  ) => {
    const { status, departmentIds = [], teamIds = [], inviteOrg } = assessment;
    const showBreakdownItem =
      departmentIds.length || teamIds.length > 1 || inviteOrg ? (
        <>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="viewBreakdown">View Breakdown</Dropdown.Item>
        </>
      ) : null;

    const key = status.toLowerCase();
    switch (key) {
      case "active": {
        // Active
        const remindMembersItem =
          teamIds.length === 1 ? (
            <>
              <Dropdown.Divider />
              <Dropdown.Item eventKey="remindMembers">
                Remind Members
              </Dropdown.Item>
            </>
          ) : null;

        return (
          <Dropdown.Menu>
            <Dropdown.Item eventKey="viewResults">View Results</Dropdown.Item>
            {showBreakdownItem}
            {remindMembersItem}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="extendSurvey">
              Extend Survey Period
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="extendSurvey">
              Edit Survey Settings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="deactivateSurvey" className="danger">
              Deactivate Survey
            </Dropdown.Item>
          </Dropdown.Menu>
        );
      }
      case "upcoming": // Upcoming
        return (
          <Dropdown.Menu>
            <Dropdown.Item eventKey="extendSurvey">
              Edit Survey Settings
            </Dropdown.Item>
            {showBreakdownItem}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="deactivateSurvey" className="danger">
              Deactivate Survey
            </Dropdown.Item>
          </Dropdown.Menu>
        );
      case "past": // Past
        return (
          <Dropdown.Menu>
            <Dropdown.Item eventKey="extendSurvey">
              Extend Survey Period
            </Dropdown.Item>
            {showBreakdownItem}
            <Dropdown.Divider />
            <Dropdown.Item eventKey="viewResults">View Results</Dropdown.Item>
          </Dropdown.Menu>
        );
      default:
        return null;
    }
  };

  const getStartAndEndDate = (date?: string) => {
    if (!date) {
      return null;
    }
    return getStringDate(new Date(date));
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Active":
        return "green";
      case "Upcoming":
        return "yellow";
      case "Past":
      default:
        return "grey";
    }
  };

  const getStatusSortValue = (status: string) => {
    switch (status) {
      case "Active":
        return 1;
      case "Upcoming":
        return 2;
      case "Past":
      default:
        return 3;
    }
  };

  const getTableStructure = (): TableHeader[] => {
    const tableHeaders: TableHeader[] = [
      {
        key: "invitees",
        label: "Invitee(s)",
      },
      {
        key: "teamworkScore",
        label: "Teamwork Score",
        sortInverse: true,
      },
      {
        key: "completionRate",
        label: "Completion Rate",
        sortInverse: true,
      },
    ];

    return [
      ...tableHeaders,
      {
        key: "startDate",
        label: "Start Date",
        isDate: true,
      },
      {
        key: "endDate",
        label: "End Date",
        isDate: true,
      },
      {
        key: "status",
        label: "Status",
      },
    ];
  };

  const getEmptyCard = () => {
    if (
      getAssessmentInstancesStatus === "loading" ||
      displayedInput !== searchInput
    ) {
      return <Loading />;
    }

    if (filteredRows.length === 0) {
      return (
        <div
          className="empty-card"
          style={{
            padding: "32px",
            marginTop: "-10px",
          }}
        >
          <div className="column-gap-12px">
            <span>
              <b>
                {displayedInput
                  ? `No survey ${entityName} found matching "${displayedInput}"`
                  : `No survey ${entityName} have been launched yet`}
              </b>
            </span>
            <p>
              {displayedInput
                ? "Try searching by another team or department name"
                : `Active, past, and upcoming ${entityName} will be shown here once launched.`}
            </p>
          </div>
          {!displayedInput && emptyStateActions ? emptyStateActions : null}
        </div>
      );
    }
    return null;
  };

  const getDeactivateWarningModalText = () => {
    if (!showDeactivateWarningModal) {
      return "";
    }

    const appendedInstanceText =
      "Deactivating this survey will prevent any more members from completing this TEAMscan. If an insight report is available, results will include any responses collected so far.";

    if (!showDeactivateWarningModal.assessmentInstanceId) {
      return appendedInstanceText;
    }

    const currentAssessmentInfo =
      allTeamAssessmentInstancesEntities[
        showDeactivateWarningModal.assessmentInstanceId
      ];

    const daysRemaining = getDaysRemaining(currentAssessmentInfo?.endDate);
    const preText =
      daysRemaining && daysRemaining > 0
        ? `This survey closes in ${daysRemaining} days. `
        : "";

    return preText + appendedInstanceText;
  };

  const getDeactivateWarningExtraBody = () => {
    return (
      <div className="mt-2">
        <p>
          <b>Note:</b> This survey instance is part of series. Deactivating this
          instance will <b>not</b> deactivate the entire series.
        </p>
      </div>
    );
  };

  const getSearchInput = () => {
    const { selectStyles, components } = getSelectProps();
    return (
      <Form.Group>
        <Select
          placeholder="Search by team, department..."
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={displayedInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setDisplayedInput(e);
            }
          }}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <WarningModal
        modalTitle="Remind Team Members"
        warningTitle="Remind all team members who have not completed the TEAMscan?"
        warningMessage="Send out an email reminder to an team members who have not completed the TEAMscan survey for this instance."
        isOpen={showRemindWarningModal !== false}
        onConfirmClick={async () => {
          if (!showRemindWarningModal) return;
          await dispatch(
            sendReminder({
              reminderType: "remindUserToTakeAssessment",
              teamId: showRemindWarningModal,
            })
          );
          setShowRemindWarningModal(false);
        }}
        hideModal={() => {
          setShowRemindWarningModal(false);
        }}
        customButtonText="Send Reminder"
        isDanger={false}
      />
      <WarningModal
        modalTitle={`Deactivate Survey ${surveyTabSelected}`}
        warningTitle={`Are you sure you want to deactivate this TEAMscan ${entityName}`}
        warningMessage={getDeactivateWarningModalText()}
        isOpen={showDeactivateWarningModal !== false}
        extraBody={getDeactivateWarningExtraBody()}
        onConfirmClick={() => {
          if (!showDeactivateWarningModal) return;
          const seriesOrInstance =
            showDeactivateWarningModal?.assessmentInstanceId
              ? "Instance"
              : "Series";
          const teamIds = showDeactivateWarningModal?.assessmentInstanceId
            ? getTeamIdsFromSurvey(
                allTeamAssessmentInstancesEntities[
                  showDeactivateWarningModal.assessmentInstanceId
                ]
              )
            : [];

          dispatch(
            deactivateTeamScan({
              seriesOrInstance,
              id: showDeactivateWarningModal.assessmentInstanceId,
              teamIds,
            })
          );
          setShowDeactivateWarningModal(false);
        }}
        hideModal={() => {
          setShowDeactivateWarningModal(false);
        }}
        customButtonText={`Deactivate ${entityName}`}
        isDanger
      />
      <PickInstanceFromMultipleModal
        isOpen={showSelectMultiResultModal !== null}
        onClose={() => setShowSelectMultiResultModal(null)}
        onPickInstance={() => {}}
        teamIds={showSelectMultiResultModal?.teamIds}
        departmentIds={showSelectMultiResultModal?.departmentIds}
      />
      <EditSurveyModal
        onHide={() => {
          setExtendExpirationWarningModal(false);
        }}
        show={!!extendExpirationWarningModal}
        editingAssessmentId={
          extendExpirationWarningModal
            ? extendExpirationWarningModal
            : undefined
        }
      />
      <div className="column-gap-16px">{getSearchInput()}</div>
      {elementBetweenSearchAndTable ?? null}
      <div className="admin-console-table-container">
        <SortableTable
          rows={filteredRows}
          tableClassName="admin-console-table"
          columnHeaders={getTableStructure()}
        />
      </div>
      {getEmptyCard()}
    </>
  );
}
