import PeopleSearchDropdown from "app/components/Dropdowns/PeopleSearchDropdown";
import Button from "app/storybookComponents/Button";
import SimpleModal from "app/components/Modals/SimpleModal";
import { useEffect, useState } from "react";
import WarningBanner from "app/storybookComponents/WarningBanner";

interface Props {
  show?: boolean;
  onClose: () => void;
  title?: string;
  descriptionText?: string;
  onSave?: (userAccountIds: number[]) => void;
  defaultUserAccountIds?: number[] | null;
}

export default function WhoCanDoSomethingModal({
  show,
  onClose,
  title = "Who can do something",
  descriptionText,
  onSave,
  defaultUserAccountIds,
}: Readonly<Props>) {
  const [userAccountIds, setUserAccountIds] = useState<number[]>([]);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (defaultUserAccountIds) {
      setUserAccountIds(defaultUserAccountIds);
    }
  }, [defaultUserAccountIds]);

  const handleUserAccountIdsChange = (newUserAccountIds: number[]) => {
    setUserAccountIds(newUserAccountIds);
    setIsModified(true);
  };

  return (
    <SimpleModal show={show} onHide={onClose} title={title}>
      {descriptionText ? <p>{descriptionText}</p> : null}
      <PeopleSearchDropdown
        isMulti
        userAccountIds={userAccountIds}
        setUserAccountIds={handleUserAccountIdsChange}
      />
      {isModified && userAccountIds.length === 0 && (
        <WarningBanner>
          You must add at least one user to use a custom list.
        </WarningBanner>
      )}
      <div>
        <Button
          onClick={() => {
            onSave?.(userAccountIds);
            onClose();
          }}
          disabled={userAccountIds.length === 0}
        >
          Save
        </Button>
      </div>
    </SimpleModal>
  );
}
